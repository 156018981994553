const JP_WEEK_DAYS = ['日', '月', '火', '水', '木', '金', '土']

// regex string
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/
const ID_REGEX = /^[a-z0-9A-Z]+$/
const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
const NUMBER_ONLY_REGEX = /^[0-9\b]+$/
const FILTER_ITEMS = ['開始日', '終了日', '有効期限日', '受信日付', 'すべて']

const JAPANESE_CHECKER_REGEX =
  /[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g

const PORTAL_ACTIONS = ['CREATE', 'UPDATE', 'DELETE']

const NOT_ID_REGEX =
  /([\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B)|([\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E])/g

const FORM_KEYWORD = ['form', 'my-page', 'change-password', 'reissue', 'compliance']
const ACTION_FORM = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
}
const PAGE_LOGO = '情報配信サイト'
const PDF_STATUS = {
  PENDING: 'PENDING',
  CREATING: 'CREATING',
  DONE: 'DONE',
  ERROR: 'ERROR',
}
const FILE_PDF_ERROR = {
  // FILE_ERROR: '例外エラーが発生しました。システム管理者へ連絡してください。',
  // FILE_NO_SUCH_KEY: 'エラーが発生しました。ファイルが存在しません。',
  // OKB: 'エラーが発生しました。0件のファイルが存在します。',
  // FORMAT_ERROR: 'エラーが発生しました。フォーマット違いファイルが存在します。',
  FILE_NO_SUCH_KEY: {
    line1: 'エラーが発生しました。',
    line2: 'ファイルが存在しません。',
  },
  FILE_ERROR: {
    line1: '例外エラーが発生しました。',
    line2: 'システム管理者へ連絡してください。',
  },
  OKB: {
    line1: 'エラーが発生しました。',
    line2: '0件のファイルが存在します。',
  },
  FORMAT_ERROR: {
    line1: 'エラーが発生しました。',
    line2: 'フォーマット違いファイルが存在します。',
  },
}

const URL_CUST_USER = '/admin/custuser'

const KEY_CODE = {
  SPACE: 0,
}
const SCREEN_NAME = {
  CHANGE_PASSWORD: 'change_password',
  PORTAL_MASTER: 'maintainance',
  HEAD_QUARTER: 'headquarter',
  BRANCH: 'branch',
  CUSTOMER: 'customer',
  CUSTUSER: 'custuser',
  DONWLOAD_STATUS: 'download_status',
  EXCEL_TEMPLATE: 'excel_template',
  DISTRIBUTION_DATA: 'distribution_data',
  DELIVERY_DATA: 'delivery_data',
  MY_PAGE: 'mypage',
}

// const S3_BUCKET_URL = 'https://ordering-transfer-data.s3.ap-northeast-1.amazonaws.com/'
// const S3_NIGHT_FUNCTION_BUCKET_URL = 'https://ordering-transfer-nightfunction.s3.ap-northeast-1.amazonaws.com/'
const S3_TEMPLATE = 'template/'
const S3_TEMPLATE_FOLDER = 'template'
const S3_CSV = 'data_csv'
const S3_TEMP = 'temp/'
const S3_TEMP_FOLDER = 'temp'
const S3_OUTPUT = 'output/'
const S3_PDF_FOLDER = 'pdf'

const S3_DATA_NIGHT_FUNCTION_BUCKET = 'ordering-transfer-nightfunction'

const S3_RCV = 'receive/'
const accountDeleteMessageConfirmEdit = '「※削除済みのユーザー」'
const DATE_TYPE = {
  DEFAULT: 'DEFAULT',
  START: 'DT_START',
  END: 'DT_END',
  EXPIRED: 'DT_DEADL',
  DATE_RECEIVE: 'DT_RCV',
}

const SORT_TYPE = {
  INCREASE: 'INCREASE',
  DECREASE: 'DECREASE',
}

const PORTAL_TYPES = {
  NOTICE: '0',
  TOPIC: '1',
  LINK: '2',
}

const RESPONSE_TYPE = {
  SUCCESS: 0,
  DATA_NOT_EXIST: 1,
  DATA_EXISTED: 2,
  USER_IS_EXPIRED: 3,
  USER_IS_DELETED: 4,
  ERROR: 9,
}

const SPECIAL_CELL_TYPE = {
  EMAIL: 'EMAIL',
  URL: 'URL',
  FILE: 'FILE',
  PERSON: 'PERSON',
  DATE_START: 'DATE_START',
  DATE_END: 'DATE_END',
  DATE_DEAL: 'DATE_DEAL',
  CHECK: 'CHECK',
  SORTED_START: 'SORTED_START',
  SORTED_END: 'SORTED_END',
  MAIL_ADDRESS_1: 'MAIL_ADDRESS_1',
  MAIL_ADDRESS_2: 'MAIL_ADDRESS_2',
  HHMM: 'HHMM',
  NU_SIZE: 'NU_SIZE',
  NU_COUNT: 'NU_COUNT',
  PL_INFO: 'PL_INFO',
  ADD_INFO: 'ADD_INFO',
  PRODUCT_RCV_INFO: 'PRODUCT_RCV_INFO',
  REDIRECT_DELIVERY_DATA_DETAIL: 'REDIRECT_DELIVERY_DATA_DETAIL',
  DT_SEND_MAIL: 'DT_SEND_MAIL',
  DT_DL_MAIL: 'DT_DL_MAIL',
  PASSWORD: 'PASSWORD',
}

const ID_CELL_TYPE = ['ID_USER', 'CD_BRANCH', 'CD_CUST', 'CD_ALRG', 'ID_DATA', 'CD_KEY', 'ID_MAIL']

const CELL_TYPE_DATA_TRANSFER = {
  NU_WAIT: 'NU_WAIT',
  NU_ALERT: 'NU_ALERT',
  KB_CYCLE: 'KB_CYCLE',
  KB_FL_FORMAT: 'KB_FL_FORMAT',
  KB_DELETE: 'KB_DELETE',
  EXCEP_NUMBER: 'EXCEP_NUMBER',
  ID_USER: 'ID_USER',
}
const UNIT = {
  QUANTITY: '件',
  MINUTES: '分',
  DAILY: '日次',
  WEEKLY: '週次',
  MORE_THAN_ONCE: '複数回',
  TEXT: 'テキスト',
  PDF: 'PDF',
}

const DELIVERY_CYCLE = {
  DAILY: '1',
  WEEKLY: '2',
  MORE_THAN_ONCE: '3',
}

const FILE_FORMAT = {
  TEXT: '1',
  PDF: '2',
}

const TOOLBAR_TYPE = {
  PREVIEW: 'PREVIEW',
  ADD: 'ADD',
  SEARCH: 'SEARCH',
  SEARCH_LEFT: 'SEARCH_LEFT',
  FILTER_LOGO: 'FILTER_LOGO',
  FILTER_DATE: 'FILTER_DATE',
  CALENDAR: 'CALENDAR',
  TIME_LABEL: 'TIME_LABEL',
  CSV: 'CSV',
  EXPIRE_DATE: 'EXPIRE_DATE',
  COMPLIANCE_CHECKBOX: 'COMPLIANCE_CHECKBOX',
  APPROVAL_SELECT: 'APPROVAL_SELECT',
  HEAD_QUARTERS_CONFIRM_SELECT: 'HEAD_QUARTERS_CONFIRM_SELECT',
  SENDING_DATE: 'SENDING_DATE',
  FOLDER_SELECT: 'FOLDER_SELECT',
  DOWNLOAD: 'DOWNLOAD',
  CHECKBOX: 'CHECKBOX',
  CHECKBOX_DISABLE: 'CHECKBOX_DISABLE',
  TURN_BACK: 'TURN_BACK',
  YEAR_MONTH_CALENDAR: 'YEAR_MONTH_CALENDAR',
  WEEK_CALENDAR: 'WEEK_CALENDAR',
}

const CD_KEY = {
  SETSTART: 'SETSTART',
  PORTAL: 'PORTAL',
  KBDEL: 'KBDEL',
  FORMAT: 'FORMAT',
  CYCLE: 'CYCLE',
  APRVL: 'APRVL',
  CNFM: 'CNFM',
}

const CD_ALRG = {
  MIN: 1,
  MAX: 98,
}

const CD_LENGTH = {
  ALRG: 2,
}

const EXCEL_TEMPLATE_FORM = {
  TEMP_FILE: 'TEMPLATE_FILE',
  TEST_CSV_FILE: 'TEST_CSV_FILE',
}

const ALLERGEN_CLASSFICATION_CODE = {
  NOT_SET: '0',
  HAVE_ALLERGEN: '1',
  NOT_APPLY: '98',
  NO_ALLERGEN: '99',
}

const KONTAMI_CLASSFICATION_CODE = {
  NOT_SET: '0',
  HAVE_KONTAMI: '1',
  NOT_APPLY: '98',
  NO_KONTAMI: '99',
}

const FLAG_BOOLEAN = {
  TRUE: '1',
  FALSE: '0',
}

const MESSAGE_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}

const CHARACTER_WIDTH = 11.12
const RIGHT_LEFT_PADDING = 30
const BRANCH_CHARACTER = 3
const MAX_CHARACTER = 10
const TABLE_WIDTH = 900

export {
  accountDeleteMessageConfirmEdit,
  ACTION_FORM,
  ALLERGEN_CLASSFICATION_CODE,
  BRANCH_CHARACTER,
  CD_ALRG,
  CD_KEY,
  CD_LENGTH,
  CELL_TYPE_DATA_TRANSFER,
  CHARACTER_WIDTH,
  DATE_TYPE,
  DELIVERY_CYCLE,
  EMAIL_REGEX,
  EXCEL_TEMPLATE_FORM,
  FILE_FORMAT,
  FILE_PDF_ERROR,
  FILTER_ITEMS,
  FLAG_BOOLEAN,
  FORM_KEYWORD,
  ID_CELL_TYPE,
  ID_REGEX,
  JAPANESE_CHECKER_REGEX,
  JP_WEEK_DAYS,
  KEY_CODE,
  KONTAMI_CLASSFICATION_CODE,
  MAX_CHARACTER,
  MESSAGE_TYPE,
  NOT_ID_REGEX,
  NUMBER_ONLY_REGEX,
  PAGE_LOGO,
  PASSWORD_REGEX,
  PDF_STATUS,
  PORTAL_ACTIONS,
  PORTAL_TYPES,
  RESPONSE_TYPE,
  RIGHT_LEFT_PADDING,
  // S3_BUCKET_URL,
  S3_CSV,
  S3_DATA_NIGHT_FUNCTION_BUCKET,
  // S3_NIGHT_FUNCTION_BUCKET_URL,
  S3_OUTPUT,
  S3_PDF_FOLDER,
  S3_RCV,
  S3_TEMP,
  S3_TEMP_FOLDER,
  S3_TEMPLATE,
  S3_TEMPLATE_FOLDER,
  SCREEN_NAME,
  SORT_TYPE,
  SPECIAL_CELL_TYPE,
  TABLE_WIDTH,
  TOOLBAR_TYPE,
  UNIT,
  URL_CUST_USER,
}
