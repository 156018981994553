import { formatDateWithPadding } from 'commons/utilities'
import { EMAIL_REGEX, ID_REGEX, PASSWORD_REGEX } from 'constants/constant'

const hquserValidate = (idUser, email1, email2, deadl, DT_TODAY, i18n) => {
  const lblIdUser = i18n.t('custUser.lblIdUser')
  const today = formatDateWithPadding(new Date(DT_TODAY), 'yyyy-MM-dd')
  const messages = {}
  if (!ID_REGEX.test(idUser))
    messages.idUser = i18n.t('validate.headquarter.idUserInvalid', { idValue: idUser, fieldName: lblIdUser })
  if (!EMAIL_REGEX.test(email1)) messages.email1 = i18n.t('validate.headquarter.email1Invalid')
  if (email2 && typeof email2 !== undefined && !EMAIL_REGEX.test(email2)) {
    messages.email2 = i18n.t('validate.headquarter.email2Invalid')
  }
  if (email2 && typeof email2 !== undefined && email2 === email1) {
    messages.email2 = i18n.t('validate.headquarter.email2Duplicate')
  }
  if (!new Date(deadl)) messages.deadl = ' '
  if (deadl && !new Date(deadl).getTime()) messages.deadl = i18n.t('validate.headquarter.msgInvalidDateFormat')
  else if (new Date(deadl) < new Date(today)) messages.deadl = i18n.t('validate.headquarter.invalidDealdate')
  return messages
}

const changePasswordValidateCustUser = (newPassword, confirmPassword, i18n) => {
  const messages = {}

  if (!PASSWORD_REGEX.test(newPassword))
    messages.newPasswordError = i18n.t('validate.changePassword.msgErrorPasswordNew')
  else messages.newPasswordError = ''

  if (confirmPassword !== newPassword)
    messages.confirmPasswordError = i18n.t('validate.changePassword.msgErrorPasswordNotMatch')
  else messages.confirmPasswordError = ''

  return messages
}

const changePasswordValidateHeadQuarter = (newPassword, confirmPassword, i18n) => {
  const messages = {}

  if (!PASSWORD_REGEX.test(newPassword))
    messages.newPasswordError = i18n.t('validate.changePassword.msgErrorPasswordNew')
  else messages.newPasswordError = ''

  if (confirmPassword !== newPassword)
    messages.confirmPasswordError = i18n.t('validate.changePassword.msgErrorPasswordNotMatch')
  else messages.confirmPasswordError = ''

  return messages
}

export { changePasswordValidateCustUser, changePasswordValidateHeadQuarter, hquserValidate }
