import { EXCEL_TEMPLATE_FORM } from 'constants/constant'

export const excelTemplateValidate = () => {}

export const fileExtensionValidate = (file, sysInfo, fieldName, i18n) => {
  const messages = {}

  const onlySupportCSVExtenstion = {
    line1: i18n.t('formExcelTemplate.onlySupportCSVExtension.line1'),
    line2: i18n.t('formExcelTemplate.onlySupportCSVExtension.line2'),
  }

  const onlySupportXlsxExtension = {
    line1: i18n.t('formExcelTemplate.onlySupportXlsxExtension.line1'),
    line2: i18n.t('formExcelTemplate.onlySupportXlsxExtension.line2'),
  }
  if (file && file.name && fieldName && fieldName === EXCEL_TEMPLATE_FORM.TEMP_FILE) {
    const fileName = file.name
    if (!fileName.includes('.xlsx')) {
      messages.invalidTemplateFile = onlySupportXlsxExtension
    }
  }
  if (file && file.name && fieldName && fieldName === EXCEL_TEMPLATE_FORM.TEST_CSV_FILE) {
    const fileCSVName = file.name.toLowerCase()
    if (!fileCSVName.includes('.csv') && !fileCSVName.includes('.txt')) {
      messages.invalidTestCSVFile = onlySupportCSVExtenstion
    }
  }
  if (file && file.size / 1024 / 1024 > sysInfo.NU_UPMAXSZ) {
    messages.NU_UPMAXSZ = `「${file.name}」${i18n.t('validate.excelTemplate.errorFileMessage.NU_UPMAXSZ', {
      maxSize: sysInfo.NU_UPMAXSZ,
    })}`
  }
  return messages
}
