import { makeStyles } from '@material-ui/core/styles'
const styles = makeStyles((theme) => ({
  width50: {
    maxWidth: 403,
  },
  pb30: {
    paddingBottom: '30px',
  },
  pb50: {
    paddingBottom: '50px',
  },
  testPdfButtonContainer: {
    padding: '5px 0px 10px 47px',
  },
  labelCustomExcelTemplate: {
    whiteSpace: 'nowrap',
  },
  labelStatus: {
    marginLeft: '122px',
    marginRight: '40px',
    display: 'flex',
    alignItems: 'center',
  },
  reloadContainer: {
    display: 'flex',
    margin: '-5px 20px 5px 47px',
    alignItems: 'center',
  },
  reloadButtonContainer: {
    margin: '10px',
    marginLeft: '0px',
  },
  status: {
    margin: '15px 43px 15px 15px',
    marginLeft: '30px',
  },
  statusText: {
    fontSize: '12px',
    color: 'rgb(59, 56, 56)',
    minWidth: '38px',
  },
  download: {
    marginRight: '60px',
  },
  reloadButton: {
    backgroundColor: 'rgb(255, 255, 255)',
    width: '50px',
    height: '25px',
  },
  iconInfo: {
    color: 'rgb(85, 130, 50)',
    width: '20px',
    height: '20px',
  },
}))

export default styles
