import {
  CLEAR_REISSUE,
  UPDATE_REISSUE_FAILED,
  UPDATE_REISSUE_REQUEST,
  UPDATE_REISSUE_SUCCESS,
} from 'constants/actionTypes/reissueActionTypes.js'

const updateReissue = (state = { updateReissue: {} }, action) => {
  switch (action.type) {
    case UPDATE_REISSUE_REQUEST:
      return {
        ...state,
        loading: true,
        updateReissue: action.payload,
        type: UPDATE_REISSUE_REQUEST,
      }
    case UPDATE_REISSUE_SUCCESS:
      return {
        ...state,
        loading: false,
        updateReissue: { ...action.payload },
        type: UPDATE_REISSUE_SUCCESS,
        message: 'success',
      }
    case UPDATE_REISSUE_FAILED:
      return {
        ...state,
        loading: false,
        updateReissue: {},
        message: action.payload,
        type: UPDATE_REISSUE_FAILED,
      }
    case CLEAR_REISSUE:
      return { type: CLEAR_REISSUE, loading: false, status: null, REISSUE: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, REISSUE: {}, message: '' }
  }
}

export { updateReissue }
