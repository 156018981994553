export const GET_CUSTOMERS_LIST_REQUEST = 'GET_CUSTOMERS_LIST_REQUEST'
export const GET_CUSTOMERS_LIST_SUCCESS = 'GET_CUSTOMERS_LIST_SUCCESS'
export const GET_CUSTOMERS_LIST_FAILED = 'GET_CUSTOMERS_LIST_FAILED'
export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST'
export const CREATE_CUSTOMER_FAILED = 'CREATE_CUSTOMER_FAILED'
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS'
export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST'
export const UPDATE_CUSTOMER_FAILED = 'UPDATE_CUSTOMER_FAILED'
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS'
export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST'
export const DELETE_CUSTOMER_FAILED = 'DELETE_CUSTOMER_FAILED'
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS'
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER'
