import './ExcelTemplate.css'

import { clearExcelTemplate, deleteExcelTemplateAction, getExcelTemplateAction } from 'actions/excelTemplateActions'
import {
  dataSearchResult,
  deleteFileFromS3,
  formatDateWithPadding,
  formatExtension,
  formatString,
  getSessionInfo,
  getTitleModalConfirm,
  getUserKbAdmin,
} from 'commons/utilities'
import { MessageErrorDialog, ModalConfirm as DeleteResult, ModalConfirm, ProgressDialog } from 'components/commons'
import TableCommon from 'components/commons/Table/TableCommon'
import ToolBar from 'components/commons/ToolBar/ToolBar'
import {
  DELETE_EXCEL_TEMPLATE_FAILED,
  DELETE_EXCEL_TEMPLATE_REQUEST,
  DELETE_EXCEL_TEMPLATE_SUCCESS,
  GET_EXCEL_TEMPLATE_LIST_FAILED,
  GET_EXCEL_TEMPLATE_LIST_REQUEST,
  GET_EXCEL_TEMPLATE_LIST_SUCCESS,
} from 'constants/actionTypes/excelTemplateActionTypes'
import {
  ACTION_FORM,
  FILTER_ITEMS,
  FLAG_BOOLEAN,
  MESSAGE_TYPE,
  // S3_CSV,
  // S3_OUTPUT,
  // S3_PDF_FOLDER,
  // S3_TEMPLATE_FOLDER,
  SCREEN_NAME,
  TOOLBAR_TYPE,
} from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
function ExcelTemplate() {
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return null
  })
  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const { t } = useTranslation()
  // const classes = styles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [listData, setListData] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [dateFilter, setDateFilter] = useState(DT_TODAY)
  // const [searchData, setSearchData] = useState([])
  const [filterItem, setFilterItem] = useState(FILTER_ITEMS[1])
  const [filterData, setFilterData] = useState([])
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState()
  const [recordDelete, setRecordDelete] = useState()

  const listExcelTemplateReducer = useSelector((state) => state.listExcelTemplate || {})
  const excelTemplateDeleteReducer = useSelector((state) => state.deleteExcelTemplate || {})
  const filteredFields = ['ID_TEMP', 'NM_TEMP']
  const [notExistFileInS3Error, setNotExistFileInS3Error] = useState('')

  const [deletePermissionMessageError, setDeletePermissionMessageError] = useState('')

  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false)
  const [deletePopUp, setDeletePopUp] = useState(false)
  const [deleteMessageType, setDeleteMessageType] = useState('')
  const handleSearchInput = (event) => {
    event.preventDefault()
    setFilterData(dataSearchResult(listData, filteredFields, searchInput))
  }

  const handleDeleteOnClick = async (data) => {
    const kbAdmin = await getUserKbAdmin(userInfo)

    if (String(kbAdmin) === FLAG_BOOLEAN.FALSE) {
      setShowModalConfirmDelete(false)
      setDeletePermissionMessageError(t('commonComponent.noDeletePermission'))
    } else {
      setShowModalConfirmDelete(true)
      setRecordDelete(data)
    }
  }

  const deleteExcelTemplate = () => {
    setShowModalConfirmDelete(false)
    dispatch(deleteExcelTemplateAction(recordDelete))
    deleteFileFromS3(process.env.REACT_APP_S3_TEMPLATE_FOLDER + '/' + recordDelete.ID_TEMP)
    if (recordDelete.NM_TEST_CSV) {
      deleteFileFromS3(process.env.REACT_APP_S3_CSV + '/' + recordDelete.NM_TEST_CSV)
    }
    deleteFileFromS3(process.env.REACT_APP_S3_OUTPUT + recordDelete.ID_TEMP)
    deleteFileFromS3(process.env.REACT_APP_S3_PDF_FOLDER + '/' + formatExtension(recordDelete.ID_TEMP, 'xlsx', 'pdf'))
  }

  const handleDownloadFile = () => {}

  // handle edit portal
  const navigateToFormExcelTemplate = (id, data, type = ACTION_FORM.CREATE) => {
    history.push({
      pathname: '/admin/excel-template-form',
      search: `?${id ? `id=${id}&` : ''}type=${type}`,
      state: {
        type: type,
        data: data,
        searchInput: searchInput,
        screen: SCREEN_NAME.EXCEL_TEMPLATE,
      },
    })
  }

  useEffect(() => {
    const { searchInput, screen } = location?.state || {}
    if (searchInput && screen === SCREEN_NAME.EXCEL_TEMPLATE) {
      setSearchInput(searchInput)
    }
    dispatch(getExcelTemplateAction({}))
  }, [dispatch, location])

  useEffect(() => {
    if (listData.length >= 0 && searchInput === '') {
      setFilterData(listData)
    }
  }, [listData, searchInput])
  useEffect(() => {
    setDeletePopUp(false)
    if (listExcelTemplateReducer.listExcelTemplate.length >= 0) {
      let dataResponse = [...listExcelTemplateReducer.listExcelTemplate]
      setListData(dataResponse)
      if (searchInput !== '') {
        setFilterData(dataSearchResult(dataResponse, filteredFields, searchInput))
      }
    }

    setLoading(listExcelTemplateReducer.loading || excelTemplateDeleteReducer.loading)
    switch (listExcelTemplateReducer.type) {
      case GET_EXCEL_TEMPLATE_LIST_REQUEST:
        break
      case GET_EXCEL_TEMPLATE_LIST_FAILED:
        if (!getSessionInfo()) {
          listExcelTemplateReducer.message && setMessageError(listExcelTemplateReducer.message)
        }

        break
      case GET_EXCEL_TEMPLATE_LIST_SUCCESS:
        break
      default:
        return
    }
  }, [listExcelTemplateReducer])

  useEffect(() => {
    setLoading(listExcelTemplateReducer.loading || excelTemplateDeleteReducer.loading)
    switch (excelTemplateDeleteReducer.type) {
      case DELETE_EXCEL_TEMPLATE_REQUEST:
        break
      case DELETE_EXCEL_TEMPLATE_SUCCESS:
        setDeletePopUp(true)
        setDeleteMessageType(MESSAGE_TYPE.SUCCESS)
        break
      case DELETE_EXCEL_TEMPLATE_FAILED:
        if (!getSessionInfo()) {
          setDeletePopUp(true)
          setDeleteMessageType(MESSAGE_TYPE.ERROR)
          setMessageError(excelTemplateDeleteReducer.message)
        }
        break
      default:
        return
    }
  }, [excelTemplateDeleteReducer])

  const headersCsv = [
    { label: t('excelTemplate.lblIDTemplate'), key: 'ID_TEMP' },
    { label: t('excelTemplate.lblNmTemplate'), key: 'NM_TEMP' },
  ]
  const headerCsvLabel = headersCsv.map((data) => data.label)
  const headerCsvKey = headersCsv.map((data) => data.key)

  const listColumnWidth = ['94px', '403px', '403px']

  const listCellHeader = [
    { columnName: '', commonStyle: 'titleHead', cellStyle: '' },
    { columnName: 'excelTemplate.lblIDTemplate', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'excelTemplate.lblNmTemplate', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
  ]

  const listCellBody = [
    { keyValue: 'ID_TEMP', cellStyle: 'data', gridStyle: 'centerAlignHq' },
    { keyValue: 'NM_TEMP', cellStyle: 'data', gridStyle: 'centerAlignHq' },
  ]

  const listToolBarComponent = [
    { type: TOOLBAR_TYPE.ADD, commonStyle: 'createContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.FILTER_LOGO, commonStyle: 'filterIconContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.SEARCH, commonStyle: 'searchContainer', componentStyle: 'search-item' },
    { type: TOOLBAR_TYPE.CSV, commonStyle: '', componentStyle: '' },
  ]
  let setDownloadParams = {
    screenName: 'excel-template',
    searchText: searchInput,
  }

  const toolbar = (
    <ToolBar
      listToolBarComponent={listToolBarComponent}
      handleCreateOnclick={navigateToFormExcelTemplate}
      setSearchInput={setSearchInput}
      searchInput={searchInput}
      handleSubmit={handleSearchInput}
      setSelectedDate={setDateFilter}
      selectedDate={dateFilter}
      headerCsvLabel={headerCsvLabel}
      headerCsvKey={headerCsvKey}
      fileCsvName={t('excelTemplate.fileNameDownload')}
      listData={listData}
      setFilterItem={setFilterItem}
      filterItem={filterItem}
      csvButtonStyle={true}
      toolbarWidth={'SMALL'}
      downloadParams={setDownloadParams}
    />
  )

  const msgDeleteConfirm =
    recordDelete && recordDelete?.ID_TEMP?.length < 21
      ? getTitleModalConfirm(
          t('excelTemplate.msgDeleteConfirm1.line1', {
            idTemplateValue: recordDelete?.ID_TEMP,
          }),
          t('excelTemplate.msgDeleteConfirm1.line2')
        )
      : getTitleModalConfirm(
          t('excelTemplate.msgDeleteConfirm2.line1', {
            idTemplateValue: formatString(recordDelete?.ID_TEMP, 29),
          }),
          t('excelTemplate.msgDeleteConfirm2.line2'),
          t('excelTemplate.msgDeleteConfirm2.line3')
        )

  const handleConfirmDeleteResult = () => {
    setRecordDelete(null)
    setDeletePopUp(false)
    dispatch(getExcelTemplateAction())
    dispatch(clearExcelTemplate())
  }

  return (
    <div className="excel-template__wrapper excel-template__maintain-wrapper">
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {notExistFileInS3Error && (
        <MessageErrorDialog message={notExistFileInS3Error} showMessageErrorDialog={setNotExistFileInS3Error} />
      )}
      {deletePermissionMessageError && (
        <MessageErrorDialog
          message={deletePermissionMessageError}
          showMessageErrorDialog={setDeletePermissionMessageError}
        />
      )}
      {loading && (
        <ProgressDialog label={recordDelete ? t('excelTemplate.msgDeleteData') : t('excelTemplate.loading')} />
      )}
      <ModalConfirm
        title={msgDeleteConfirm}
        showModal={showModalConfirmDelete}
        setShowModal={setShowModalConfirmDelete}
        handleFunction={deleteExcelTemplate}
      />
      <DeleteResult
        title={
          deleteMessageType === MESSAGE_TYPE.SUCCESS
            ? t('excelTemplate.deleteDataSuccess')
            : t('excelTemplate.deleteDataError')
        }
        showModal={deletePopUp}
        handleFunction={handleConfirmDeleteResult}
        screen={'excelTemplate'}
      />
      {loading ? (
        <ProgressDialog label={recordDelete ? t('excelTemplate.msgDeleteData') : t('excelTemplate.loading')} />
      ) : (
        <div className="excel-template__container maintain-container">
          {/* Table */}
          <TableCommon
            tableWidth={'SMALL'}
            listColumnWidth={listColumnWidth}
            listCellHeader={listCellHeader}
            listCellBody={listCellBody}
            listDataRender={filterData}
            handleUpdate={navigateToFormExcelTemplate}
            canDownloadFile={true}
            handleDownloadFile={handleDownloadFile}
            idProperty={'ID_TEMP'}
            handleDelete={handleDeleteOnClick}
            setNotExistFileInS3Error={setNotExistFileInS3Error}
            toolbar={toolbar}
          />
        </div>
      )}
    </div>
  )
}

export default ExcelTemplate
