import './FormDistributionData.css'

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { CheckSharp } from '@material-ui/icons'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import DescriptionIcon from '@material-ui/icons/Description'
import FolderIcon from '@material-ui/icons/Folder'
import InfoIcon from '@material-ui/icons/Info'
import ListAltIcon from '@material-ui/icons/ListAlt'
import getCodeInfo from 'actions/codeActions'
import { getCustomerNameAction } from 'actions/custUserActions'
import { clearCustUser } from 'actions/custUserActions'
import {
  clearDistributionData,
  createDistributionDataAction,
  updateDistributionDataAction,
} from 'actions/distributionDataActions'
import { IMAGES } from 'assets'
import axios from 'commons/baseUrl'
import { formatDateWithPadding, getSessionInfo, getTitleModalConfirm, getUserKbAdmin } from 'commons/utilities'
import { Image } from 'components/commons'
import {
  CustomCheckbox,
  FormInputs,
  MessageErrorDialog,
  ModalConfirm,
  ModalConfirmNavigation,
  ProgressDialog,
  SelectInput,
} from 'components/commons'
import Information from 'components/commons/Information/Information'
import { GET_CUSTOMER_NAME_FAILED, GET_CUSTOMER_NAME_SUCCESS } from 'constants/actionTypes/custUserActionTypes'
import {
  CREATE_DISTRIBUTION_DATA_FAILED,
  CREATE_DISTRIBUTION_DATA_REQUEST,
  CREATE_DISTRIBUTION_DATA_SUCCESS,
  UPDATE_DISTRIBUTION_DATA_FAILED,
  UPDATE_DISTRIBUTION_DATA_REQUEST,
  UPDATE_DISTRIBUTION_DATA_SUCCESS,
} from 'constants/actionTypes/distributionDataActionTypes'
import { API_GET_EXCEPTION_TEMPLATE } from 'constants/apiPath'
import { ACTION_FORM, CD_KEY, FLAG_BOOLEAN, SCREEN_NAME } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import styles from './FormDistributionDataStyle'

function FormDistributionData() {
  const [messageError, setMessageError] = useState(false)
  const codeInfo = useSelector((state) => state.codeInfo.code)
  // const listExcelTemplateReducer = useSelector((state) => state.listExcelTemplate.listExcelTemplate)
  // const [listExcelTemplateReducer, setListExcelTemplateReducer] = useState([])
  const dispatch = useDispatch()
  const [canSubmit, setCanSubmit] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const classes = styles()
  const { i18n, t } = useTranslation()
  const [dropDownType, setDropDownType] = useState({})
  const [listDefaultTemplateDropDown, setListDefaultTemplateDropDown] = useState([])
  const [listExceptionTemplateDropDown, setListExceptionTemplateDropDown] = useState([])
  const location = useLocation()
  const history = useHistory()
  const [type, setType] = useState('CREATE')
  const [isBlocked, setIsBlocked] = useState(false)
  const [showOutputStartDropDown, setShowOutputStartDropDown] = useState(false)
  const [showDefaultTemplateDropDown, setShowDefaultTemplateDropDown] = useState(false)
  const createDistributionDataReducer = useSelector((state) => state.createDistributionData || {})
  const updateDistributionDataReducer = useSelector((state) => state.updateDistributionData || {})
  const [loading, setLoading] = useState(false)
  const [nameTemplate, setNameTemplate] = useState('')
  const [showModalAccountTemplate, setShowModalAccountTemplate] = useState(false)
  const [modalCustomerCode, setModalCustomerCode] = useState('')
  const [canModalSubmit, setCanModalSubmit] = useState(false)
  const [showModalAccountConfirm, setShowModalAccountConfirm] = useState(false)
  const [showModalDeleteCustomerConfirm, setShowModalDeleteCustomerConfirm] = useState(false)
  const getCustomerName = useSelector((state) => state.getCustomerName || {})
  const [error, setError] = useState({})
  const [idTemplate, setIdTemplate] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [customerDelete, setCustomerDelete] = useState({})
  const [onFocusCustomerId, setOnFocusCustomerId] = useState(true)
  const [listCustomerDataRender, setListCustomerDataRender] = useState([
    {
      cd_cust: '',
      cust_name: '',
      id_temp: '',
    },
  ])
  const [exceptionTemplate, setExceptionTemplate] = useState('')
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return {}
  })
  const [dataForm, setDataForm] = useState({
    DT_REGIST: '',
    DT_UPDATE: '',
    DT_DELETE: '',
    ID_DATA: '',
    ID_FOLDER: '',
    KB_CYCLE: '',
    KB_DELETE: '0',
    KB_FL_FORMAT: '',
    KB_OUTPUT_ST: '',
    KB_TITLE_LN: '0',
    KB_TITLE_OUT: '0',
    NM_DATA: '',
    NM_DEF_TMP: '',
    NM_DISP_FLD: '',
    NM_RUDUSER: '',
    NM_USER: '',
    NU_ALERT: null,
    NU_WAIT: null,
  })
  const [listDataExcelTemplateDelete, setListDataExcelTemplateDelete] = useState([])
  const [initialCustomerData, setInitialCustomerData] = useState([])
  const [searchInput, setSearchInput] = useState('')

  let typeResult = {
    CYCLE: [],
    FORMAT: [],
    KBDEL: [],
    SETSTART: [],
  }
  const [createPermissionMessageError, setCreatePermissionMessageError] = useState('')
  const [updatePermissionMessageError, setUpdatePermissionMessageError] = useState('')

  // submit data
  const handleSubmit = async (e) => {
    e.preventDefault()
    const kbAdmin = await getUserKbAdmin(userInfo)
    if (String(kbAdmin) === FLAG_BOOLEAN.FALSE) {
      if (type === ACTION_FORM.CREATE) {
        setCreatePermissionMessageError(t('commonComponent.noCreatePermission'))
      }
      if (type === ACTION_FORM.UPDATE) {
        setUpdatePermissionMessageError(t('commonComponent.noUpdatePermission'))
      }
    } else {
      setShowModal(true)
    }
  }

  useEffect(() => {
    dispatch(getCodeInfo())
  }, [dispatch])

  useEffect(() => {
    setLoading(createDistributionDataReducer.loading || updateDistributionDataReducer.loading)
    setIsBlocked(false)
    if (showModalAccountTemplate) setIsBlocked(true)
    switch (createDistributionDataReducer.type) {
      case CREATE_DISTRIBUTION_DATA_REQUEST:
        break
      case CREATE_DISTRIBUTION_DATA_FAILED:
        if (!getSessionInfo()) {
          setMessageError(createDistributionDataReducer.message)
        }
        break
      case CREATE_DISTRIBUTION_DATA_SUCCESS:
        history.push({
          pathname: '/admin/distribution-data',
          state: {
            searchInput: searchInput,
            screen: SCREEN_NAME.DISTRIBUTION_DATA,
          },
        })
        dispatch(clearDistributionData())
        break
      default:
        break
    }
    switch (updateDistributionDataReducer.type) {
      case UPDATE_DISTRIBUTION_DATA_REQUEST:
        break
      case UPDATE_DISTRIBUTION_DATA_FAILED:
        if (!getSessionInfo()) {
          setMessageError(updateDistributionDataReducer.message)
        }
        break
      case UPDATE_DISTRIBUTION_DATA_SUCCESS:
        history.push({
          pathname: '/admin/distribution-data',
          state: {
            searchInput: searchInput,
            screen: SCREEN_NAME.DISTRIBUTION_DATA,
          },
        })
        dispatch(clearDistributionData())

        break
      default:
        break
    }
  }, [createDistributionDataReducer, updateDistributionDataReducer, history, dispatch])

  useEffect(() => {
    if (getCustomerName.type === GET_CUSTOMER_NAME_SUCCESS) {
      if (getCustomerName.customerName.length > 0) {
        setCustomerName(getCustomerName.customerName[0].NM_CUST)
        setError({})
        // setCanModalSubmit(true)
      } else {
        setCustomerName('')
        setError({ cd_cust: i18n.t('validate.distribution.cdCustInvalid') })
      }
    }
    if (getCustomerName.type === GET_CUSTOMER_NAME_FAILED) {
      setCustomerName('')
      setError({ cd_cust: i18n.t('validate.distribution.cdCustInvalid') })
      setCanModalSubmit(false)
    }
  }, [getCustomerName])

  const checkCustomerExist = (customer = {}, list = []) => {
    let canInsertCustomer = true
    for (let item of list) {
      if (item['cd_cust'] === customer['cd_cust']) {
        canInsertCustomer = false
        break
      }
    }
    if (canInsertCustomer === false) {
      setShowModalAccountTemplate(true)
      setMessageError(getTitleModalConfirm(i18n.t('validate.distribution.accountHasExisted')))
    } else {
      setListCustomerDataRender([...list, { ...customer }])
      setCanSubmit(true)
    }
  }
  const addCustomer = (customer = {}) => {
    let customerRow = {
      cd_cust: customer?.CD_CUST,
      cust_name: customer?.NM_CUST,
      id_temp: idTemplate,
    }
    const listCustomer = [...listCustomerDataRender, { ...customerRow }]
    checkCustomerExist(customerRow, listCustomerDataRender)
    if (type === ACTION_FORM.UPDATE) {
      let deleteCustomerList = initialCustomerData.filter(
        (x) => listCustomer.find((el) => el.cd_cust === x.cd_cust && el.id_temp === x.id_temp) === undefined
      )
      setListDataExcelTemplateDelete(deleteCustomerList)
    }
  }

  const deleteCustomer = () => {
    let listDefaultCustomerData = [...listCustomerDataRender]
    let customerIndex = listCustomerDataRender.findIndex(
      (customer) => customer.cd_cust === customerDelete.cd_cust && customer.id_temp === customerDelete.id_temp
    )
    if (customerIndex > -1) {
      listDefaultCustomerData.splice(customerIndex, 1)
    }
    if (type === ACTION_FORM.UPDATE) {
      let deleteCustomerList = initialCustomerData.filter((x) => !listDefaultCustomerData.includes(x))
      setListDataExcelTemplateDelete(deleteCustomerList)
    }
    setListCustomerDataRender(listDefaultCustomerData)
    setCanSubmit(true)
    setShowModalDeleteCustomerConfirm(false)
    setCustomerDelete({})
  }

  const checkRequire = React.useCallback(() => {
    if (
      !dataForm.ID_DATA ||
      !dataForm.NM_DATA ||
      !dataForm.ID_FOLDER ||
      !dataForm.NM_DISP_FLD ||
      !dataForm.KB_CYCLE ||
      dataForm.NU_WAIT === null ||
      !String(dataForm.NU_WAIT).length ||
      dataForm.NU_ALERT === null ||
      !String(dataForm.NU_ALERT).length ||
      !dataForm.KB_FL_FORMAT
    ) {
      return false
    }
    if (dataForm.KB_FL_FORMAT === '1' && dataForm.KB_OUTPUT_ST === '') {
      return false
    }
    if (dataForm.KB_FL_FORMAT === '2' && dataForm.NM_DEF_TMP === '') {
      return false
    }
    if (showModalAccountTemplate) {
      if (!modalCustomerCode || !idTemplate) {
        return false
      }
    }

    return isBlocked
  }, [dataForm, isBlocked])

  useEffect(() => {
    if (!checkRequire()) {
      setCanSubmit(false)
    } else {
      setCanSubmit(true)
    }
  }, [dataForm, checkRequire])

  const checkModalRequire = React.useCallback(() => {
    if (!exceptionTemplate || !modalCustomerCode || !customerName) return false
    else return isBlocked
  }, [exceptionTemplate, modalCustomerCode, customerName, isBlocked])

  useEffect(() => {
    if (checkModalRequire() && Object.keys(error).length === 0) {
      setCanModalSubmit(true)
    } else {
      setCanModalSubmit(false)
    }
  }, [error, checkModalRequire])

  useEffect(() => {
    const { data, type, listDefaultTemplateDropDown = [], searchInput } = location?.state || {}

    if (searchInput) {
      setSearchInput(searchInput)
    }
    if (type) setType(type)
    if (listDefaultTemplateDropDown.length > 0 && !data) {
      setListDefaultTemplateDropDown(listDefaultTemplateDropDown)
      setDataForm({
        ...dataForm,
        NM_RUDUSER: userInfo.NM_USER,
      })
    }
    if (listDefaultTemplateDropDown.length > 0 && data) {
      const defaultTemplateValue =
        listDefaultTemplateDropDown.find((el) => String(el.label) === String(data.NM_DEF_TMP))?.value || ''
      setListDefaultTemplateDropDown(listDefaultTemplateDropDown)
      setDataForm({
        ...data,
        NM_DEF_TMP: defaultTemplateValue,
        DT_REGIST: data?.DT_REGIST || '',
        DT_UPDATE: data?.DT_UPDATE || '',
        DT_DELETE: data?.DT_DELETE || '',
        KB_OUTPUT_ST: data.hasOwnProperty('KB_OUTPUT_ST') ? data.KB_OUTPUT_ST : '',
      })
      setNameTemplate(listDefaultTemplateDropDown.find((el) => el.value === defaultTemplateValue)?.name || '')
    }
  }, [location])

  useEffect(() => {
    if (type === ACTION_FORM.UPDATE) {
      let params = {
        ID_DATA: dataForm.ID_DATA,
      }
      setLoading(true)
      axios
        .get(API_GET_EXCEPTION_TEMPLATE, { params })
        .then((res) => {
          if (res?.data?.data?.length > 0) {
            let customerData = res?.data?.data.map((el) => ({
              cd_cust: el.CD_CUST,
              cust_name: el.NM_CUST,
              id_temp: el.ID_TEMP,
            }))
            setListCustomerDataRender([...listCustomerDataRender, ...customerData])
            setInitialCustomerData([...customerData])
          }
        })
        .finally(() => {
          setLoading(false)
        })
        .catch((error) => {})
    }
  }, [type])

  useEffect(() => {
    if (codeInfo.length > 0) {
      codeInfo.forEach((code) => {
        switch (code.CD_KEY) {
          case CD_KEY.CYCLE:
            getDropDownValue(CD_KEY.CYCLE, code)
            break
          case CD_KEY.FORMAT:
            getDropDownValue(CD_KEY.FORMAT, code)
            break

          case CD_KEY.SETSTART:
            getDropDownValue(CD_KEY.SETSTART, code)
            break

          case CD_KEY.KBDEL:
            getDropDownValue(CD_KEY.KBDEL, code)
            break
          default:
            break
        }
      })
    }
    setDropDownType(typeResult)
  }, [codeInfo])

  const getDropDownValue = (key = '', code = {}) => {
    Object.keys(code)
      .filter((el) => el.startsWith('MP_ITEM'))
      .forEach((mp) => {
        typeResult[key].push({ value: code[mp].CD_VALUE, label: code[mp].NM_VALUE })
      })
  }

  const handleUpdate = (name, value) => {
    setIsBlocked(true)
    if (name === 'KB_TITLE_LN') {
      setDataForm((prev) => ({ ...prev, KB_TITLE_LN: (value + 0).toString() }))
      return
    }
    if (name === 'KB_TITLE_OUT') {
      setDataForm((prev) => ({ ...prev, KB_TITLE_OUT: (value + 0).toString() }))
      return
    }
    if (name === 'KB_FL_FORMAT') {
      if (value === '1') {
        setShowDefaultTemplateDropDown(false)
        setShowOutputStartDropDown(true)
      }
      if (value === '2') {
        setShowDefaultTemplateDropDown(true)
        setShowOutputStartDropDown(false)
      }
    }
    if (name === 'NM_DEF_TMP') {
      setNameTemplate(listDefaultTemplateDropDown.find((el) => el.value === value).name)
      setListExceptionTemplateDropDown(listDefaultTemplateDropDown.filter((el) => String(el.value) !== String(value)))
    }

    setDataForm((prev) => ({ ...prev, [name]: value }))
  }

  const handleUpdateModal = (name, value) => {
    if (name === 'CD_CUST') {
      setModalCustomerCode(value)
      setError({})
      setCustomerName('')
    }
    if (name === 'NM_EXCEP_TMP') {
      setExceptionTemplate(value)
      setIdTemplate(listExceptionTemplateDropDown.find((el) => String(el.value) === String(value)).label)
    }
  }

  // clear and exit
  const handleCancelButton = () => {
    history.push({
      pathname: '/admin/distribution-data',
      state: {
        searchInput: searchInput,
        screen: SCREEN_NAME.DISTRIBUTION_DATA,
      },
    })
  }

  const getTitleModal = (type, dataForm) => {
    const formType =
      type === ACTION_FORM.CREATE ? t('commonComponent.form.createButton') : t('commonComponent.form.updateButton')
    return getTitleModalConfirm(
      t('formDistribution.msgConfirmSubmission.line1', {
        lblIdData: t('formDistribution.lblDataId'),
        idDataValue: dataForm.ID_DATA,
        formType: formType,
      }),
      t('formDistribution.msgConfirmSubmission.line2')
    )
  }

  const getTitleModalDeleteCustomer = () => {
    return getTitleModalConfirm(
      t('formDistribution.msgDeleteCustomerConfirm.line1', {
        lblCustomerCode: customerDelete?.cd_cust,
        lblCustomerName: customerDelete?.cust_name,
      }),
      t('formDistribution.msgDeleteCustomerConfirm.line2')
    )
  }

  const getObjUpdate = (submitData, data) => {
    let result = {
      KY_PARTS: submitData.ID_DATA + data.cd_cust,
      ID_DATA: submitData.ID_DATA,
      CD_CUST: data.cd_cust,
      ID_TEMP: data.id_temp,
      NM_USER: userInfo.NM_USER,
      DT_REGIST: '',
      DT_UPDATE: '',
      NM_RUDUSER: userInfo.NM_USER,
    }
    return result
  }

  const submitData = () => {
    const submitData = {
      ...dataForm,
      NU_WAIT: Number(dataForm.NU_WAIT),
      NU_ALERT: Number(dataForm.NU_ALERT),
      NM_RUDUSER: userInfo.NM_USER,
      NM_DEF_TMP: String(
        listDefaultTemplateDropDown.find((el) => String(el.value) === String(dataForm.NM_DEF_TMP))?.label || ''
      ),
    }
    const data_excel_template = []
    const data_template_delete = []
    listCustomerDataRender.slice(1).forEach((data) => {
      data_excel_template.push(getObjUpdate(submitData, data))
    })

    listDataExcelTemplateDelete.forEach((data) => {
      data_template_delete.push(getObjUpdate(submitData, data))
    })

    if (type === ACTION_FORM.CREATE) dispatch(createDistributionDataAction(submitData, data_excel_template, i18n))
    if (type === ACTION_FORM.UPDATE)
      dispatch(updateDistributionDataAction(submitData, data_excel_template, data_template_delete))
    setIsBlocked(false)
    setShowModal(false)
  }

  const handleAddAccountTemplate = () => {
    setError({})
    setExceptionTemplate('')
    setModalCustomerCode('')
    setCustomerName('')
    setListExceptionTemplateDropDown(
      listDefaultTemplateDropDown.filter((el) => String(el.value) !== String(dataForm.NM_DEF_TMP))
    )
    if (dataForm.NM_DEF_TMP !== '') {
      setShowModalAccountTemplate(true)
      return
    }
  }

  const handleCloseModalAccountTemplate = () => {
    setShowModalAccountTemplate(false)
    setModalCustomerCode('')
  }

  const handleModalSubmit = React.useCallback(
    (e) => {
      e.preventDefault()
      if (customerName && Object.keys(error).length === 0) {
        // setError({ cd_cust: i18n.t('validate.distribution.cdCustInvalid') })
        // setCanModalSubmit(false)
        setError({})
        setShowModalAccountConfirm(true)
        setCanModalSubmit(true)
      }
    },
    [customerName]
  )

  const handleCancelAccountModal = () => {
    setShowModalAccountTemplate(false)
    setModalCustomerCode('')
    setExceptionTemplate('')
    setError({})
  }
  useEffect(() => {
    if (!onFocusCustomerId) {
      dispatch(getCustomerNameAction({ customerID: modalCustomerCode }))
    } else {
      setCustomerName('')
    }
    setOnFocusCustomerId(true)
    dispatch(clearCustUser())
  }, [onFocusCustomerId, dispatch])

  const formAccoutTempalte = (
    <div className="form-account-template">
      <div className="form-account-cd-cust">
        {/*  CD_CUST */}
        <FormInputs
          id="standard-basic"
          label={t('formDistribution.lblCdCust')}
          required
          icon={<CheckSharp className="icon--deactive" />}
          type="text"
          name="CD_CUST"
          className="width200"
          value={modalCustomerCode || ''}
          setValue={(e) => handleUpdateModal('CD_CUST', e)}
          error={error && !!error.cd_cust}
          helperText={error && error.cd_cust ? error.cd_cust : null}
          errorPosition={'100px'}
          errorTopPosition={'-10%'}
          setOnFocusCustomerId={setOnFocusCustomerId}
          inputProps={{ style: { fontSize: 12 } }}
        />
        <div className="nm-cust-container">{customerName}</div>
      </div>
      <div className="form-template-container">
        <div className="form-template-icon">
          <DescriptionIcon className="icon--deactive form-template-icon" />
        </div>
        <div className="form-template-select">
          <SelectInput
            label={t('formDistribution.lblTableCustomerTemplate')}
            required
            name="NM_EXCEP_TMP"
            defaultIcon={false}
            className={`textDrop ${classes.dropdownMessage} ${classes.formTemplate}`}
            items={listExceptionTemplateDropDown}
            value={exceptionTemplate || ''}
            onChange={(e) => handleUpdateModal('NM_EXCEP_TMP', e.target.value)}
            inputProps={{ style: { fontSize: 12 } }}
          />
        </div>
      </div>

      {/* Button */}
      <div className="buttonSpace button-modal-account">
        <Button
          className="button submit-button"
          type="submit"
          variant="contained"
          onClick={handleModalSubmit}
          disabled={!canModalSubmit}
        >
          {t('formHeadQuarter.btnSubmit')}
        </Button>
        <Button className="button clear-button" variant="outlined" onClick={handleCancelAccountModal}>
          {t('formHeadQuarter.btnCancel')}
        </Button>
      </div>
    </div>
  )

  const handleSubmitAccountTemplate = () => {
    setShowModalAccountConfirm(false)
    if (customerName.length > 0) {
      setError({})
      setShowModalAccountConfirm(false)
      setShowModalAccountTemplate(false)
      addCustomer(getCustomerName?.customerName[0])
      setCustomerName('')
      setCanModalSubmit(true)
    } else {
      setCanModalSubmit(false)
      setError({ cd_cust: i18n.t('validate.distribution.cdCustInvalid') })
    }
  }

  const handleCloseAccountTemplate = () => {
    setShowModalAccountConfirm(false)
    setCanModalSubmit(true)
  }

  const modalAccountTemplate = (
    <Dialog
      open={showModalAccountTemplate}
      onClose={handleCloseModalAccountTemplate}
      disableBackdropClick
      disableEscapeKeyDown
      aria-labelledby="scroll-dialog-title"
      className="modal-account-template"
    >
      <DialogTitle id="scroll-dialog-title">
        <span className="modal-account-title-text">{i18n.t('formDistribution.lblExceptionRegist')}</span>
      </DialogTitle>
      <DialogContent dividers>{formAccoutTempalte}</DialogContent>
    </Dialog>
  )
  const modalConfirmAccountTemplate = (
    <ModalConfirm
      title={getTitleModalConfirm(
        t('formDistribution.msgConfirmExceptionData.line1'),
        t('formDistribution.msgConfirmExceptionData.line2', {
          lblIdData: modalCustomerCode || '',
        })
      )}
      showModal={showModalAccountConfirm}
      setShowModal={setShowModalAccountConfirm}
      handleFunction={handleSubmitAccountTemplate}
      handleClose={handleCloseAccountTemplate}
      screen={'distribution'}
    />
  )

  const modalConfirmDeleteCustomer = (
    <ModalConfirm
      title={getTitleModalDeleteCustomer()}
      showModal={showModalDeleteCustomerConfirm}
      setShowModal={setShowModalDeleteCustomerConfirm}
      handleFunction={deleteCustomer}
    />
  )

  const confirmNavigationMessage = `<div class="message__pre-text"><div>
  ${t('formAllergen.msgConfirmNavigate.line1')} </div><div>
  ${t('formAllergen.msgConfirmNavigate.line2')}</div></div>`

  return (
    <div className="portal-createWrapper">
      {<ModalConfirmNavigation title={confirmNavigationMessage} isBlocked={isBlocked} searchInput={searchInput} />}
      <ModalConfirm
        title={getTitleModal(type, dataForm)}
        showModal={showModal}
        setShowModal={setShowModal}
        handleFunction={submitData}
      />
      {modalConfirmAccountTemplate}
      {modalConfirmDeleteCustomer}
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {createPermissionMessageError && (
        <MessageErrorDialog
          message={createPermissionMessageError}
          showMessageErrorDialog={setCreatePermissionMessageError}
        />
      )}
      {updatePermissionMessageError && (
        <MessageErrorDialog
          message={updatePermissionMessageError}
          showMessageErrorDialog={setUpdatePermissionMessageError}
        />
      )}
      {loading ? (
        <ProgressDialog label={t('formHeadQuarter.loading')} />
      ) : (
        <form>
          <div className="portal-create form-scroll">
            <div className="portal-create-content form-min-width">
              <div className="formEdit-container">
                <div className="id-data-container">
                  {/*  ID_DATA */}
                  <FormInputs
                    id="standard-basic"
                    canEnterJapanese={false}
                    label={t('formDistribution.lblDataId')}
                    required
                    // icon={<CallToActionIcon className="icon--deactive" />}
                    icon={
                      <Image
                        src={IMAGES.ICON.WINDOW_MAXSIZE}
                        alt="Window MaxSize Icon"
                        className="iconForm icon--deactive"
                      />
                    }
                    type="text"
                    name="ID_DATA"
                    className="width120"
                    value={dataForm.ID_DATA || ''}
                    setValue={(e) => handleUpdate('ID_DATA', e)}
                    disabled={type === ACTION_FORM.UPDATE}
                  />
                  {/*  NM_DATA */}
                  <FormInputs
                    id="standard-basic"
                    label={t('formDistribution.lblDataName')}
                    required
                    type="text"
                    name="NM_DATA"
                    className="width300"
                    value={dataForm.NM_DATA || ''}
                    setValue={(e) => handleUpdate('NM_DATA', e)}
                  />
                </div>
                <div className="id-folder-container">
                  {/*  ID_FOLDER */}
                  <FormInputs
                    id="standard-basic"
                    label={t('formDistribution.lblFolderName')}
                    required
                    type="text"
                    name="ID_FOLDER"
                    icon={<FolderIcon className="icon--deactive" />}
                    className="width120"
                    value={dataForm.ID_FOLDER || ''}
                    setValue={(e) => handleUpdate('ID_FOLDER', e)}
                  />

                  {/*  NM_DISP_FLD */}
                  <FormInputs
                    id="standard-basic"
                    label={t('formDistribution.lblFolderDisplayName')}
                    required
                    type="text"
                    name="NM_DISP_FLD"
                    className="width300"
                    value={dataForm.NM_DISP_FLD || ''}
                    setValue={(e) => handleUpdate('NM_DISP_FLD', e)}
                  />
                </div>
                {/*  KB_CYCLE */}
                <SelectInput
                  label={t('formDistribution.lblDeliveryCycle')}
                  required
                  name="KB_CYCLE"
                  // icon={<LoopIcon />}
                  icon={<Image src={IMAGES.ICON.SYNC} alt="Sync Icon" className="iconForm icon--deactive" />}
                  className={`textDrop ${classes.dropdownMessage}`}
                  items={dropDownType[CD_KEY.CYCLE]}
                  onChange={(e) => handleUpdate('KB_CYCLE', e.target.value)}
                  value={dataForm.KB_CYCLE}
                />
                {/* NU_WAIT */}
                <div className="nu-await-container">
                  <FormInputs
                    id="standard-basic"
                    label={t('formDistribution.lblWaitTime')}
                    required
                    type="text"
                    onlyNumberSpecial={true}
                    name="NU_WAIT"
                    // icon={<HourglassEmptyIcon className="icon--deactive" />}
                    icon={
                      <Image src={IMAGES.ICON.HOUR_GLASS} alt="Hour Glass Icon" className="iconForm icon--deactive" />
                    }
                    className="width160 marginRight20"
                    minutesLabel={<div className="minutes-container">{'分'}</div>}
                    value={dataForm.NU_WAIT !== null ? String(dataForm.NU_WAIT) : ''}
                    setValue={(e) => handleUpdate('NU_WAIT', e)}
                    numberMaxlength={3}
                  />
                  <Information
                    icon={<InfoIcon className={classes.iconInfo} />}
                    message={t('formDistribution.infoWaitTimeText')}
                  />
                </div>
                {/* NU_ALERT */}
                <div className="nu-alert-container">
                  <FormInputs
                    id="standard-basic"
                    label={t('formDistribution.lblWarningTime')}
                    required
                    type="text"
                    onlyNumberSpecial={true}
                    name="NU_ALERT"
                    icon={<AccessAlarmIcon className="icon--deactive" />}
                    className="width160 marginRight20"
                    minutesLabel={<div className="minutes-container">{'分'}</div>}
                    // value={dataForm.NU_ALERT || ''}
                    value={dataForm.NU_ALERT !== null ? String(dataForm.NU_ALERT) : ''}
                    setValue={(e) => handleUpdate('NU_ALERT', e)}
                    numberMaxlength={3}
                  />
                  <Information
                    icon={<InfoIcon className={classes.iconInfo} />}
                    message={t('formDistribution.infoWarningTimeText')}
                  />
                </div>
                {/* Check box */}
                <div className="row_checkbox--send-email margin-top-20 mutiple-line">
                  <CustomCheckbox
                    label={t('formDistribution.checkBoxTitleLine')}
                    name="KB_TITLE_LN"
                    className="checkbox title-line"
                    value={Boolean(Number(dataForm.KB_TITLE_LN))}
                    onChange={(e) => handleUpdate('KB_TITLE_LN', e)}
                  />

                  <Information
                    icon={<InfoIcon className={classes.iconInfo} />}
                    message={t('formDistribution.infoCheckBoxTitleLine')}
                  />
                </div>
                {/* Check box */}
                <div className="row_checkbox--send-email  margin-20">
                  <CustomCheckbox
                    label={t('formDistribution.checkBoxTitleLineOutPut')}
                    name="KB_TITLE_OUT"
                    className="checkbox title-line-output"
                    value={Boolean(Number(dataForm.KB_TITLE_OUT))}
                    onChange={(e) => handleUpdate('KB_TITLE_OUT', e)}
                  />
                  <Information
                    icon={<InfoIcon className={classes.iconInfo} />}
                    message={t('formDistribution.infoCheckBoxTitleLineOutput')}
                  />
                </div>
                {/* KB_FL_FORMAT */}
                <SelectInput
                  label={t('formDistribution.lblFileFormat')}
                  required
                  name="KB_FL_FORMAT"
                  // icon={<InsertDriveFileIcon className="icon--deactive" />}
                  icon={<Image src={IMAGES.ICON.FILE} alt="File Regular Icon" className="iconForm icon--deactive" />}
                  className={`textDrop ${classes.dropdownMessage} ${classes.width180}`}
                  items={dropDownType[CD_KEY.FORMAT]}
                  value={dataForm.KB_FL_FORMAT}
                  onChange={(e) => handleUpdate('KB_FL_FORMAT', e.target.value)}
                />

                {/* KB_OUTPUT_ST */}
                {showOutputStartDropDown || dataForm.KB_FL_FORMAT === '1' ? (
                  <div>
                    <SelectInput
                      label={t('formDistribution.lblOutputPosition')}
                      required
                      name="KB_OUTPUT_ST"
                      icon={<ListAltIcon className="icon--deactive" />}
                      className={`textDrop ${classes.dropdownMessage} ${classes.width290}`}
                      items={dropDownType[CD_KEY.SETSTART]}
                      value={dataForm.KB_OUTPUT_ST || ''}
                      onChange={(e) => handleUpdate('KB_OUTPUT_ST', e.target.value)}
                    />
                  </div>
                ) : (
                  ''
                )}

                {/* NM_DEF_TMP */}
                {showDefaultTemplateDropDown || dataForm.KB_FL_FORMAT === '2' ? (
                  <div>
                    <div
                      className={`default-template-container ${
                        nameTemplate === '' ? 'hidden-input' : 'no-border-input'
                      }`}
                    >
                      <SelectInput
                        label={t('formDistribution.lblDefaultTemplate')}
                        required
                        name="NM_DEF_TMP"
                        icon={<DescriptionIcon className="icon--deactive" />}
                        className={`textDrop ${classes.dropdownMessage} ${classes.width210} `}
                        items={listDefaultTemplateDropDown}
                        value={dataForm.NM_DEF_TMP || ''}
                        onChange={(e) => handleUpdate('NM_DEF_TMP', e.target.value)}
                      />
                      <FormInputs
                        id="standard-basic"
                        label={nameTemplate === '' ? t('formDistribution.lblDefaultTemplateName') : ''}
                        type="text"
                        name="NM_TEMP"
                        className="width300 marginRight20"
                        value={nameTemplate || ''}
                        defaultIcon={false}
                        disabled
                        customStyleClass={nameTemplate === '' ? 'hidden-input' : 'no-border-input'}
                      />

                      <Information
                        icon={<InfoIcon className={`${classes.iconInfo}`} />}
                        message={t('formDistribution.infoDefaultTemplate')}
                      />
                    </div>
                    {/* Output Table */}
                    <div className="output-table-container">
                      <div className="customer-template-container">{t('formDistribution.lblCustomerTemplate')}</div>
                      <div className="table-container">
                        <Table aria-label="common table" className={classes.outputWidthTable}>
                          <colgroup>
                            <col style={{ width: '15%' }}></col>
                            <col style={{ width: '50%' }}></col>
                            <col style={{ width: '35%' }}></col>
                          </colgroup>
                          <TableHead className={classes.tableHead}>
                            <TableRow className={classes.titleHeadRow}>
                              <TableCell className={`${classes.titleHead} ${classes.titleCustomerCode}`}>
                                {t('formDistribution.lblTableCustomerCode')}
                              </TableCell>
                              <TableCell className={`${classes.titleHead} ${classes.titleCustomerName}`}>
                                {t('formDistribution.lblTableCustomerName')}
                              </TableCell>
                              <TableCell className={`${classes.titleHead} ${classes.titleCustomerTemplate}`}>
                                {t('formDistribution.lblTableCustomerTemplate')}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {listCustomerDataRender.slice(1).map((data, index) => (
                              <TableRow
                                key={index}
                                className={index % 2 === 0 ? classes.customerTemplateRow : classes.tableRow}
                              >
                                <TableCell className={`${classes.bodyCell} ${classes.overFlowText}`}>
                                  {data.cd_cust}
                                </TableCell>
                                <TableCell className={`${classes.bodyCell} ${classes.overFlowText}`}>
                                  {data.cust_name}
                                </TableCell>
                                <TableCell className={`${classes.bodyCell} ${classes.overFlowText}`}>
                                  {data.id_temp}
                                </TableCell>
                                <div className="iconDeleteTemplate">
                                  <Image
                                    src={IMAGES.ICON.RETURN_ICON}
                                    className={classes.iconDeleteTemplate}
                                    onClick={() => {
                                      setCustomerDelete(data)
                                      setShowModalDeleteCustomerConfirm(true)
                                    }}
                                  />
                                </div>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                      <div className="output-table-add">
                        <AddCircleOutlineIcon
                          className="icon--deactive add-account-template"
                          onClick={handleAddAccountTemplate}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {/* DELETE */}
                <div>
                  <SelectInput
                    label={t('formDistribution.lblDelete')}
                    name="KB_DELETE"
                    icon={<Image src={IMAGES.ICON.DELETE_ICON_FORM_DISTRIBUTION} className={classes.iconDelete} />}
                    className={`textDrop ${classes.width100} ${classes.fontSize16}`}
                    items={dropDownType[CD_KEY.KBDEL]}
                    value={dataForm.KB_DELETE}
                    onChange={(e) => handleUpdate('KB_DELETE', e.target.value)}
                  />
                </div>
                {/* Form 1 */}
                <div className="formEdit-input ">
                  <div className="input-spacing"></div>
                  <FormInputs
                    id="standard-basic"
                    canSpacing={true}
                    label={t('formHeadQuarter.lblNmRuduser')}
                    value={userInfo.NM_USER || ''}
                    type="text"
                    name="NM_RUDUSER"
                    // setValue={(e) => handleUpdate('NM_RUDUSER', e)}
                    disabled
                    className="row_1"
                  />
                </div>
                {/* Form 2 */}
                <div className="formEdit-input">
                  <div className="input-spacing"></div>
                  <div className="formEdit-input wrap-mb">
                    <FormInputs
                      id="standard-basic"
                      canSpacing={true}
                      label={t('formHeadQuarter.lblDtRegist')}
                      value={
                        type === 'UPDATE'
                          ? dataForm.DT_REGIST &&
                            formatDateWithPadding(new Date(dataForm.DT_REGIST), 'yyyy-MM-dd HH:mm:ss')
                          : ''
                      }
                      type="text"
                      name="DT_REGIST"
                      disabled
                      className="row_2"
                    />
                    <FormInputs
                      id="standard-basic"
                      canSpacing={true}
                      label={t('formHeadQuarter.lblDtUpdate')}
                      value={
                        type === 'UPDATE' && dataForm.DT_UPDATE
                          ? formatDateWithPadding(new Date(dataForm.DT_UPDATE), 'yyyy-MM-dd HH:mm:ss')
                          : ''
                      }
                      type="text"
                      name="DT_UPDATE"
                      disabled
                      className="row_2"
                    />
                  </div>
                </div>
                {/* FORM 3 */}
                <div className="formEdit-input">
                  <div className="input-spacing"></div>
                  <FormInputs
                    id="standard-basic"
                    canSpacing={true}
                    label={t('formHeadQuarter.lblDtDelete')}
                    type="text"
                    value={
                      type === 'UPDATE'
                        ? dataForm.DT_DELETE &&
                          formatDateWithPadding(new Date(dataForm.DT_DELETE), 'yyyy-MM-dd HH:mm:ss')
                        : ''
                    }
                    name="DT_DELETE"
                    disabled
                    className="row_2"
                  />
                </div>
                {/* Button */}
                <div className="buttonSpace">
                  <Button
                    className="button submit-button"
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!canSubmit}
                  >
                    {t('formHeadQuarter.btnSubmit')}
                  </Button>
                  <Button className="button clear-button" variant="outlined" onClick={handleCancelButton}>
                    {t('formHeadQuarter.btnCancel')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      {modalAccountTemplate}
    </div>
  )
}

export default FormDistributionData
