import CARD_USER from './address-card-solid.svg'
import COLAPSE_MENU from './angle-double-left.svg'
import RELOAD_DISABLE from './arrow-rotate-right-solid-disable.svg'
import RELOAD_ENABLE from './arrow-rotate-right-solid-enable.svg'
import BAR_MENU_ICON from './bars-solid.svg'
import BUILDING from './building-regular.svg'
import BUILDING_MENU_ICON from './building-solid.svg'
import CALENDAR from './calendar.svg'
import CALENDAR_ALT from './calendar-alt.svg'
import CLIPBOARD from './clipboard.png'
import CLOCK_MENU_ICON from './clock-regular.svg'
import CLOCK from './clock-regular.svg'
import DATABASE_MENU_ICON from './database-solid.svg'
import DEFAULT_IMAGE from './default.png'
import DISTURB from './do_not_disturb.svg'
import DOWNLOAD_ICON from './download_file_icon.png'
import DOWNLOAD_ICON_DISABLE from './download-disable.png'
import ALERT from './exclamation.svg'
import ALERT_ICON from './exclamation-triangle.svg'
import PREVIEW_FILE from './file-alt.svg'
import FILE_MENU_ICON from './file-alt-regular.svg'
import FILE from './file-regular.svg'
import FILE_TEMPLATE from './file-template.svg'
import FILTER_ICON_TABLE_HQUSER from './filter-solid.svg'
import HOUR_GLASS from './hourglass-end-solid.svg'
import TRANSFER from './import_export.svg'
import DETAIL from './list-alt.svg'
import LIST_ALL from './list-alt-regular.svg'
import EDIT_ICON_TABLE_HQUSER from './pen-solid.svg'
import PHONE_ALT from './phone-alt-solid.svg'
import PLUS_ICON_TABLE_HQUSER from './plus-solid.svg'
import QUESTION_ICON from './question-circle.svg'
import SEARCH_MENU_ICON from './search-solid.svg'
import SPEAKER from './speaker.png'
import SYNC from './sync-solid.svg'
import RETURN_ICON from './times-solid-grey.svg'
import DELETE_ICON from './trash.svg'
import DELETE_ICON_TABLE_HQUSER from './trash-alt-solid.svg'
import DELETE_ICON_FORM_DISTRIBUTION from './trash-alt-solid-grey.svg'
import UNDO from './undo-solid.svg'
import LOCAL_DINING from './utensils-solid.svg'
import WINDOW_MAXSIZE from './window-maximize-regular.svg'

export const Images = {
  DEFAULT_IMAGE: DEFAULT_IMAGE,
  ICON: {
    DISTURB: DISTURB,
    TRANSFER: TRANSFER,
    RETURN_ICON: RETURN_ICON,
    WINDOW_MAXSIZE: WINDOW_MAXSIZE,
    SYNC: SYNC,
    LOCAL_DINING: LOCAL_DINING,
    HOUR_GLASS: HOUR_GLASS,
    CLOCK: CLOCK,
    FILE: FILE,
    CALENDAR: CALENDAR,
    SPEAKER: SPEAKER,
    TRASH: DELETE_ICON,
    DOWNLOAD_ICON: DOWNLOAD_ICON,
    DOWNLOAD_ICON_DISABLE: DOWNLOAD_ICON_DISABLE,
    PREVIEW_FILE: PREVIEW_FILE,
    CALENDAR_ALT: CALENDAR_ALT,
    ALERT: ALERT,
    ALERT_ICON: ALERT_ICON,
    QUESTION_ICON: QUESTION_ICON,
    DELETE_ICON_TABLE_HQUSER: DELETE_ICON_TABLE_HQUSER,
    DELETE_ICON_FORM_DISTRIBUTION: DELETE_ICON_FORM_DISTRIBUTION,
    PLUS_ICON_TABLE_HQUSER: PLUS_ICON_TABLE_HQUSER,
    EDIT_ICON_TABLE_HQUSER: EDIT_ICON_TABLE_HQUSER,
    FILTER_ICON_TABLE_HQUSER: FILTER_ICON_TABLE_HQUSER,
    CARD_USER: CARD_USER,
    COLAPSE_MENU: COLAPSE_MENU,
    BUILDING: BUILDING,
    LIST_ALL: LIST_ALL,
    DETAIL: DETAIL,
    FILE_TEMPLATE: FILE_TEMPLATE,
    PHONE: PHONE_ALT,
    UNDO: UNDO,
    RELOAD_DISABLE: RELOAD_DISABLE,
    RELOAD_ENABLE: RELOAD_ENABLE,
    BRANCH: {
      BUILDING: BUILDING,
      CLIPBOARD: CLIPBOARD,
    },
    MENU: {
      database: DATABASE_MENU_ICON,
      file: FILE_MENU_ICON,
      clock: CLOCK_MENU_ICON,
      building: BUILDING_MENU_ICON,
      search: SEARCH_MENU_ICON,
      bar: BAR_MENU_ICON,
    },
  },
}
