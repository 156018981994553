import {
  GET_MENU_LIST_FAILED,
  GET_MENU_LIST_REQUEST,
  GET_MENU_LIST_SUCCESS,
} from 'constants/actionTypes/menuListActionTypes'

const listMenu = (state = { listMenu: [] }, action) => {
  switch (action.type) {
    case GET_MENU_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_MENU_LIST_REQUEST,
      }
    case GET_MENU_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        listMenu: action.payload,
        type: GET_MENU_LIST_SUCCESS,
      }
    case GET_MENU_LIST_FAILED:
      return {
        ...state,
        loading: false,
        listMenu: [],
        message: action.payload,
        type: GET_MENU_LIST_FAILED,
      }
    default:
      return state
  }
}
export { listMenu }
