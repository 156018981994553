import Auth from '@aws-amplify/auth'
import axios from 'commons/baseUrl'
import {
  AUTH_ALERT,
  USER_LOGIN_FAILED,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from 'constants/actionTypes/userActionTypes'
import { API_GET_USER_DETAIL } from 'constants/apiPath'

const login = (params, userId, password, i18n, handleLoginState) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST })
    await Auth.signIn(userId, password)
      .then(async (success) => {
        sessionStorage.removeItem('_clear')
        const res = await axios.post(API_GET_USER_DETAIL, JSON.stringify(params))
        // eslint-disable-next-line
        if (!res.data || res.data.STS == 1 || res.data.STS == 4 || res.data.STS == 3 || res.data.STS == 9) {
          dispatch({ type: USER_LOGIN_FAILED, payload: it('login.msgLoginFailed') })
          throw new Error()
        }

        const dataResponse = res.data || null
        if (dataResponse) {
          dispatch({ type: USER_LOGIN_SUCCESS, payload: dataResponse })
          localStorage.setItem('userInfo', JSON.stringify(dataResponse?.data?.NM_USER))
          handleLoginState(USER_LOGIN_SUCCESS)
        }
      })
      .catch(async (err) => {
        dispatch({ type: USER_LOGIN_FAILED, payload: err.message })
        await Auth.signOut()
        // sessionStorage.clear()
        handleLoginState(USER_LOGIN_FAILED, err.message)
      })
  } catch (error) {
    dispatch({ type: USER_LOGIN_FAILED, payload: error.message })
    handleLoginState(USER_LOGIN_FAILED, error.message)
  }
}

const showAlertAuth = () => async (dispatch) => {
  dispatch({ type: AUTH_ALERT, payload: null })
}

const logout = () => async (dispatch) => {
  dispatch({ type: USER_LOGOUT, payload: null })
  await Auth.signOut()
  sessionStorage.clear()
}

export { login, logout, showAlertAuth }
