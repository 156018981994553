import {
  CLEAR_SQS_CONVERT_EXCEL2PDF,
  SQS_CONVERT_EXCEL2PDF_FAILED,
  SQS_CONVERT_EXCEL2PDF_REQUEST,
  SQS_CONVERT_EXCEL2PDF_SUCCESS,
} from 'constants/actionTypes/excelTemplateActionTypes'

const exportPDFReducer = (state = { exportPDF: {} }, action) => {
  switch (action.type) {
    case SQS_CONVERT_EXCEL2PDF_REQUEST:
      return {
        ...state,
        loading: true,
        exportPDF: {},
        type: SQS_CONVERT_EXCEL2PDF_REQUEST,
      }
    case SQS_CONVERT_EXCEL2PDF_SUCCESS:
      return {
        ...state,
        loading: false,
        exportPDF: { ...action.payload },
        type: SQS_CONVERT_EXCEL2PDF_SUCCESS,
        message: 'success',
      }
    case SQS_CONVERT_EXCEL2PDF_FAILED:
      return {
        ...state,
        loading: false,
        exportPDF: {},
        message: action.payload,
        type: SQS_CONVERT_EXCEL2PDF_FAILED,
      }
    case CLEAR_SQS_CONVERT_EXCEL2PDF:
      return { type: CLEAR_SQS_CONVERT_EXCEL2PDF, loading: false, status: null, exportPDF: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, REISSUE: {}, message: '' }
  }
}

export { exportPDFReducer }
