import './FormBranch.css'
import '../../../components/commons/FormEdits/FormEdit.css'

import { Button } from '@material-ui/core'
import { DateRange } from '@material-ui/icons'
import { clearBranch, createBranchAction, updateBranchAction } from 'actions/branchActions'
import { IMAGES } from 'assets'
import {
  focusNextElement,
  formatDateWithPadding,
  getDateInfoSystem,
  getSessionInfo,
  getTitleModalConfirm,
  getUserKbAdmin,
  shortenValue,
} from 'commons/utilities'
import {
  CustomCalendar,
  FormInputs,
  Image,
  MessageErrorDialog,
  ModalConfirm,
  ModalConfirmNavigation,
  ProgressDialog,
} from 'components/commons'
import {
  CREATE_BRANCH_FAILED,
  CREATE_BRANCH_REQUEST,
  CREATE_BRANCH_SUCCESS,
  UPDATE_BRANCH_FAILED,
  UPDATE_BRANCH_REQUEST,
  UPDATE_BRANCH_SUCCESS,
} from 'constants/actionTypes/branchActionTypes'
import { ACTION_FORM, FLAG_BOOLEAN, SCREEN_NAME } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import branchValidate from 'validation/branchValidate'

export default function FormBranch() {
  const { i18n, t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return {}
  })

  const createBranchReducer = useSelector((state) => state.createBranch || {})
  const updateBranchReducer = useSelector((state) => state.updateBranch || {})
  const [type, setType] = useState(ACTION_FORM.CREATE)
  const [isBlocked, setIsBlocked] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [errorData, setErrorData] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [dataForm, setDataForm] = useState({
    CD_BRANCH: '',
    AN_BRANCH: '',
    NM_BRANCH: '',
    DT_START: formatDateWithPadding(new Date(Date.now()), 'yyyy-MM-dd'),
    DT_END: getDateInfoSystem().DT_DEND,
    DT_REGIST: '',
    DT_UPDATE: '',
    DT_DELETE: '',
    NM_RUDUSER: userInfo.NM_USER,
  })
  const [filteredDate, setFilteredDate] = useState({})
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState(false)
  const [isValidStartDate, setIsValidStartDate] = useState(true)
  const [isValidEndDate, setIsValidEndDate] = useState(true)
  const [isInStartDateRange, setIsInStartDateRange] = useState(true)
  const [isInEndDateRange, setIsInEndDateRange] = useState(true)
  const [searchInput, setSearchInput] = useState('')
  const [createPermissionMessageError, setCreatePermissionMessageError] = useState('')
  const [updatePermissionMessageError, setUpdatePermissionMessageError] = useState('')

  // handle update field portal
  const handleUpdate = (name, value) => {
    setErrorData({})
    setIsBlocked(true)
    if (name === 'DT_START' || name === 'DT_END') {
      let t = dataForm
      t[name] = value
      setDataForm((prev) => ({ ...prev, ...t }))
      // setErrorData((prev) => ({ ...prev, DATE: '' }))
      return
    }
    setDataForm((prev) => ({ ...prev, [name]: value }))
  }

  // clear and exit
  const handleCancelButton = () => {
    history.push({
      pathname: '/admin/branch',
      state: {
        date: filteredDate,
        searchInput: searchInput,
        screen: SCREEN_NAME.BRANCH,
      },
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault()

    const kbAdmin = await getUserKbAdmin(userInfo)
    if (String(kbAdmin) === FLAG_BOOLEAN.FALSE) {
      if (type === ACTION_FORM.CREATE) {
        setCreatePermissionMessageError(t('commonComponent.noCreatePermission'))
      }
      if (type === ACTION_FORM.UPDATE) {
        setUpdatePermissionMessageError(t('commonComponent.noUpdatePermission'))
      }
    } else {
      const dateError = branchValidate(dataForm.DT_START, dataForm.DT_END, i18n)
      if (dateError.DATE || dateError.InvalidDtEnd) {
        setErrorData(dateError)
      } else {
        setShowModal(true)
        setErrorData({})
      }
    }
  }

  const submitData = () => {
    const submitData = { ...dataForm, NM_RUDUSER: userInfo.NM_USER }
    if (type === ACTION_FORM.CREATE) dispatch(createBranchAction(submitData, i18n))
    if (type === ACTION_FORM.UPDATE) dispatch(updateBranchAction(submitData))
    setIsBlocked(false)
    setShowModal(false)
  }

  const checkRequire = React.useCallback(() => {
    if (!dataForm.CD_BRANCH || !dataForm.AN_BRANCH || !dataForm.DT_START || !dataForm.DT_END) return false
    return isBlocked
  }, [dataForm, isBlocked])

  useEffect(() => {
    if (
      Object.keys(errorData).length > 0 ||
      !isValidStartDate ||
      !isValidEndDate ||
      !isInStartDateRange ||
      !isInEndDateRange ||
      !checkRequire()
    ) {
      setCanSubmit(false)
    } else {
      setCanSubmit(true)
    }
  }, [dataForm, errorData, checkRequire])

  const getTitleModal = (type, dataForm) => {
    const formType =
      type === ACTION_FORM.CREATE ? t('commonComponent.form.createButton') : t('commonComponent.form.updateButton')
    return getTitleModalConfirm(
      t('formBranch.msgConfirmSubmission.line1', {
        lblCdBranch: t('formBranch.lblCdBranch'),
        CdBranchValue: shortenValue(dataForm.CD_BRANCH, 10),
        formType: formType,
      }),
      t('formBranch.msgConfirmSubmission.line2')
    )
  }

  useEffect(() => {
    const { data, type, date, searchInput } = location?.state || {}
    if (searchInput) {
      setSearchInput(searchInput)
    }
    if (type) setType(type)
    if (!data) {
      setDataForm({ ...dataForm, NM_RUDUSER: userInfo.NM_USER })
    } else {
      setDataForm({ ...data })
    }
    if (date) setFilteredDate(date)
  }, [location])

  useEffect(() => {
    setLoading(createBranchReducer.loading || updateBranchReducer.loading)
    setIsBlocked(false)
    switch (createBranchReducer.type) {
      case CREATE_BRANCH_REQUEST:
        break
      case CREATE_BRANCH_FAILED:
        if (!getSessionInfo()) {
          setMessageError(createBranchReducer.message)
        }

        break
      case CREATE_BRANCH_SUCCESS:
        history.push({
          pathname: '/admin/branch',
          state: {
            date: filteredDate,
            searchInput: searchInput,
            screen: SCREEN_NAME.BRANCH,
          },
        })
        dispatch(clearBranch())
        break
      default:
        break
    }
    switch (updateBranchReducer.type) {
      case UPDATE_BRANCH_REQUEST:
        break
      case UPDATE_BRANCH_FAILED:
        if (!getSessionInfo()) {
          setMessageError(updateBranchReducer.message)
        }
        break
      case UPDATE_BRANCH_SUCCESS:
        history.push({
          pathname: '/admin/branch',
          state: {
            date: filteredDate,
            searchInput: searchInput,
            screen: SCREEN_NAME.BRANCH,
          },
        })
        dispatch(clearBranch())
        break
      default:
        break
    }
  }, [createBranchReducer, updateBranchReducer, history, dispatch])

  const customEventEnter = (e) => {
    if (e.code === 'Enter') {
      setShowModal(true)
      e.preventDefault()
    }
  }

  const confirmNavigationMessage = `<div class="message__pre-text"><div>
  ${t('formBranch.msgConfirmNavigate.line1')} </div><div>
  ${t('formBranch.msgConfirmNavigate.line2')}</div></div>`

  return (
    <div className="portal-createWrapper">
      {
        <ModalConfirmNavigation
          title={confirmNavigationMessage}
          isBlocked={isBlocked}
          date={filteredDate}
          searchInput={searchInput}
        />
      }
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {loading && <ProgressDialog label={t('formCustomer.loading')} />}
      <ModalConfirm
        title={getTitleModal(type, dataForm)}
        showModal={showModal}
        setShowModal={setShowModal}
        handleFunction={submitData}
        focusSubmit={true}
      />
      {createPermissionMessageError && (
        <MessageErrorDialog
          message={createPermissionMessageError}
          showMessageErrorDialog={setCreatePermissionMessageError}
        />
      )}
      {updatePermissionMessageError && (
        <MessageErrorDialog
          message={updatePermissionMessageError}
          showMessageErrorDialog={setUpdatePermissionMessageError}
        />
      )}
      <form
        onKeyDown={(e) => {
          focusNextElement(e)
        }}
      >
        <div className="portal-create form-scroll">
          <div className="portal-create-content form-min-width">
            <div className="formEdit-container">
              {/* CD_BRANCH */}
              <FormInputs
                id="standard-basic"
                canSpacing={false}
                canEnterJapanese={false}
                label={t('formBranch.lblCdBranch')}
                value={dataForm.CD_BRANCH || ''}
                required
                icon={<Image src={IMAGES.ICON.BRANCH.BUILDING} className="iconForm icon--deactive" />}
                type="text"
                name="CD_BRANCH"
                setValue={(e) => handleUpdate('CD_BRANCH', e)}
                disabled={type === ACTION_FORM.UPDATE}
                className="width120"
              />

              {/*NM_BRANCH  */}
              <FormInputs
                id="standard-basic"
                label={t('formBranch.lblNmBranch')}
                value={dataForm.NM_BRANCH || ''}
                icon={<Image src={IMAGES.ICON.LIST_ALL} className="iconForm icon--deactive" />}
                type="text"
                name="NM_BRANCH"
                setValue={(e) => handleUpdate('NM_BRANCH', e)}
                className="width300"
              />

              {/*AN_BRANCH*/}
              <FormInputs
                id="standard-basic"
                label={t('formBranch.lblAnBranch')}
                value={dataForm.AN_BRANCH || ''}
                required
                icon={<Image src={IMAGES.ICON.LIST_ALL} className="iconForm icon--deactive" />}
                type="text"
                name="NM_USER"
                setValue={(e) => handleUpdate('AN_BRANCH', e)}
                className="width120"
              />

              {/* Calendar */}
              <div className="formEdit-calendar">
                <div className="calender-spacing">
                  <DateRange className="icon--deactive" />
                </div>
                <div>
                  <div className="calendar">
                    <CustomCalendar
                      className="calendar-item calendar-item-1"
                      value={dataForm.DT_START ? new Date(dataForm.DT_START) : ''}
                      onChange={(date) => handleUpdate('DT_START', date)}
                      setIsValidDate={setIsValidStartDate}
                      setIsInDateRange={setIsInStartDateRange}
                      label={t('formBranch.lblDtStart')}
                      name="DT_START"
                      id="DT_START"
                      required
                      // minDate={
                      //   new Date(new Date(sysInfo.DT_TODAY).setFullYear(new Date(sysInfo.DT_TODAY).getFullYear() - 100))
                      // }
                      // maxDate={
                      //   new Date(new Date(sysInfo.DT_TODAY).setFullYear(new Date(sysInfo.DT_TODAY).getFullYear() + 100))
                      // }
                    />
                    <CustomCalendar
                      className="calendar-item calendar-item-2"
                      value={dataForm?.DT_END ? new Date(dataForm.DT_END) : ''}
                      onChange={(date) => handleUpdate('DT_END', date)}
                      setIsValidDate={setIsValidEndDate}
                      setIsInDateRange={setIsInEndDateRange}
                      label={t('formBranch.lblDtEnd')}
                      name="DT_END"
                      id="DT_END"
                      required
                      // minDate={
                      //   new Date(new Date(sysInfo.DT_TODAY).setFullYear(new Date(sysInfo.DT_TODAY).getFullYear() - 100))
                      // }
                      // maxDate={
                      //   new Date(new Date(sysInfo.DT_TODAY).setFullYear(new Date(sysInfo.DT_TODAY).getFullYear() + 100))
                      // }
                    />
                    <div className={`branch-error-message ${errorData && errorData.DATE ? '' : 'hidden'}`}>
                      {errorData && errorData.DATE ? errorData.DATE : null}
                    </div>
                    <div className={`error-message-dtEnd ${errorData && errorData.InvalidDtEnd ? '' : 'hidden'}`}>
                      {errorData && errorData.InvalidDtEnd ? errorData.InvalidDtEnd : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="formEdit-input ">
                <div className="input-spacing"></div>
                <FormInputs
                  id="standard-basic"
                  label={t('formBranch.lblNmRuduser')}
                  value={dataForm.NM_RUDUSER || ''}
                  type="text"
                  name="NM_RUDUSER"
                  className="input__row-1"
                  setValue={(e) => handleUpdate('NM_RUDUSER', e)}
                  disabled
                />
              </div>
              <div className="formEdit-input">
                <div className="input-spacing"></div>
                <div className="formEdit-input wrap-mb">
                  <FormInputs
                    id="standard-basic"
                    label={t('formBranch.lblDtRegist')}
                    value={
                      type === ACTION_FORM.UPDATE
                        ? dataForm.DT_REGIST &&
                          formatDateWithPadding(new Date(dataForm.DT_REGIST), 'yyyy-MM-dd HH:mm:ss')
                        : ''
                    }
                    type="text"
                    name="DT_REGIST"
                    disabled
                    className="row_2"
                  />
                  <FormInputs
                    id="standard-basic"
                    label={t('formBranch.lblDtUpdate')}
                    value={
                      type === ACTION_FORM.UPDATE && dataForm.DT_UPDATE
                        ? formatDateWithPadding(new Date(dataForm.DT_UPDATE), 'yyyy-MM-dd HH:mm:ss')
                        : ''
                    }
                    type="text"
                    name="DT_UPDATE"
                    disabled
                    className="row_2"
                  />
                </div>
              </div>
              <div className="formEdit-input">
                <div className="input-spacing"></div>
                <FormInputs
                  id="standard-basic"
                  label={t('formBranch.lblDtDelete')}
                  type="text"
                  value={
                    type === ACTION_FORM.UPDATE
                      ? dataForm.DT_DELETE && formatDateWithPadding(new Date(dataForm.DT_DELETE), 'yyyy-MM-dd HH:mm:ss')
                      : ''
                  }
                  name="DT_DELETE"
                  disabled
                  className="row_2"
                />
              </div>
            </div>
            <div className="buttonSpace">
              <Button
                className="button submit-button"
                type="submit"
                onClick={handleSubmit}
                variant="contained"
                disabled={!canSubmit}
                onKeyDown={(e) => {
                  customEventEnter(e)
                }}
              >
                {t('formCustomer.btnSubmit')}
              </Button>
              <Button className="button clear-button" onClick={handleCancelButton} variant="outlined">
                {t('formCustomer.btnCancel')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
