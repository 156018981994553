import './ForgotPassword.css'

import { Button, Grid } from '@material-ui/core'
import { EmailOutlined, Person } from '@material-ui/icons'
import { forgotPassword } from 'actions/forgotPasswordActions'
import { FormInputs, MessageErrorDialog, ProgressDialog } from 'components/commons'
import {
  FORGOT_PASS_FAILED,
  FORGOT_PASS_REQUEST,
  FORGOT_PASS_SUCCESS,
} from 'constants/actionTypes/forgotPassActionTypes'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import forgotPasswordValidate from 'validation/forgotPasswordValidate'

function ForgotPassword() {
  const { i18n, t } = useTranslation()
  const [userId, setUserId] = useState('')
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showProgress, setShowProgress] = useState(false)
  const [showMessageErrorDialog, setShowMessageErrorDialog] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const forgotPassState = useSelector((state) => {
    return state.forgotPass
  })

  React.useEffect(() => {
    switch (forgotPassState.type) {
      case FORGOT_PASS_REQUEST:
        break
      case FORGOT_PASS_SUCCESS:
        break
      case FORGOT_PASS_FAILED:
        if (forgotPassState.message) {
          setShowMessageErrorDialog(true)
          setErrorMessage(forgotPassState.message)
        }
        break
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPassState])

  // reset state before naviate to login page
  const handleForgotPasswordState = () => {
    setUserId('')
    setEmail('')
    setErrorMessage('')
    setShowProgress(false)
    setShowMessageErrorDialog(false)
    history.push('/login')
  }

  // handle submit data
  const handleForgotPassword = async (e) => {
    e.preventDefault()
    let { validate, error } = forgotPasswordValidate(userId, email, i18n)

    if (!validate) {
      setShowMessageErrorDialog(true)
      setErrorMessage(error)
      return false
    }
    // show loading state
    setShowProgress(forgotPassState.loading)
    // call action forgotPassword
    dispatch(forgotPassword(userId, email, i18n))
  }
  return (
    <div className="wrapper__fpwd">
      {showProgress && <ProgressDialog label={t('forgotPassword.lblLoginProgress')} />}
      {showMessageErrorDialog && (
        <MessageErrorDialog message={errorMessage} showMessageErrorDialog={setShowMessageErrorDialog} />
      )}
      <Grid item md={12} xs={12} className="wrapper__fpwd-container">
        <div className="wrapper__fpwd-title">{t('forgotPassword.lblTitle')}</div>
        <div className="wrapper__fpwd-form">
          <form autoCapitalize="off" autoCorrect="off">
            <div className="wrapper__fpwd-helptext">
              <span className="section-note">※</span>
              <span>
                <div>{t('forgotPassword.lblHelpTextForm1')}</div>
                <div>{t('forgotPassword.lblHelpTextForm2')}</div>
              </span>
            </div>
            <FormInputs
              label={t('forgotPassword.lblId')}
              icon={<Person viewBox="0 0 22 22" />}
              type="text"
              name="ID_USER"
              setValue={setUserId}
              inputProps={{ tabIndex: '1' }}
              required
            />
            <FormInputs
              label={t('forgotPassword.lblEmail')}
              icon={<EmailOutlined />}
              type="email"
              name="EMAIL"
              setValue={setEmail}
              inputProps={{ tabIndex: '2' }}
              required
            />
            <div className="form-forgot__button">
              <Button type="submit" onClick={handleForgotPassword} tabIndex="3">
                {t('forgotPassword.btnSubmit')}
              </Button>
              <Button className="btn-cancel" onClick={handleForgotPasswordState} tabIndex="4">
                {t('forgotPassword.btnCancel')}
              </Button>
            </div>
          </form>
        </div>
      </Grid>
    </div>
  )
}

export default ForgotPassword
