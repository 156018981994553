import './DeliveryData.css'

import { getDeliveryDataAction } from 'actions/deliveryDataAction'
import {
  caculateTableWidth,
  createListYmRegist,
  dataSearchResult,
  formatDateWithPadding,
  getSessionInfo,
} from 'commons/utilities'
import { MessageErrorDialog, ProgressDialog } from 'components/commons'
import ToolBar from 'components/commons/ToolBar/ToolBar'
import {
  GET_DELIVERY_DATA_LIST_FAILED,
  GET_DELIVERY_DATA_LIST_NULL,
  GET_DELIVERY_DATA_LIST_REQUEST,
  GET_DELIVERY_DATA_LIST_SUCCESS,
} from 'constants/actionTypes/deliveryDataActionTypes'
import {
  BRANCH_CHARACTER,
  CHARACTER_WIDTH,
  MAX_CHARACTER,
  RIGHT_LEFT_PADDING,
  SCREEN_NAME,
  TABLE_WIDTH,
  TOOLBAR_TYPE,
} from 'constants/constant'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import useWindowDimensions from './CustomHook'
import TableDeliveryData from './TableDeliveryData'

function DeliveryData() {
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })
  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [listData, setListData] = useState([])
  // const [dateFilter, setDateFilter] = useState('')
  const ymRegistRef = useRef()
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState()

  const listDeliveryDataReducer = useSelector((state) => state.listDeliveryData || {})
  const [searchInput, setSearchInput] = useState('')
  const searchFields = ['CD_CUST', 'NM_CUST', 'NM_BRANCH', 'NM_DISP_FLD']
  // const [listFolderName, setListFolderName] = useState([])
  const [listFilterData, setListFilterData] = useState([])
  const [listRawData, setListRawData] = useState([])
  const currentYear = String(new Date(DT_TODAY).getUTCFullYear())
  const temp = String(new Date(DT_TODAY).getUTCMonth() + 1)
  const currentMonth = temp.length < 2 ? `0${temp}` : `${temp}`
  const currentYmRegist = currentYear + '-' + currentMonth

  const [filterItemYmRegist, setFilterItemYmRegist] = useState('0')

  const [listYmRegist, setListYmRegist] = useState([])

  const [listYmRegistOption, setListYmRegistOption] = useState([])

  const { tableDeliveryWidth } = useWindowDimensions()
  const handleSearchInput = (event) => {
    event.preventDefault()
    const listDataSearch = dataSearchResult(listRawData, searchFields, searchInput)
    let listFolderName = getListFolderName(listRawData)

    const dataFormat = arrayToMapHandler(listDataSearch, listFolderName)

    setListFilterData(dataFormat.data)
  }

  // const getYear = () => {
  //   const listYearFiltered = listYear.filter((year) => year.value === String(filterItemYear))
  //   return listYearFiltered[0].label
  // }
  // const getMonth = () => {
  //   const listMonthFiltered = listMonth.filter((month) => month.value === String(filterItemMonth))
  //   return listMonthFiltered[0].label
  // }

  const getYmRegistLabel = () => {
    const listYmRegistFiltered = listYmRegistOption.filter((dtWeek) => dtWeek.value === String(filterItemYmRegist))
    const ymRegistLabel = listYmRegistFiltered[0]?.label
    return ymRegistLabel
  }
  useEffect(() => {
    ymRegistRef.current = getYmRegistLabel()
  }, [filterItemYmRegist])

  const handleFilterDate = (e) => {
    e.preventDefault()
    setTimeout(() => {
      dispatch(getDeliveryDataAction({ date: ymRegistRef.current }))
    }, 1)
  }

  useEffect(() => {
    const { date, searchInput, currentFilteredItem, listYmRegistCurrent, listYmRegistOptionCurrent, screen } =
      location?.state || {}
    if (searchInput && screen === SCREEN_NAME.DELIVERY_DATA) {
      setSearchInput(searchInput)
    }

    if (listYmRegistCurrent) {
      setListYmRegist(listYmRegistCurrent)
    }
    if (listYmRegistOptionCurrent) {
      setListYmRegistOption(listYmRegistOptionCurrent)
    }

    if (date && currentFilteredItem) {
      setFilterItemYmRegist(String(currentFilteredItem))
      dispatch(getDeliveryDataAction({ date: date }))
    } else {
      dispatch(getDeliveryDataAction({}))
    }
    window.history.replaceState({}, document.title)
  }, [location, dispatch])

  useEffect(() => {
    window.addEventListener('beforeunload', reloadHandler)
    return () => {
      window.removeEventListener('beforeunload', reloadHandler)
    }
  }, [])
  const reloadHandler = (e) => {
    window.reload()
  }

  const getListFolderName = (listData = []) => {
    let listFolderNameArr = []
    for (const item of listData) {
      if (!listFolderNameArr.includes(item.NM_DISP_FLD)) {
        listFolderNameArr.push(item.NM_DISP_FLD)
      }
    }
    return listFolderNameArr
  }

  useEffect(() => {
    if (listDeliveryDataReducer?.listDeliveryData) {
      if (listDeliveryDataReducer?.listDeliveryData?.listYmRegist) {
        const listYmRegist = listDeliveryDataReducer?.listDeliveryData?.listYmRegist
        setListYmRegist(listYmRegist)
        setListYmRegistOption(createListYmRegist(listYmRegist, currentYmRegist))
      }
      const listData = listDeliveryDataReducer?.listDeliveryData?.data || []
      let listFolderName = getListFolderName(listData)
      const dataFormat = arrayToMapHandler(listData, listFolderName)
      setListRawData(listData)
      setListData(dataFormat?.data)

      if (searchInput !== '') {
        const listDataSearch = dataSearchResult(listData, searchFields, searchInput)
        const dataFormat = arrayToMapHandler(listDataSearch, listFolderName)
        setListFilterData(dataFormat.data)
      }
    }
    setLoading(listDeliveryDataReducer?.loading)
    switch (listDeliveryDataReducer?.type) {
      case GET_DELIVERY_DATA_LIST_REQUEST:
        break
      case GET_DELIVERY_DATA_LIST_NULL:
        break
      case GET_DELIVERY_DATA_LIST_FAILED:
        if (!getSessionInfo()) {
          listDeliveryDataReducer.message && setMessageError(listDeliveryDataReducer.message)
        }
        break
      case GET_DELIVERY_DATA_LIST_SUCCESS:
        break
      default:
        return
    }
  }, [listDeliveryDataReducer])

  useEffect(() => {
    if (listData && searchInput === '') {
      setListFilterData(listData)
    }
  }, [listData, searchInput])

  const createListColumnWidth = (listFolderName, branchList) => {
    let listColumnWidth = []
    listColumnWidth.push('62px')
    listColumnWidth = caculateBranchColumnWidth(listColumnWidth, branchList)
    listColumnWidth = caculateFolderNameWidth(listColumnWidth, listFolderName)
    let totalTableWidth = caculateTableWidth(listColumnWidth)
    if (totalTableWidth < TABLE_WIDTH) {
      listColumnWidth[listColumnWidth.length - 1] = ''
    }

    return listColumnWidth
  }

  const caculateBranchColumnWidth = (listColumnWidth = [], branchList) => {
    let maxcount = 0
    if (branchList) {
      branchList.forEach((branch) => {
        if (branch?.NM_BRANCH?.length > maxcount) {
          maxcount = branch.NM_BRANCH.length
        }
      })
    }
    if (maxcount < BRANCH_CHARACTER) {
      listColumnWidth.push(BRANCH_CHARACTER * CHARACTER_WIDTH + RIGHT_LEFT_PADDING + 'px')
    } else {
      listColumnWidth.push(maxcount * CHARACTER_WIDTH + RIGHT_LEFT_PADDING + 'px')
    }
    return listColumnWidth
  }

  const caculateFolderNameWidth = (listColumnWidth = [], listFolderName, totalTableWidth) => {
    if (listFolderName.length === 1) {
      listColumnWidth.push('')
      return listColumnWidth
    }
    for (let index = 0; index <= listFolderName?.length - 1; index++) {
      if (listFolderName[index].length >= MAX_CHARACTER) {
        listColumnWidth.push(MAX_CHARACTER * CHARACTER_WIDTH + RIGHT_LEFT_PADDING + 'px')
      } else {
        listColumnWidth.push(listFolderName[index].length * CHARACTER_WIDTH + RIGHT_LEFT_PADDING + 'px')
      }
    }
    return listColumnWidth
  }

  const createListCellHeader = (listFolderNameArr = []) => {
    const listCellHeader = []
    const branchHeaderCell = { columnName: '', commonStyle: 'paddingHeadDelivery', cellStyle: 'personTitleDelivery' }
    listCellHeader.push(branchHeaderCell)
    listCellHeader.push({
      columnName: t('deliveryData.lblNmBranch'),
      commonStyle: 'paddingHeadDelivery',
      cellStyle: 'personTitleDelivery',
    })
    for (let index = 0; index < listFolderNameArr?.length; index++) {
      let folderNameCellHeader = { columnName: '', commonStyle: 'paddingHeadDelivery', cellStyle: '' }
      const folderName = listFolderNameArr[index]
      folderNameCellHeader.columnName = folderName
      listCellHeader.push(folderNameCellHeader)
    }

    return listCellHeader
  }

  const listToolBarComponent = [
    { type: TOOLBAR_TYPE.FILTER_LOGO, commonStyle: 'filterIconContainer', componentStyle: '' },
    {
      type: TOOLBAR_TYPE.YEAR_MONTH_CALENDAR,
      commonStyle: 'year-month-calendar-container',
      componentStyle: 'calendar-item',
    },
    { type: TOOLBAR_TYPE.SEARCH_LEFT, commonStyle: 'searchLeftContainer', componentStyle: 'search-item' },
    { type: TOOLBAR_TYPE.CSV, commonStyle: '', componentStyle: '' },
  ]

  const monthYearFormat = (date) => {
    if (date) {
      return date.substring(0, 7)
    }
  }

  let setDownloadParams = {
    screenName: 'delivery-data',
    searchText: searchInput,
    date: monthYearFormat(ymRegistRef.current),
    // date: dateFilter,
  }
  const toolbar = (
    <ToolBar
      listToolBarComponent={listToolBarComponent}
      setSearchInput={setSearchInput}
      searchInput={searchInput}
      handleSubmit={handleSearchInput}
      handleFilterDate={handleFilterDate}
      listData={listData}
      downloadParams={setDownloadParams}
      filterLabel={t('deliveryData.lblDisplayDate')}
      toolbarNoHeight={true}
      toolbarNoHeightPaddingTop={false}
      setFilterItemYmRegist={setFilterItemYmRegist}
      filterItemYmRegist={filterItemYmRegist}
      listYmRegistOption={listYmRegistOption}
      listYmRegist={listYmRegist}
    />
  )

  const redirectToDeliveryDataDetail = (data, cdCust, nmCust) => {
    const branch = {
      CD_BRANCH: data?.CD_BRANCH,
      NM_BRANCH: data?.NM_BRANCH,
    }
    const customer = {
      CD_CUST: cdCust,
      NM_CUST: nmCust,
    }
    const ymRegist = getYmRegistLabel()

    history.push({
      pathname: '/admin/delivery-data-detail',
      state: {
        branch: branch,
        customer: customer,
        date: ymRegist,
        listYmRegistOption: listYmRegistOption,
        filterItemYmRegist: filterItemYmRegist,
        searchInput: searchInput,
        listYmRegist: listYmRegist,
        screen: SCREEN_NAME.DELIVERY_DATA,
      },
    })
  }

  //return item of branch_list
  let handlerListBranchObjectInMap = (data, key, mapOfCust) => {
    //each branch has a list folders - (key-value) =(cd_branch ,[folder-name])
    let mapOfCdBranch = new Map()

    //each folder has a list files - (key-value) =(folder-name ,[file-name])
    // let mapOfListFile = new Map()
    let innerResponse = []
    for (const item of data) {
      let displayFolder = item.NM_DISP_FLD
      let cd_branch = item.CD_BRANCH
      if (!cd_branch) {
        continue
      }
      //check existed branch
      else if (mapOfCdBranch.has(cd_branch)) {
        let DISP_FLD = mapOfCdBranch.get(cd_branch).DISP_FLD
        if (DISP_FLD.filter((e) => e.NM_DISP_FLD === displayFolder).length === 0) {
          DISP_FLD.push({ NM_DISP_FLD: displayFolder, count: calculateSumOfNuCount(item, data) })
        } else {
          //check folder in branch
        }
      } else {
        //fist new folder
        let poolOfFolder = []

        poolOfFolder.push({ NM_DISP_FLD: displayFolder, count: calculateSumOfNuCount(item, data) })
        mapOfCdBranch.set(cd_branch, {
          CD_BRANCH: cd_branch,
          NM_BRANCH: item.NM_BRANCH || '',
          DISP_FLD: poolOfFolder,
        })
      }
    }

    for (const value of mapOfCdBranch.values()) {
      innerResponse.push(value)
    }
    return innerResponse
  }

  const calculateSumOfNuCount = (itemCurrent, data) => {
    let sum = 0
    data.forEach((item) => {
      if (
        itemCurrent.CD_CUST === item.CD_CUST &&
        itemCurrent.CD_BRANCH === item.CD_BRANCH &&
        itemCurrent.NM_DISP_FLD === item.NM_DISP_FLD
      ) {
        sum += item.NU_DL_COUNT
      }
    })
    return sum
  }

  const arrayToMapHandler = (data, listFolderFile) => {
    let mapOfCust = new Map()
    let response = {
      list_folder_name: listFolderFile,
      data: [],
    }
    for (const item of data) {
      if (!item.CD_CUST) {
        continue
      } else if (mapOfCust.has(item.CD_CUST)) {
        let prevList = mapOfCust.get(item.CD_CUST)
        prevList.list.push(item)
      } else {
        let list = []
        list.push(item)
        mapOfCust.set(item.CD_CUST, {
          NM_CUST: item.NM_CUST || '',
          CD_CUST: item.CD_CUST,
          list: list,
        })
      }
    }

    for (let [key, value] of mapOfCust.entries()) {
      let listOfBranch = handlerListBranchObjectInMap(
        value.list,
        key,
        mapOfCust
        // list_folder_name
      )
      response.data.push({
        CD_CUST: value.CD_CUST,
        NM_CUST: value.NM_CUST,
        BRANCH_LIST: listOfBranch,
      })
    }
    // response.list_folder_name = list_folder_name;
    return response
  }

  const getListDisplayFolderName = (data) => {
    let dataListFolder = data?.BRANCH_LIST?.reduce((prev, curr) => {
      for (const { NM_DISP_FLD } of curr.DISP_FLD) {
        prev.push(NM_DISP_FLD)
      }

      return prev
    }, [])

    let uniqueFolder = [...new Set([...dataListFolder])]

    return uniqueFolder.sort((a, b) => a.localeCompare(b, 'jp', { ignorePunctuation: true }))
  }

  const caculatePaddingLeftLastCell = (data) => {
    let dataListFolder = data?.reduce((prev, curr) => {
      for (const { NM_DISP_FLD } of curr.DISP_FLD) {
        prev.push(NM_DISP_FLD)
      }

      return prev
    }, [])

    let uniqueFolder = [...new Set([...dataListFolder])].sort((a, b) =>
      a.localeCompare(b, 'jp', { ignorePunctuation: true })
    )
    let CHARACTER_COUNT = uniqueFolder[uniqueFolder.length - 1].length
    if (CHARACTER_COUNT >= MAX_CHARACTER) {
      CHARACTER_COUNT = MAX_CHARACTER
    }

    let paddingLeft = (CHARACTER_WIDTH * CHARACTER_COUNT) / 2
    return paddingLeft
  }

  const isTableHaveScrollOnBottom = () => {
    let listColumnWidth,
      currentTableWidth,
      haveScroll = true
    if (listFilterData.length) {
      listFilterData.forEach((data) => {
        listColumnWidth = createListColumnWidth(getListDisplayFolderName(data), data.BRANCH_LIST)
        currentTableWidth = caculateTableWidth(listColumnWidth)
        if (currentTableWidth > tableDeliveryWidth) haveScroll = false
      })
      return haveScroll
    }
    return true
  }

  return (
    <div className="delivery-data__wrapper">
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {loading && <ProgressDialog label={t('headquarter.loading')} />}
      {loading ? (
        <ProgressDialog label={t('headquarter.loading')} />
      ) : (
        <div className="delivery-data__container">
          <div className={`${isTableHaveScrollOnBottom() ? 'table-scroll' : ''}`}>
            <div>{toolbar}</div>
            <div className="list-table-container">
              {listFilterData &&
                listFilterData
                  .sort((a, b) => a.CD_CUST.localeCompare(b.CD_CUST))
                  .map((data, index) => {
                    const listColumnWidth = createListColumnWidth(getListDisplayFolderName(data), data.BRANCH_LIST)
                    const currentTableWidth = caculateTableWidth(listColumnWidth)
                    return index === 0 ? (
                      <div
                        style={
                          currentTableWidth > tableDeliveryWidth
                            ? { maxWidth: tableDeliveryWidth + 'px', overflowX: 'auto' }
                            : {}
                        }
                      >
                        {/* Table */}
                        <TableDeliveryData
                          cdCust={data?.CD_CUST}
                          nmCust={data?.NM_CUST}
                          listColumnWidth={listColumnWidth}
                          // listCellHeader={listCellHeader}
                          // listFolderName={listFolderName}
                          listCellHeader={createListCellHeader(getListDisplayFolderName(data))}
                          listDataRender={data?.BRANCH_LIST}
                          listFolderName={getListDisplayFolderName(data)}
                          hasHeaderLine={true}
                          toolbar={toolbar}
                          hasStartLineItem={true}
                          startLineItem={t('deliveryData.customerInfo', {
                            cdCust: data?.CD_CUST,
                            nmCust: data?.NM_CUST,
                          })}
                          canViewDetail={true}
                          canUpdate={false}
                          canDelete={false}
                          handleRedirect={redirectToDeliveryDataDetail}
                          paddingLastCell={caculatePaddingLeftLastCell(data?.BRANCH_LIST)}
                        />
                        <div className="spacing"></div>
                      </div>
                    ) : (
                      <div
                        style={
                          currentTableWidth > tableDeliveryWidth
                            ? { maxWidth: tableDeliveryWidth + 'px', overflowX: 'auto' }
                            : {}
                        }
                      >
                        {/* Table */}
                        <TableDeliveryData
                          cdCust={data?.CD_CUST}
                          nmCust={data?.NM_CUST}
                          listColumnWidth={listColumnWidth}
                          // listCellHeader={listCellHeader}
                          // listFolderName={listFolderName}
                          listDataRender={data?.BRANCH_LIST}
                          listCellHeader={createListCellHeader(getListDisplayFolderName(data))}
                          listFolderName={getListDisplayFolderName(data)}
                          hasHeaderLine={true}
                          hasStartLineItem={true}
                          startLineItem={t('deliveryData.customerInfo', {
                            cdCust: data?.CD_CUST,
                            nmCust: data?.NM_CUST,
                          })}
                          canViewDetail={true}
                          canUpdate={false}
                          canDelete={false}
                          handleRedirect={redirectToDeliveryDataDetail}
                          paddingLastCell={caculatePaddingLeftLastCell(data?.BRANCH_LIST)}
                        />
                        <div className="spacing"></div>
                      </div>
                    )
                  })}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DeliveryData
