import { EMAIL_REGEX, PASSWORD_REGEX } from 'constants/constant'

const myPageValidate = (email1, email2, i18n) => {
  const messages = {}
  if (!EMAIL_REGEX.test(email1)) messages.email1 = i18n.t('validate.headquarter.email1Invalid')
  if (email2 && typeof email2 !== undefined && !EMAIL_REGEX.test(email2)) {
    messages.email2 = i18n.t('validate.headquarter.email2Invalid')
  }
  if (email2 && typeof email2 !== undefined && email2 === email1) {
    messages.email2 = i18n.t('validate.headquarter.email2Duplicate')
  }
  return messages
}

const changePasswordValidate = (oldPassword, currentPassword, newPassword, confirmPassword, i18n) => {
  const messages = {}

  if (currentPassword !== oldPassword)
    messages.currentPasswordError = i18n.t('validate.changePassword.msgErrorPassword')
  else messages.currentPasswordError = ''

  if (newPassword === oldPassword) messages.newPasswordError = i18n.t('validate.changePassword.msgErrorNoChange')
  else if (!PASSWORD_REGEX.test(newPassword))
    messages.newPasswordError = i18n.t('validate.changePassword.msgErrorPasswordNew')
  else messages.newPasswordError = ''

  if (confirmPassword !== newPassword)
    messages.confirmPasswordError = i18n.t('validate.changePassword.msgErrorPasswordNotMatch')
  else messages.confirmPasswordError = ''

  return messages
}

export { changePasswordValidate, myPageValidate }
