import './FormEdit.css'

import { IconButton } from '@material-ui/core'
import { Add, Cloud, DateRange, Description, Folder, Info, Notifications } from '@material-ui/icons'
import { IMAGES } from 'assets'
import {
  checkExistedFileInS3,
  formatDateWithPadding,
  getPreSignedUrlS3,
  handleDownload,
  parseDateTime,
} from 'commons/utilities'
import CustomCalendar from 'components/commons/FormInputs/CustomCalendar'
import Information from 'components/commons/Information/Information'
import { ACTION_FORM, PORTAL_TYPES } from 'constants/constant'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomCheckbox from '../CustomCheckbox/Checkbox'
import FormInputs from '../FormInputs/FormInputs'
import Image from '../Image/Image'
import MessageErrorDialog from '../MessageErrorDialog/MessageErrorDialog'
import styles from './FormEditsStyles'

function FormEdit({
  category,
  data,
  error,
  setFiles,
  handleUpdate,
  type,
  sysInfo,
  setIsValidStartDate,
  setIsValidEndDate,
  setIsInStartDateRange,
  setIsInEndDateRange,
  setShowModalCormfirmDuplicatedUpload,
  uploadFile,
  ...rest
}) {
  const { t } = useTranslation()
  const classes = styles()
  const [showMessageError, setShowMessageError] = useState(false)
  const [downloadErrorMessage, setDownloadErrorMessage] = useState('')
  // const exactDTRegist = data.DT_REGIST.substring(0, 19)

  const checkDuplicateUpload = (fileList) => {
    const arr = []
    fileList.forEach((file) => {
      let fileName = file.name
      arr.push(fileName)
    })
    const uniqueFiles = [...new Set(arr)]
    return arr.length !== uniqueFiles.length ? true : false
  }

  const handleUploadFile = (e) => {
    e.preventDefault()
    const fs = data.LT_ATTACH
      ? [...data.LT_ATTACH.filter((f) => f instanceof File), ...[...e.target.files]]
      : [...e.target.files]
    // case file exisis LT_ATTACH
    setFiles(fs)
    const isDupplicateUpload = checkDuplicateUpload(fs)
    if (isDupplicateUpload) {
      setShowModalCormfirmDuplicatedUpload(true)
    } else {
      uploadFile(fs)
    }
  }

  const handleDeleteFile = (index) => {
    try {
      let f = data.LT_ATTACH
      f.splice(index, 1)
      setFiles([...data.LT_ATTACH.filter((f) => f instanceof File)])
      handleUpdate('LT_ATTACH', f)
    } catch (err) {}
  }

  const handleDownloadFile = async (file) => {
    const errorMessage = t('commonComponent.notExistFileInS3')
    const fileName = file
    const key = 'attempt-topics/' + data.KB_MESSAGE + '_' + parseDateTime(data.DT_REGIST) + '/' + file
    const bucketName = process.env.REACT_APP_S3_DATA_BUCKET
    const params = {
      bucket: bucketName,
      filePath: key,
    }
    if (file instanceof File) {
      const result = handleDownload(file, fileName)
      setShowMessageError(!result)
      return result
    } else {
      const res = await checkExistedFileInS3(params)
      if (!res.data) {
        setDownloadErrorMessage(errorMessage)
      } else {
        setDownloadErrorMessage('')
        const filePath = await getPreSignedUrlS3(bucketName, key)
        const result = handleDownload(filePath, fileName)
        return result
      }
    }

    // if (file instanceof File) {
    //   const result = handleDownload(file)
    //   setShowMessageError(!result)
    //   return
    // }
    // const key = 'attempt-topics/' + data.KB_MESSAGE + '_' + parseDateTime(data.DT_REGIST) + '/' + file
    // const bucketName = process.env.REACT_APP_S3_DATA_BUCKET
    // const fileObject = await getFileFromS3(bucketName, key)
    // if (fileObject) {
    //   const { ContentType, Body } = fileObject
    //   const bufferArray = Body?.data
    //   const result = await handleDownload(bufferArray, fileName, ContentType)
    //   if (!result) {
    //     setDownloadErrorMessage(errorMessage)
    //   }
    // }
  }
  const renderPortalType = () => {
    if (category === PORTAL_TYPES.NOTICE)
      return (
        <>
          <FormInputs
            id="standard-basic"
            label={t('maintenanceCreateEdit.lblText')}
            value={data.NM_MESSAGE || ''}
            multiline
            required
            icon={<Description className="icon--deactive" />}
            type="text"
            name="NM_MESSAGE"
            setValue={(e) => handleUpdate('NM_MESSAGE', e)}
          />
          <FormInputs
            id="standard-basic"
            className={classes.width50}
            label={t('maintenanceCreateEdit.lblSender')}
            value={data.NM_SENDER || ''}
            icon={<Image src={IMAGES.ICON.SPEAKER} alt="Speaker Icon" className="iconForm icon--deactive" />}
            type="text"
            name="NM_SENDER"
            setValue={(e) => handleUpdate('NM_SENDER', e)}
          />
        </>
      )
    if (category === PORTAL_TYPES.TOPIC)
      return (
        <>
          <FormInputs
            id="standard-basic"
            label={t('maintenanceCreateEdit.lblText')}
            value={data.NM_MESSAGE || ''}
            multiline
            required
            icon={<Description className="icon--deactive" />}
            type="text"
            name="NM_MESSAGE"
            setValue={(e) => handleUpdate('NM_MESSAGE', e)}
          />
          <div className="folder-container">
            <div className="folder-icon">
              <Folder className="icon--deactive" />
            </div>
            <div className="folder-container_content">
              <div className={`folder-container_label`}>{t('maintenanceCreateEdit.lblFile')}</div>
              {data &&
                data.LT_ATTACH &&
                [...data.LT_ATTACH].map((file, index) => {
                  return (
                    <div className="file-container" key={(file instanceof File ? file.name : file) + index}>
                      <div className="spacingRight max-w-250 fileStyle">{file instanceof File ? file.name : file}</div>
                      <div className="spacingRight max-w-250">
                        <Image
                          src={IMAGES.ICON.DOWNLOAD_ICON}
                          alt="Delete Icon"
                          className="iconTrash"
                          onClick={() => handleDownloadFile(file)}
                        />
                        <Image
                          src={IMAGES.ICON.TRASH}
                          alt="Delete Icon"
                          className="iconTrash"
                          onClick={() => handleDeleteFile(index)}
                        />
                      </div>
                    </div>
                  )
                })}
              <label htmlFor="portal__upload--file">
                <input
                  id="portal__upload--file"
                  type="file"
                  hidden
                  multiple
                  onChange={handleUploadFile}
                  onClick={(event) => {
                    event.target.value = null
                  }}
                />
                <IconButton aria-label="upload picture" component="span">
                  <Add className="icon--active" />
                </IconButton>
              </label>
            </div>
          </div>
          <FormInputs
            className={classes.width50}
            label={t('maintenanceCreateEdit.lblSender')}
            value={data.NM_SENDER || ''}
            icon={<Image src={IMAGES.ICON.SPEAKER} alt="Speaker Icon" className="iconForm icon--deactive" />}
            type="text"
            name="NM_SENDER"
            setValue={(e) => handleUpdate('NM_SENDER', e)}
          />
        </>
      )
    if (category === PORTAL_TYPES.LINK)
      return (
        <FormInputs
          id="standard-basic"
          label={t('maintenanceCreateEdit.lblUrl')}
          value={data.NM_URL || ''}
          required
          icon={<Cloud className="icon--deactive" />}
          type="text"
          name="NM_URL"
          setValue={(e) => handleUpdate('NM_URL', e)}
          error={error && error.NM_URL}
          helperText={error && error.NM_URL ? error.NM_URL : null}
        />
      )
  }

  const renderInfoPortal = () => {
    return (
      <>
        <div className="formEdit-input ">
          <div className="input-spacing"></div>
          <FormInputs
            id="standard-basic"
            label={t('maintenanceCreateEdit.lblRuduserUpdate')}
            value={data.NM_RUDUSER || ''}
            type="text"
            name="NM_RUDUSER"
            setValue={(e) => handleUpdate('NM_RUDUSER', e)}
            disabled
            className="row_1"
          />
        </div>
        <div className="formEdit-input">
          <div className="input-spacing"></div>
          <div className="formEdit-input wrap-mb">
            <FormInputs
              id="standard-basic"
              label={t('maintenanceCreateEdit.lblDateRegist')}
              value={
                type === ACTION_FORM.UPDATE
                  ? data.DT_REGIST && formatDateWithPadding(data.DT_REGIST, 'yyyy-MM-dd HH:mm:ss')
                  : ''
              }
              type="text"
              name="DT_REGIST"
              disabled
              className="row_2"
            />
            <FormInputs
              id="standard-basic"
              label={t('maintenanceCreateEdit.lblDateUpdate')}
              value={
                type === ACTION_FORM.UPDATE && data.DT_UPDATE
                  ? formatDateWithPadding(data.DT_UPDATE, 'yyyy-MM-dd HH:mm:ss')
                  : ''
              }
              type="text"
              name="DT_UPDATE"
              disabled
              className="row_2"
            />
          </div>
        </div>
        <div className="formEdit-input">
          <div className="input-spacing"></div>
          <FormInputs
            id="standard-basic"
            label={t('maintenanceCreateEdit.lblDateDelete')}
            type="text"
            value={
              type === ACTION_FORM.UPDATE
                ? data.DT_DELETE && formatDateWithPadding(data.DT_DELETE, 'yyyy-MM-dd HH:mm:ss')
                : ''
            }
            name="DT_DELETE"
            disabled
            className="row_2"
          />
        </div>
      </>
    )
  }
  return (
    <div className="formEdit-container">
      {/* Message error */}
      {showMessageError && (
        <MessageErrorDialog
          message={t('maintenanceCreateEdit.errorFileMessage.FILE_NOT_FOUND_SYSTEM')}
          showMessageErrorDialog={setShowMessageError}
        />
      )}
      {downloadErrorMessage && (
        <MessageErrorDialog message={downloadErrorMessage} showMessageErrorDialog={setDownloadErrorMessage} />
      )}
      {/* Title */}
      <FormInputs
        id="standard-basic"
        label={t('maintenanceCreateEdit.lblTitle')}
        value={data.NM_TITLE || ''}
        required
        icon={<Notifications className="icon--deactive" />}
        type="text"
        name="NM_TITLE"
        setValue={(e) => handleUpdate('NM_TITLE', e)}
      />
      {renderPortalType()}
      {/* Calendar */}
      <div className="formEdit-calendar">
        <div className="calender-spacing">
          <DateRange className="icon--deactive" />
        </div>
        <div>
          <div className="calendar">
            <CustomCalendar
              className="calendar-item calendar-item-1"
              value={data?.DT_START ? new Date(data.DT_START) : ''}
              onChange={(date) => handleUpdate('DT_START', date)}
              setIsValidDate={setIsValidStartDate}
              setIsInDateRange={setIsInStartDateRange}
              label={t('maintenanceCreateEdit.lblStartDate')}
              name="DT_START"
              id="DT_START"
              required
              // minDate={new Date(new Date(sysInfo.DT_TODAY).setFullYear(new Date(sysInfo.DT_TODAY).getFullYear() - 100))}
              // maxDate={new Date(new Date(sysInfo.DT_TODAY).setFullYear(new Date(sysInfo.DT_TODAY).getFullYear() + 100))}
            />
            <CustomCalendar
              className="calendar-item calendar-item-2"
              value={data?.DT_END ? new Date(data.DT_END) : ''}
              onChange={(date) => handleUpdate('DT_END', date)}
              setIsValidDate={setIsValidEndDate}
              setIsInDateRange={setIsInEndDateRange}
              label={t('maintenanceCreateEdit.lblEndDate')}
              name="DT_END"
              id="DT_END"
              required
              // minDate={new Date(new Date(sysInfo.DT_TODAY).setFullYear(new Date(sysInfo.DT_TODAY).getFullYear() - 100))}
              // maxDate={new Date(new Date(sysInfo.DT_TODAY).setFullYear(new Date(sysInfo.DT_TODAY).getFullYear() + 100))}
            />
            <div className={`error-message ${error && error.DATE ? '' : 'hidden'}`}>
              {error && error.DATE ? error.DATE : null}
            </div>
            <div className={`error-message-dtEnd ${error && error.InvalidDtEnd ? '' : 'hidden'}`}>
              {error && error.InvalidDtEnd ? error.InvalidDtEnd : null}
            </div>
          </div>
        </div>
      </div>
      {/* Checkbox send email */}
      {category === PORTAL_TYPES.NOTICE ? (
        <div className="row_checkbox--send-email margin-20">
          <CustomCheckbox
            label={t('maintenanceCreateEdit.titleSendEmail')}
            checked={Boolean(Number(data.KB_MLSND))}
            onChange={(e) => handleUpdate('KB_MLSND', e)}
            className="checkbox"
          />
          <Information
            icon={<Info className={classes.iconInfo} />}
            message={t('maintenanceCreateEdit.infoTooltipSendEmail')}
          />

          {/* <CustomTooltip title={t('maintenanceCreateEdit.infoTooltipSendEmail')} placement="right-start">
            <Info className={classes.iconInfo} />
          </CustomTooltip> */}
        </div>
      ) : null}
      {renderInfoPortal()}
    </div>
  )
}

export default FormEdit
