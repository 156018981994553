export const GET_CUSTUSER_LIST_REQUEST = 'GET_CUSTUSER_LIST_REQUEST'
export const GET_CUSTUSER_LIST_SUCCESS = 'GET_CUSTUSER_LIST_SUCCESS'
export const GET_CUSTUSER_LIST_FAILED = 'GET_CUSTUSER_LIST_FAILED'

export const CREATE_CUSTUSER_REQUEST = 'CREATE_CUSTUSER_REQUEST'
export const CREATE_CUSTUSER_SUCCESS = 'CREATE_CUSTUSER_SUCCESS'
export const CREATE_CUSTUSER_FAILED = 'CREATE_CUSTUSER_FAILED'

export const UPDATE_CUSTUSER_REQUEST = 'UPDATE_CUSTUSER_REQUEST'
export const UPDATE_CUSTUSER_SUCCESS = 'UPDATE_CUSTUSER_SUCCESS'
export const UPDATE_CUSTUSER_FAILED = 'UPDATE_CUSTUSER_FAILED'

export const DELETE_CUSTUSER_REQUEST = 'DELETE_CUSTUSER_REQUEST'
export const DELETE_CUSTUSER_SUCCESS = 'DELETE_CUSTUSER_SUCCESS'
export const DELETE_CUSTUSER_FAILED = 'DELETE_CUSTUSER_FAILED'

export const GET_CUSTOMER_NAME_REQUEST = 'GET_CUSTOMER_NAME_REQUEST'
export const GET_CUSTOMER_NAME_SUCCESS = 'GET_CUSTOMER_NAME_SUCCESS'
export const GET_CUSTOMER_NAME_FAILED = 'GET_CUSTOMER_NAME_FAILED'
export const CLEAR_CUSTOMER_NAME = 'CLEAR_CUSTOMER_NAME'

export const CLEAR_CUSTUSER = 'RESET_CUSTUSER'
