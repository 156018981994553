import './PasswordForm.css'

import { FormControl, Grid, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { Lock, Visibility, VisibilityOff } from '@material-ui/icons'
import { SCREEN_NAME } from 'constants/constant'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  customPaswordMargin: {
    marginTop: '-2px',
  },
}))
const theme = createTheme({
  palette: {
    primary: green,
  },
})

function PasswordFormInput(props) {
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation()
  const customClassLabel = props.customClassLabel ? props.customClassLabel : ''

  return (
    <div className="content">
      <FormControl className={classes.textField}>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item className="wrap-lock-icon">
            {props.hasOwnProperty('noIcon') ? '' : <Lock style={{ color: green[500], fontSize: 24 }} />}
            {props.screen === SCREEN_NAME.HEAD_QUARTER || props.screen === SCREEN_NAME.CUSTUSER ? (
              <Lock style={{ opacity: 0.4, position: 'absolute', top: 12, left: -39, fontSize: 24 }} />
            ) : (
              ' '
            )}
          </Grid>
          <Grid item className="content-input">
            <ThemeProvider theme={theme}>
              <InputLabel
                className={customClassLabel}
                htmlFor="standard-adornment-password"
                style={{ color: green[500], paddingLeft: 41 }}
              >
                {props.required && (
                  <div
                    className={`changePass-labelEnd ${
                      props.screen === t('changePassword.lblScreenName') || props.screen === 'myPage'
                        ? 'left170'
                        : props.label === t('formHeadQuarter.lblPassWordReEnter')
                        ? 'left125'
                        : props.label === t('reissue.lblNewPassReEnter')
                        ? 'left200'
                        : props.label === t('formHeadQuarter.lblPassWord')
                        ? 'left70'
                        : ''
                    } 
                    `}
                  >
                    *
                  </div>
                )}
                <div className="changePass-label">
                  <div
                    className={`changePass-Text ${
                      props.screen === SCREEN_NAME.MY_PAGE || props.screen === SCREEN_NAME.CHANGE_PASSWORD
                        ? classes.customPaswordMargin
                        : ''
                    }`}
                  >
                    {props.label}
                  </div>
                </div>
              </InputLabel>
              <Input
                autoComplete="new-password"
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={props.password}
                inputProps={{ tabIndex: props.tabIndex }}
                onChange={(e) => {
                  props.setPassword(e.target.value)
                  if (!e.target.value) {
                    props.setErrorMessage('')
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      tabIndex={-1}
                      className="icon-visibility backgorund-password-hover"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {props.errorMessage && (
                <p
                  className={`error-message ${
                    (props.screen === 'headQuarter' || props.screen === 'custUser') &&
                    props.label === t('formHeadQuarter.lblPassWord')
                      ? 'left460-top18'
                      : (props.screen === 'headQuarter' || props.screen === 'custUser') &&
                        props.label === t('formHeadQuarter.lblPassWordReEnter')
                      ? 'left460-top18'
                      : ''
                  } `}
                >
                  {props.errorMessage}
                </p>
              )}
            </ThemeProvider>
          </Grid>
        </Grid>
      </FormControl>
    </div>
  )
}

export default PasswordFormInput
