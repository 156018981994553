import './ExcelTemplateCreateEdit.css'
import '../../../components/commons/FormEdits/FormEdit.css'

import { Button, IconButton } from '@material-ui/core'
import { Add, Folder } from '@material-ui/icons'
import InfoIcon from '@material-ui/icons/Info'
import { IMAGES } from 'assets'
import axios from 'commons/baseUrl'
// import axios from 'commons/baseUrl'
import {
  checkExistedFileInS3,
  checkFileExportFroms3,
  deleteFileFromS3,
  formatDateWithPadding,
  formatExtension,
  getPreSignedUrlS3,
  getSessionInfo,
  getTitleModalConfirm,
  getUserKbAdmin,
  handleDownloadSingleFile,
  shortenValue,
  uploadTemplateFile,
} from 'commons/utilities'
import {
  Image,
  MessageErrorDialog,
  ModalConfirm as DeleteResult,
  ModalConfirm,
  ModalConfirmNavigation,
} from 'components/commons'
import FormInputs from 'components/commons/FormInputs/FormInputs'
import Information from 'components/commons/Information/Information'
import ModalNotification from 'components/commons/ModalNotification/ModalNotification'
import ProgressDialog from 'components/commons/ProgressDialog/ProgressDialog'
import { API_CHECK_EXCEL_TEMPLATE_EXIST_IN_DB } from 'constants/apiPath'
import {
  ACTION_FORM,
  EXCEL_TEMPLATE_FORM,
  FILE_PDF_ERROR,
  FLAG_BOOLEAN,
  PDF_STATUS,
  // S3_CSV,
  // S3_OUTPUT,
  // S3_PDF_FOLDER,
  // S3_TEMPLATE_FOLDER,
  SCREEN_NAME,
} from 'constants/constant'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { excelTemplateValidate, fileExtensionValidate } from 'validation/excelTemplateValidate'

import {
  clearExcelTemplate,
  clearExportPDF,
  createExcelTemplateAction,
  exportPDF,
  updateExcelTemplateAction,
} from '../../../actions/excelTemplateActions'
import {
  CREATE_EXCEL_TEMPLATE_FAILED,
  CREATE_EXCEL_TEMPLATE_REQUEST,
  CREATE_EXCEL_TEMPLATE_SUCCESS,
  SQS_CONVERT_EXCEL2PDF_FAILED,
  SQS_CONVERT_EXCEL2PDF_REQUEST,
  SQS_CONVERT_EXCEL2PDF_SUCCESS,
  UPDATE_EXCEL_TEMPLATE_FAILED,
  UPDATE_EXCEL_TEMPLATE_REQUEST,
  UPDATE_EXCEL_TEMPLATE_SUCCESS,
} from '../../../constants/actionTypes/excelTemplateActionTypes'
import styles from './ExcelTemplateCreateEditStyles'

export default function ExcelTemplateCreateEdit() {
  const classes = styles()
  const { i18n, t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const [fileError, setFileError] = useState('')
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return {}
  })
  const createExcelTemplateReducer = useSelector((state) => state.createExcelTemplate || {})
  const updateExcelTemplateReducer = useSelector((state) => state.updateExcelTemplate || {})
  const exportPDFReducer = useSelector((state) => state.exportPDFReducer || {})
  const [type, setType] = useState(ACTION_FORM.CREATE)
  const [isBlocked, setIsBlocked] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [errorData, setErrorData] = useState({})
  const [isError, setIsError] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [dataForm, setDataForm] = useState({
    ID_TEMP: '',
    NM_TEMP: '',
    NM_USER: '',
    NM_TEST_CSV: '',
    DT_REGIST: '',
    DT_UPDATE: '',
    KB_DELETE: '0',
    DT_DELETE: '',
    NM_RUDUSER: userInfo.NM_USER,
  })
  // const [exportPDFISOString, setExportPDFISOString] = useState(new Date().toISOString())
  const exportPDFISOString = useRef(new Date().toISOString())
  const [searchInput, setSearchInput] = useState('')
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState(false)
  const [selectedTemplateFile, setSelectedTemplateFile] = useState('')
  const [selectedCSVFile, setSelectedCSVFile] = useState('')
  const [canCreateTestPdfFile, setCanCreateTestPdfFile] = useState(false)
  const [delayRenderComponent, setDelayRenderComponent] = useState(false)
  const [onSelectedTemplateFile, setOnSelectedTemplateFile] = useState({})
  const [onSelectedCSVFile, setOnSelectedCSVFile] = useState({})
  const [canReloadPdfStatus, setCanReloadPdfStatus] = useState(false)
  const [canDownloadPdf, setCanDownloadPdf] = useState(false)
  const [pdfStatus, setPdfStatus] = useState('')
  const [onClickPDF, setOnClickPDF] = useState(false)
  const [pdfExportFileName, setPdfExportFileName] = useState('')
  const [showModalCormfirmS3Override, setShowModalCormfirmS3Override] = useState(false)
  const [showModalCormfirmOverrideTemplateFile, setShowModalCormfirmOverrideTemplateFile] = useState(false)
  const [showModalExportingPDFNotification, setShowModalExportingPDFNotification] = useState(false)
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })
  const [showModalCormfirmDuplicatedCSV, setShowModalCormfirmDuplicatedCSV] = useState(false)
  const [showModalCormfirmDuplicatedTemplate, setShowModalCormfirmDuplicatedTemplate] = useState(false)
  const [createPermissionMessageError, setCreatePermissionMessageError] = useState('')
  const [updatePermissionMessageError, setUpdatePermissionMessageError] = useState('')
  const [onEditNmTemp, setOnEditNmTemp] = useState(false)
  const [fileErrorMessage, setFileErrorMessage] = useState('')
  // handle update field excel-template
  const handleUpdate = (name, value) => {
    setErrorData({})
    if (type === ACTION_FORM.UPDATE && name !== 'NM_TEMP') {
      setIsBlocked(true)
    } else {
      setOnEditNmTemp(true)
    }

    if (type === ACTION_FORM.CREATE) {
      setIsBlocked(true)
    }
    setDataForm((prev) => ({ ...prev, [name]: value }))
  }
  const timer = useRef(null)

  // clear and exit
  const handleCancelButton = async () => {
    // setCanReloadPdfStatus(false)
    // if (isBlocked) {
    // } else {
    //   setPdfStatus(PDF_STATUS.PENDING)
    // }
    history.push({
      pathname: '/admin/excel-template',
      state: {
        searchInput: searchInput,
        screen: SCREEN_NAME.EXCEL_TEMPLATE,
      },
    })
    // const fileTemplateName = selectedTemplateFile instanceof File ? selectedTemplateFile.name : selectedTemplateFile
    // if (type === ACTION_FORM.CREATE) {
    //   await deleteFileFromS3(S3_TEMPLATE_FOLDER + '/' + selectedTemplateFile.name)
    // }
    // if (selectedCSVFile) {
    //   await deleteFileFromS3(S3_CSV + '/' + selectedCSVFile.name)
    // }
    // if (dataForm.NM_TEST_CSV) {
    //   await deleteFileFromS3(S3_CSV + '/' + dataForm.NM_TEST_CSV)
    // }
    // await deleteFileFromS3(S3_OUTPUT + fileTemplateName)
    // await deleteFileFromS3(S3_PDF_FOLDER + '/' + formatExtension(fileTemplateName, 'xlsx', 'pdf'))
  }

  const deleteDataInS3 = async () => {
    const fileTemplateName = selectedTemplateFile instanceof File ? selectedTemplateFile.name : selectedTemplateFile

    if (type === ACTION_FORM.CREATE) {
      await deleteFileFromS3(process.env.REACT_APP_S3_TEMPLATE_FOLDER + '/' + selectedTemplateFile.name)
      await deleteFileFromS3(process.env.REACT_APP_S3_OUTPUT + fileTemplateName)
      //await deleteFileFromS3(S3_PDF_FOLDER + '/' + formatExtension(fileTemplateName, 'xlsx', 'pdf'))
    }
    if (selectedCSVFile) {
      await deleteFileFromS3(process.env.REACT_APP_S3_CSV + '/' + selectedCSVFile.name)
    }
    if (dataForm.NM_TEST_CSV) {
      await deleteFileFromS3(process.env.REACT_APP_S3_CSV + '/' + dataForm.NM_TEST_CSV)
    }
  }

  // submit data
  const handleSubmit = async (e) => {
    e.preventDefault()
    const kbAdmin = await getUserKbAdmin(userInfo)
    if (String(kbAdmin) === FLAG_BOOLEAN.FALSE) {
      if (type === ACTION_FORM.CREATE) {
        setCreatePermissionMessageError(t('commonComponent.noCreatePermission'))
      }
      if (type === ACTION_FORM.UPDATE) {
        setUpdatePermissionMessageError(t('commonComponent.noUpdatePermission'))
      }
    } else {
      const error = excelTemplateValidate(dataForm, i18n)
      if (error && error !== undefined) {
        setErrorData(error)
      } else {
        if (pdfStatus === PDF_STATUS.CREATING) {
          setShowModalExportingPDFNotification(true)
        } else {
          setShowModal(true)
          setErrorData()
        }
      }
    }
  }

  // useEffect(() => {
  //   clearInterval(timer.current)
  // }, [selectedTemplateFile])
  const overrideData = () => {
    const filePath = process.env.REACT_APP_S3_TEMPLATE_FOLDER
    dispatch(updateExcelTemplateAction({ ...dataForm, NM_RUDUSER: userInfo.NM_USER, KB_DELETE: '0' }))
    if (selectedTemplateFile instanceof File) {
      uploadTemplateFile(selectedTemplateFile, filePath)
    }

    setIsBlocked(false)
    setShowModalCormfirmOverrideTemplateFile(false)
    setShowModal(false)
  }

  const submitData = async () => {
    const filePath = process.env.REACT_APP_S3_TEMPLATE_FOLDER
    if (type === ACTION_FORM.CREATE) {
      const res = await axios.get(API_CHECK_EXCEL_TEMPLATE_EXIST_IN_DB, {
        params: { idTemp: selectedTemplateFile.name },
      })

      if (!res?.data?.data) {
        setShowModalCormfirmOverrideTemplateFile(false)
        uploadTemplateFile(selectedTemplateFile, filePath)
        dispatch(
          createExcelTemplateAction({ ...dataForm, NM_RUDUSER: userInfo.NM_USER, NM_USER: userInfo.NM_USER }, i18n)
        )
      } else {
        setShowModalCormfirmOverrideTemplateFile(true)
      }
    }

    if (type === ACTION_FORM.UPDATE) {
      if (selectedTemplateFile instanceof File) {
        uploadTemplateFile(selectedTemplateFile, filePath)
      }

      dispatch(updateExcelTemplateAction({ ...dataForm, NM_RUDUSER: userInfo.NM_USER }))
    }
    setIsBlocked(false)
    setShowModal(false)
  }

  const checkRequire = React.useCallback(() => {
    if (!dataForm.ID_TEMP || !dataForm.NM_TEMP) {
      return false
    }
    if (dataForm.ID_TEMP && dataForm.NM_TEMP) {
      return true
    }
    // return isBlocked
  }, [dataForm])

  const getTitleModal = (type, dataForm) => {
    const formType =
      type === ACTION_FORM.CREATE ? t('commonComponent.form.createButton') : t('commonComponent.form.updateButton')
    return getTitleModalConfirm(
      t('formExcelTemplate.msgConfirmSubmission.line1', {
        lblIDTemplate: t('formExcelTemplate.lblTemplateFile'),
        idTemplateValue: shortenValue(dataForm.ID_TEMP, 15),
        formType: formType,
      }),
      t('formExcelTemplate.msgConfirmSubmission.line2')
    )
  }

  useEffect(() => {
    const { data, type, searchInput } = location?.state || {}
    if (searchInput) {
      setSearchInput(searchInput)
    }
    if (type) setType(type)
    if (!data) {
      setDataForm({ ...dataForm, NM_RUDUSER: userInfo.NM_USER })
    } else {
      setSelectedTemplateFile(data.ID_TEMP)
      setDataForm({ ...data })
    }
    if (selectedTemplateFile && selectedCSVFile) {
      setCanCreateTestPdfFile(true)
    } else {
      setCanCreateTestPdfFile(false)
    }
  }, [location])

  useEffect(() => {
    setLoading(createExcelTemplateReducer.loading || updateExcelTemplateReducer.loading)
    // setIsBlocked(false)
    switch (createExcelTemplateReducer.type) {
      case CREATE_EXCEL_TEMPLATE_REQUEST:
        break
      case CREATE_EXCEL_TEMPLATE_FAILED:
        if (!getSessionInfo()) {
          setMessageError(createExcelTemplateReducer.message)
        }
        break
      case CREATE_EXCEL_TEMPLATE_SUCCESS:
        history.push({
          pathname: '/admin/excel-template',
          state: {
            searchInput: searchInput,
            screen: SCREEN_NAME.EXCEL_TEMPLATE,
          },
        })
        dispatch(clearExcelTemplate())
        break
      default:
        break
    }
    switch (updateExcelTemplateReducer.type) {
      case UPDATE_EXCEL_TEMPLATE_REQUEST:
        break
      case UPDATE_EXCEL_TEMPLATE_FAILED:
        if (!getSessionInfo()) {
          setMessageError(updateExcelTemplateReducer.message)
        }
        break
      case UPDATE_EXCEL_TEMPLATE_SUCCESS:
        history.push({
          pathname: '/admin/excel-template',
          state: {
            searchInput: searchInput,
            screen: SCREEN_NAME.EXCEL_TEMPLATE,
          },
        })
        dispatch(clearExcelTemplate())
        break
      default:
        break
    }
  }, [createExcelTemplateReducer, updateExcelTemplateReducer, history, dispatch])

  useEffect(() => {
    switch (exportPDFReducer.type) {
      case SQS_CONVERT_EXCEL2PDF_REQUEST:
        setCanReloadPdfStatus(true)
        setCanDownloadPdf(false)
        break
      case SQS_CONVERT_EXCEL2PDF_SUCCESS:
        setPdfStatus(PDF_STATUS.CREATING)
        setCanDownloadPdf(false)
        setCanReloadPdfStatus(true)
        dispatch(clearExportPDF())
        break
      case SQS_CONVERT_EXCEL2PDF_FAILED:
        if (!getSessionInfo()) {
          setMessageError(exportPDFReducer.message)
        }
        break
      default:
        break
    }
  }, [exportPDFReducer, dispatch])

  const handleOnTemplateFileChange = (event) => {
    const file = event.target.files[0]

    if (file) {
      if (file.name === selectedTemplateFile.name || file.name === dataForm.ID_TEMP) {
        setOnSelectedTemplateFile(file)
        setShowModalCormfirmDuplicatedTemplate(true)
      } else {
        setShowModalCormfirmDuplicatedTemplate(false)
        handleSetTemplateFile(file)
      }
    }
    event.target.value = ''
  }

  const handleSetTemplateFile = (file) => {
    setIsBlocked(true)
    setShowModalCormfirmDuplicatedTemplate(false)
    const { invalidTemplateFile, NU_UPMAXSZ } = fileExtensionValidate(
      file,
      sysInfo,
      EXCEL_TEMPLATE_FORM.TEMP_FILE,
      i18n
    )
    if (invalidTemplateFile) {
      const onlySupportXlsxExtension = `<div class="message__pre-text"><div>
      ${invalidTemplateFile.line1} </div><div>
      ${invalidTemplateFile.line2}</div></div>`
      setErrorData({ onlySupportXlsxExtension })
      setIsError(true)
      setCanSubmit(false)
    } else if (NU_UPMAXSZ) {
      setErrorData({ NU_UPMAXSZ })
      setIsError(true)
      setCanSubmit(false)
    } else {
      setIsError(false)
      setDataForm({ ...dataForm, ID_TEMP: file.name })
      setSelectedTemplateFile(file)
    }
    if (selectedCSVFile) {
      setCanCreateTestPdfFile(true)
    } else {
      setCanCreateTestPdfFile(false)
    }
  }

  const handleOnCSVFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      if (file.name === selectedCSVFile.name) {
        setOnSelectedCSVFile(file)
        setShowModalCormfirmDuplicatedCSV(true)
      } else {
        setShowModalCormfirmDuplicatedCSV(false)
        handleSetCSVFile(file)
      }
    }
    event.target.value = ''
  }

  const handleSetCSVFile = (file) => {
    setShowModalCormfirmDuplicatedCSV(false)
    const { invalidTestCSVFile, NU_UPMAXSZ } = fileExtensionValidate(
      file,
      sysInfo,
      EXCEL_TEMPLATE_FORM.TEST_CSV_FILE,
      i18n
    )
    if (invalidTestCSVFile) {
      const onlySupportCSVExtenstion = `<div class="message__pre-text"><div>${invalidTestCSVFile.line1}</div><div>
      ${invalidTestCSVFile.line2}</div></div>`
      setErrorData({ onlySupportCSVExtenstion })
      setIsError(true)
      setCanSubmit(false)
    } else if (NU_UPMAXSZ) {
      setErrorData({ NU_UPMAXSZ })
      setIsError(true)
      setCanSubmit(false)
    } else {
      setIsError(false)
      setDataForm({ ...dataForm, NM_TEST_CSV: file.name })
      setSelectedCSVFile(file)
    }
    if (selectedTemplateFile) {
      setCanCreateTestPdfFile(true)
    } else {
      setCanCreateTestPdfFile(false)
    }
  }

  const handleExportPdf = async () => {
    setCanCreateTestPdfFile(false)
    const params = {
      bucket: process.env.REACT_APP_S3_DATA_BUCKET,
      filePath: process.env.REACT_APP_S3_TEMPLATE_FOLDER + '/' + selectedTemplateFile?.name,
    }
    const res = await checkExistedFileInS3(params)
    if (res.data) {
      setShowModalCormfirmS3Override(true)
    } else {
      exportFilePdf()
    }
  }
  const deleteFileError = async () => {
    try {
      setFileErrorMessage(() => '')
      const filePath = fileError
      setFileError('')
      await deleteFileFromS3(filePath)
      // setPdfStatus(PDF_STATUS.PENDING)
      setCanCreateTestPdfFile(true)
      dispatch(clearExportPDF())
      clearInterval(timer.current)
      setCanReloadPdfStatus(false)
    } catch {}
  }
  const exportFilePdf = async () => {
    setOnClickPDF(true)
    setShowModalCormfirmS3Override(false)
    exportPDFISOString.current = new Date().toISOString()
    // setPdfStatus(PDF_STATUS.CREATING)
    const fileTemplateName = selectedTemplateFile instanceof File ? selectedTemplateFile.name : selectedTemplateFile
    if (selectedTemplateFile instanceof File) {
      await uploadTemplateFile(selectedTemplateFile, process.env.REACT_APP_S3_TEMPLATE_FOLDER)
    }

    await uploadTemplateFile(selectedCSVFile, process.env.REACT_APP_S3_CSV)
    dispatch(
      exportPDF({
        templatePath: process.env.REACT_APP_S3_TEMPLATE_FOLDER + '/' + fileTemplateName,
        dataPath: process.env.REACT_APP_S3_CSV + '/' + selectedCSVFile.name,
        outputPath: process.env.REACT_APP_S3_OUTPUT + fileTemplateName,
        outputPdf: process.env.REACT_APP_S3_PDF_FOLDER,
      })
    )
  }

  const reloadStatus = async () => {
    const fileTemplateName = selectedTemplateFile instanceof File ? selectedTemplateFile.name : selectedTemplateFile
    const selectedDataFile = selectedCSVFile instanceof File ? selectedCSVFile.name : selectedCSVFile
    const params = {
      bucket: process.env.REACT_APP_S3_DATA_BUCKET,
      fileData: process.env.REACT_APP_S3_CSV + '/' + selectedDataFile,
      filePath:
        process.env.REACT_APP_S3_PDF_FOLDER +
        '/' +
        fileTemplateName.toString().substring(0, fileTemplateName.length - 5) +
        '.pdf',
      checkNewFile: true,
      exportPDFISOString: exportPDFISOString.current,
    }
    const res = await checkFileExportFroms3(params)
    // outputPdfErrorMessage
    if (
      res.STS.toString() === '10' ||
      res.STS.toString() === '11' ||
      res.STS.toString() === '12' ||
      res.STS.toString() === '13'
    ) {
      if (res.STS.toString() === '10') {
        setFileErrorMessage(
          outputPdfErrorMessage(FILE_PDF_ERROR.FILE_NO_SUCH_KEY.line1, FILE_PDF_ERROR.FILE_NO_SUCH_KEY.line2)
        )
      } else if (res.STS.toString() === '11') {
        setFileErrorMessage(outputPdfErrorMessage(FILE_PDF_ERROR.FILE_ERROR.line1, FILE_PDF_ERROR.FILE_ERROR.line2))
      } else if (res.STS.toString() === '12') {
        setFileErrorMessage(outputPdfErrorMessage(FILE_PDF_ERROR.FORMAT_ERROR.line1, FILE_PDF_ERROR.FORMAT_ERROR.line2))
      } else if (res.STS.toString() === '13') {
        setFileErrorMessage(outputPdfErrorMessage(FILE_PDF_ERROR['OKB'].line1, FILE_PDF_ERROR['OKB'].line2))
      }
      setFileError(res.data)
      clearInterval(timer.current)
      // setFileErrorMessage(res.message)
      setPdfStatus(PDF_STATUS.ERROR)
      setCanReloadPdfStatus(false)
    } else if (res.data) {
      setPdfStatus(PDF_STATUS.DONE)
      setCanReloadPdfStatus(false)
      setCanDownloadPdf(true)
      fileExportPdfName()
      setFileError('')
    }
  }

  useEffect(() => {
    if (checkRequire() && (isBlocked || onEditNmTemp)) {
      setCanSubmit(true)
    } else {
      setCanSubmit(false)
    }
  }, [dataForm, isBlocked, onEditNmTemp])

  useEffect(() => {
    if (onClickPDF && pdfStatus === PDF_STATUS.CREATING) {
      // useRef value stored in .current property
      timer.current = setInterval(async () => {
        const fileTemplateName = selectedTemplateFile instanceof File ? selectedTemplateFile.name : selectedTemplateFile
        const selectedDataFile = selectedCSVFile instanceof File ? selectedCSVFile.name : selectedCSVFile
        const params = {
          bucket: process.env.REACT_APP_S3_DATA_BUCKET,
          fileData: process.env.REACT_APP_S3_CSV + '/' + selectedDataFile,
          filePath:
            process.env.REACT_APP_S3_PDF_FOLDER +
            '/' +
            fileTemplateName.toString().substring(0, fileTemplateName.length - 5) +
            '.pdf',
          checkNewFile: true,
          exportPDFISOString: exportPDFISOString.current,
        }
        const res = await checkFileExportFroms3(params)
        // outputPdfErrorMessage
        if (
          res.STS.toString() === '10' ||
          res.STS.toString() === '11' ||
          res.STS.toString() === '12' ||
          res.STS.toString() === '13'
        ) {
          if (res.STS.toString() === '10') {
            setFileErrorMessage(
              outputPdfErrorMessage(FILE_PDF_ERROR.FILE_NO_SUCH_KEY.line1, FILE_PDF_ERROR.FILE_NO_SUCH_KEY.line2)
            )
          } else if (res.STS.toString() === '11') {
            setFileErrorMessage(outputPdfErrorMessage(FILE_PDF_ERROR.FILE_ERROR.line1, FILE_PDF_ERROR.FILE_ERROR.line2))
          } else if (res.STS.toString() === '12') {
            setFileErrorMessage(
              outputPdfErrorMessage(FILE_PDF_ERROR.FORMAT_ERROR.line1, FILE_PDF_ERROR.FORMAT_ERROR.line2)
            )
          } else if (res.STS.toString() === '13') {
            setFileErrorMessage(outputPdfErrorMessage(FILE_PDF_ERROR['OKB'].line1, FILE_PDF_ERROR['OKB'].line2))
          }
          setFileError(res.data)
          clearInterval(timer.current)
          // setFileErrorMessage(res.message)
          setPdfStatus(PDF_STATUS.ERROR)
          setCanReloadPdfStatus(false)
        } else if (res.data) {
          setPdfStatus(PDF_STATUS.DONE)
          setCanReloadPdfStatus(false)
          setCanDownloadPdf(true)
          fileExportPdfName()
          setFileError('')
        }
      }, 60000)

      // clear on component unmount
      return () => {
        clearInterval(timer.current)
      }
    }
  }, [pdfStatus, onClickPDF])

  useEffect(() => {
    setPdfStatus(PDF_STATUS.PENDING)
  }, [])
  useEffect(() => {
    setFileError('')
    clearInterval(timer.current)
    setOnClickPDF(false)
    setCanReloadPdfStatus(false)
    if (pdfStatus === PDF_STATUS.CREATING) {
      setPdfStatus(() => {
        return PDF_STATUS.PENDING
      })
    }
  }, [selectedTemplateFile, selectedCSVFile])
  const handleDownloadPdf = async () => {
    const fileTemplateName = selectedTemplateFile instanceof File ? selectedTemplateFile.name : selectedTemplateFile
    // const fileUrl = S3_BUCKET_URL + S3_PDF_FOLDER + '/' + formatExtension(fileTemplateName, 'xlsx', 'pdf')
    const fileName = formatExtension(fileTemplateName, '.xlsx', '.pdf')
    const key = process.env.REACT_APP_S3_PDF_FOLDER + '/' + fileName
    const bucketName = process.env.REACT_APP_S3_DATA_BUCKET
    const fileUrl = await getPreSignedUrlS3(bucketName, key)
    handleDownloadSingleFile(fileUrl, fileName)
  }

  const fileExportPdfName = () => {
    if (!canCreateTestPdfFile) {
      const fileTemplateName = selectedTemplateFile instanceof File ? selectedTemplateFile.name : selectedTemplateFile
      setPdfExportFileName(fileTemplateName.substring(0, fileTemplateName.length - 5) + '.pdf')
    }
    // return formatExtension(fileTemplateName, 'xlsx', 'pdf')
  }
  const confirmNavigationMessage = `<div class="message__pre-text"><div>
  ${t('formExcelTemplate.msgConfirmNavigate.line1')} </div><div>
  ${t('formExcelTemplate.msgConfirmNavigate.line2')}</div></div>`

  const inCreatingPdfConfirmNavigationMessage = `<div class="message__pre-text"><div>
  ${t('formExcelTemplate.msgInCreatingPdfConfirmNavigate.line1')} </div><div>
  ${t('formExcelTemplate.msgInCreatingPdfConfirmNavigate.line2')}</div></div>`

  useEffect(() => {
    const handler = setTimeout(() => {
      setDelayRenderComponent(true)
    }, 1)
    return () => {
      clearTimeout(handler)
    }
  }, [])

  useEffect(() => {
    if (createExcelTemplateReducer.message === 'success') {
      history.push({
        pathname: '/admin/excel-template',
        state: {
          searchInput: searchInput,
          screen: SCREEN_NAME.EXCEL_TEMPLATE,
        },
      })
      dispatch(clearExcelTemplate())
    }
    if (createExcelTemplateReducer.message === 'failed') {
      setIsError(true)
      dispatch(clearExcelTemplate())
    }
  }, [createExcelTemplateReducer, selectedTemplateFile, history, dispatch])

  const outputPdfErrorMessage = (line1, line2) => {
    return `<div class="message__pre-text"><div>
    ${line1} </div><div>
    ${line2}</div></div>`
  }
  const duplicateUploadMessage = `<div class="message__pre-text"><div>
  ${t('commonComponent.duplicatedUpload.line1')} </div><div>
  ${t('commonComponent.duplicatedUpload.line2')}</div></div>`

  const notYetCompleteExportPDF = `<div class="message__pre-text"><div>
  ${t('excelTemplate.notYetCompleteExportPDF.line1')} </div><div>
  ${t('excelTemplate.notYetCompleteExportPDF.line2')}</div></div>`

  const overrideFileS3Message = `<div class="message__pre-text">
  <div class ="message-container" >
    <div class = "display-inline">${`「 `}</div>
    <div class="modal-confirm-overflow-text">${t('excelTemplate.msgOverrideS3Confirm.line1', {
      fileName: selectedTemplateFile?.name,
    })}
    </div>
    <div class = "display-inline">${`」`}</div>
  </div>
  <div class = "message-line2">${t('excelTemplate.msgOverrideS3Confirm.line2')}</div>
  <div>${t('excelTemplate.msgOverrideS3Confirm.line3')}</div>
  </div>`

  return (
    <div className="portal-createWrapper">
      <ModalConfirmNavigation
        title={pdfStatus === PDF_STATUS.CREATING ? inCreatingPdfConfirmNavigationMessage : confirmNavigationMessage}
        isBlocked={isBlocked}
        searchInput={searchInput}
        deleteData={deleteDataInS3}
      />

      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {isError && errorData && errorData.onlySupportXlsxExtension && (
        <MessageErrorDialog message={errorData.onlySupportXlsxExtension} showMessageErrorDialog={setIsError} />
      )}
      {isError && errorData && errorData.onlySupportCSVExtenstion && (
        <MessageErrorDialog message={errorData.onlySupportCSVExtenstion} showMessageErrorDialog={setIsError} />
      )}
      {isError && errorData && errorData.NU_UPMAXSZ && (
        <MessageErrorDialog message={errorData.NU_UPMAXSZ} showMessageErrorDialog={setIsError} />
      )}
      {loading && <ProgressDialog label={t('formExcelTemplate.loading')} />}
      <ModalConfirm
        title={getTitleModal(type, dataForm)}
        showModal={showModal}
        setShowModal={setShowModal}
        handleFunction={submitData}
      />
      {showModalExportingPDFNotification && (
        <ModalNotification
          message={notYetCompleteExportPDF}
          showModalNotification={setShowModalExportingPDFNotification}
          onClose={() => {
            setIsBlocked(true)
          }}
        />
      )}

      <ModalConfirm
        title={duplicateUploadMessage}
        showModal={showModalCormfirmDuplicatedTemplate}
        setShowModal={setShowModalCormfirmDuplicatedTemplate}
        handleFunction={() => handleSetTemplateFile(onSelectedTemplateFile)}
      />
      <ModalConfirm
        title={duplicateUploadMessage}
        showModal={showModalCormfirmDuplicatedCSV}
        setShowModal={setShowModalCormfirmDuplicatedCSV}
        handleFunction={() => handleSetCSVFile(onSelectedCSVFile)}
      />
      <ModalConfirm
        title={overrideFileS3Message}
        showModal={showModalCormfirmS3Override}
        setShowModal={setShowModalCormfirmS3Override}
        handleFunction={() => exportFilePdf()}
      />

      <ModalConfirm
        title={overrideFileS3Message}
        showModal={showModalCormfirmOverrideTemplateFile}
        setShowModal={setShowModalCormfirmOverrideTemplateFile}
        handleFunction={() => overrideData()}
      />
      {createPermissionMessageError && (
        <MessageErrorDialog
          message={createPermissionMessageError}
          showMessageErrorDialog={setCreatePermissionMessageError}
        />
      )}
      {updatePermissionMessageError && (
        <MessageErrorDialog
          message={updatePermissionMessageError}
          showMessageErrorDialog={setUpdatePermissionMessageError}
        />
      )}
      <DeleteResult
        title={fileErrorMessage}
        showModal={fileError !== ''}
        handleFunction={deleteFileError}
        screen={'excelTemplate'}
      />
      {delayRenderComponent && (
        <form>
          <div className="portal-create form-scroll">
            <div className="portal-create-content form-min-width">
              <div className="formEdit-container">
                <div className="folder-container">
                  <div className="folder-icon">
                    <Folder className="icon--deactive" />
                  </div>
                  <div className="folder-container_content">
                    <div className={`folder-container_label ${selectedTemplateFile ? 'shinked' : ''}`}>
                      {t('formExcelTemplate.lblTemplateFile')}
                      <span className={` ${selectedTemplateFile ? 'require-shinked' : 'require-star'}`}></span>
                    </div>
                    <label htmlFor="excel-template__upload--file">
                      {type === ACTION_FORM.UPDATE && (
                        <input id="excel-template__upload--file" type="file" hidden disabled={true} accept=".xlsx" />
                      )}
                      {type === ACTION_FORM.CREATE && (
                        <input
                          id="excel-template__upload--file"
                          type="file"
                          hidden
                          onChange={handleOnTemplateFileChange}
                          accept=".xlsx"
                        />
                      )}
                      <div className="file-container">
                        {type === ACTION_FORM.UPDATE && (
                          <div className="spacingRight templateFileDisabledStyle">
                            {selectedTemplateFile instanceof File ? selectedTemplateFile.name : selectedTemplateFile}
                          </div>
                        )}
                        {type === ACTION_FORM.CREATE && (
                          <div className="spacingRight templateFileStyle">
                            {selectedTemplateFile instanceof File ? selectedTemplateFile.name : selectedTemplateFile}
                          </div>
                        )}

                        <div className="spacingRight"></div>
                      </div>
                      {!selectedTemplateFile && (
                        <IconButton aria-label="upload picture" component="span">
                          <Add className="icon--active" />
                        </IconButton>
                      )}
                    </label>
                  </div>
                </div>
                <div className={classes.pb30}>
                  <FormInputs
                    className={classes.width50}
                    label={t('formExcelTemplate.lblNmTemplate')}
                    value={dataForm.NM_TEMP || ''}
                    icon={<Image src={IMAGES.ICON.SPEAKER} alt="Speaker Icon" className="iconForm icon--deactive" />}
                    type="text"
                    name="NM_TEMP"
                    required
                    setValue={(e) => handleUpdate('NM_TEMP', e)}
                  />
                </div>
                <div className="folder-container">
                  <div className="folder-icon">
                    <Folder className="icon--deactive" />
                  </div>
                  <div className="folder-container_content">
                    <div className="label-info-container">
                      <div
                        className={`folder-container_label  ${classes.labelCustomExcelTemplate} ${
                          selectedCSVFile ? 'shinked-no-require' : ''
                        }`}
                      >
                        {t('formExcelTemplate.lblTestCSVFile')}
                      </div>
                      {!selectedCSVFile && (
                        <Information
                          icon={<InfoIcon className={`${classes.iconInfo}`} />}
                          message={t('excelTemplate.lblPdfInfo')}
                        />
                      )}
                    </div>
                    <label htmlFor="excel-template__upload--csvfile">
                      {
                        <input
                          id="excel-template__upload--csvfile"
                          type="file"
                          hidden
                          onChange={handleOnCSVFileChange}
                          accept=".csv,.txt"
                        />
                      }
                      <div className="file-container">
                        <div className="spacingRight templateFileStyle">
                          {selectedCSVFile instanceof File ? selectedCSVFile.name : selectedCSVFile}
                        </div>
                        <div className="spacingRight"></div>
                      </div>
                      {!selectedCSVFile && (
                        <IconButton aria-label="upload picture" component="span">
                          <Add className="icon--active" />
                        </IconButton>
                      )}
                    </label>
                  </div>
                </div>
                <div className={classes.testPdfButtonContainer}>
                  <Button
                    variant="contained"
                    className="testPdfButton"
                    disabled={!canCreateTestPdfFile}
                    onClick={handleExportPdf}
                  >
                    {t('formExcelTemplate.lblTestPdfCreate')}
                  </Button>
                </div>
                <div className="status-container">
                  <div className={classes.labelStatus}>
                    <span className={`status-label-text ${classes.labelCustomExcelTemplate}`}>
                      {t('excelTemplate.lblStatus')}
                    </span>
                  </div>
                  <Information
                    icon={<InfoIcon className={`${classes.iconInfo}`} />}
                    message={t('excelTemplate.lblStatusInfo.line1')}
                    line2={t('excelTemplate.lblStatusInfo.line2')}
                  />
                </div>

                <div className={classes.reloadContainer}>
                  <div className={classes.reloadButtonContainer}>
                    <Button
                      variant="outlined"
                      className={classes.reloadButton}
                      onClick={reloadStatus}
                      disabled={!canReloadPdfStatus}
                    >
                      {canReloadPdfStatus ? (
                        <Image src={IMAGES.ICON.RELOAD_ENABLE} alt="Reload Icon" className="iconReload"></Image>
                      ) : (
                        <Image src={IMAGES.ICON.RELOAD_DISABLE} alt="Reload Icon" className="iconReload"></Image>
                      )}
                    </Button>
                  </div>
                  <div className={classes.status}>
                    {pdfStatus === PDF_STATUS.PENDING && (
                      <div className={classes.statusText}>{t('excelTemplate.status.pending')}</div>
                    )}
                    {pdfStatus === PDF_STATUS.CREATING && (
                      <div className={classes.statusText}>{t('excelTemplate.status.creating')}</div>
                    )}
                    {pdfStatus === PDF_STATUS.DONE && (
                      <div className={classes.statusText}>{t('excelTemplate.status.done')}</div>
                    )}
                    {pdfStatus === PDF_STATUS.ERROR && (
                      <div className={classes.statusText}>{t('excelTemplate.status.error')}</div>
                    )}
                  </div>
                  {canDownloadPdf && (
                    <div className={classes.download}>
                      <span className="file-pdf-link" onClick={handleDownloadPdf}>
                        {pdfExportFileName}
                      </span>
                    </div>
                  )}

                  {/* <Information
                    icon={<InfoIcon className={`${classes.iconInfo}`} />}
                    message={t('formDistribution.infoDefaultTemplate')}
                  /> */}
                </div>
                <div className="formEdit-input ">
                  <div className="input-spacing"></div>
                  <FormInputs
                    id="standard-basic"
                    label={t('formExcelTemplate.lblNmRuduserUpdate')}
                    value={dataForm.NM_RUDUSER || ''}
                    type="text"
                    name="NM_RUDUSER"
                    setValue={(e) => handleUpdate('NM_RUDUSER', e)}
                    disabled
                    className="row_1"
                  />
                </div>
                <div className="formEdit-input">
                  <div className="input-spacing"></div>
                  <div className="formEdit-input wrap-mb">
                    <FormInputs
                      id="standard-basic"
                      label={t('formExcelTemplate.lblDateRegist')}
                      value={
                        type === ACTION_FORM.UPDATE
                          ? dataForm.DT_REGIST &&
                            formatDateWithPadding(new Date(dataForm.DT_REGIST), 'yyyy-MM-dd HH:mm:ss')
                          : ''
                      }
                      type="text"
                      name="DT_REGIST"
                      disabled
                      className="row_2"
                    />
                    <FormInputs
                      id="standard-basic"
                      label={t('formExcelTemplate.lblDateUpdate')}
                      value={
                        type === ACTION_FORM.UPDATE && dataForm.DT_UPDATE
                          ? formatDateWithPadding(new Date(dataForm.DT_UPDATE), 'yyyy-MM-dd HH:mm:ss')
                          : ''
                      }
                      type="text"
                      name="DT_UPDATE"
                      disabled
                      className="row_2"
                    />
                  </div>
                </div>
              </div>
              <div className="buttonSpace">
                <Button
                  className="button submit-button"
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained"
                  disabled={!canSubmit}
                >
                  {t('formExcelTemplate.btnSubmit')}
                </Button>
                <Button className="button clear-button" onClick={handleCancelButton} variant="outlined">
                  {t('formExcelTemplate.btnCancel')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}
