import {
  CLEAR_CUSTOMER_NAME,
  CLEAR_CUSTUSER,
  CREATE_CUSTUSER_FAILED,
  CREATE_CUSTUSER_REQUEST,
  CREATE_CUSTUSER_SUCCESS,
  DELETE_CUSTUSER_FAILED,
  DELETE_CUSTUSER_REQUEST,
  DELETE_CUSTUSER_SUCCESS,
  GET_CUSTOMER_NAME_FAILED,
  GET_CUSTOMER_NAME_REQUEST,
  GET_CUSTOMER_NAME_SUCCESS,
  GET_CUSTUSER_LIST_FAILED,
  GET_CUSTUSER_LIST_REQUEST,
  GET_CUSTUSER_LIST_SUCCESS,
  UPDATE_CUSTUSER_FAILED,
  UPDATE_CUSTUSER_REQUEST,
  UPDATE_CUSTUSER_SUCCESS,
} from 'constants/actionTypes/custUserActionTypes'

const listCustUser = (state = { listCustUser: [] }, action) => {
  switch (action.type) {
    case GET_CUSTUSER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_CUSTUSER_LIST_REQUEST,
      }
    case GET_CUSTUSER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        listCustUser: action.payload,
        type: GET_CUSTUSER_LIST_SUCCESS,
      }
    case GET_CUSTUSER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        listCustUser: [],
        message: action.payload,
        type: GET_CUSTUSER_LIST_FAILED,
      }
    default:
      return state
  }
}

const createCustUser = (state = { custUser: {} }, action) => {
  switch (action.type) {
    case CREATE_CUSTUSER_REQUEST:
      return {
        ...state,
        loading: true,
        custUser: action.payload,
        type: CREATE_CUSTUSER_REQUEST,
      }
    case CREATE_CUSTUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        custUser: { ...action.payload },
        type: CREATE_CUSTUSER_SUCCESS,
        message: 'success',
      }
    case CREATE_CUSTUSER_FAILED:
      return {
        ...state,
        loading: false,
        custUser: {},
        message: action.payload,
        type: CREATE_CUSTUSER_FAILED,
      }
    case CLEAR_CUSTUSER:
      return { type: CLEAR_CUSTUSER, loading: false, status: null, custUser: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, custUser: {}, message: '' }
  }
}

const updateCustUser = (state = { custUser: {} }, action) => {
  switch (action.type) {
    case UPDATE_CUSTUSER_REQUEST:
      return {
        ...state,
        loading: true,
        custUser: action.payload,
        type: UPDATE_CUSTUSER_REQUEST,
      }
    case UPDATE_CUSTUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        custUser: { ...action.payload },
        type: UPDATE_CUSTUSER_SUCCESS,
        message: 'success',
      }
    case UPDATE_CUSTUSER_FAILED:
      return {
        ...state,
        loading: false,
        custUser: {},
        message: action.payload,
        type: UPDATE_CUSTUSER_FAILED,
      }
    case CLEAR_CUSTUSER:
      return { type: CLEAR_CUSTUSER, loading: false, status: null, custUser: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, custUser: {}, message: '' }
  }
}

const deleteCustUser = (state = { custUser: {} }, action) => {
  switch (action.type) {
    case DELETE_CUSTUSER_REQUEST:
      return {
        ...state,
        loading: true,
        custUser: action.payload,
        type: DELETE_CUSTUSER_REQUEST,
      }
    case DELETE_CUSTUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        custUser: { ...action.payload },
        type: DELETE_CUSTUSER_SUCCESS,
        message: 'success',
      }
    case DELETE_CUSTUSER_FAILED:
      return {
        ...state,
        loading: false,
        custUser: {},
        message: action.payload,
        type: DELETE_CUSTUSER_FAILED,
      }
    case CLEAR_CUSTUSER:
      return { type: CLEAR_CUSTUSER, loading: false, status: null, custUser: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, custUser: {}, message: '' }
  }
}

const getCustomerName = (state = { customerName: [] }, action) => {
  switch (action.type) {
    case GET_CUSTOMER_NAME_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_CUSTOMER_NAME_REQUEST,
      }
    case GET_CUSTOMER_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        customerName: action.payload,
        type: GET_CUSTOMER_NAME_SUCCESS,
      }
    case GET_CUSTOMER_NAME_FAILED:
      return {
        ...state,
        loading: false,
        customerName: [],
        message: action.payload,
        type: GET_CUSTOMER_NAME_FAILED,
      }
    case CLEAR_CUSTOMER_NAME:
      return {
        ...state,
        loading: false,
        customerName: [],
        message: action.payload,
        type: CLEAR_CUSTOMER_NAME,
      }
    default:
      return state
  }
}

export { createCustUser, deleteCustUser, getCustomerName, listCustUser, updateCustUser }
