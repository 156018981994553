import axios from 'commons/baseUrl'
import {
  CLEAR_COMPLIANCE,
  GET_COMPLIANCE_LIST_FAILED,
  GET_COMPLIANCE_LIST_REQUEST,
  GET_COMPLIANCE_LIST_SUCCESS,
  UPDATE_COMPLIANCE_FAILED,
  UPDATE_COMPLIANCE_REQUEST,
  UPDATE_COMPLIANCE_SUCCESS,
} from 'constants/actionTypes/complianceActionTypes'
import { API_GET_COMPLIANCE_DATA, API_UPDATE_COMPLIANCE_DATA } from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const getComplianceAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_COMPLIANCE_LIST_REQUEST })
    const res = await axios.get(API_GET_COMPLIANCE_DATA, { params })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_COMPLIANCE_LIST_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data) {
      dispatch({ type: GET_COMPLIANCE_LIST_SUCCESS, payload: res?.data?.data })
    }
  } catch (err) {
    dispatch({ type: GET_COMPLIANCE_LIST_FAILED, payload: null })
  }
}

const updateComplianceAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_COMPLIANCE_REQUEST, payload: data })
    const res = await axios.post(API_UPDATE_COMPLIANCE_DATA, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: UPDATE_COMPLIANCE_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: UPDATE_COMPLIANCE_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: UPDATE_COMPLIANCE_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: UPDATE_COMPLIANCE_FAILED, payload: err.message })
  }
}

const clearCompliance = (data) => async (dispatch) => {
  dispatch({ type: CLEAR_COMPLIANCE, payload: '' })
}

export { clearCompliance, getComplianceAction, updateComplianceAction }
