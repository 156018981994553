import axios from 'commons/baseUrl'
import {
  GET_MENU_LIST_FAILED,
  GET_MENU_LIST_REQUEST,
  GET_MENU_LIST_SUCCESS,
} from 'constants/actionTypes/menuListActionTypes'
import { API_GET_MENU_LIST } from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const getMenuListAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_MENU_LIST_REQUEST })
    const res = await axios.get(API_GET_MENU_LIST, { params })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_MENU_LIST_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data.data.length >= 0) {
      dispatch({ type: GET_MENU_LIST_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: GET_MENU_LIST_FAILED, payload: err.message })
  }
}
export { getMenuListAction }
