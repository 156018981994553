import {
  clearDistributionData,
  deleteDistributionDataAction,
  getDistributionDataAction,
} from 'actions/distributionDataActions'
import { getExcelTemplateAction } from 'actions/excelTemplateActions'
import { dataSearchResult, getSessionInfo, getTitleModalConfirm, getUserKbAdmin } from 'commons/utilities'
// formatDateWithPadding
import { MessageErrorDialog, ModalConfirm as DeleteResult, ModalConfirm, ProgressDialog } from 'components/commons'
import TableCommon from 'components/commons/Table/TableCommon'
import ToolBar from 'components/commons/ToolBar/ToolBar'
import {
  DELETE_DISTRIBUTION_DATA_FAILED,
  DELETE_DISTRIBUTION_DATA_REQUEST,
  DELETE_DISTRIBUTION_DATA_SUCCESS,
  GET_DISTRIBUTION_DATA_FAILED,
  GET_DISTRIBUTION_DATA_REQUEST,
  GET_DISTRIBUTION_DATA_SUCCESS,
} from 'constants/actionTypes/distributionDataActionTypes'
import { FLAG_BOOLEAN, MESSAGE_TYPE, SCREEN_NAME, TOOLBAR_TYPE } from 'constants/constant'
import { ACTION_FORM } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

function DistributionData() {
  // const sysInfo = useSelector((state) => {
  //   if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
  //   return {}
  // })
  // const DT_TODAY = sysInfo?.DT_TODAY
  //   ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
  //   : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return null
  })
  const history = useHistory()
  const location = useLocation()
  const [listData, setListData] = useState([])
  const [searchData, setSearchData] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [filterData, setFilterData] = useState([])
  // const [dateFilter, setDateFilter] = useState(DT_TODAY)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [loadingList, setLoadingList] = useState(false)
  const [recordDelete, setRecordDelete] = useState()
  const listDistributionDataReducer = useSelector((state) => state.listDistributionData || {})
  const deleteDistributionDataReducer = useSelector((state) => state.deleteDistributionData || {})
  const [messageError, setMessageError] = useState()
  const { t } = useTranslation()
  const listExcelTemplateReducer = useSelector((state) => state.listExcelTemplate.listExcelTemplate)

  const filteredFields = ['ID_DATA', 'NM_DATA', 'ID_FOLDER', 'NM_DISP_FLD']
  const [deletePermissionMessageError, setDeletePermissionMessageError] = useState('')

  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false)
  const [deletePopUp, setDeletePopUp] = useState(false)
  const [deleteMessageType, setDeleteMessageType] = useState('')
  const handleSearchInput = (event) => {
    event.preventDefault()
    setFilterData(dataSearchResult(listData, filteredFields, searchInput))
  }

  useEffect(() => {
    const { searchInput, screen } = location?.state || {}

    if (searchInput && screen === SCREEN_NAME.DISTRIBUTION_DATA) {
      setSearchInput(searchInput)
    }
    dispatch(getDistributionDataAction())
    dispatch(getExcelTemplateAction())
  }, [dispatch, location])

  useEffect(() => {
    if (listData.length >= 0 && searchInput === '') {
      setFilterData(listData)
    }
  }, [listData, searchInput, searchData])

  useEffect(() => {
    setDeletePopUp(false)
    if (listDistributionDataReducer.listDistributionData.length >= 0) {
      let dataResponse = [...listDistributionDataReducer.listDistributionData]
      setListData(dataResponse)
      if (searchInput !== '') {
        setFilterData(dataSearchResult(dataResponse, filteredFields, searchInput))
      }
    }

    setLoadingList(true)
    switch (listDistributionDataReducer.type) {
      case GET_DISTRIBUTION_DATA_REQUEST:
        setLoadingList(true)
        break
      case GET_DISTRIBUTION_DATA_FAILED:
        setLoadingList(false)
        if (!getSessionInfo()) {
          setMessageError(listDistributionDataReducer.message)
        }
        break
      case GET_DISTRIBUTION_DATA_SUCCESS:
        setLoadingList(false)
        break
      default:
        return
    }
  }, [listDistributionDataReducer])

  useEffect(() => {
    setLoading(deleteDistributionDataReducer.loading)

    switch (deleteDistributionDataReducer.type) {
      case DELETE_DISTRIBUTION_DATA_REQUEST:
        break
      case DELETE_DISTRIBUTION_DATA_SUCCESS:
        setDeletePopUp(true)
        setDeleteMessageType(MESSAGE_TYPE.SUCCESS)
        break
      case DELETE_DISTRIBUTION_DATA_FAILED:
        if (!getSessionInfo()) {
          setDeletePopUp(true)
          setDeleteMessageType(MESSAGE_TYPE.ERROR)
          setMessageError(deleteDistributionDataReducer.message)
        }
        break
      default:
        return
    }
  }, [deleteDistributionDataReducer])

  const headersCsv = [
    { label: t('distribution.lblDataId'), key: 'ID_DATA' },
    { label: t('distribution.lblDataName'), key: 'NM_DATA' },
    { label: t('distribution.lblFolderName'), key: 'ID_FOLDER' },
    { label: t('distribution.lblFolderDisplayName'), key: 'NM_DISP_FLD' },
    { label: t('distribution.lblWaitTime'), key: 'NU_WAIT' },
    { label: t('distribution.lblWarningTime'), key: 'NU_ALERT' },
    { label: t('distribution.lblDeliveryCycle'), key: 'KB_CYCLE' },
    { label: t('distribution.lblFileFormat'), key: 'KB_FL_FORMAT' },
    { label: t('distribution.lblTemplate'), key: 'NM_DEF_TMP' },
    { label: t('distribution.lblExceptionRegistration'), key: 'KB_TITLE_LN' },
    { label: t('distribution.lblDelete'), key: 'KB_DELETE' },
  ]
  const headerCsvLabel = headersCsv.map((data) => data.label)
  const headerCsvKey = headersCsv.map((data) => data.key)

  const listColumnWidth = ['62px', '8%', '12%', '8%', '12%', '8%', '8%', '8%', '11%', '9.35%', '6%', '6%']

  const listCellHeader = [
    { columnName: '', commonStyle: 'titleHead' },
    { columnName: 'distribution.lblDataId', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'distribution.lblDataName', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'distribution.lblFolderName', commonStyle: 'titleHead', cellStyle: 'personTitle' },
    { columnName: 'distribution.lblFolderDisplayName', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
    { columnName: 'distribution.lblWaitTime', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
    { columnName: 'distribution.lblWarningTime', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
    { columnName: 'distribution.lblDeliveryCycle', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
    { columnName: 'distribution.lblFileFormat', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
    { columnName: 'distribution.lblTemplate', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
    {
      columnName: 'distribution.lblExceptionRegistration',
      commonStyle: 'titleHead',
      cellStyle: 'dateTitle',
    },
    { columnName: 'distribution.lblDelete', commonStyle: 'titleHeadDistribution', cellStyle: 'dateTitle' },
  ]

  const listCellBody = [
    { keyValue: 'ID_DATA', cellStyle: 'dataCommon', gridStyle: 'centerAlignHq' },
    { keyValue: 'NM_DATA', cellStyle: 'dataCommon', gridStyle: 'centerAlignHq' },
    { keyValue: 'ID_FOLDER', cellStyle: 'dataCommonDistribution', gridStyle: 'centerAlignHq' },
    { keyValue: 'NM_DISP_FLD', cellStyle: 'dataCommonDistribution', gridStyle: 'centerAlignHq' },
    { keyValue: 'NU_WAIT', cellStyle: 'dataCommonDistribution', gridStyle: 'centerAlignHq' },
    { keyValue: 'NU_ALERT', cellStyle: 'dataCommonDistribution', gridStyle: 'centerAlignHq' },
    { keyValue: 'KB_CYCLE', cellStyle: 'dataCommonDistribution', gridStyle: 'centerAlignHq' },
    { keyValue: 'KB_FL_FORMAT', cellStyle: 'dataCommonDistribution', gridStyle: 'centerAlignHq' },
    { keyValue: 'NM_DEF_TMP', cellStyle: 'dataCommonDistribution', gridStyle: 'centerAlignHq' },
    { keyValue: 'EXCEP_NUMBER', cellStyle: 'dataCommonDistribution', gridStyle: 'centerAlignHq' },
    { keyValue: 'KB_DELETE', cellStyle: 'dataRightHq', gridStyle: 'centerAlignDistribution' },
  ]

  const listToolBarComponent = [
    { type: TOOLBAR_TYPE.ADD, commonStyle: 'createContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.FILTER_LOGO, commonStyle: 'filterIconContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.SEARCH, commonStyle: 'searchContainer', componentStyle: 'search-item' },
    { type: TOOLBAR_TYPE.CSV, commonStyle: '', componentStyle: '' },
  ]

  const navigateToFormDistributionData = (id, data, type = ACTION_FORM.CREATE) => {
    history.push({
      pathname: '/admin/distribution-data-form',
      search: `?${id ? `id=${id}&` : ''}type=${type}`,
      state: {
        type: type,
        data: data,
        listExcelTemplate: listExcelTemplateReducer,
        listDefaultTemplateDropDown: getListDefaultTemplateDropDown(),
        searchInput: searchInput,
        screen: SCREEN_NAME.DISTRIBUTION_DATA,
        // dateFilter: dateFilter,
      },
    })
  }

  const getListDefaultTemplateDropDown = () => {
    if (listExcelTemplateReducer.length > 0) {
      return listExcelTemplateReducer.map((item, index) => ({
        value: index + 1,
        label: item?.ID_TEMP || '',
        name: item?.NM_TEMP || '',
      }))
    }
    return []
  }

  let setDownloadParams = {
    screenName: 'data-transfer',
    searchText: searchInput,
  }

  const toolbar = (
    <ToolBar
      listToolBarComponent={listToolBarComponent}
      handleCreateOnclick={navigateToFormDistributionData}
      setSearchData={setSearchData}
      setSearchInput={setSearchInput}
      searchInput={searchInput}
      handleSubmit={handleSearchInput}
      downloadParams={setDownloadParams}
      headerCsvLabel={headerCsvLabel}
      headerCsvKey={headerCsvKey}
      fileCsvName={t('distribution.fileNameDownload')}
      listData={listData}
      listDataRender={filterData}
    />
  )

  const handleDeleteOnClick = async (data) => {
    const kbAdmin = await getUserKbAdmin(userInfo)

    if (String(kbAdmin) === FLAG_BOOLEAN.FALSE) {
      setShowModalConfirmDelete(false)
      setDeletePermissionMessageError(t('commonComponent.noDeletePermission'))
    } else {
      setShowModalConfirmDelete(true)
      setRecordDelete(data)
    }
  }

  const deleteDistributionData = () => {
    setShowModalConfirmDelete(false)
    let params = {}
    dispatch(deleteDistributionDataAction(recordDelete, params))
  }

  const msgDeleteConfirm = recordDelete
    ? getTitleModalConfirm(
        t('distribution.msgDeleteConfirm.line1', {
          idDataValue: recordDelete.ID_DATA,
        }),
        t('distribution.msgDeleteConfirm.line2')
      )
    : ''

  const handleConfirmDeleteResult = () => {
    setRecordDelete(null)
    setDeletePopUp(false)
    // dispatch(getDistributionDataAction())
    dispatch(clearDistributionData())
  }
  return (
    <div className="distribution__wrapper">
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {deletePermissionMessageError && (
        <MessageErrorDialog
          message={deletePermissionMessageError}
          showMessageErrorDialog={setDeletePermissionMessageError}
        />
      )}
      {loading ||
        (loadingList && (
          <ProgressDialog label={recordDelete ? t('distribution.msgDeleteData') : t('distribution.loading')} />
        ))}
      <ModalConfirm
        title={msgDeleteConfirm}
        showModal={showModalConfirmDelete}
        setShowModal={setShowModalConfirmDelete}
        handleFunction={deleteDistributionData}
      />
      <DeleteResult
        title={
          deleteMessageType === MESSAGE_TYPE.SUCCESS
            ? t('distribution.deleteDataSuccess')
            : t('distribution.deleteDataError')
        }
        showModal={deletePopUp}
        handleFunction={handleConfirmDeleteResult}
        screen={'distribution'}
      />
      {loading || loadingList ? (
        <ProgressDialog label={recordDelete ? t('distribution.msgDeleteData') : t('distribution.loading')} />
      ) : (
        <div className="distribution__container maintain-container">
          {/* Table */}
          <TableCommon
            listColumnWidth={listColumnWidth}
            listCellHeader={listCellHeader}
            listCellBody={listCellBody}
            listDataRender={filterData}
            handleUpdate={navigateToFormDistributionData}
            idProperty={'ID_DATA'}
            handleDelete={handleDeleteOnClick}
            toolbar={toolbar}
          />
        </div>
      )}
    </div>
  )
}

export default DistributionData
