import './AllergenCreateEdit.css'
import '../../../components/commons/FormEdits/FormEdit.css'

import { Button } from '@material-ui/core'
import { DateRange } from '@material-ui/icons'
import getCodeInfo from 'actions/codeActions'
// LocalDining
import { IMAGES } from 'assets'
import {
  focusNextElement,
  formatDateWithPadding,
  getDateInfoSystem,
  getSessionInfo,
  getTitleModalConfirm,
  getUserKbAdmin,
  shortenValue,
} from 'commons/utilities'
import { Image, MessageErrorDialog, ModalConfirm, ModalConfirmNavigation, SelectInput } from 'components/commons'
import CustomCalendar from 'components/commons/FormInputs/CustomCalendar'
import FormInputs from 'components/commons/FormInputs/FormInputs'
import ProgressDialog from 'components/commons/ProgressDialog/ProgressDialog'
import { ACTION_FORM, FLAG_BOOLEAN } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { allergenValidate } from 'validation/allergenValidate'

import { clearAllergen, createAllergenAction, updateAllergenAction } from '../../../actions/allergenActions'
import {
  CREATE_ALLERGEN_FAILED,
  CREATE_ALLERGEN_REQUEST,
  CREATE_ALLERGEN_SUCCESS,
  UPDATE_ALLERGEN_FAILED,
  UPDATE_ALLERGEN_REQUEST,
  UPDATE_ALLERGEN_SUCCESS,
} from '../../../constants/actionTypes/allergenActionTypes'
import styles from './AllergenCreateEditStyles'
export default function AllergenCreateEdit() {
  const classes = styles()
  const { i18n, t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const [delayRenderComponent, setDelayRenderComponent] = useState(false)
  const [dropDownKBSPEC, setDropDownKBSPEC] = useState([])
  const dispatch = useDispatch()

  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return {}
  })

  const createAllergenReducer = useSelector((state) => state.createAllergen || {})
  const updateAllergenReducer = useSelector((state) => state.updateAllergen || {})
  const codeInfo = useSelector((state) => state.codeInfo.code || {})
  const [type, setType] = useState(ACTION_FORM.CREATE)
  const [isBlocked, setIsBlocked] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [errorData, setErrorData] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [dataForm, setDataForm] = useState({
    CD_ALRG: '',
    NM_ALRG: '',
    DT_START: formatDateWithPadding(new Date(Date.now()), 'yyyy-MM-dd'),
    DT_END: getDateInfoSystem().DT_DEND,
    NM_RUDUSER: userInfo.NM_USER,
    NM_USER: '',
    DT_REGIST: '',
    DT_UPDATE: '',
    DT_DELETE: '',
    NM_ALRG_DSP: '',
    NU_DSP: 0,
    KB_SPEC: '',
  })
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState(false)
  const [isValidStartDate, setIsValidStartDate] = useState(true)
  const [isValidEndDate, setIsValidEndDate] = useState(true)
  const [isInStartDateRange, setIsInStartDateRange] = useState(true)
  const [isInEndDateRange, setIsInEndDateRange] = useState(true)
  const [createPermissionMessageError, setCreatePermissionMessageError] = useState('')
  const [updatePermissionMessageError, setUpdatePermissionMessageError] = useState('')
  // handle update field portal
  const handleUpdate = (name, value) => {
    setErrorData({})
    setIsBlocked(true)
    if (name === 'DT_START' || name === 'DT_END') {
      let t = dataForm
      t[name] = value
      setDataForm((prev) => ({ ...prev, ...t }))
      setErrorData((prev) => ({ ...prev, DATE: '' }))
      return
    }
    setDataForm((prev) => ({ ...prev, [name]: value }))
  }

  // clear and exit
  const handleCancelButton = () => {
    history.push({
      pathname: '/admin/allergen',
    })
  }

  // submit data
  const handleSubmit = async (e) => {
    e.preventDefault()
    const kbAdmin = await getUserKbAdmin(userInfo)
    if (String(kbAdmin) === FLAG_BOOLEAN.FALSE) {
      if (type === ACTION_FORM.CREATE) {
        setCreatePermissionMessageError(t('commonComponent.noCreatePermission'))
      }
      if (type === ACTION_FORM.UPDATE) {
        setUpdatePermissionMessageError(t('commonComponent.noUpdatePermission'))
      }
    } else {
      const error = allergenValidate(dataForm.CD_ALRG, dataForm.DT_START, dataForm.DT_END, i18n)
      if (error.DATE || error.InvalidDtEnd || error.CD_ALRG) {
        setErrorData(error)
        setCanSubmit(false)
      } else {
        setShowModal(true)
        setErrorData()
      }
    }
  }
  const submitData = () => {
    if (type === ACTION_FORM.CREATE)
      dispatch(createAllergenAction({ ...dataForm, NM_RUDUSER: userInfo.NM_USER, NM_USER: userInfo.NM_USER }, i18n))
    if (type === ACTION_FORM.UPDATE) dispatch(updateAllergenAction({ ...dataForm, NM_RUDUSER: userInfo.NM_USER }))
    setIsBlocked(false)
    setShowModal(false)
  }

  const checkRequire = React.useCallback(() => {
    if (
      !dataForm.CD_ALRG ||
      !dataForm.NM_ALRG ||
      !dataForm.DT_START ||
      !dataForm.DT_END ||
      !dataForm.NU_DSP ||
      !dataForm.NM_ALRG_DSP ||
      !dataForm.KB_SPEC
    )
      return false
    return isBlocked
  }, [dataForm, isBlocked])

  useEffect(() => {
    dispatch(getCodeInfo({}))
  }, [dispatch])

  useEffect(() => {
    if (!isValidStartDate || !isValidEndDate || !isInStartDateRange || !isInEndDateRange || !checkRequire()) {
      setCanSubmit(false)
    } else {
      setCanSubmit(true)
    }
  }, [dataForm, checkRequire])

  const getTitleModal = (type, dataForm) => {
    const formType =
      type === ACTION_FORM.CREATE ? t('commonComponent.form.createButton') : t('commonComponent.form.updateButton')
    return getTitleModalConfirm(
      t('formAllergen.msgConfirmSubmission.line1', {
        lblCdAllergen: t('formAllergen.lblCdAllergen'),
        cdAllergenValue: shortenValue(dataForm.CD_ALRG, 10),
        formType: formType,
      }),
      t('formAllergen.msgConfirmSubmission.line2')
    )
  }

  useEffect(() => {
    if (Object.keys(codeInfo).length) {
      const data = codeInfo.filter((x) => x['CD_KEY'].toString().toUpperCase() === 'ARGSPEC')[0]
      let types = []
      if (data) {
        types = [
          { value: data.MP_ITEM01['CD_VALUE'], label: data.MP_ITEM01['NM_VALUE'] },
          { value: data.MP_ITEM02['CD_VALUE'], label: data.MP_ITEM02['NM_VALUE'] },
        ]
      }
      setDropDownKBSPEC(types)
    }
  }, [codeInfo])

  useEffect(() => {
    const { data, type } = location?.state || {}
    if (type) setType(type)
    if (!data) {
      setDataForm({ ...dataForm, NM_RUDUSER: userInfo.NM_USER })
    } else {
      setDataForm({ ...data })
    }
  }, [location])

  useEffect(() => {
    setLoading(createAllergenReducer.loading || updateAllergenReducer.loading)
    setIsBlocked(false)
    switch (createAllergenReducer.type) {
      case CREATE_ALLERGEN_REQUEST:
        break
      case CREATE_ALLERGEN_FAILED:
        if (!getSessionInfo()) {
          setMessageError(createAllergenReducer.message)
        }
        break
      case CREATE_ALLERGEN_SUCCESS:
        history.push({
          pathname: '/admin/allergen',
        })
        dispatch(clearAllergen())
        break
      default:
        break
    }
    switch (updateAllergenReducer.type) {
      case UPDATE_ALLERGEN_REQUEST:
        break
      case UPDATE_ALLERGEN_FAILED:
        if (!getSessionInfo()) {
          setMessageError(updateAllergenReducer.message)
        }
        break
      case UPDATE_ALLERGEN_SUCCESS:
        history.push({
          pathname: '/admin/allergen',
        })
        dispatch(clearAllergen())
        break
      default:
        break
    }
  }, [createAllergenReducer, updateAllergenReducer, history, dispatch])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDelayRenderComponent(true)
    }, 1)
    return () => {
      clearTimeout(handler)
    }
  }, [])

  const customEventEnter = (e) => {
    if (e.code === 'Enter') {
      setShowModal(true)
      e.preventDefault()
    }
  }

  const confirmNavigationMessage = `<div class="message__pre-text"><div>
  ${t('formAllergen.msgConfirmNavigate.line1')} </div><div>
  ${t('formAllergen.msgConfirmNavigate.line2')}</div></div>`

  return (
    <div className="portal-createWrapper">
      {<ModalConfirmNavigation title={confirmNavigationMessage} isBlocked={isBlocked} />}
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {createPermissionMessageError && (
        <MessageErrorDialog
          message={createPermissionMessageError}
          showMessageErrorDialog={setCreatePermissionMessageError}
        />
      )}
      {updatePermissionMessageError && (
        <MessageErrorDialog
          message={updatePermissionMessageError}
          showMessageErrorDialog={setUpdatePermissionMessageError}
        />
      )}
      {loading && <ProgressDialog label={t('formAllergen.loading')} />}
      <ModalConfirm
        title={getTitleModal(type, dataForm)}
        showModal={showModal}
        setShowModal={setShowModal}
        handleFunction={submitData}
        focusSubmit={true}
      />
      {delayRenderComponent && (
        <form
          onKeyDown={(e) => {
            focusNextElement(e)
          }}
        >
          <div className="portal-create form-scroll">
            <div className="portal-create-content form-min-width">
              <div className="formEdit-container">
                {/*CD_ALRG */}
                <FormInputs
                  id="standard-basic"
                  canSpacing={false}
                  label={t('formAllergen.lblCdAllergen')}
                  value={dataForm.CD_ALRG || ''}
                  required
                  icon={
                    <Image src={IMAGES.ICON.LOCAL_DINING} alt="Local Dining Icon" className="iconForm icon--deactive" />
                  }
                  error={errorData && !!errorData.CD_ALRG}
                  helperText={errorData && errorData.CD_ALRG ? errorData.CD_ALRG : null}
                  setValue={(e) => handleUpdate('CD_ALRG', e)}
                  className="width120"
                  disabled={type === ACTION_FORM.UPDATE}
                  onlyNumber
                  errorPosition="110px"
                />

                {/*NM_ALRG  */}
                <FormInputs
                  id="standard-basic"
                  label={t('formAllergen.lblNmAllergen')}
                  value={dataForm.NM_ALRG || ''}
                  required
                  icon={<Image src={IMAGES.ICON.LIST_ALL} className="iconForm icon--deactive" />}
                  type="text"
                  name="NM_ALRG"
                  setValue={(e) => handleUpdate('NM_ALRG', e)}
                  className="width300"
                />
                {/* new Form control from 19/04/2022  NM_ALRG_DSP*/}
                <FormInputs
                  id="standard-basic"
                  label={t('formAllergen.lblNmAlrgDsp')}
                  value={dataForm.NM_ALRG_DSP || ''}
                  required
                  icon={<Image src={IMAGES.ICON.LIST_ALL} className="iconForm icon--deactive" />}
                  type="text"
                  name="NM_ALRG_DSP"
                  setValue={(e) => handleUpdate('NM_ALRG_DSP', e)}
                  className="width300"
                />
                {/* new Form control from 19/04/2022  KB_SPEC*/}
                <SelectInput
                  id={dataForm.KB_SPEC ? 'KB_MESSAGE__selected' : 'KB_MESSAGE__select'}
                  label={t('formAllergen.lblKbSpec')}
                  value={dataForm.KB_SPEC}
                  name="KB_SPEC"
                  icon={<Image src={IMAGES.ICON.DISTURB} className="iconForm icon--deactive" />}
                  onChange={(e) => handleUpdate('KB_SPEC', e.target.value)}
                  className={`textDrop ${classes.dropdownMessage}`}
                  items={dropDownKBSPEC}
                  required
                />
                {/* new Form control from 19/04/2022  NU_DSP*/}
                <FormInputs
                  id="standard-basic"
                  label={t('formAllergen.lblNuDsp')}
                  value={dataForm.NU_DSP || ''}
                  required
                  icon={<Image src={IMAGES.ICON.TRANSFER} className="iconForm icon--deactive" />}
                  name="NU_DSP"
                  setValue={(e) => handleUpdate('NU_DSP', e)}
                  className="width300"
                  onlyNumber
                  canSpacing={false}
                />
                {/* Calendar */}
                <div className="formEdit-calendar">
                  <div className="calender-spacing">
                    <DateRange className="icon--deactive" />
                  </div>
                  <div>
                    <div className="calendar">
                      <CustomCalendar
                        className="calendar-item calendar-item-1"
                        value={dataForm?.DT_START ? new Date(dataForm.DT_START) : ''}
                        onChange={(date) => handleUpdate('DT_START', date)}
                        setIsValidDate={setIsValidStartDate}
                        setIsInDateRange={setIsInStartDateRange}
                        label={t('formAllergen.lblStartDate')}
                        name="DT_START"
                        id="DT_START"
                        required
                      />
                      <CustomCalendar
                        className="calendar-item calendar-item-2"
                        value={dataForm?.DT_END ? new Date(dataForm.DT_END) : ''}
                        onChange={(date) => handleUpdate('DT_END', date)}
                        setIsValidDate={setIsValidEndDate}
                        setIsInDateRange={setIsInEndDateRange}
                        label={t('formAllergen.lblEndDate')}
                        name="DT_END"
                        id="DT_END"
                        required
                      />
                      <div className={`allergen-error-message ${errorData && errorData.DATE ? '' : 'hidden'}`}>
                        {errorData && errorData.DATE ? errorData.DATE : null}
                      </div>
                      <div className={`error-message-dtEnd ${errorData && errorData.InvalidDtEnd ? '' : 'hidden'}`}>
                        {errorData && errorData.InvalidDtEnd ? errorData.InvalidDtEnd : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="formEdit-input ">
                  <div className="input-spacing"></div>
                  <FormInputs
                    id="standard-basic"
                    label={t('formAllergen.lblNmRuduserUpdate')}
                    value={dataForm.NM_RUDUSER || ''}
                    type="text"
                    name="NM_RUDUSER"
                    setValue={(e) => handleUpdate('NM_RUDUSER', e)}
                    disabled
                    className="row_1"
                  />
                </div>
                <div className="formEdit-input">
                  <div className="input-spacing"></div>
                  <div className="formEdit-input wrap-mb">
                    <FormInputs
                      id="standard-basic"
                      label={t('formAllergen.lblDateRegist')}
                      value={
                        type === ACTION_FORM.UPDATE
                          ? dataForm.DT_REGIST &&
                            formatDateWithPadding(new Date(dataForm.DT_REGIST), 'yyyy-MM-dd HH:mm:ss')
                          : ''
                      }
                      type="text"
                      name="DT_REGIST"
                      disabled
                      className="row_2"
                    />
                    <FormInputs
                      id="standard-basic"
                      label={t('formAllergen.lblDateUpdate')}
                      value={
                        type === ACTION_FORM.UPDATE && dataForm.DT_UPDATE
                          ? formatDateWithPadding(new Date(dataForm.DT_UPDATE), 'yyyy-MM-dd HH:mm:ss')
                          : ''
                      }
                      type="text"
                      name="DT_UPDATE"
                      disabled
                      className="row_2"
                    />
                  </div>
                </div>
                <div className="formEdit-input">
                  <div className="input-spacing"></div>
                  <FormInputs
                    id="standard-basic"
                    label={t('formAllergen.lblDateDelete')}
                    type="text"
                    value={
                      type === ACTION_FORM.UPDATE
                        ? dataForm.DT_DELETE &&
                          formatDateWithPadding(new Date(dataForm.DT_DELETE), 'yyyy-MM-dd HH:mm:ss')
                        : ''
                    }
                    name="DT_DELETE"
                    disabled
                    className="row_2"
                  />
                </div>
              </div>
              <div className="buttonSpace">
                <Button
                  className="button submit-button"
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained"
                  disabled={!canSubmit}
                  onKeyDown={(e) => {
                    customEventEnter(e)
                  }}
                >
                  {t('formAllergen.btnSubmit')}
                </Button>
                <Button className="button clear-button" onClick={handleCancelButton} variant="outlined">
                  {t('formAllergen.btnCancel')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}
