import './DownloadStatus.css'

import { getDownloadStatusAction } from 'actions/downloadStatusAction'
import { caculateTableWidth, formatDateWithPadding, getSessionInfo } from 'commons/utilities'
import { MessageErrorDialog, ModalConfirm, ProgressDialog } from 'components/commons'
import ToolBar from 'components/commons/ToolBar/ToolBar'
import {
  GET_DOWNLOAD_STATUS_LIST_FAILED,
  GET_DOWNLOAD_STATUS_LIST_REQUEST,
  GET_DOWNLOAD_STATUS_LIST_SUCCESS,
} from 'constants/actionTypes/downloadStatusActionTypes'
import {
  CHARACTER_WIDTH,
  MAX_CHARACTER,
  RIGHT_LEFT_PADDING,
  SCREEN_NAME,
  TABLE_WIDTH,
  TOOLBAR_TYPE,
} from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import useWindowDimensions from '../DeliveryData/CustomHook.jsx'
import TableDownloadStatus from './TableDownloadStatus'

function DownloadStatus() {
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })
  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [listData, setListData] = useState([])

  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState()
  const [recordDelete, setRecordDelete] = useState()
  const listDownloadStatusReducer = useSelector((state) => state.listDownloadStatus || {})
  const [isValidDate, setIsValidDate] = useState(true)
  const [isInDateRange, setIsInDateRange] = useState(true)
  const [listFilterItem, setListFilterItem] = useState([])
  const { tableDeliveryWidth } = useWindowDimensions()

  const setUndownloadedWithState = () => {
    const { undownloaded } = location?.state || {}

    if (Number(undownloaded) === 0) {
      return Number(undownloaded)
    } else {
      return 1
    }
  }

  const setFilterItemWithState = () => {
    const { filterItem } = location?.state || {}
    return filterItem !== undefined ? String(filterItem) : '0'
  }

  const [filterItem, setFilterItem] = useState(setFilterItemWithState())
  const [dateFilter, setDateFilter] = useState(
    history.location && history.location.state ? history.location.state.date : DT_TODAY
  )
  const [undownloaded, setUndownloaded] = useState(setUndownloadedWithState)
  const [isDateSelected, setIsDateSelected] = useState(false)
  const [listRawData, setListRawData] = useState([])
  const handleFilterDate = React.useCallback(
    (dateFilter) => {
      setIsDateSelected(true)
      if (isValidDate && isInDateRange) {
        if (filterItem === '0') {
          dispatch(
            getDownloadStatusAction({
              date: dateFilter,
              // undownloaded: undownloaded,
            })
          )
        } else {
          dispatch(
            getDownloadStatusAction({
              date: dateFilter,
              folderName: !!listFilterItem[filterItem] && listFilterItem[filterItem].label,
              // undownloaded: undownloaded,
            })
          )
        }
      }
    },

    [dispatch, filterItem]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', reloadHandler)
    return () => {
      window.removeEventListener('beforeunload', reloadHandler)
    }
  }, [])
  const reloadHandler = (e) => {
    window.reload()
  }
  const getListFolderName = (listData = []) => {
    let listFolderNameArr = []
    for (const item of listData) {
      if (!listFolderNameArr.includes(item.NM_DISP_FLD)) {
        listFolderNameArr.push(item.NM_DISP_FLD)
      }
    }
    return listFolderNameArr
  }

  useEffect(() => {
    if (listDownloadStatusReducer?.listDownloadStatus) {
      const listData = listDownloadStatusReducer?.listDownloadStatus

      const listFolderName = getListFolderName(listData)

      let dataFormat, folderName

      if (history.location && history.location.state && history.location.state?.undownloaded?.toString() === '0') {
        let filteredData = listData
        const folderName =
          !!listFilterItem[filterItem] && filterItem !== '0' ? listFilterItem[filterItem].label : undefined
        if (folderName) {
          filteredData = filteredData.filter((data) => {
            return data.NM_DISP_FLD === folderName
          })
        }
        dataFormat = arrayToMapHandler(filteredData, listFolderName)
      } else {
        let filteredData = listData
        if (Number(undownloaded) === 1) {
          filteredData = listData?.filter((data) => {
            return Number(data?.KB_DL) !== Number(undownloaded)
          })
        }
        folderName = !!listFilterItem[filterItem] && filterItem !== '0' ? listFilterItem[filterItem].label : undefined
        const checkFolderNameExists =
          listFolderName.find((folder) => folder === folderName) !== undefined ? true : false
        if (folderName && checkFolderNameExists) {
          filteredData = filteredData.filter((data) => {
            return data.NM_DISP_FLD === folderName
          })
        }

        dataFormat = arrayToMapHandler(filteredData, listFolderName)
      }

      setListData(dataFormat?.data)
      setListRawData(listData)

      if (listFolderName) {
        let array = []
        array.push({
          value: '0',
          label: t('downloadStatus.allFolder'),
        })
        for (let i = 0; i < listFolderName?.length; i++) {
          let j = i + 1
          let filterItem = {
            value: j.toString(),
            label: listFolderName[i],
          }
          array.push(filterItem)
        }
        setListFilterItem(array)

        folderName = !!array[filterItem] && filterItem !== '0' ? array[filterItem].label : undefined
        const folderNameExist =
          array.find((folder) => String(folder.label).trim() === String(folderName).trim()) !== undefined ? true : false
        if (!folderNameExist) {
          setFilterItem('0')
        }
      }
    }

    setLoading(listDownloadStatusReducer.loading)
    switch (listDownloadStatusReducer.type) {
      case GET_DOWNLOAD_STATUS_LIST_REQUEST:
        break
      case GET_DOWNLOAD_STATUS_LIST_FAILED:
        if (!getSessionInfo()) {
          setMessageError(listDownloadStatusReducer.message)
        }
        break
      case GET_DOWNLOAD_STATUS_LIST_SUCCESS:
        break
      default:
        return
    }
  }, [listDownloadStatusReducer])

  const handleSelectFolderName = (filterItem) => {
    const folderName = !!listFilterItem[filterItem] && filterItem !== '0' ? listFilterItem[filterItem].label : undefined
    let listFolderName = getListFolderName(listRawData)
    let dataFormat = {}
    let listDataFilter = listRawData
    if (folderName !== undefined) {
      listDataFilter = listRawData.filter((data) => data.NM_DISP_FLD === folderName)
      if (Number(undownloaded) === 1) {
        listDataFilter = listDataFilter.filter((el) => Number(el.KB_DL) !== Number(undownloaded))
      }

      dataFormat = arrayToMapHandler(listDataFilter, listFolderName)
    } else {
      listDataFilter = listRawData
      if (Number(undownloaded) === 1) {
        listDataFilter = listDataFilter.filter((el) => Number(el.KB_DL) !== Number(undownloaded))
      }
      dataFormat = arrayToMapHandler(listDataFilter, listFolderName)
    }

    setFilterItem(filterItem)
    setListData(dataFormat.data)
  }
  const handleFilterByUndownloaded = (value) => {
    let listDataFilter = listRawData
    if (Number(value) === 1) {
      listDataFilter = listDataFilter.filter((data) => Number(data.KB_DL) !== Number(value))
    }

    const folderName = !!listFilterItem[filterItem] && filterItem !== '0' ? listFilterItem[filterItem].label : undefined
    if (folderName !== undefined) listDataFilter = listDataFilter.filter((data) => data.NM_DISP_FLD === folderName)
    let listFolderName = getListFolderName(listRawData)
    const dataFormat = arrayToMapHandler(listDataFilter, listFolderName)

    setListData(dataFormat.data)
  }
  const createListColumnWidth = (listFolderName, branchList) => {
    let listColumnWidth = []
    listColumnWidth.push('62px')
    listColumnWidth = caculateFolderNameWidth(listColumnWidth, listFolderName)
    let totalTableWidth = caculateTableWidth(listColumnWidth)
    if (totalTableWidth < TABLE_WIDTH) {
      listColumnWidth[listColumnWidth.length - 1] = ''
    }

    return listColumnWidth
  }

  const caculateFolderNameWidth = (listColumnWidth = [], listFolderName, totalTableWidth) => {
    if (listFolderName.length === 1) {
      listColumnWidth.push('')
      return listColumnWidth
    }
    for (let index = 0; index <= listFolderName?.length - 1; index++) {
      if (listFolderName[index].length >= MAX_CHARACTER) {
        listColumnWidth.push(MAX_CHARACTER * CHARACTER_WIDTH + RIGHT_LEFT_PADDING + 'px')
      } else {
        listColumnWidth.push(listFolderName[index].length * CHARACTER_WIDTH + RIGHT_LEFT_PADDING + 'px')
      }
    }
    return listColumnWidth
  }

  const createListCellHeader = (listFolderNameArr = []) => {
    const listCellHeader = []
    const branchHeaderCell = { columnName: '', commonStyle: 'paddingHeadDownload', cellStyle: 'downloadTitle' }
    listCellHeader.push(branchHeaderCell)
    for (let index = 0; index < listFolderNameArr?.length; index++) {
      let folderNameCellHeader = {
        columnName: '',
        commonStyle: 'paddingHeadDownload',
        cellStyle: 'downloadTitle',
      }
      const folderName = listFolderNameArr[index]
      folderNameCellHeader.columnName = folderName
      listCellHeader.push(folderNameCellHeader)
    }
    return listCellHeader
  }

  // const listCellHeader = createListCellHeader(listFolderName)

  const listToolBarComponent = [
    { type: TOOLBAR_TYPE.FILTER_LOGO_ONLY, commonStyle: 'filterIconOnlyContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.CHECKBOX, commonStyle: 'checkboxContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.FILTER_LOGO, commonStyle: 'filterIconContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.FOLDER_SELECT, commonStyle: 'select-item', componentStyle: '' },
    { type: TOOLBAR_TYPE.SENDING_DATE, commonStyle: 'expireDateContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.CALENDAR, commonStyle: 'filterContainer', componentStyle: 'calendar-item' },
    { type: TOOLBAR_TYPE.CSV, commonStyle: '', componentStyle: '' },
  ]
  let setDownloadParams = {
    screenName: 'download-status',
    folderName: !!listFilterItem[filterItem] && filterItem !== '0' ? listFilterItem[filterItem].label : undefined,
    date: dateFilter,
    undownloaded: undownloaded,
  }

  useEffect(
    () => {
      const { date, screen } = location?.state || {}
      if (date && screen === SCREEN_NAME.DONWLOAD_STATUS) {
        setDateFilter(date)
        setIsDateSelected(true)
        dispatch(
          getDownloadStatusAction({
            date: date,
          })
        )
      } else {
        setDateFilter(DT_TODAY)
        setIsDateSelected(false)
        dispatch(getDownloadStatusAction({}))
      }
      window.history.replaceState({}, document.title)
    },
    [dispatch],
    [location]
  )

  const toolbar = (
    <ToolBar
      listToolBarComponent={listToolBarComponent}
      handleFilterDate={handleFilterDate}
      handleSelectFolderName={handleSelectFolderName}
      setSelectedDate={setDateFilter}
      selectedDate={dateFilter}
      listData={listData}
      downloadParams={setDownloadParams}
      setIsValidDate={setIsValidDate}
      setIsInDateRange={setIsInDateRange}
      hasFilterIcon={false}
      filterLabel={t('downloadStatus.lblIdFolder')}
      setFilterItem={setFilterItem}
      filterItem={filterItem}
      listFilterItem={listFilterItem}
      toolbarNoHeight={true}
      toolbarNoHeightPaddingTop={false}
      CustomDropdownClassName={'wrapper-download'}
      undownloaded={undownloaded}
      setUndownloaded={setUndownloaded}
      handleFilterByUndownloaded={handleFilterByUndownloaded}
    />
  )
  const msgDeleteConfirm = recordDelete ? t('headquarter.msgDeleteConfirm', { idUserValue: recordDelete.ID_USER }) : ''
  //return item of branch_list
  let handlerListBranchObjectInMap = (data, folderNameFilter) => {
    //each branch has a list folders - (key-value) =(cd_branch ,[folder-name])
    let mapOfCdBranch = new Map()

    //each folder has a list files - (key-value) =(folder-name ,[file-name])
    let mapOfListFile = new Map()
    let innerResponse = []
    for (const item of data) {
      let displayFolder = item.NM_DISP_FLD
      let cd_branch = item.CD_BRANCH
      let ky_src = item.KY_SRC
      //count file of each folder has the same ky_src
      putFileNameInFolder(item.NM_FILE_DSP, ky_src, mapOfListFile)
      let countFileInFolder = mapOfListFile.get(ky_src).listFolderFile.length
      if (!cd_branch) {
        continue
      }
      //check existed branch
      else if (mapOfCdBranch.has(cd_branch)) {
        let DISP_FLD = mapOfCdBranch.get(cd_branch).DISP_FLD
        if (DISP_FLD.filter((e) => e.NM_DISP_FLD === displayFolder).length === 0) {
          DISP_FLD.push({ NM_DISP_FLD: displayFolder, count: countFileInFolder, KY_SRC: ky_src })

          // if (!list_folder_name.includes(displayFolder)) {
          //   list_folder_name.push(displayFolder)
          // }
        } else {
          //check folder in branch
        }
      } else {
        //fist new folder
        let poolOfFolder = []

        poolOfFolder.push({
          NM_DISP_FLD: displayFolder,
          count: countFileInFolder,
          KY_SRC: ky_src,
        })
        // if (!list_folder_name.includes(displayFolder)) {
        //   list_folder_name.push(displayFolder)
        // }

        mapOfCdBranch.set(cd_branch, {
          CD_BRANCH: cd_branch,
          NM_BRANCH: item.NM_BRANCH || '',
          DISP_FLD: poolOfFolder,
        })
      }
    }
    for (const value of mapOfCdBranch.values()) {
      for (const item of value.DISP_FLD) {
        //update count
        item.count = mapOfListFile.get(item.KY_SRC).listFolderFile.length
      }
      innerResponse.push(value)
    }
    return innerResponse
  }

  const arrayToMapHandler = (data, listFolderFile) => {
    let mapOfCust = new Map()
    let response = {
      list_folder_name: listFolderFile,
      data: [],
    }
    for (const item of data) {
      if (!item.CD_CUST) {
        continue
      } else if (mapOfCust.has(item.CD_CUST)) {
        let prevList = mapOfCust.get(item.CD_CUST)
        prevList.list.push(item)
      } else {
        let list = []
        list.push(item)
        mapOfCust.set(item.CD_CUST, {
          NM_CUST: item.NM_CUST || '',
          CD_CUST: item.CD_CUST,
          list: list,
        })
      }
    }
    //   return mapOfCust
    // let list_folder_name = [];

    for (let [key, value] of mapOfCust.entries()) {
      let listOfBranch = handlerListBranchObjectInMap(
        value.list,
        key
        // list_folder_name
      )
      response.data.push({
        CD_CUST: value.CD_CUST,
        NM_CUST: value.NM_CUST,
        BRANCH_LIST: listOfBranch,
      })
    }
    // response.list_folder_name = list_folder_name;
    return response
  }

  const putFileNameInFolder = (fileName = '', kySRC, mapOfListFile) => {
    if (fileName) {
      if (mapOfListFile.has(kySRC)) {
        const listFile = mapOfListFile.get(kySRC).listFolderFile
        if (!listFile.includes(kySRC)) {
          listFile.push(fileName)
        }
      } else {
        let listFolderFile = []
        //push first new file
        listFolderFile.push(fileName)
        mapOfListFile.set(kySRC, { listFolderFile: listFolderFile })
      }
    }
  }
  const getListDisplayFolderName = (data) => {
    let dataListFolder = data.BRANCH_LIST.reduce((prev, curr) => {
      for (const { NM_DISP_FLD } of curr.DISP_FLD) {
        prev.push(NM_DISP_FLD)
      }

      return prev
    }, [])

    let uniqueFolder = [...new Set([...dataListFolder])]

    return uniqueFolder
  }

  const isTableHaveScrollOnBottom = () => {
    let listColumnWidth,
      currentTableWidth,
      haveScroll = true
    if (listData.length) {
      listData.forEach((data) => {
        listColumnWidth = createListColumnWidth(getListDisplayFolderName(data), data.BRANCH_LIST)
        currentTableWidth = caculateTableWidth(listColumnWidth)
        if (currentTableWidth > tableDeliveryWidth) haveScroll = false
      })
      return haveScroll
    }
    return true
  }

  return (
    <div className="download-status__wrapper">
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {loading && <ProgressDialog label={t('headquarter.loading')} />}
      <ModalConfirm title={msgDeleteConfirm} showModal={Boolean(recordDelete)} setShowModal={setRecordDelete} />
      {loading ? (
        <ProgressDialog label={t('headquarter.loading')} />
      ) : (
        <div className="download-status__container">
          <div className={`${isTableHaveScrollOnBottom() ? 'table-scroll' : ''}`}>
            <div>{toolbar}</div>
            <div className="table-list-container">
              {listData &&
                listData.map((data, index) => {
                  const listColumnWidth = createListColumnWidth(getListDisplayFolderName(data), data.BRANCH_LIST)
                  const currentTableWidth = caculateTableWidth(listColumnWidth)

                  return index === 0 ? (
                    <div
                      style={
                        currentTableWidth > tableDeliveryWidth
                          ? { maxWidth: tableDeliveryWidth + 'px', overflowX: 'auto' }
                          : {}
                      }
                    >
                      {/* Table */}
                      <TableDownloadStatus
                        listColumnWidth={listColumnWidth}
                        // listCellHeader={listCellHeader}
                        listCellHeader={createListCellHeader(getListDisplayFolderName(data))}
                        listFolderName={getListDisplayFolderName(data)}
                        listDataRender={data.BRANCH_LIST}
                        cdCust={data.CD_CUST}
                        nmCust={data.NM_CUST}
                        handleDelete={setRecordDelete}
                        hasFirstCell={false}
                        hasHeaderLine={true}
                        isDateSelected={isDateSelected}
                        selectedDate={dateFilter}
                        undownloaded={undownloaded}
                        hasRecordCounter={true}
                        hasStartLineItem={true}
                        startLineItem={t('downloadStatus.customerInfo', {
                          cdCust: data.CD_CUST,
                          nmCust: data.NM_CUST,
                        })}
                        key={index}
                        filterItem={filterItem}
                      />
                      <div className="spacing"></div>
                    </div>
                  ) : (
                    <div
                      style={
                        currentTableWidth > tableDeliveryWidth
                          ? { maxWidth: tableDeliveryWidth + 'px', overflowX: 'auto' }
                          : {}
                      }
                    >
                      {/* Table */}
                      <TableDownloadStatus
                        listColumnWidth={listColumnWidth}
                        // listCellHeader={listCellHeader}
                        listCellHeader={createListCellHeader(getListDisplayFolderName(data))}
                        // listFolderName={listFolderName}
                        listFolderName={getListDisplayFolderName(data)}
                        listDataRender={data.BRANCH_LIST}
                        cdCust={data.CD_CUST}
                        nmCust={data.NM_CUST}
                        isDateSelected={isDateSelected}
                        selectedDate={dateFilter}
                        handleDelete={setRecordDelete}
                        undownloaded={undownloaded}
                        hasFirstCell={false}
                        hasHeaderLine={true}
                        hasRecordCounter={true}
                        hasStartLineItem={true}
                        startLineItem={t('downloadStatus.customerInfo', {
                          cdCust: data.CD_CUST,
                          nmCust: data.NM_CUST,
                        })}
                        key={index}
                        filterItem={filterItem}
                      />
                      <div className="spacing"></div>
                    </div>
                  )
                })}

              {listData && listData.length === 0 && (
                <TableDownloadStatus
                  // handleDelete={setRecordDelete}
                  hasFirstCell={false}
                  hasHeaderLine={false}
                  toolbar={toolbar}
                  isDateSelected={isDateSelected}
                  selectedDate={dateFilter}
                  hasRecordCounter={false}
                  hasStartLineItem={false}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DownloadStatus
