/* --- User API Path --- */
export const API_GET_USER_DETAIL = '/get-user-detail'

export const API_CHANGE_PASSWORD = '/change-password'

/* --- Portal API Path --- */
export const API_GET_PORTALS_DETAIL = '/get-portals'

export const API_GET_PORTALS_MASTER = '/get-portals-master'

export const API_CREATE_PORTAL = '/create-portal'

export const API_UPDATE_PORTAL = '/update-portal'

export const API_DELETE_PORTAL = '/delete-portal'

/* --- System Info API Path --- */
export const API_GET_SYSTEM_INFO = 'get-sys-setting'

/* --- Code Info API Path --- */
export const API_GET_CODE_INFO = '/get-code'

/* --- upload file --- */
export const API_SIGN_S3 = '/upload-s3'

/* --- headquarter --- */
export const API_GET_HEADEQUARTER = '/get-hquser'

export const API_CREATE_HEADQUARTER = '/create-hquser'
export const API_UPDATE_HEADQUARTER = '/update-hquser'
export const API_DELETE_HEADQUARTER = '/delete-hquser'

/* --- branch --- */
export const API_GET_BRANCH = '/get-branch'
export const API_CREATE_BRANCH = '/create-branch'
export const API_UPDATE_BRANCH = '/update-branch'
export const API_DELETE_BRANCH = '/delete-branch'

/* --- custuser --- */
export const API_GET_CUSTUSER = '/get-custusers'
export const API_CREATE_CUSTUSER = '/create-custuser'
export const API_UPDATE_CUSTUSER = '/update-custuser'
export const API_DELETE_CUSTUSER = '/delete-custuser'
export const API_GET_CUSTOMER_NAME = '/get-customer-name'

/* --- customer --- */
export const API_GET_CUSTOMER = '/get-customers'
export const API_CREATE_CUSTOMER = '/create-customer'
export const API_UPDATE_CUSTOMER = '/update-customer'
export const API_DELETE_CUSTOMER = '/delete-customer'

/* --- distribution data --- */
export const API_GET_DISTRIBUTION_DATA = '/data-transfer'
export const API_CREATE_DISTRIBUTION_DATA = '/create-data-transfer'
export const API_UPDATE_DISTRIBUTION_DATA = '/update-data-transfer'
export const API_DELETE_DISTRIBUTION_DATA = '/delete-data-transfer'

/* --- allergen --- */
export const API_GET_ALLERGEN = '/get-allergens'
export const API_CREATE_ALLERGEN = '/create-allergen'
export const API_UPDATE_ALLERGEN = '/update-allergen'
export const API_DELETE_ALLERGEN = '/delete-allergen'

/* --- download CSV --- */
export const API_DOWNLOAD_CSV = '/download-file-csv'

/* --- check file existed in s3 --- */
export const API_CHECK_EXISTED_FILE = '/s3-check-existed-file'

/* --- exception template --- */
export const API_GET_EXCEPTION_TEMPLATE = '/get-exception-template'

/* --- exceltemplate --- */
export const API_GET_EXCEL_TEMPLATE = '/get-excel-template'
export const API_CREATE_EXCEL_TEMPLATE = '/create-excel-template'
export const API_UPDATE_EXCEL_TEMPLATE = '/update-excel-template'
export const API_DELETE_EXCEL_TEMPLATE = '/delete-excel-template'

export const API_SQS_CONVERT_EXCEL2PDF = '/sqs-convert-excel2pdf'

/* --- reception-data --- */
export const API_GET_RECEPTION_DATA = 'get-data-receive'

/* --- compliance-data --- */
export const API_GET_COMPLIANCE_DATA = '/get-compliance'
export const API_UPDATE_COMPLIANCE_DATA = '/update-compliance'

/* --- download-status --- */
export const API_GET_DOWNLOAD_STATUS = '/get-transfer-ctrl'

/* --- download-status-detail--- */
export const API_GET_DOWNLOAD_STATUS_DETAIL = '/get-customer-transfer-ctrl-detail'

/* --- delivery-data --- */
export const API_GET_DELIVERY_DATA = '/get-delivery-data'

/* --- delivery-data-detail --- */
export const API_GET_DELIVERY_DATA_DETAIL = '/get-delivery-data-detail'

/* --- my-page --- */
export const API_UPDATE_MY_PAGE = '/update-my-page'

/* --- reissue --- */
export const API_UPDATE_REISSUE = '/update-reissue'

/* --- menu-list--- */
export const API_GET_MENU_LIST = '/get-menu-list'

/* --- delete-file-from-s3--- */
export const API_DELETE_FILE_FROM_S3 = '/delete-file-in-s3'

/* --- check-template-file-exist-in-db--- */
export const API_CHECK_EXCEL_TEMPLATE_EXIST_IN_DB = '/check-exist-excel-template-in-db'

/* --- generate-presigned-url-s3--- */
export const API_GENERATE_PRESIGNED_URL_S3 = '/generate-presigned-url'

/* --- get-file-from-s3--- */
export const API_GET_FILE_FROM_S3 = '/download-file'

export const API_CHECK_EXPORT_FILE_FROM_S3 = '/check-file-export-pdf'
