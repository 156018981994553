import { getTitleModalConfirm, validURL } from 'commons/utilities'
import { PORTAL_TYPES } from 'constants/constant'

export const portalValidate = (data, i18n) => {
  const messages = {}
  if (data.KB_MESSAGE === PORTAL_TYPES.LINK && data.NM_URL && !validURL(data.NM_URL))
    messages.NM_URL = i18n.t('validate.portal.msgUrlInvalid')
  if (data.DT_START && !new Date(data.DT_START).getTime())
    messages.DATE = i18n.t('validate.portal.msgInvalidDateFormat')
  if (data.DT_END && !new Date(data.DT_END).getTime()) messages.DATE = i18n.t('validate.portal.msgInvalidDateFormat')
  if (data.DT_START && data.DT_END && new Date(data.DT_END) < new Date(data.DT_START)) {
    messages.InvalidDtEnd = i18n.t('validate.portal.msgDtEndInvalid')
  }
  return messages
}

export const fileValidate = (files, sysInfo, fileAttack, i18n) => {
  try {
    let messageError = {},
      filesInvalidFormat = [],
      filesInvalidSize = [],
      filesOver = [],
      fileExist = [],
      fileValid = []
    for (let index = 0; index < files.length; index++) {
      const element = files[index]
      if (element instanceof File) {
        let extFile = element.name.substr(element.name.lastIndexOf('.') + 1)
        if (sysInfo.NM_EXT?.indexOf(extFile) < 0) {
          messageError.NM_EXT = i18n.t('validate.portal.errorFileMessage.NM_EXT')
          filesInvalidFormat.push(element)
          break
        }
        if (element.size / 1024 / 1024 > sysInfo.NU_UPMAXSZ) {
          messageError.NU_UPMAXSZ = `<div class="message__pre-text">
          <div>「${element.name}」${i18n.t('validate.portal.errorFileMessage.NU_UPMAXSZ1')}</div>
          <div> ${i18n.t('validate.portal.errorFileMessage.NU_UPMAXSZ2')}</div>
          <div> ${i18n.t('validate.portal.errorFileMessage.NU_UPMAXSZ3', {
            maxSize: sysInfo.NU_UPMAXSZ,
          })}</div>
          </div>`
          filesInvalidSize.push(element)
          break
        }
        if (index + 1 > sysInfo.NU_UPMAX) {
          messageError.NU_UPMAX = `<div class="message__pre-text">
          <div>${i18n.t('validate.portal.errorFileMessage.NU_UPMAX1')}</div>
          <div>${i18n.t('validate.portal.errorFileMessage.NU_UPMAX2', {
            maxFile: sysInfo.NU_UPMAX,
          })}</div>
          </div>`
          filesOver.push(element)
          break
        }
        if (fileAttack && fileAttack.indexOf(element.name) > -1) {
          fileExist.push(element)
          break
        }
      }
      fileValid.push(element)
    }
    if (fileExist.length)
      messageError.FILE_EXIST = `${getTitleModalConfirm(
        `「${fileExist.map((f) => f.name).join(', ')}」` +
          `${i18n.t('validate.portal.errorFileMessage.FILE_EXIST.line1')}`,
        i18n.t('validate.portal.errorFileMessage.FILE_EXIST.line2')
      )}`
    return { messageError, filesInvalidFormat, filesInvalidSize, filesOver, fileExist, fileValid }
  } catch (e) {
    return null
  }
}
