import {
  CLEAR_HEADQUARTER,
  CREATE_HEADQUARTER_FAILED,
  CREATE_HEADQUARTER_REQUEST,
  CREATE_HEADQUARTER_SUCCESS,
  DELETE_HEADQUARTER_FAILED,
  DELETE_HEADQUARTER_REQUEST,
  DELETE_HEADQUARTER_SUCCESS,
  GET_HEADQUARTERS_LIST_FAILED,
  GET_HEADQUARTERS_LIST_REQUEST,
  GET_HEADQUARTERS_LIST_SUCCESS,
  UPDATE_HEADQUARTER_FAILED,
  UPDATE_HEADQUARTER_REQUEST,
  UPDATE_HEADQUARTER_SUCCESS,
} from 'constants/actionTypes/headQuartersActionTypes'

const listHeadQuarters = (state = { listHeadQuarters: [] }, action) => {
  switch (action.type) {
    case GET_HEADQUARTERS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_HEADQUARTERS_LIST_REQUEST,
      }
    case GET_HEADQUARTERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        listHeadQuarters: action.payload,
        type: GET_HEADQUARTERS_LIST_SUCCESS,
      }
    case GET_HEADQUARTERS_LIST_FAILED:
      return {
        ...state,
        loading: false,
        listHeadQuarters: [],
        message: action.payload,
        type: GET_HEADQUARTERS_LIST_FAILED,
      }
    default:
      return state
  }
}

const createHquser = (state = { hquser: {} }, action) => {
  switch (action.type) {
    case CREATE_HEADQUARTER_REQUEST:
      return {
        ...state,
        loading: true,
        hquser: action.payload,
        type: CREATE_HEADQUARTER_REQUEST,
      }
    case CREATE_HEADQUARTER_SUCCESS:
      return {
        ...state,
        loading: false,
        hquser: { ...action.payload },
        type: CREATE_HEADQUARTER_SUCCESS,
        message: 'success',
      }
    case CREATE_HEADQUARTER_FAILED:
      return {
        ...state,
        loading: false,
        hquser: {},
        message: action.payload,
        type: CREATE_HEADQUARTER_FAILED,
      }
    case CLEAR_HEADQUARTER:
      return { type: CLEAR_HEADQUARTER, loading: false, status: null, hquser: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, hquser: {}, message: '' }
  }
}

const updateHquser = (state = { hquser: {} }, action) => {
  switch (action.type) {
    case UPDATE_HEADQUARTER_REQUEST:
      return {
        ...state,
        loading: true,
        hquser: action.payload,
        type: UPDATE_HEADQUARTER_REQUEST,
      }
    case UPDATE_HEADQUARTER_SUCCESS:
      return {
        ...state,
        loading: false,
        hquser: { ...action.payload },
        type: UPDATE_HEADQUARTER_SUCCESS,
        message: 'success',
      }
    case UPDATE_HEADQUARTER_FAILED:
      return {
        ...state,
        loading: false,
        hquser: {},
        message: action.payload,
        type: UPDATE_HEADQUARTER_FAILED,
      }
    case CLEAR_HEADQUARTER:
      return { type: CLEAR_HEADQUARTER, loading: false, status: null, hquser: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, hquser: {}, message: '' }
  }
}

const deleteHquser = (state = { hquser: {} }, action) => {
  switch (action.type) {
    case DELETE_HEADQUARTER_REQUEST:
      return {
        ...state,
        loading: true,
        hquser: action.payload,
        type: DELETE_HEADQUARTER_REQUEST,
      }
    case DELETE_HEADQUARTER_SUCCESS:
      return {
        ...state,
        loading: false,
        hquser: { ...action.payload },
        type: DELETE_HEADQUARTER_SUCCESS,
        message: 'success',
      }
    case DELETE_HEADQUARTER_FAILED:
      return {
        ...state,
        loading: false,
        hquser: {},
        message: action.payload,
        type: DELETE_HEADQUARTER_FAILED,
      }
    case CLEAR_HEADQUARTER:
      return { type: CLEAR_HEADQUARTER, loading: false, status: null, hquser: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, hquser: {}, message: '' }
  }
}

export { createHquser, deleteHquser, listHeadQuarters, updateHquser }
