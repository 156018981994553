import './Information.css'

import React from 'react'

function Information({ icon, message, line2 }) {
  return (
    <div className="info-container">
      <span className="icon-container">{icon}</span>
      {line2 ? (
        <div className="multi-infoText-container">
          <div className="infoText">
            <div>{message}</div>
            <div>{line2}</div>
          </div>
        </div>
      ) : (
        <div className="infoText-container">
          <div className="infoText">
            <div>{message}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Information
