import { makeStyles } from '@material-ui/core/styles'
const styles = makeStyles((theme) => ({
  arrow: {
    position: 'absolute',
    boxSizing: 'border-box',
    width: 20,
    height: 15,
    '&::before': {
      content: '',
      border: '1px solid rgb(85, 130, 50)',
      backgroundColor: '#fff',
      boxSizing: 'border-box',
      width: '100%',
      height: '100%',
    },
    '&::after': {},
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid rgb(85, 130, 50)',
    color: 'rgb(85, 130, 50)',
    left: -10,
    width: '100%',
    maxWidth: 'unset',
    fontSize: 12,
    whiteSpace: 'pre',
  },
}))
export default styles
