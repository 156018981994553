import './PassWord.css'

import { Lock } from '@material-ui/icons'
import PasswordFormInput from 'components/commons/FormInputs/PasswordForm'
import { SCREEN_NAME } from 'constants/constant'
import React from 'react'
import { useTranslation } from 'react-i18next'

function PassWord({ dataForm, error, handleUpdate, setError, required, screen = '' }) {
  const { t } = useTranslation()

  return (
    <div
      className={`background-password-form ${
        screen === SCREEN_NAME.HEAD_QUARTER || screen === SCREEN_NAME.CUSTUSER ? 'input-password-custom' : ''
      }`}
    >
      <div className={'hq-password-header'}>
        <div className="hq-password-icon">
          <Lock style={{ fontSize: 24 }} />
        </div>
        <div className="hq-password-title">{t('formHeadQuarter.lblPassWord')}</div>
      </div>
      <div className="hq-password">
        <div style={{ width: '39px', height: '38px' }}></div>

        <PasswordFormInput
          noIcon={false}
          label={t('formHeadQuarter.lblPassWord')}
          password={dataForm.password}
          setPassword={(e) => handleUpdate('password', e)}
          errorMessage={error?.errorPassword}
          setErrorMessage={(value) => setError({ ...error, ...{ errorPassword: value } })}
          tabIndex="1"
          required={required}
          screen={screen}
        ></PasswordFormInput>
      </div>
      <div className="hq-password">
        <div style={{ width: '39px', height: '38px' }}></div>
        <PasswordFormInput
          noIcon={false}
          label={t('formHeadQuarter.lblPassWordReEnter')}
          password={dataForm.confirmPassword}
          setPassword={(e) => handleUpdate('confirmPassword', e)}
          errorMessage={error?.errorConfirmPassword}
          setErrorMessage={(value) => setError({ ...error, ...{ errorConfirmPassword: value } })}
          tabIndex="2"
          required={required}
          screen={screen}
        ></PasswordFormInput>
      </div>
    </div>
  )
}

export { PassWord }
