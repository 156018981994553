import { combineReducers } from 'redux'

import { createAllergen, deleteAllergen, listAllergen, updateAllergen } from './allergenReducer'
import { createBranch, deleteBranch, listBranch, updateBranch } from './branchReducers'
import { userChangePassReducer } from './changePassReducer'
import { codeInfo } from './codeReducers'
import { listCompliance, updateCompliance } from './complianceReducer'
import { createCustomer, deleteCustomer, listCustomers, updateCustomer } from './customerReducers'
import { createCustUser, deleteCustUser, getCustomerName, listCustUser, updateCustUser } from './custUserReducers'
import { listDeliveryDataDetail } from './deliveryDataDetailReducer'
import { listDeliveryData } from './deliveryDataReducer'
import {
  createDistributionData,
  deleteDistributionData,
  listDistributionData,
  updateDistributionData,
} from './distributionDataReducers'
import { listDownloadStatusDetail } from './downloadStatusDetailReducer'
import { listDownloadStatus } from './downloadStatusReducer'
import {
  checkExistExcelTemplate,
  createExcelTemplate,
  deleteExcelTemplate,
  listExcelTemplate,
  updateExcelTemplate,
} from './excelTemplateReducer'
import { listExceptionData } from './exceptionDataTemplateReducers'
import { exportPDFReducer } from './exportPDFReducer'
import { ForgotPasswordReducer } from './forgotPassReducers'
import { createHquser, deleteHquser, listHeadQuarters, updateHquser } from './headQuartersReducer'
import { listMenu } from './menuListReducer'
import { getMyPage, updateMyPage } from './myPageReducer'
import { createPortal, deletePortal, listPortals, updatePortal } from './portalReducers'
import { listReceptionData } from './receptionDataReducer'
import { updateReissue } from './reissueReducers'
import { systemInfo } from './systemReducers'
import { userLoginReducer } from './userReducers'

const appReducer = combineReducers({
  userInfo: userLoginReducer,
  userChangePass: userChangePassReducer,
  forgotPass: ForgotPasswordReducer,
  listPortals: listPortals,
  listHeadQuarters: listHeadQuarters,
  systemInfo,
  codeInfo,
  createPortal,
  updatePortal,
  deletePortal,
  createHquser,
  deleteHquser,
  updateHquser,
  listBranch,
  createBranch,
  deleteBranch,
  updateBranch,
  listCustUser,
  createCustUser,
  updateCustUser,
  getCustomerName,
  deleteCustUser,
  listCustomers,
  updateCustomer,
  createCustomer,
  deleteCustomer,
  createDistributionData,
  deleteDistributionData,
  listDistributionData,
  updateDistributionData,
  listAllergen,
  createAllergen,
  updateAllergen,
  deleteAllergen,
  listExcelTemplate,
  deleteExcelTemplate,
  updateExcelTemplate,
  createExcelTemplate,
  listExceptionData,
  listReceptionData,
  listCompliance,
  updateCompliance,
  listDownloadStatus,
  listDownloadStatusDetail,
  listDeliveryData,
  listDeliveryDataDetail,
  updateMyPage,
  getMyPage,
  updateReissue,
  listMenu,
  exportPDFReducer,
  checkExistExcelTemplate,
})

const rootReducer = (state, action) => {
  // clear all state in case 'USER_LOGOUT'
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }

  // combine state
  return appReducer(state, action)
}

export default rootReducer
