import './MyPage.css'
import 'components/commons/FormEdits/FormEdit'

import Button from '@material-ui/core/Button'
import { Info, MailOutline } from '@material-ui/icons'
import { clearMyPage, getMyPageAction, updateMyPageAction } from 'actions/myPageActions'
import { IMAGES } from 'assets'
import {
  formatDateWithPadding,
  getDateInfoSystem,
  getSessionInfo,
  getTitleModalConfirm,
  getUserInfo,
} from 'commons/utilities'
import {
  CustomCheckbox,
  FormInputs,
  Image,
  MessageErrorDialog,
  ModalConfirm,
  ModalConfirmNavigation,
  ProgressDialog,
} from 'components/commons'
import PasswordFormInput from 'components/commons/FormInputs/PasswordForm'
import Information from 'components/commons/Information/Information'
import {
  GET_MY_PAGE_FAILED,
  GET_MY_PAGE_REQUEST,
  GET_MY_PAGE_SUCCESS,
  // CLEAR_MY_PAGE,
  UPDATE_MY_PAGE_FAILED,
  UPDATE_MY_PAGE_REQUEST,
  UPDATE_MY_PAGE_SUCCESS,
} from 'constants/actionTypes/myPageActionTypes'
import { SCREEN_NAME } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
// import changePasswordValidate from 'validation/changePassValidate'
import { changePasswordValidate, myPageValidate } from 'validation/myPageValidate.js'

// import ChangePassWord from 'views/changePassword/changePassword'
import styles from './MyPageStyle'

function MyPage() {
  const classes = styles()
  const [error, setError] = useState({})
  const history = useHistory()
  const [isBlocked, setIsBlocked] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [messageError, setMessageError] = useState(false)
  const userState = useSelector((state) => state.userInfo)
  const { userInfo } = userState
  const updateMyPageReducer = useSelector((state) => state.updateMyPage || {})
  const getMyPageReducer = useSelector((state) => state.getMyPage || {})
  const { hquser } = getMyPageReducer

  const dispatch = useDispatch()
  const [getDataloading, setGetDatagLoading] = useState(false)
  const [updateloading, setUpdateLoading] = useState(false)

  useEffect(() => {
    dispatch(getMyPageAction({ userId: userInfo?.data?.ID_USER }))
  }, [dispatch])

  const initialDataForm = {
    ID_USER: userInfo?.data?.ID_USER || '',
    NM_USER: '',
    NM_MAIL1: '',
    NM_MAIL2: '',
    DT_DEADL: getDateInfoSystem().DT_DEFAULT,
    DT_REGIST: '',
    DT_UPDATE: '',
    KB_DATA: '',
    KB_SYSTEM: '',
    KB_ADMIN: '1',
    password: '',
    newPassword: '',
    confirmPassword: '',
  }
  const [dataForm, setDataForm] = useState(initialDataForm)

  const [isInputPassword, setIsInputPassword] = useState(false)
  const { i18n, t } = useTranslation()

  // hide message error base on form input field
  const hideMessageError = (name = '') => {
    if (name === 'NM_MAIL1') {
      setError({ ...error, ...{ email1: '' } })
    }

    if (name === 'NM_MAIL2') {
      setError({ ...error, ...{ email2: '' } })
    }
    if (name === 'password') {
      setError({ ...error, ...{ errorPassword: '' } })
    }
    if (name === 'newPassword') {
      setError({ ...error, ...{ errorNewPassword: '' } })
    }
    if (name === 'confirmPassword') {
      setError({ ...error, ...{ errorConfirmPassword: '' } })
    }
  }

  const handleUpdate = (name, value) => {
    hideMessageError(name)
    setIsBlocked(true)
    if (name === 'KB_DATA') {
      setDataForm((prev) => ({ ...prev, KB_DATA: (value + 0).toString() }))
      return
    }
    if (name === 'KB_SYSTEM') {
      setDataForm((prev) => ({ ...prev, KB_SYSTEM: (value + 0).toString() }))
      return
    }
    setDataForm((prev) => ({ ...prev, [name]: value }))
  }

  const checkRequire = React.useCallback(() => {
    if (!dataForm.NM_USER || !dataForm.NM_MAIL1) return false
    return isBlocked
  }, [dataForm, isBlocked])

  const isErrorUserInfo = (error = {}) => {
    const { email1, email2 } = error
    if (email1 || email2) {
      return true
    }
    return false
  }
  const isErrorChangePassword = (error = {}) => {
    const { errorNewPassword, errorPassword, errorConfirmPassword } = error
    if (errorNewPassword || errorPassword || errorConfirmPassword) {
      return true
    }
    return false
  }
  useEffect(() => {
    const { password, newPassword, confirmPassword } = dataForm
    if (password.length > 0 || newPassword.length > 0 || confirmPassword.length > 0) {
      setIsInputPassword(true)
    } else {
      setIsInputPassword(false)
    }
    if (isErrorUserInfo(error) || !checkRequire()) {
      setCanSubmit(false)
    } else {
      setCanSubmit(true)
    }
    if (dataForm.password || dataForm.newPassword || dataForm.confirmPassword) {
      if (
        dataForm.password &&
        dataForm.newPassword &&
        dataForm.confirmPassword &&
        !isErrorChangePassword(error) &&
        checkRequire()
      ) {
        setCanSubmit(true)
      } else {
        setCanSubmit(false)
      }
    }
  }, [dataForm, error, checkRequire])

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { email1, email2 } = myPageValidate(dataForm.NM_MAIL1, dataForm.NM_MAIL2, i18n)
    if (email1 || email2) {
      setError({ email1, email2 })
      return
    }
    const userInfoDetail = await getUserInfo(userInfo?.data)
    const passwordInDB = userInfoDetail.ID_PASSW
    if (dataForm.password && dataForm.newPassword && dataForm.confirmPassword) {
      const { currentPasswordError, newPasswordError, confirmPasswordError } = changePasswordValidate(
        passwordInDB,
        dataForm.password,
        dataForm.newPassword,
        dataForm.confirmPassword,
        i18n
      )
      if (currentPasswordError || newPasswordError || confirmPasswordError) {
        const errorPassword = {
          errorPassword: currentPasswordError ? currentPasswordError : undefined,
          errorNewPassword: newPasswordError ? newPasswordError : undefined,
          errorConfirmPassword: confirmPasswordError ? confirmPasswordError : undefined,
        }
        setError({
          ...error,
          ...errorPassword,
        })
        return
      }
    }

    setShowModal(true)
    setError()
  }

  const submitData = () => {
    const submitData = { ...dataForm }
    dispatch(updateMyPageAction(submitData))
    setIsBlocked(false)
    setShowModal(false)
  }

  const getTitleModal = isInputPassword
    ? getTitleModalConfirm(t('myPage.msgConfirmWithPassword.line1'), t('myPage.msgConfirmWithPassword.line2'))
    : getTitleModalConfirm(t('myPage.msgConfirmWithoutPassword.line1'), t('myPage.msgConfirmWithoutPassword.line2'))

  useEffect(() => {
    setGetDatagLoading(getMyPageReducer.loading)
    setIsBlocked(false)
    switch (getMyPageReducer.type) {
      case GET_MY_PAGE_REQUEST:
        break
      case GET_MY_PAGE_FAILED:
        if (!getSessionInfo()) {
          setMessageError(getMyPageReducer.message)
        }
        break
      case GET_MY_PAGE_SUCCESS:
        if (Object.keys(hquser).length > 0) {
          setDataForm((prev) => ({
            ...prev,
            NM_USER: hquser.NM_USER || '',
            NM_MAIL1: hquser.NM_MAIL1 || '',
            NM_MAIL2: hquser.NM_MAIL2 || '',
            KB_DATA: hquser.KB_DATA || '',
            KB_SYSTEM: hquser.KB_SYSTEM || '',
            KB_ADMIN: hquser.KB_ADMIN || '',
            DT_UPDATE: formatDateWithPadding(new Date(hquser.DT_UPDATE), 'yyyy-MM-dd HH:mm:ss') || '',
          }))
        }
        // dispatch(clearMyPage())
        break
      default:
        return
    }
  }, [getMyPageReducer])

  useEffect(() => {
    setUpdateLoading(updateMyPageReducer.loading)
    setIsBlocked(false)

    switch (updateMyPageReducer.type) {
      case UPDATE_MY_PAGE_REQUEST:
        break
      case UPDATE_MY_PAGE_FAILED:
        if (!getSessionInfo()) {
          setMessageError(updateMyPageReducer.message)
        }
        break
      case UPDATE_MY_PAGE_SUCCESS:
        history.push({
          pathname: '/admin/my-page',
        })
        dispatch(getMyPageAction({ userId: userInfo?.data?.ID_USER }))
        dispatch(clearMyPage())
        break
      default:
        break
    }
  }, [updateMyPageReducer])

  // const resetErrorMessage = () => {
  //   setError({})
  // }

  // clear and exit
  const handleCancelButton = () => {
    history.push({
      pathname: '/admin/portal',
    })
  }

  const handleClearOnClick = () => {
    // setDataForm(initialDataForm)
    // setErrorMessage('')
    // resetErrorMessage()
    handleCancelButton()
  }

  if (getDataloading) {
    return <ProgressDialog label={t('formHeadQuarter.loading')} />
  }

  return (
    <div className="myPage_container ">
      {
        <ModalConfirmNavigation
          title={getTitleModalConfirm(t('myPage.msgConfirmNavigate.line1'), t('myPage.msgConfirmNavigate.line2'))}
          isBlocked={isBlocked}
        />
      }
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      <ModalConfirm
        title={getTitleModal}
        showModal={showModal}
        setShowModal={setShowModal}
        handleFunction={submitData}
      />

      {updateloading && <ProgressDialog label={t('formHeadQuarter.loading')} />}

      <div className="myPage_wrapper">
        <div className="myPage_content">
          {/* Change HQ user  */}
          <div className="change_userInfo">
            {/* Header HQ user  */}
            <div className="userInfo_header">
              <div className="userInfo_header_text">
                <div className="userInfo_header_text_wrapper">{t('changePassword.lblChangeHqHeaderText')}</div>
              </div>
            </div>
            {/* Form HQ user  */}
            <div className="userInfo_content formEdit-container">
              <form className="userInfo_form">
                <div className="userInfo_form_container portal-create form-scroll">
                  <div className="portal-create-content form-min-width">
                    <div className="nm-user-container">
                      {/*NM_USER  */}
                      <FormInputs
                        id="standard-basic"
                        label={t('formHeadQuarter.lblUserName')}
                        value={dataForm.NM_USER || ''}
                        required
                        icon={
                          <Image src={IMAGES.ICON.CARD_USER} alt="Card User Icon" className="iconForm icon--deactive" />
                        }
                        type="text"
                        name="NM_USER"
                        setValue={(e) => handleUpdate('NM_USER', e)}
                        className="width450"
                      />

                      {/* Checkbox */}
                      <div className="row_checkbox--send-email">
                        <CustomCheckbox
                          label={t('formHeadQuarter.lblCheckRegister')}
                          value={Boolean(Number(dataForm.KB_ADMIN))}
                          // onChange={(e) => handleUpdate('KB_ADMIN', e)}
                          className="checkbox"
                          disabled={true}
                        />
                      </div>
                    </div>

                    {/*NM_EMAIL1*/}
                    <FormInputs
                      id="standard-basic"
                      label={t('formHeadQuarter.lblEmail1')}
                      value={dataForm.NM_MAIL1 || ''}
                      required
                      icon={<MailOutline className="icon--deactive" />}
                      type="email"
                      name="NM_MAIL1"
                      setValue={(e) => handleUpdate('NM_MAIL1', e)}
                      error={error && !!error.email1}
                      helperText={error && error.email1 ? error.email1 : null}
                      className="width450"
                      //inputProps={{ maxLength: 30 }}
                      errorPosition={'105px'}
                    />
                    {/*NM_MAIL2  */}
                    <FormInputs
                      id="standard-basic"
                      label={t('formHeadQuarter.lblEmail2')}
                      value={dataForm.NM_MAIL2 || ''}
                      icon={<MailOutline className="icon--deactive" />}
                      type="email"
                      name="NM_MAIL2"
                      setValue={(e) => handleUpdate('NM_MAIL2', e)}
                      error={error && !!error.email2}
                      helperText={error && error.email2 && dataForm.NM_MAIL2 ? error.email2 : null}
                      className="width450"
                      //inputProps={{ maxLength: 30 }}
                      errorPosition={'105px'}
                    />
                    {/* KB_DATA */}
                    <div className="row_checkbox--send-email margin-top-30 mutiple-line">
                      <CustomCheckbox
                        label={t('formHeadQuarter.lblKbData')}
                        name="KB_DATA"
                        value={Boolean(Number(dataForm.KB_DATA))}
                        onChange={(e) => handleUpdate('KB_DATA', e)}
                        className="checkbox"
                      />

                      <Information
                        icon={<Info className={classes.iconInfo} />}
                        message={t('formHeadQuarter.lblTooltipNotifyData')}
                      />
                    </div>
                    {/* KB_SYSTEM */}
                    <div className="row_checkbox--send-email margin-20">
                      <CustomCheckbox
                        label={t('formHeadQuarter.lblKbSystem')}
                        name="KB_SYSTEM"
                        value={Boolean(Number(dataForm.KB_SYSTEM))}
                        onChange={(e) => handleUpdate('KB_SYSTEM', e)}
                        className="checkbox"
                      />
                      <Information
                        icon={<Info className={classes.iconInfo} />}
                        message={t('formHeadQuarter.lblTooltipNotifySystem')}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* Change PassWord  */}
          <div className="change_userPass">
            {/* Header Change PassWord  */}
            <div className="change_userPass_header">
              <div className="change_userPass_header_text">
                <div className="change_userPass_header_text_wrapper">{t('changePassword.lblChangePassHeaderText')}</div>
              </div>
            </div>
            {/* Form Change PassWord  */}
            <div className="userInfo_content">
              <form className="userInfo_form">
                <div className="userInfo_form_container portal-create form-scroll">
                  <div className="portal-create-content form-min-width">
                    <div className="changepass-container">
                      <PasswordFormInput
                        password={dataForm.password}
                        setPassword={(e) => handleUpdate('password', e)}
                        errorMessage={error?.errorPassword}
                        setErrorMessage={(value) => setError({ ...error, ...{ errorPassword: value } })}
                        label={t('changePassword.lblOldPassword')}
                        tabIndex="0"
                        screen={SCREEN_NAME.MY_PAGE}
                        required={isInputPassword}
                        customClassLabel={'customChangePassword'}
                      ></PasswordFormInput>
                      <PasswordFormInput
                        password={dataForm.newPassword}
                        setPassword={(e) => handleUpdate('newPassword', e)}
                        errorMessage={error?.errorNewPassword}
                        setErrorMessage={(value) => setError({ ...error, ...{ errorNewPassword: value } })}
                        label={t('changePassword.lblNewPassword')}
                        tabIndex="0"
                        screen={SCREEN_NAME.MY_PAGE}
                        customClassLabel={'customChangePassword'}
                        required={isInputPassword}
                      ></PasswordFormInput>
                      <PasswordFormInput
                        password={dataForm.confirmPassword}
                        setPassword={(e) => handleUpdate('confirmPassword', e)}
                        errorMessage={error?.errorConfirmPassword}
                        setErrorMessage={(value) => setError({ ...error, ...{ errorConfirmPassword: value } })}
                        label={t('myPage.lblPassWordReEnter')}
                        screen={SCREEN_NAME.MY_PAGE}
                        tabIndex="0"
                        customClassLabel={'customChangePassword'}
                        required={isInputPassword}
                      ></PasswordFormInput>

                      {/* Form User Update, Delete Time  */}
                      <div className="change_userPass_infor formEdit-container">
                        <div className="formEdit-input">
                          <div className="input-spacing"></div>
                          <FormInputs
                            id="standard-basic"
                            canSpacing={true}
                            label={t('changePassword.lblExpireDate')}
                            value={userInfo?.data?.DT_DEADL || ''}
                            type="text"
                            name="DT_DEADL"
                            // setValue={(e) => handleUpdate('NM_RUDUSER', e)}
                            disabled
                            className="width120"
                          />
                        </div>

                        <div className="formEdit-input">
                          <div className="input-spacing"></div>
                          <FormInputs
                            id="standard-basic"
                            canSpacing={true}
                            label={t('changePassword.lblUpdatePerson')}
                            type="text"
                            value={dataForm.ID_USER || ''}
                            name="DT_DELETE"
                            disabled
                            className="width210"
                          />
                        </div>

                        <div className="formEdit-input">
                          <div className="input-spacing"></div>
                          <div className="formEdit-input wrap-mb">
                            <FormInputs
                              id="standard-basic"
                              canSpacing={true}
                              label={t('changePassword.lblRegisteredDate')}
                              value={
                                formatDateWithPadding(new Date(userInfo?.data?.DT_REGIST), 'yyyy-MM-dd HH:mm:ss') || ''
                              }
                              type="text"
                              name="DT_REGIST"
                              disabled
                              className="width160"
                            />
                            <FormInputs
                              id="standard-basic"
                              canSpacing={true}
                              label={t('changePassword.lblUpdatedDate')}
                              value={dataForm.DT_UPDATE || ''}
                              type="text"
                              name="DT_UPDATE"
                              disabled
                              className="width160"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="btn-container">
                        <Button
                          className="button submit-button"
                          type="submit"
                          variant="contained"
                          tabIndex="0"
                          disabled={!canSubmit}
                          onClick={handleSubmit}
                        >
                          {t('changePassword.lblChangePasswordConfirm')}
                        </Button>
                        <Button
                          className="button clear-button"
                          variant="outlined"
                          tabIndex="0"
                          onClick={handleClearOnClick}
                        >
                          {t('myPage.btnCancel')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyPage
