import './Checkbox.css'

import Checkbox from '@material-ui/core/Checkbox'
import React from 'react'

/*eslint-disable */
function CustomCheckbox({
  onChange = () => {},
  size = 'small',
  name,
  color,
  defaultChecked,
  inputProps,
  value,
  label = '',
  className,
  disabled = false,
  ...rest
}) {
  const handleChange = (event) => {
    if (typeof onChange === 'function') onChange(event.target.checked)
  }

  return (
    <div className={`custom-checkbox ${className}`}>
      <label>
        <Checkbox
          checked={defaultChecked || value}
          disabled ={disabled}
          color={color}
          name={name}
          size={size}
          inputProps={inputProps}
          onChange={handleChange}
          {...rest}
        />
        {label}
      </label>
    </div>
  )
}
/*eslint-disable */
export default CustomCheckbox
