export const GET_EXCEL_TEMPLATE_LIST_REQUEST = 'GET_EXCEL_TEMPLATE_LIST_REQUEST'
export const GET_EXCEL_TEMPLATE_LIST_SUCCESS = 'GET_EXCEL_TEMPLATE_LIST_SUCCESS'
export const GET_EXCEL_TEMPLATE_LIST_FAILED = 'GET_EXCEL_TEMPLATE_LIST_FAILED'
export const CREATE_EXCEL_TEMPLATE_REQUEST = 'CREATE_EXCEL_TEMPLATE_REQUEST'
export const CREATE_EXCEL_TEMPLATE_FAILED = 'CREATE_EXCEL_TEMPLATE_FAILED'
export const CREATE_EXCEL_TEMPLATE_SUCCESS = 'CREATE_EXCEL_TEMPLATE_SUCCESS'
export const UPDATE_EXCEL_TEMPLATE_REQUEST = 'UPDATE_EXCEL_TEMPLATE_REQUEST'
export const UPDATE_EXCEL_TEMPLATE_FAILED = 'UPDATE_EXCEL_TEMPLATE_FAILED'
export const UPDATE_EXCEL_TEMPLATE_SUCCESS = 'UPDATE_EXCEL_TEMPLATE_SUCCESS'
export const DELETE_EXCEL_TEMPLATE_REQUEST = 'DELETE_EXCEL_TEMPLATE_REQUEST'
export const DELETE_EXCEL_TEMPLATE_FAILED = 'DELETE_EXCEL_TEMPLATE_FAILED'
export const DELETE_EXCEL_TEMPLATE_SUCCESS = 'DELETE_EXCEL_TEMPLATE_SUCCESS'
export const CLEAR_EXCEL_TEMPLATE = 'CLEAR_EXCEL_TEMPLATE'

export const SQS_CONVERT_EXCEL2PDF_REQUEST = 'SQS_CONVERT_EXCEL2PDF_REQUEST'
export const SQS_CONVERT_EXCEL2PDF_SUCCESS = 'SQS_CONVERT_EXCEL2PDF_SUCCESS'
export const SQS_CONVERT_EXCEL2PDF_FAILED = 'SQS_CONVERT_EXCEL2PDF_FAILED'
export const CLEAR_SQS_CONVERT_EXCEL2PDF = 'CLEAR_SQS_CONVERT_EXCEL2PDF'

export const CHECK_EXCEL_TEMPLATE_REQUEST = 'CHECK_EXCEL_TEMPLATE_REQUEST'
export const CHECK_EXCEL_TEMPLATE_SUCCESS = 'CHECK_EXCEL_TEMPLATE_SUCCESS'
export const CHECK_EXCEL_TEMPLATE_FAILED = 'CHECK_EXCEL_TEMPLATE_FAILED'
