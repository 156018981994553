import './ReceptionData.css'

import { getReceptionDataAction } from 'actions/receptionDataAction'
import { formatDateWithPadding, getSessionInfo } from 'commons/utilities'
import { MessageErrorDialog, ProgressDialog } from 'components/commons'
import TableCommon from 'components/commons/Table/TableCommon'
import ToolBar from 'components/commons/ToolBar/ToolBar'
import {
  GET_RECEPTION_DATA_LIST_FAILED,
  GET_RECEPTION_DATA_LIST_REQUEST,
  GET_RECEPTION_DATA_LIST_SUCCESS,
} from 'constants/actionTypes/receptionDataActionTypes'
import { DATE_TYPE, TOOLBAR_TYPE } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

function ReceptionData() {
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })
  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const [listData, setListData] = useState([])
  const [dateFilter, setDateFilter] = useState(DT_TODAY)
  const [filterData, setFilterData] = useState([])
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState()
  const [isValidDate, setIsValidDate] = useState(true)
  const [isInDateRange, setIsInDateRange] = useState(true)
  const listReceptionDataReducer = useSelector((state) => state.listReceptionData || {})
  const [notExistFileInS3Error, setNotExistFileInS3Error] = useState('')

  const handleFilterDate = React.useCallback(
    (date) => {
      if (isValidDate && isInDateRange) {
        let params = {
          dateType: DATE_TYPE.DATE_RECEIVE,
          date: date,
        }
        dispatch(getReceptionDataAction(params))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    dispatch(getReceptionDataAction({}))
    window.history.replaceState({}, document.title)
  }, [dispatch, location])

  useEffect(() => {
    if (listData.length >= 0) {
      setFilterData(listData)
    }
  }, [listData])

  useEffect(() => {
    if (listReceptionDataReducer?.listReceptionData?.length >= 0) {
      let dataResponse = [...listReceptionDataReducer?.listReceptionData]
      setListData(dataResponse)
    }

    setLoading(listReceptionDataReducer?.loading)
    switch (listReceptionDataReducer.type) {
      case GET_RECEPTION_DATA_LIST_REQUEST:
        break
      case GET_RECEPTION_DATA_LIST_FAILED:
        if (!getSessionInfo()) {
          listReceptionDataReducer.message && setMessageError(listReceptionDataReducer.message)
        }
        break
      case GET_RECEPTION_DATA_LIST_SUCCESS:
        break
      default:
        return
    }
  }, [listReceptionDataReducer])

  const listColumnWidth = ['15%', '28%', '10%', '10%', '10%', '10%', '9%', '8%']

  const listCellHeader = [
    { columnName: 'receptionData.lblIdReceptionData', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'receptionData.lblNmReceptionData', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'receptionData.lblNmBranch', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'receptionData.lblDTReceive', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'receptionData.lblTMReceive', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'receptionData.NUSize', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'receptionData.NUCount', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: '', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
  ]

  const listCellBody = [
    { keyValue: 'ID_RCV_DATA', cellStyle: 'data', gridStyle: 'centerAlignHq' },
    { keyValue: 'NM_RCV_DATA', cellStyle: 'data', gridStyle: 'centerAlignHq' },
    { keyValue: 'NM_BRANCH', cellStyle: 'data', gridStyle: 'centerAlignHq' },
    { keyValue: 'DT_RCV', cellStyle: 'data', gridStyle: 'centerAlignHq' },
    { keyValue: 'TM_RCV', specialType: 'HHMM' },
    // { keyValue: 'NU_SIZE', cellStyle: 'dataRight', gridStyle: 'centerAlignHq' },
    { keyValue: 'NU_SIZE', specialType: 'NU_SIZE' },
    // { keyValue: 'NU_COUNT', cellStyle: 'dataRight', gridStyle: 'centerAlignHq' },
    { keyValue: 'NU_COUNT', specialType: 'NU_COUNT' },
  ]

  const listToolBarComponent = [
    { type: TOOLBAR_TYPE.FILTER_LOGO, commonStyle: 'filterIconContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.CALENDAR, commonStyle: 'filterContainer', componentStyle: 'calendar-item' },
  ]
  const toolbar = (
    <ToolBar
      // toolbarWidth={'SMALL'}
      listToolBarComponent={listToolBarComponent}
      handleFilterDate={handleFilterDate}
      setSelectedDate={setDateFilter}
      selectedDate={dateFilter}
      listData={listData}
      csvButtonStyle={true}
      setIsValidDate={setIsValidDate}
      setIsInDateRange={setIsInDateRange}
      canDownloadData={false}
      filterLabel={t('receptionData.lblDTReceive')}
      toolbarNoHeightPaddingTop={true}
      toolbarNoHeight={true}
    />
  )

  const sortData = (listData) => {
    return listData.sort((firstData, secondData) => {
      return (
        secondData.DT_RCV.localeCompare(firstData.DT_RCV) || firstData.ID_RCV_DATA.localeCompare(secondData.ID_RCV_DATA)
      )
    })
  }
  return (
    <div className="reception-data__wrapper reception-data__maintain-wrapper">
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {notExistFileInS3Error && (
        <MessageErrorDialog message={notExistFileInS3Error} showMessageErrorDialog={setNotExistFileInS3Error} />
      )}
      {loading && <ProgressDialog label={t('receptionData.loading')} />}
      {loading ? (
        <ProgressDialog label={t('receptionData.loading')} />
      ) : (
        <div className="reception-data__container maintain-container">
          {/* Table */}
          <TableCommon
            // tableWidth={'SMALL'}
            listColumnWidth={listColumnWidth}
            listCellHeader={listCellHeader}
            listCellBody={listCellBody}
            listDataRender={sortData(filterData)}
            idProperty={'ID_RCV_DATA'}
            toolbar={toolbar}
            canDelete={false}
            canUpdate={false}
            hasFirstCell={false}
            hasEndCell={true}
            canDownloadFile={true}
            hasHeaderLine={true}
            hasRecordCounter={true}
            setNotExistFileInS3Error={setNotExistFileInS3Error}
          />
        </div>
      )}
    </div>
  )
}

export default ReceptionData
