import axios from 'commons/baseUrl'
import {
  CLEAR_CUSTOMER,
  CREATE_CUSTOMER_FAILED,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILED,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  GET_CUSTOMERS_LIST_FAILED,
  GET_CUSTOMERS_LIST_REQUEST,
  GET_CUSTOMERS_LIST_SUCCESS,
  UPDATE_CUSTOMER_FAILED,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
} from 'constants/actionTypes/customerActionTypes'
import { API_CREATE_CUSTOMER, API_DELETE_CUSTOMER, API_GET_CUSTOMER, API_UPDATE_CUSTOMER } from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const getCustomersAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_CUSTOMERS_LIST_REQUEST })
    const res = await axios.get(API_GET_CUSTOMER, { params })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_CUSTOMERS_LIST_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data.data.length >= 0) {
      dispatch({ type: GET_CUSTOMERS_LIST_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: GET_CUSTOMERS_LIST_FAILED, payload: null })
  }
}

const createCustomerAction = (data, i18n) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CUSTOMER_REQUEST, payload: data })
    const res = await axios.post(API_CREATE_CUSTOMER, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: CREATE_CUSTOMER_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_EXISTED) {
      dispatch({
        type: CREATE_CUSTOMER_FAILED,
        payload: i18n.t('validate.customer.cdCustInvalid', {
          fieldName: i18n.t('customer.lblCdCust'),
          cdCustValue: data.CD_CUST,
        }),
      })
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: CREATE_CUSTOMER_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: CREATE_CUSTOMER_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: CREATE_CUSTOMER_FAILED, payload: err.message })
  }
}

const updateCustomerAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CUSTOMER_REQUEST, payload: data })
    const res = await axios.post(API_UPDATE_CUSTOMER, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: UPDATE_CUSTOMER_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: UPDATE_CUSTOMER_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: UPDATE_CUSTOMER_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: UPDATE_CUSTOMER_FAILED, payload: err.message })
  }
}

const deleteCustomerAction = (data, params) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CUSTOMER_REQUEST, payload: data })
    const res = await axios.post(API_DELETE_CUSTOMER, data)
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: DELETE_CUSTOMER_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: DELETE_CUSTOMER_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: DELETE_CUSTOMER_SUCCESS, payload: res.data.data })
      dispatch(getCustomersAction(params))
    }
  } catch (err) {
    dispatch({ type: DELETE_CUSTOMER_SUCCESS, payload: err.message })
  }
}

const clearCustomer = (data) => async (dispatch) => {
  dispatch({ type: CLEAR_CUSTOMER, payload: '' })
}

export { clearCustomer, createCustomerAction, deleteCustomerAction, getCustomersAction, updateCustomerAction }
