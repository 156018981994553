import './DeliveryDataDetail.css'

// import { Button } from '@material-ui/core'
import { getDeliveryDataDetailAction } from 'actions/deliveryDataDetailAction'
// import { IMAGES } from 'assets'
import { caculateTableWidth, findWeekDayJP, formatDateWithPadding, getSessionInfo } from 'commons/utilities'
import { MessageErrorDialog, ProgressDialog } from 'components/commons'
import ToolBar from 'components/commons/ToolBar/ToolBar'
import { GET_DELIVERY_DATA_LIST_NULL } from 'constants/actionTypes/deliveryDataActionTypes'
import {
  GET_DELIVERY_DATA_DETAIL_LIST_FAILED,
  GET_DELIVERY_DATA_DETAIL_LIST_REQUEST,
  GET_DELIVERY_DATA_DETAIL_LIST_SUCCESS,
} from 'constants/actionTypes/deliveryDataDetailActionTypes'
import {
  CHARACTER_WIDTH,
  MAX_CHARACTER,
  RIGHT_LEFT_PADDING,
  SCREEN_NAME,
  TABLE_WIDTH,
  TOOLBAR_TYPE,
} from 'constants/constant'
import React, { useEffect, useRef, useState } from 'react'
// import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import TableDeliveryDataDetail from './TableDeliveryDataDetail'

// import styles from './HeadQuartersStyles'

function DeliveryDataDetail() {
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })
  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const { t } = useTranslation()
  // const classes = styles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [customer, setCustomer] = useState({})
  const [branch, setBranch] = useState({})
  const [listData, setListData] = useState([])
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState()
  const listDeliveryDataDetailReducer = useSelector((state) => state.listDeliveryDataDetail || {})
  const [listFolderName, setListFolderName] = useState([])
  // const [filteredDate, setFilteredDate] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const [listYmRegist, setListYmRegist] = useState([])
  const ymRegistRef = useRef()

  const [listYmRegistOption, setListYmRegistOption] = useState([])

  const [filterItemYmRegist, setFilterItemYmRegist] = useState('0')

  const monthYearFormat = (date) => {
    if (date) {
      return date.substring(0, 7)
    }
  }

  const getYmRegistLabel = () => {
    const listYmRegistFiltered = listYmRegistOption.filter((dtWeek) => dtWeek.value === String(filterItemYmRegist))
    const ymRegistLabel = listYmRegistFiltered[0]?.label
    return ymRegistLabel
  }

  useEffect(() => {
    ymRegistRef.current = getYmRegistLabel()
  }, [filterItemYmRegist])

  const handleFilterDate = (e) => {
    e.preventDefault()
    setTimeout(() => {
      if (branch && customer) {
        dispatch(
          getDeliveryDataDetailAction({
            date: monthYearFormat(ymRegistRef.current),
            cdBranch: branch?.CD_BRANCH,
            cdCust: customer?.CD_CUST,
          })
        )
      }
    }, 1)
  }

  useEffect(() => {
    const { branch, customer, date, searchInput, filterItemYmRegist, listYmRegistOption, listYmRegist } =
      location?.state || {}
    if (searchInput) {
      setSearchInput(searchInput)
    }
    if (filterItemYmRegist) {
      setFilterItemYmRegist(filterItemYmRegist)
    }
    if (listYmRegistOption) {
      setListYmRegistOption(listYmRegistOption)
    }
    if (branch) {
      setBranch(branch)
    }
    if (customer) {
      setCustomer(customer)
    }
    if (listYmRegist) {
      setListYmRegist(listYmRegist)
    }
    if (date) {
      // setFilteredDate(date)
      ymRegistRef.current = date
      dispatch(
        getDeliveryDataDetailAction({
          date: date,
          cdBranch: branch?.CD_BRANCH,
          cdCust: customer?.CD_CUST,
        })
      )
    } else {
      dispatch(
        getDeliveryDataDetailAction({
          date: monthYearFormat(DT_TODAY),
          cdBranch: branch?.CD_BRANCH,
          cdCust: customer?.CD_CUST,
        })
      )
    }
  }, [location, dispatch])

  useEffect(() => {
    if (listDeliveryDataDetailReducer?.listDeliveryDataDetail) {
      const listDeliveryDataDetail = listDeliveryDataDetailReducer?.listDeliveryDataDetail
      const listFolderName = listDeliveryDataDetail?.list_folder_name
      const customer = listDeliveryDataDetail?.customer
      const branch = customer?.branch
      const listDaysInMonth = getDaysInMonth(ymRegistRef.current)
      let listDtRegist = []
      if (listDaysInMonth?.length > 0) {
        const dispFolder = []
        listFolderName?.forEach((folder) => {
          dispFolder.push({
            NM_DISP_FLD: folder,
            NU_DL_COUNT: '',
          })
        })
        listDaysInMonth?.forEach((day) => {
          let arr = branch?.data.filter((data) => data?.DT_REGIST === day)
          if (arr?.length > 0) {
            arr.forEach((el) => {
              listDtRegist.push({ ...el, DT_REGIST: el?.DT_REGIST + ' ( ' + findWeekDayJP(el?.DT_REGIST) + ' )' })
            })
          } else {
            listDtRegist.push({
              DT_REGIST: day + ' ( ' + findWeekDayJP(day) + ' )',
              DISP_FLD: dispFolder,
            })
          }
        })
      }
      setListData(listDtRegist)
      setListFolderName(listFolderName?.sort((a, b) => a.localeCompare(b, 'jp', { ignorePunctuation: true })))
    }

    setLoading(listDeliveryDataDetailReducer?.loading)
    switch (listDeliveryDataDetailReducer?.type) {
      case GET_DELIVERY_DATA_DETAIL_LIST_REQUEST:
        break
      case GET_DELIVERY_DATA_LIST_NULL:
        break
      case GET_DELIVERY_DATA_DETAIL_LIST_FAILED:
        if (!getSessionInfo()) {
          setMessageError(listDeliveryDataDetailReducer?.message)
        }
        break
      case GET_DELIVERY_DATA_DETAIL_LIST_SUCCESS:
        break
      default:
        return
    }
  }, [listDeliveryDataDetailReducer])

  function getDaysInMonth(ymRegist) {
    const date = new Date(ymRegist)
    const month = date.getMonth()
    let days = []
    while (date.getMonth() === month) {
      days.push(formatDateWithPadding(date, 'yyyy-MM-dd'))
      date.setDate(date.getDate() + 1)
    }
    return days
  }

  const createListColumnWidth = (listFolderName) => {
    let listColumnWidth = []
    if (listFolderName?.length === 0) {
      listColumnWidth.push('100%')
      return listColumnWidth
    }
    listColumnWidth.push('120px')
    listColumnWidth = caculateFolderNameWidth(listColumnWidth, listFolderName)
    let totalTableWidth = caculateTableWidth(listColumnWidth)
    if (totalTableWidth < TABLE_WIDTH) {
      listColumnWidth[listColumnWidth.length - 1] = ''
      return listColumnWidth
    }
    listColumnWidth.push('')
    return listColumnWidth
  }

  const caculateFolderNameWidth = (listColumnWidth = [], listFolderName) => {
    for (let index = 0; index <= listFolderName?.length - 1; index++) {
      if (listFolderName[index].length >= MAX_CHARACTER) {
        listColumnWidth.push(MAX_CHARACTER * CHARACTER_WIDTH + RIGHT_LEFT_PADDING + 'px')
      } else {
        listColumnWidth.push(listFolderName[index].length * CHARACTER_WIDTH + RIGHT_LEFT_PADDING + 'px')
      }
    }
    return listColumnWidth
  }
  const listColumnWidth = createListColumnWidth(listFolderName)
  const totalTableWidth = caculateTableWidth(listColumnWidth)

  const createListCellHeader = (listFolderName = []) => {
    const listCellHeader = []
    listCellHeader.push({
      columnName: t('deliveryDataDetail.lblDate'),
      commonStyle: 'titleHead',
      cellStyle: 'deliveryDatePadding',
    })
    for (let index = 0; index < listFolderName?.length; index++) {
      let folderNameCellHeader = {
        columnName: '',
        commonStyle: 'titleHead',
        cellStyle: listFolderName?.length === 1 ? 'oneFolderNameDelivery' : 'personTitleDelivery',
      }
      const folderName = listFolderName[index]
      folderNameCellHeader.columnName = folderName
      listCellHeader.push(folderNameCellHeader)
    }
    return listCellHeader
  }

  const listCellHeader = createListCellHeader(listFolderName)

  const listToolBarComponent = [
    // { type: TOOLBAR_TYPE.DOWNLOAD, commonStyle: 'downloadIconContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.TURN_BACK, commonStyle: 'turnBackIconContainer', componentStyle: 'icon-turnback' },
    { type: TOOLBAR_TYPE.FILTER_LOGO, commonStyle: 'filterIconContainer', componentStyle: '' },
    {
      type: TOOLBAR_TYPE.YEAR_MONTH_CALENDAR,
      commonStyle: 'year-month-calendar-container',
      componentStyle: 'calendar-item',
    },
    { type: TOOLBAR_TYPE.CSV, commonStyle: '', componentStyle: '' },
  ]

  let setDownloadParams = {
    screenName: 'delivery-data-detail',
    date: monthYearFormat(ymRegistRef.current),
    cdBranch: branch.CD_BRANCH,
    cdCust: customer.CD_CUST,
  }

  const handleGoBack = () => {
    history.push({
      pathname: '/admin/delivery-data',
      state: {
        date: ymRegistRef.current,
        searchInput: searchInput,
        currentFilteredItem: filterItemYmRegist,
        listYmRegistCurrent: listYmRegist,
        listYmRegistOptionCurrent: listYmRegistOption,
        screen: SCREEN_NAME.DELIVERY_DATA,
      },
    })
  }

  const toolbar = (
    <ToolBar
      toolbarWidth={'SMALL'}
      handleGoBack={handleGoBack}
      listToolBarComponent={listToolBarComponent}
      listData={listData}
      downloadParams={setDownloadParams}
      filterLabel={t('deliveryData.lblDisplayDate')}
      toolbarNoHeight={true}
      handleFilterDate={handleFilterDate}
      setFilterItemYmRegist={setFilterItemYmRegist}
      filterItemYmRegist={filterItemYmRegist}
      listYmRegistOption={listYmRegistOption}
      totalTableWidth={totalTableWidth}
    />
  )

  const caculatePaddingLeftLastCell = () => {
    if (listFolderName?.length > 0) {
      let CHARACTER_COUNT = listFolderName[listFolderName.length - 1].length
      if (CHARACTER_COUNT >= MAX_CHARACTER) {
        CHARACTER_COUNT = MAX_CHARACTER
      }

      let paddingLeft = (CHARACTER_WIDTH * CHARACTER_COUNT) / 2

      return paddingLeft
    }
  }

  return (
    <div className="delivery-detail__wrapper">
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {loading && <ProgressDialog label={t('headquarter.loading')} />}

      {loading ? (
        <ProgressDialog label={t('headquarter.loading')} />
      ) : (
        <div className="hquser__container maintain-container">
          {/* Table */}
          <TableDeliveryDataDetail
            tableWidth={'SMALL'}
            totalTableWidth={totalTableWidth}
            customer={customer}
            branch={branch}
            listColumnWidth={listColumnWidth}
            listCellHeader={listCellHeader}
            listDataRender={listData}
            listFolderName={listFolderName}
            hasHeaderLine={true}
            toolbar={toolbar}
            hasStartLineItem={true}
            canUpdate={false}
            canDelete={false}
            paddingLastCell={caculatePaddingLeftLastCell()}
          />
        </div>
      )}
    </div>
  )
}

export default DeliveryDataDetail
