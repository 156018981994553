import { checkEmail } from 'commons/utilities'

const forgotPasswordValidate = (userId, email, i18n) => {
  if (!userId.trim().length) {
    return {
      validate: false,
      error: i18n.t('validate.forgotPassword.msgValidateId'),
    }
  }
  // validate email
  if (!checkEmail(email)) {
    return {
      validate: false,
      error: i18n.t('validate.forgotPassword.msgValidateEmail'),
    }
  }
  return {
    validate: true,
    error: null,
  }
}

export default forgotPasswordValidate
