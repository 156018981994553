import {
  GET_DOWNLOAD_STATUS_LIST_FAILED,
  GET_DOWNLOAD_STATUS_LIST_REQUEST,
  GET_DOWNLOAD_STATUS_LIST_SUCCESS,
} from 'constants/actionTypes/downloadStatusActionTypes'
const listDownloadStatus = (state = { listDownloadStatus: [] }, action) => {
  switch (action.type) {
    case GET_DOWNLOAD_STATUS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_DOWNLOAD_STATUS_LIST_REQUEST,
      }
    case GET_DOWNLOAD_STATUS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        listDownloadStatus: action.payload,
        type: GET_DOWNLOAD_STATUS_LIST_SUCCESS,
      }
    case GET_DOWNLOAD_STATUS_LIST_FAILED:
      return {
        ...state,
        loading: false,
        listDownloadStatus: [],
        message: action.payload,
        type: GET_DOWNLOAD_STATUS_LIST_FAILED,
      }
    default:
      return state
  }
}
export { listDownloadStatus }
