import './Login.css'

import { Button, Grid } from '@material-ui/core'
import { Lock, Person } from '@material-ui/icons'
import { getCustUserAction } from 'actions/custUserActions'
import { login } from 'actions/userActions'
import { Card, FormInputs, MessageErrorDialog, ProgressDialog } from 'components/commons'
import {
  GET_CUSTUSER_LIST_FAILED,
  GET_CUSTUSER_LIST_REQUEST,
  GET_CUSTUSER_LIST_SUCCESS,
} from 'constants/actionTypes/custUserActionTypes'
import { USER_LOGIN_FAILED, USER_LOGIN_SUCCESS } from 'constants/actionTypes/userActionTypes'
import { URL_CUST_USER } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import userLoginValidate from 'validation/userValidate'

function Login() {
  const { i18n, t } = useTranslation()
  const [userId, setUserId] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [showProgress, setShowProgress] = useState(false)
  const [showMessageErrorDialog, setShowMessageErrorDialog] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const [returnUrl, setReturnUrl] = useState(false)
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return null
  })
  const listCustUsersReducer = useSelector((state) => state.listCustUser || {})

  const convertMessage = (message) => {
    if (message.includes('Incorrect')) {
      return t('login.msgErrorPasswordIncorrect')
    }

    if (message.includes('disabled')) {
      return t('login.msgErrorDisable')
    }

    return t('login.msgErrorCannotAccess')
  }

  const handleLoginState = (type, errMessage = '') => {
    switch (type) {
      case USER_LOGIN_SUCCESS:
        if (!(history.location.state?.from === URL_CUST_USER)) {
          setShowProgress(false)
          setRedirect(true)
        }
        break
      case USER_LOGIN_FAILED:
        setShowMessageErrorDialog(true)
        setErrorMessage(convertMessage(errMessage))
        setShowProgress(false)
        break
      default:
        return
    }
  }

  const checkRedirectCustUser = () => {
    if (listCustUsersReducer?.listCustUser?.length >= 0) {
      for (let i = 0; i < listCustUsersReducer.listCustUser.length; i++) {
        const item = listCustUsersReducer.listCustUser[i]
        if (item['KB_REISSUE'] === '1' && item['KB_PASSW'] === '0') {
          setReturnUrl(true)
          break
        }
      }
    }
    setShowProgress(false)
    setRedirect(true)
  }
  useEffect(() => {
    switch (listCustUsersReducer.type) {
      case GET_CUSTUSER_LIST_REQUEST:
        break
      case GET_CUSTUSER_LIST_FAILED:
        break
      case GET_CUSTUSER_LIST_SUCCESS:
        checkRedirectCustUser()
        break
      default:
        return
    }
  }, [listCustUsersReducer])
  const handleLogin = async (e) => {
    e.preventDefault()
    let { validate, error } = userLoginValidate(userId, password, i18n)

    if (!validate) {
      setShowMessageErrorDialog(true)
      setErrorMessage(error)
      return false
    }

    //入力したユーザー情報を取得
    const params = {
      userId: userId,
    }

    //メールとパスワードでログイン
    setShowProgress(true)
    dispatch(login(params, userId, password, i18n, handleLoginState))
  }

  useEffect(() => {
    if (showMessageErrorDialog) {
      const idUser = document.getElementById('ID_USER')
      idUser.focus()
      idUser.select()
    }
  }, [showMessageErrorDialog])

  useEffect(() => {
    localStorage.removeItem('currentSession')
    if (userInfo) {
      dispatch(getCustUserAction())
      if (!(history.location.state?.from === URL_CUST_USER)) setRedirect(true)
    }
  }, [userInfo])

  if (redirect) {
    if (userInfo?.KB_PASSW === '1') {
      return <Redirect to="/admin/change-password" />
    }
    if (history.location.state?.from === URL_CUST_USER) {
      return returnUrl === true ? <Redirect to={URL_CUST_USER} /> : <Redirect to="/admin/portal" />
    }
    if (history.location.state?.from !== URL_CUST_USER) {
      return (
        <Redirect
          to={{
            //pathname: history.location.state?.from,
            pathname: '/admin/portal',
          }}
        />
      )
    }
    return <Redirect to="/admin/portal" />
  }
  return (
    <div className="login">
      {showProgress && <ProgressDialog label={t('headquarter.loading')} />}
      {showMessageErrorDialog && (
        <MessageErrorDialog message={errorMessage} showMessageErrorDialog={setShowMessageErrorDialog} />
      )}
      <Grid className="login-wrap">
        <Grid item md={12} xs={12} className="login-container">
          <div className="login-form">
            <Card
              title={t('login.lblTitle')}
              content={
                <form autoCapitalize="off" autoCorrect="off">
                  <FormInputs
                    label={t('login.lblId')}
                    icon={<Person viewBox="0 0 24 24" />}
                    type="text"
                    name="ID_USER"
                    id="ID_USER"
                    setValue={setUserId}
                    inputProps={{ tabIndex: '1' }}
                    autoComplete={'user-name'}
                    canSubmitByEnter={true}
                  />
                  <FormInputs
                    label={t('login.lblPassword')}
                    icon={<Lock />}
                    type="password"
                    name="ID_PASSW"
                    id="ID_PASSW"
                    setValue={setPassword}
                    inputProps={{ tabIndex: '2' }}
                    autoComplete={'password'}
                    canSubmitByEnter={true}
                  />
                  <div className="spacing"></div>
                  <div className="login-button">
                    <Button type="submit" onClick={handleLogin} tabIndex="3">
                      {t('login.btnLogin')}
                    </Button>
                  </div>
                </form>
              }
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default Login
