import './Allergen.css'

// import { Grid, TableBody, TableCell, TableRow } from '@material-ui/core'
import { clearAllergen, deleteAllergenAction, getAllergenAction } from 'actions/allergenActions'
import getCodeInfo from 'actions/codeActions'
// import { IMAGES } from 'assets'
import { formatDateWithPadding, getSessionInfo, getTitleModalConfirm, getUserKbAdmin } from 'commons/utilities'
import {
  // Image,
  MessageErrorDialog,
  ModalConfirm as DeleteResult,
  ModalConfirm,
  ProgressDialog,
  // SearchCalendar,
  // SearchFormInput,
  // SelectInput,
} from 'components/commons'
import TableCommon from 'components/commons/Table/TableCommon'
import ToolBar from 'components/commons/ToolBar/ToolBar'
import {
  DELETE_ALLERGEN_FAILED,
  DELETE_ALLERGEN_REQUEST,
  DELETE_ALLERGEN_SUCCESS,
  GET_ALLERGEN_LIST_FAILED,
  GET_ALLERGEN_LIST_REQUEST,
  GET_ALLERGEN_LIST_SUCCESS,
} from 'constants/actionTypes/allergenActionTypes'
import { ACTION_FORM, FLAG_BOOLEAN, MESSAGE_TYPE, SPECIAL_CELL_TYPE, TOOLBAR_TYPE } from 'constants/constant'
import React, { useEffect, useState } from 'react'
// import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// import styles from './BranchStyles'

function Allergen() {
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return null
  })
  const { t } = useTranslation()
  // const classes = styles()
  const dispatch = useDispatch()
  const history = useHistory()
  const [listData, setListData] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [dateFilter, setDateFilter] = useState(formatDateWithPadding(new Date(), 'yyyy-MM-dd'))

  // const [searchData, setSearchData] = useState([])
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState()
  const [recordDelete, setRecordDelete] = useState()
  const listAllergenReducer = useSelector((state) => state.listAllergen || {})
  const allergenDeleteReducer = useSelector((state) => state.deleteAllergen || {})
  const [deletePermissionMessageError, setDeletePermissionMessageError] = useState('')

  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false)
  const [deletePopUp, setDeletePopUp] = useState(false)
  const [deleteMessageType, setDeleteMessageType] = useState('')
  const codeInfo = useSelector((state) => state.codeInfo || {})

  const handleDeleteOnClick = async (data) => {
    const kbAdmin = await getUserKbAdmin(userInfo)

    if (String(kbAdmin) === FLAG_BOOLEAN.FALSE) {
      setShowModalConfirmDelete(false)
      setDeletePermissionMessageError(t('commonComponent.noDeletePermission'))
    } else {
      setShowModalConfirmDelete(true)
      setRecordDelete(data)
    }
  }

  const deleteAllergen = () => {
    setShowModalConfirmDelete(false)
    dispatch(deleteAllergenAction(recordDelete))
  }

  // handle edit portal
  const navigateToFormAllergen = (id, data, type = ACTION_FORM.CREATE) => {
    history.push({
      pathname: '/admin/allergen-form',
      search: `?${id ? `id=${id}&` : ''}type=${type}`,
      state: {
        type: type,
        data: data,
      },
    })
  }

  useEffect(() => {
    dispatch(getAllergenAction())
    dispatch(getCodeInfo({}))
  }, [dispatch])

  useEffect(() => {
    setDeletePopUp(false)

    if (listAllergenReducer.listAllergen.length >= 0 && Object.keys(codeInfo.code).length >= 0) {
      setListData(getFormatListData([...listAllergenReducer.listAllergen]))
    }

    setLoading(listAllergenReducer.loading || allergenDeleteReducer.loading || codeInfo.loading)
    switch (listAllergenReducer.type) {
      case GET_ALLERGEN_LIST_REQUEST:
        break
      case GET_ALLERGEN_LIST_FAILED:
        if (!getSessionInfo()) {
          listAllergenReducer.message && setMessageError(listAllergenReducer.message)
        }
        break
      case GET_ALLERGEN_LIST_SUCCESS:
        break
      default:
        return
    }
  }, [listAllergenReducer, codeInfo])

  useEffect(() => {
    setLoading(listAllergenReducer.loading || allergenDeleteReducer.loading)
    switch (allergenDeleteReducer.type) {
      case DELETE_ALLERGEN_REQUEST:
        break
      case DELETE_ALLERGEN_SUCCESS:
        setDeletePopUp(true)
        setDeleteMessageType(MESSAGE_TYPE.SUCCESS)
        break
      case DELETE_ALLERGEN_FAILED:
        if (!getSessionInfo()) {
          setDeletePopUp(true)
          setDeleteMessageType(MESSAGE_TYPE.ERROR)
          listAllergenReducer.message && setMessageError(allergenDeleteReducer.message)
        }
        break
      default:
        return
    }
  }, [allergenDeleteReducer])

  const headersCsv = [
    { label: t('allergen.lblCdAllergen'), key: 'CD_ALRG' },
    { label: t('allergen.lblNmAllergen'), key: 'NM_ALRG' },
    { label: t('allergen.lblKbSpec'), key: 'KB_SPEC' },
    { label: t('allergen.lblNuDsp'), key: 'NU_DSP' },
    { label: t('allergen.lblDtStart'), key: 'DT_START' },
    { label: t('allergen.lblDtEnd'), key: 'DT_END' },
  ]
  const headerCsvLabel = headersCsv.map((data) => data.label)
  const headerCsvKey = headersCsv.map((data) => data.key)

  // const listColumnWidth = ['62px', '15%', '', '15%', '15%']
  const listColumnWidth = ['62px', '15%', '35%', '12%', '12%', '12%', '12%']

  const listCellHeader = [
    { columnName: '', commonStyle: 'titleHead', cellStyle: '' },
    { columnName: 'allergen.lblCdAllergen', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'allergen.lblNmAllergen', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'allergen.lblKbSpec', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'allergen.lblNuDsp', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'allergen.lblDtStart', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
    { columnName: 'allergen.lblDtEnd', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
  ]

  const listCellBody = [
    { keyValue: 'CD_ALRG', cellStyle: 'data', gridStyle: 'centerAlignHq' },
    { keyValue: 'NM_ALRG', cellStyle: 'data', gridStyle: 'centerAlignHq' },
    { keyValue: 'KB_SPEC_NAME', cellStyle: 'dataKbSpec', gridStyle: 'centerAlignHq' },
    { keyValue: 'NU_DSP', cellStyle: 'dataNuDisp', gridStyle: 'centerAlignHq' },
    { specialType: SPECIAL_CELL_TYPE.DATE_START },
    { specialType: SPECIAL_CELL_TYPE.DATE_END },
  ]

  const listToolBarComponent = [
    { type: TOOLBAR_TYPE.ADD, commonStyle: 'createContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.CSV, commonStyle: '', componentStyle: '' },
  ]
  let setDownloadParams = {
    screenName: 'allergen',
  }

  const toolbar = (
    <ToolBar
      listToolBarComponent={listToolBarComponent}
      handleCreateOnclick={navigateToFormAllergen}
      setSearchInput={setSearchInput}
      searchInput={searchInput}
      setSelectedDate={setDateFilter}
      selectedDate={dateFilter}
      headerCsvLabel={headerCsvLabel}
      headerCsvKey={headerCsvKey}
      fileCsvName={t('allergen.fileNameDownload')}
      listData={listData}
      csvButtonStyle={true}
      toolbarWidth={'SMALL'}
      downloadParams={setDownloadParams}
    />
  )
  const sortDataWithNuDSP = (arr) => {
    return arr.sort((a, b) => Number.parseFloat(a['NU_DSP']) - Number.parseFloat(b['NU_DSP']))
  }

  const getFormatListData = (currentData = []) => {
    if (Object.keys(codeInfo?.code).length) {
      const data = codeInfo?.code.filter((x) => x['CD_KEY'].toString().toUpperCase() === 'ARGSPEC')[0] || []
      const listSpec = Object.values(data).filter((el) => el.hasOwnProperty('CD_VALUE'))
      const formatListData = currentData.map((data) => ({
        ...data,
        KB_SPEC_NAME: listSpec.find((el) => el.CD_VALUE === data.KB_SPEC)?.NM_VALUE || data.KB_SPEC,
      }))
      return formatListData
    }
    return []
  }

  const msgDeleteConfirm = recordDelete
    ? getTitleModalConfirm(
        t('allergen.msgDeleteConfirm.line1', {
          cdAllergenValue: recordDelete.CD_ALRG,
        }),
        t('allergen.msgDeleteConfirm.line2')
      )
    : ''

  const handleConfirmDeleteResult = () => {
    setRecordDelete(null)
    setDeletePopUp(false)
    dispatch(getAllergenAction())
    dispatch(clearAllergen())
  }

  return (
    <div className="allergen__wrapper allergen__maintain-wrapper">
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {deletePermissionMessageError && (
        <MessageErrorDialog
          message={deletePermissionMessageError}
          showMessageErrorDialog={setDeletePermissionMessageError}
        />
      )}
      {loading && <ProgressDialog label={recordDelete ? t('allergen.msgDeleteData') : t('allergen.loading')} />}
      <ModalConfirm
        title={msgDeleteConfirm}
        showModal={showModalConfirmDelete}
        setShowModal={setShowModalConfirmDelete}
        handleFunction={deleteAllergen}
      />
      <DeleteResult
        title={
          deleteMessageType === MESSAGE_TYPE.SUCCESS ? t('allergen.deleteDataSuccess') : t('allergen.deleteDataError')
        }
        showModal={deletePopUp}
        handleFunction={handleConfirmDeleteResult}
        screen={'allergen'}
      />
      {loading ? (
        <ProgressDialog label={recordDelete ? t('allergen.msgDeleteData') : t('allergen.loading')} />
      ) : (
        <div className="allergen__container maintain-container">
          {/* Table */}
          <TableCommon
            tableWidth={'SMALL'}
            listColumnWidth={listColumnWidth}
            listCellHeader={listCellHeader}
            listCellBody={listCellBody}
            listDataRender={sortDataWithNuDSP(listData)}
            handleUpdate={navigateToFormAllergen}
            idProperty={'CD_ALRG'}
            handleDelete={handleDeleteOnClick}
            toolbar={toolbar}
          />
        </div>
      )}
    </div>
  )
}

export default Allergen
