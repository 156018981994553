import {
  GET_DOWNLOAD_STATUS_DETAIL_LIST_FAILED,
  GET_DOWNLOAD_STATUS_DETAIL_LIST_REQUEST,
  GET_DOWNLOAD_STATUS_DETAIL_LIST_SUCCESS,
} from 'constants/actionTypes/downloadStatusDetailActionTypes'
const listDownloadStatusDetail = (state = { listDownloadStatusDetail: [] }, action) => {
  switch (action.type) {
    case GET_DOWNLOAD_STATUS_DETAIL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_DOWNLOAD_STATUS_DETAIL_LIST_REQUEST,
      }
    case GET_DOWNLOAD_STATUS_DETAIL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        listDownloadStatusDetail: action.payload,
        type: GET_DOWNLOAD_STATUS_DETAIL_LIST_SUCCESS,
      }
    case GET_DOWNLOAD_STATUS_DETAIL_LIST_FAILED:
      return {
        ...state,
        loading: false,
        listDownloadStatusDetail: [],
        message: action.payload,
        type: GET_DOWNLOAD_STATUS_DETAIL_LIST_FAILED,
      }
    default:
      return state
  }
}
export { listDownloadStatusDetail }
