export const GET_BRANCH_LIST_REQUEST = 'GET_BRANCH_LIST_REQUEST'
export const GET_BRANCH_LIST_SUCCESS = 'GET_BRANCH_LIST_SUCCESS'
export const GET_BRANCH_LIST_FAILED = 'GET_BRANCH_LIST_FAILED'
export const CREATE_BRANCH_REQUEST = 'CREATE_BRANCH_REQUEST'
export const CREATE_BRANCH_FAILED = 'CREATE_BRANCH_FAILED'
export const CREATE_BRANCH_SUCCESS = 'CREATE_BRANCH_SUCCESS'
export const UPDATE_BRANCH_REQUEST = 'UPDATE_BRANCH_REQUEST'
export const UPDATE_BRANCH_FAILED = 'UPDATE_BRANCH_FAILED'
export const UPDATE_BRANCH_SUCCESS = 'UPDATE_BRANCH_SUCCESS'
export const DELETE_BRANCH_REQUEST = 'DELETE_BRANCH_REQUEST'
export const DELETE_BRANCH_FAILED = 'DELETE_BRANCH_FAILED'
export const DELETE_BRANCH_SUCCESS = 'DELETE_BRANCH_SUCCESS'
export const CLEAR_BRANCH = 'CLEAR_BRANCH'
