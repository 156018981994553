import '../../components/commons/Table/TableCommon.css'

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { formatString } from 'commons/utilities'
import { Image } from 'components/commons'
import { ACTION_FORM, DATE_TYPE, SCREEN_NAME, SORT_TYPE, SPECIAL_CELL_TYPE } from 'constants/constant'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { IMAGES } from '../../assets'
import sortedDown from '../../assets/img/sort-down-solid.svg'
import sortedUp from '../../assets/img/sort-up-solid.svg'
import styles from '../../components/commons/Table/TableCommonStyle'

function TableDownloadStatus({
  listColumnWidth = [],
  listCellHeader = [],
  listCellBody = [],
  listFolderName = [],
  listDataRender = [],
  handleUpdate,
  handleDelete,
  canDownloadFile = false,
  canUpdate = true,
  canDelete = true,
  hasFirstCell = true,
  hasEndCell = false,
  sortedByStartDate,
  sortedByEndDate,
  startDateSortedType,
  endDateSortedType,
  idProperty = '',
  tableWidth,
  toolbar,
  hasHeaderLine = false,
  hasRecordCounter = false,
  hasStartLineItem = false,
  startLineItem,
  handleNavigate,
  cdCust,
  nmCust,
  selectedDate,
  isDateSelected,
  undownloaded,
  filterItem,
}) {
  const { t } = useTranslation()
  const classes = styles(listColumnWidth)
  const SortedByStartDateIcon = () => <img src={sortedUp} alt="Sorted Up Icon" className="date-icon" />
  const SortedByEndDateIcon = () => <img src={sortedUp} alt="Sorted Up Icon" className="date-icon" />
  const StartDateReverseIcon = () => <img src={sortedDown} alt="Sorted Up Icon" className="date-icon" />
  const EndDateReverseIcon = () => <img src={sortedDown} alt="Sorted Up Icon" className="date-icon" />

  const renderCellHeader = (cellHeader) => {
    if (typeof cellHeader !== 'undefined') {
      const headerName = t(cellHeader.columnName)
      if (headerName.length > 10) {
        let substring = headerName.substring(0, 10).concat('...')
        return <div className={classes.overFlowText}>{substring}</div>
      }
    }
    return <div className={classes.overFlowText}>{t(cellHeader.columnName)}</div>
  }

  const renderSpecialCellBody = (cellData) => {
    if (cellData.length > 10) {
      let substring = cellData.substring(0, 10).concat('...')
      return <div className={classes.overFlowText}>{substring}</div>
    }
    return <div className={classes.overFlowText}>{cellData}</div>
  }

  /**
   * Get single cell in header of table
   * @return {JSX}
   */
  const getCellHeader = (cellHeader = {}, index = 0) => {
    let component = (
      <TableCell className={`${classes[cellHeader.commonStyle]} ${classes[cellHeader.cellStyle]}`} key={index}>
        <div className={`${classes.cellHeaderContainer} `}>{renderCellHeader(cellHeader)}</div>
      </TableCell>
    )
    if (cellHeader.hasOwnProperty('specialType')) {
      switch (cellHeader.specialType) {
        /* SORTED START */
        case SPECIAL_CELL_TYPE.SORTED_START:
          component = (
            <TableCell className={`${classes.titleHead}  ${classes.dateTitle}`} onClick={sortedByStartDate}>
              <div className="startDate-container">
                {t('maintenance.lblStartDate')}
                <div className="sortedIcon-container">
                  {startDateSortedType === DATE_TYPE.DEFAULT ? (
                    <>
                      <SortedByStartDateIcon />
                      <StartDateReverseIcon />
                    </>
                  ) : startDateSortedType === SORT_TYPE.INCREASE ? (
                    <SortedByStartDateIcon />
                  ) : (
                    <StartDateReverseIcon />
                  )}
                </div>
              </div>
            </TableCell>
          )
          return component
        /* SORTED END */
        case SPECIAL_CELL_TYPE.SORTED_END:
          component = (
            <TableCell className={`${classes.titleHead}  ${classes.dateTitle}`} onClick={sortedByEndDate}>
              <div className="startDate-container">
                {t('maintenance.lblEndDate')}
                <div className="sortedIcon-container">
                  {endDateSortedType === DATE_TYPE.DEFAULT ? (
                    <>
                      <SortedByEndDateIcon />
                      <EndDateReverseIcon />
                    </>
                  ) : endDateSortedType === SORT_TYPE.INCREASE ? (
                    <SortedByEndDateIcon />
                  ) : (
                    <EndDateReverseIcon />
                  )}
                </div>
              </div>
            </TableCell>
          )
          return component
        default:
          return <></>
      }
    }
    return component
  }

  const renderBranchFolderCount = (itemFolderName, index, data) => {
    const dataDisplayFolder = data.DISP_FLD.find((x) => x.NM_DISP_FLD === itemFolderName)
    return (
      <TableCell className={`${classes.dataDownload}`} key={index}>
        <Grid className={`${classes.centerAlignHq}`}>
          <div
            style={{
              textAlign: 'center',
              justifyContent: 'center',
              width: listColumnWidth[index],
            }}
          >
            <Link
              to={{
                pathname: '/admin/download-detail',
                state: {
                  customer: {
                    CD_CUST: cdCust,
                    NM_CUST: nmCust,
                  },
                  branch: { NM_BRANCH: data.NM_BRANCH, CD_BRANCH: data.CD_BRANCH },
                  NM_DISP_FLD: itemFolderName,
                  KY_SRC: dataDisplayFolder?.KY_SRC,
                  date: isDateSelected ? selectedDate : '',
                  undownloaded: undownloaded,
                  filterItem: filterItem,
                  screen: SCREEN_NAME.DONWLOAD_STATUS,
                },
              }}
            >
              <div>{!!dataDisplayFolder ? `${dataDisplayFolder.count}件` : ''}</div>
            </Link>
          </div>
        </Grid>
      </TableCell>
    )
  }

  const calculateTotalFolderCountForOneTable = () => {
    let sum = 0
    for (let i = 0; i < listDataRender.length; i++) {
      let folders = listDataRender[i].DISP_FLD
      for (let j = 0; j < folders.length; j++) {
        sum += folders[j].count
      }
    }
    return sum
  }

  return (
    <div className="table-container-fit-content">
      <Grid item md={12}>
        <TableContainer>
          <div>
            <div className={tableWidth === 'SMALL' ? classes.smallWidthTable : classes.fullWidthTable}>
              {hasStartLineItem && <span className={classes.startLineItem}>{formatString(startLineItem, 25)}</span>}
              {hasRecordCounter && (
                <span
                  className={tableWidth === 'SMALL' ? classes.recordCounterSmallWidth : classes.recordCounterFullWidth}
                >
                  {t('receptionData.counter', {
                    count: calculateTotalFolderCountForOneTable(),
                  })}
                </span>
              )}
              {hasHeaderLine && (
                <div className={classes.headerLine}>
                  <hr className={tableWidth === 'SMALL' ? classes.smallWidthHeaderLine : classes.fullWidthHeaderLine} />
                </div>
              )}
            </div>
            <Table
              aria-label="common table"
              className={tableWidth === 'SMALL' ? classes.smallWidthTable : classes.fullWidthTable}
            >
              {/* Col Group */}
              <colgroup>
                {listColumnWidth.map((col, index) => (
                  <col
                    style={col !== '' ? { width: col } : {}}
                    key={index}
                    className={
                      window.location.href.includes('/admin/download') && index === 0
                        ? classes.firstColumnDownloadTable
                        : ''
                    }
                  ></col>
                ))}
              </colgroup>
              {/* Header */}
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {/* Header Cell */}
                  {listCellHeader && listCellHeader.map((cellHeader, index) => getCellHeader(cellHeader, index))}
                </TableRow>
              </TableHead>
              {/* Body */}
              <TableBody>
                {/* Body Row */}
                {listDataRender
                  .sort((a, b) => a?.CD_BRANCH.localeCompare(b?.CD_BRANCH))
                  .map((data, index) => {
                    return (
                      <TableRow key={index} className={index % 2 === 0 ? '' : classes.tableRow} style={{ height: 50 }}>
                        {/* Body Cell */}
                        {hasFirstCell && (
                          <TableCell className={classes.iconUpdate}>
                            <Grid className={classes.iconContainer}>
                              {canUpdate && (
                                <Image
                                  src={IMAGES.ICON.EDIT_ICON_TABLE_HQUSER}
                                  onClick={() => handleUpdate(data[`${idProperty}`], data, ACTION_FORM.UPDATE)}
                                  className={classes.iconAction}
                                />
                              )}

                              {canDelete && (
                                <Image
                                  src={IMAGES.ICON.DELETE_ICON_TABLE_HQUSER}
                                  className={classes.iconAction}
                                  onClick={() => {
                                    handleDelete(data)
                                  }}
                                />
                              )}
                            </Grid>
                          </TableCell>
                        )}
                        <TableCell className={`${classes.data} ${classes.mailContainer}`}>
                          <Grid className={`${classes.centerAlignHq}`}>{renderSpecialCellBody(data.NM_BRANCH)}</Grid>
                        </TableCell>
                        {listFolderName.map((itemFolderName, indexFolderName) =>
                          renderBranchFolderCount(itemFolderName, indexFolderName, data)
                        )}
                        {hasEndCell && (
                          <TableCell className={classes.iconUpdate}>
                            <Grid className={classes.iconContainer}>
                              {canUpdate && (
                                <Image
                                  src={IMAGES.ICON.EDIT_ICON_TABLE_HQUSER}
                                  onClick={() => handleUpdate(data[`${idProperty}`], data, ACTION_FORM.UPDATE)}
                                  className={classes.iconAction}
                                />
                              )}

                              {canDelete && (
                                <Image
                                  src={IMAGES.ICON.DELETE_ICON_TABLE_HQUSER}
                                  className={classes.iconAction}
                                  onClick={() => {
                                    handleDelete(data)
                                  }}
                                />
                              )}
                            </Grid>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </Grid>
    </div>
  )
}

export default TableDownloadStatus
