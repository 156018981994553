import axios from 'commons/baseUrl'
import {
  CLEAR_DISTRIBUTION_DATA,
  CREATE_DISTRIBUTION_DATA_FAILED,
  CREATE_DISTRIBUTION_DATA_REQUEST,
  CREATE_DISTRIBUTION_DATA_SUCCESS,
  DELETE_DISTRIBUTION_DATA_FAILED,
  DELETE_DISTRIBUTION_DATA_REQUEST,
  DELETE_DISTRIBUTION_DATA_SUCCESS,
  GET_DISTRIBUTION_DATA_FAILED,
  GET_DISTRIBUTION_DATA_REQUEST,
  GET_DISTRIBUTION_DATA_SUCCESS,
  UPDATE_DISTRIBUTION_DATA_FAILED,
  UPDATE_DISTRIBUTION_DATA_REQUEST,
  UPDATE_DISTRIBUTION_DATA_SUCCESS,
} from 'constants/actionTypes/distributionDataActionTypes'
import {
  API_CREATE_DISTRIBUTION_DATA,
  API_DELETE_DISTRIBUTION_DATA,
  API_GET_DISTRIBUTION_DATA,
  API_UPDATE_DISTRIBUTION_DATA,
} from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const getDistributionDataAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_DISTRIBUTION_DATA_REQUEST })
    const res = await axios.get(API_GET_DISTRIBUTION_DATA, { params })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_DISTRIBUTION_DATA_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data.data.length >= 0) {
      dispatch({ type: GET_DISTRIBUTION_DATA_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: GET_DISTRIBUTION_DATA_FAILED, payload: null })
  }
}

const createDistributionDataAction = (data, data_excel_template, i18n) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_DISTRIBUTION_DATA_REQUEST, payload: data })

    const res = await axios.post(API_CREATE_DISTRIBUTION_DATA, { data, data_excel_template })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: CREATE_DISTRIBUTION_DATA_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_EXISTED) {
      dispatch({
        type: CREATE_DISTRIBUTION_DATA_FAILED,
        payload: i18n.t('validate.distribution.idUserInvalid', {
          fieldName: i18n.t('distribution.lblDataId'),
          idUserValue: data.ID_DATA,
        }),
      })
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: CREATE_DISTRIBUTION_DATA_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: CREATE_DISTRIBUTION_DATA_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: CREATE_DISTRIBUTION_DATA_FAILED, payload: err.message })
  }
}

const updateDistributionDataAction = (data, data_excel_template, data_template_delete) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_DISTRIBUTION_DATA_REQUEST, payload: data })
    const res = await axios.post(API_UPDATE_DISTRIBUTION_DATA, {
      data,
      data_excel_template,
      data_template_delete,
    })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: UPDATE_DISTRIBUTION_DATA_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: UPDATE_DISTRIBUTION_DATA_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: UPDATE_DISTRIBUTION_DATA_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: UPDATE_DISTRIBUTION_DATA_FAILED, payload: err.message })
  }
}

const deleteDistributionDataAction = (data, params) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_DISTRIBUTION_DATA_REQUEST, payload: data })
    const res = await axios.post(API_DELETE_DISTRIBUTION_DATA, data)
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: DELETE_DISTRIBUTION_DATA_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: DELETE_DISTRIBUTION_DATA_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: DELETE_DISTRIBUTION_DATA_SUCCESS, payload: res.data.data })
      dispatch(getDistributionDataAction(params))
    }
  } catch (err) {
    dispatch({ type: DELETE_DISTRIBUTION_DATA_SUCCESS, payload: err.message })
  }
}

const clearDistributionData = (data) => async (dispatch) => {
  dispatch({ type: CLEAR_DISTRIBUTION_DATA, payload: '' })
}

export {
  clearDistributionData,
  createDistributionDataAction,
  deleteDistributionDataAction,
  getDistributionDataAction,
  updateDistributionDataAction,
}
