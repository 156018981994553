import './CustUser.css'

import { clearCustUser, deleteCustUserAction, getCustUserAction } from 'actions/custUserActions'
import {
  dataSearchResult,
  formatDateWithPadding,
  getSessionInfo,
  getTitleModalConfirm,
  getUserKbAdmin,
} from 'commons/utilities'
import {
  MessageErrorDialog,
  ModalConfirm as DeleteResult,
  ModalConfirm,
  ProgressDialog,
  // SearchCalendar,
  // SearchFormInput,
} from 'components/commons'
import TableCommon from 'components/commons/Table/TableCommon'
import ToolBar from 'components/commons/ToolBar/ToolBar'
import {
  DELETE_CUSTUSER_FAILED,
  DELETE_CUSTUSER_REQUEST,
  DELETE_CUSTUSER_SUCCESS,
  GET_CUSTUSER_LIST_FAILED,
  GET_CUSTUSER_LIST_REQUEST,
  GET_CUSTUSER_LIST_SUCCESS,
} from 'constants/actionTypes/custUserActionTypes'
import { FLAG_BOOLEAN, MESSAGE_TYPE, SCREEN_NAME, TOOLBAR_TYPE } from 'constants/constant'
import { ACTION_FORM } from 'constants/constant'
import React, { useEffect, useState } from 'react'
// import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

function HeadQuarters() {
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return null
  })
  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [listData, setListData] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [dateFilter, setDateFilter] = useState(DT_TODAY)
  const [searchData, setSearchData] = useState([])
  const [filterData, setFilterData] = useState([])
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState()
  const [recordDelete, setRecordDelete] = useState()
  const listCustUsersReducer = useSelector((state) => state.listCustUser || {})
  const custUserDeleteReducer = useSelector((state) => state.deleteCustUser || {})
  const [isValidDate, setIsValidDate] = useState(true)
  const [isInDateRange, setIsInDateRange] = useState(true)
  const filteredFields = ['CD_CUST', 'NM_CUST', 'ID_USER', 'NM_USER', 'NM_MAIL1', 'NM_MAIL2']
  const [deletePermissionMessageError, setDeletePermissionMessageError] = useState('')

  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false)
  const [deletePopUp, setDeletePopUp] = useState(false)
  const [deleteMessageType, setDeleteMessageType] = useState('')

  const handleSearchInput = (event) => {
    event.preventDefault()
    setFilterData(dataSearchResult(listData, filteredFields, searchInput))
  }

  const handleFilterDate = React.useCallback(
    (dateFilter) => {
      if (isValidDate && isInDateRange) {
        dispatch(getCustUserAction({ date: dateFilter }))
      }
    },
    [dispatch]
  )

  const handleDeleteOnClick = async (data) => {
    const kbAdmin = await getUserKbAdmin(userInfo)

    if (String(kbAdmin) === FLAG_BOOLEAN.FALSE) {
      setShowModalConfirmDelete(false)
      setDeletePermissionMessageError(t('commonComponent.noDeletePermission'))
    } else {
      setShowModalConfirmDelete(true)
      setRecordDelete(data)
    }
  }

  const deleteCustUser = () => {
    let params = { date: dateFilter }
    dispatch(deleteCustUserAction(recordDelete, params))
    setShowModalConfirmDelete(false)
  }
  // handle edit custuser
  const navigateToFormCustUser = (id, data, type = ACTION_FORM.CREATE) => {
    history.push({
      pathname: '/admin/custuser-form',
      search: `?${id ? `id=${id}&` : ''}type=${type}`,
      state: {
        type: type,
        data: data,
        date: dateFilter,
        searchInput: searchInput,
        screen: SCREEN_NAME.CUSTUSER,
      },
    })
  }

  useEffect(
    () => {
      const { date, searchInput, screen } = location?.state || {}
      if (searchInput && screen === SCREEN_NAME.CUSTUSER) {
        setSearchInput(searchInput)
      }
      if (date && screen === SCREEN_NAME.CUSTUSER) {
        setDateFilter(date)
        dispatch(
          getCustUserAction({
            date: date,
          })
        )
      } else {
        setDateFilter(DT_TODAY)
        dispatch(getCustUserAction({}))
      }
      window.history.replaceState({}, document.title)
    },
    [dispatch],
    [location]
  )

  useEffect(() => {
    if (listData?.length >= 0 && searchInput.length === 0) {
      setFilterData(listData)
    }
  }, [listData, searchInput, searchData])

  useEffect(() => {
    setDeletePopUp(false)
    if (listCustUsersReducer?.listCustUser?.length >= 0) {
      let dataResponse = [...listCustUsersReducer?.listCustUser]
      setListData(dataResponse)
      if (searchInput !== '') {
        setFilterData(dataSearchResult(dataResponse, filteredFields, searchInput))
      }
    }
    setLoading(listCustUsersReducer?.loading || custUserDeleteReducer?.loading)
    switch (listCustUsersReducer.type) {
      case GET_CUSTUSER_LIST_REQUEST:
        break
      case GET_CUSTUSER_LIST_FAILED:
        if (!getSessionInfo()) {
          listCustUsersReducer.message && setMessageError(listCustUsersReducer.message)
        }
        break
      case GET_CUSTUSER_LIST_SUCCESS:
        break
      default:
        return
    }
  }, [listCustUsersReducer])

  useEffect(() => {
    setLoading(listCustUsersReducer?.loading || custUserDeleteReducer?.loading)

    switch (custUserDeleteReducer.type) {
      case DELETE_CUSTUSER_REQUEST:
        break
      case DELETE_CUSTUSER_SUCCESS:
        setDeletePopUp(true)
        setDeleteMessageType(MESSAGE_TYPE.SUCCESS)
        break
      case DELETE_CUSTUSER_FAILED:
        if (!getSessionInfo()) {
          setDeletePopUp(true)
          setDeleteMessageType(MESSAGE_TYPE.ERROR)
          setMessageError(custUserDeleteReducer.message)
        }
        break
      default:
        return
    }
  }, [custUserDeleteReducer])

  const handleConfirmDeleteResult = () => {
    setRecordDelete(null)
    setDeletePopUp(false)
    dispatch(getCustUserAction())
    dispatch(clearCustUser())
  }

  const headersCsv = [
    { label: t('custUser.lblIdCust'), key: 'CD_CUST' },
    { label: t('custUser.lblIdUser'), key: 'ID_USER' },
    { label: t('custUser.lblNmUser'), key: 'NM_USER' },
    { label: t('custUser.lblEmail1'), key: 'NM_MAIL1' },
    { label: t('custUser.lblEmail2'), key: 'NM_MAIL2' },
    { label: t('custUser.lblDeliveryNoti'), key: 'KB_TRN' },
    { label: t('custUser.lblUnReceivedNoti'), key: 'KB_DL' },
    { label: t('formCustUser.lblNmNotes'), key: 'NM_NOTES' },
    { label: t('custUser.lblIdMenu'), key: 'ID_MENU' },
    { label: t('custUser.lblExpiredDate'), key: 'DT_DEADL' },
  ]
  let setDownloadParams = {
    screenName: 'custuser',
    searchText: searchInput,
    date: dateFilter,
  }
  const headerCsvLabel = headersCsv.map((data) => data.label)
  const headerCsvKey = headersCsv.map((data) => data.key)

  const listColumnWidth = ['62px', '8%', '', '8%', '8%', '14%', '12%', '8%', '5%', '6%', '8%']

  const listCellHeader = [
    { columnName: '', commonStyle: 'titleHead' },
    { columnName: 'custUser.lblIdCust', commonStyle: 'titleHead', cellStyle: 'titleSpacingLeft20' },
    { columnName: 'custUser.lblNmCust', commonStyle: 'titleHead', cellStyle: 'titleSpacingLeft20' },
    { columnName: 'custUser.lblIdUser', commonStyle: 'titleHead', cellStyle: 'titleSpacingLeft20' },
    { columnName: 'custUser.lblNmUser', commonStyle: 'titleHead', cellStyle: 'titleSpacingLeft20' },
    { columnName: 'custUser.lblEmail1', commonStyle: 'titleHead', cellStyle: 'titleSpacingLeftRight20' },
    { columnName: 'custUser.lblEmail2', commonStyle: 'titleHead', cellStyle: 'titleSpacingRight20' },
    { columnName: 'custUser.lblPassword', commonStyle: 'titleHead', cellStyle: 'titleSpacingRight20' },
    { columnName: 'custUser.lblDeliveryNoti', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
    { columnName: 'custUser.lblUnReceivedNoti', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
    { columnName: 'custUser.lblExpiredDate', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
  ]

  const listCellBody = [
    { keyValue: 'CD_CUST', cellStyle: 'dataCustUser', gridStyle: 'centerAlignHq' },
    { keyValue: 'NM_CUST', cellStyle: 'dataCustUser', gridStyle: 'centerAlignHq' },
    { keyValue: 'ID_USER', cellStyle: 'dataCustUser', gridStyle: 'centerAlignHq' },
    { keyValue: 'NM_USER', cellStyle: 'dataCustUser', gridStyle: 'centerAlignHq' },
    { keyValue: 'NM_MAIL1', cellStyle: 'dataCustUserLeftRight20', gridStyle: 'centerAlignHq' },
    { keyValue: 'NM_MAIL2', cellStyle: 'dataCustUserRight20', gridStyle: 'centerAlignHq' },
    { specialType: 'PASSWORD' },
    { keyValue: 'KB_TRN', specialType: 'CHECK' },
    { keyValue: 'KB_DL', specialType: 'CHECK' },
    { specialType: 'DATE_DEAL' },
  ]

  const listToolBarComponent = [
    { type: TOOLBAR_TYPE.ADD, commonStyle: 'createContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.FILTER_LOGO, commonStyle: 'filterIconContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.SEARCH, commonStyle: 'searchContainer', componentStyle: 'search-item' },
    { type: TOOLBAR_TYPE.EXPIRE_DATE, commonStyle: 'expireDateContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.CALENDAR, commonStyle: 'filterContainer', componentStyle: 'calendar-item' },
    { type: TOOLBAR_TYPE.CSV, commonStyle: '', componentStyle: '' },
  ]

  const toolbar = (
    <ToolBar
      listToolBarComponent={listToolBarComponent}
      handleCreateOnclick={navigateToFormCustUser}
      setSearchData={setSearchData}
      setSearchInput={setSearchInput}
      searchInput={searchInput}
      handleSubmit={handleSearchInput}
      handleFilterDate={handleFilterDate}
      setSelectedDate={setDateFilter}
      selectedDate={dateFilter}
      headerCsvLabel={headerCsvLabel}
      headerCsvKey={headerCsvKey}
      fileCsvName={t('custUser.fileNameDownload')}
      listData={listData}
      downloadParams={setDownloadParams}
      setIsValidDate={setIsValidDate}
      setIsInDateRange={setIsInDateRange}
    />
  )

  const handleRedirectReissue = (data) => {
    history.push({
      pathname: '/admin/reissue',
      search: `?`,
      state: {
        data: data,
        searchInput: searchInput,
        dateFilter: dateFilter,
        screen: SCREEN_NAME.CUSTUSER,
      },
    })
  }

  const msgDeleteConfirm = recordDelete
    ? getTitleModalConfirm(
        t('custUser.msgDeleteConfirm.line1', {
          idCustValue: recordDelete.ID_USER,
        }),
        t('custUser.msgDeleteConfirm.line2')
      )
    : ''

  return (
    <div className="custuser__wrapper">
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {deletePermissionMessageError && (
        <MessageErrorDialog
          message={deletePermissionMessageError}
          showMessageErrorDialog={setDeletePermissionMessageError}
        />
      )}

      {loading && <ProgressDialog label={recordDelete ? t('custUser.msgDeleteData') : t('custUser.loading')} />}
      <ModalConfirm
        title={msgDeleteConfirm}
        showModal={showModalConfirmDelete}
        setShowModal={setShowModalConfirmDelete}
        handleFunction={deleteCustUser}
      />
      <DeleteResult
        title={
          deleteMessageType === MESSAGE_TYPE.SUCCESS ? t('custUser.deleteDataSuccess') : t('custUser.deleteDataError')
        }
        showModal={deletePopUp}
        handleFunction={handleConfirmDeleteResult}
        screen={'custuser'}
      />
      {loading ? (
        <ProgressDialog label={recordDelete ? t('custUser.msgDeleteData') : t('custUser.loading')} />
      ) : (
        <div className="custuser__container maintain-container">
          {/* Table */}
          <TableCommon
            listColumnWidth={listColumnWidth}
            listCellHeader={listCellHeader}
            listCellBody={listCellBody}
            listDataRender={filterData}
            handleUpdate={navigateToFormCustUser}
            idProperty={'ID_MENU'}
            handleDelete={handleDeleteOnClick}
            toolbar={toolbar}
            handleRedirectReissue={handleRedirectReissue}
          />
        </div>
      )}
    </div>
  )
}

export default HeadQuarters
