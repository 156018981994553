import { makeStyles } from '@material-ui/core/styles'
const styles = makeStyles((theme) => ({
  dropdownMessage: {
    fontSize: '14px !important',
  },
  margin: {
    margin: theme.spacing(1),
  },
  selectTopicPortal: {
    fontSize: '14px !important',
  },
  wrapper: {
    width: '100%',
  },
  titleMessage: {
    width: '100 %',
  },
  iconInfo: {
    color: 'rgb(85, 130, 50)',
    width: '20px',
    height: '20px',
  },
  customButtonSpace: {
    marginTop: '92px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: '30px',
  },
  formatTextArea: {},
  typingTextArea: {},
  width50: {
    maxWidth: 430,
  },
}))

export default styles
