import {
  CHECK_EXCEL_TEMPLATE_FAILED,
  CHECK_EXCEL_TEMPLATE_REQUEST,
  CHECK_EXCEL_TEMPLATE_SUCCESS,
  CLEAR_EXCEL_TEMPLATE,
  CREATE_EXCEL_TEMPLATE_FAILED,
  CREATE_EXCEL_TEMPLATE_REQUEST,
  CREATE_EXCEL_TEMPLATE_SUCCESS,
  DELETE_EXCEL_TEMPLATE_FAILED,
  DELETE_EXCEL_TEMPLATE_REQUEST,
  DELETE_EXCEL_TEMPLATE_SUCCESS,
  GET_EXCEL_TEMPLATE_LIST_FAILED,
  GET_EXCEL_TEMPLATE_LIST_REQUEST,
  GET_EXCEL_TEMPLATE_LIST_SUCCESS,
  UPDATE_EXCEL_TEMPLATE_FAILED,
  UPDATE_EXCEL_TEMPLATE_REQUEST,
  UPDATE_EXCEL_TEMPLATE_SUCCESS,
} from 'constants/actionTypes/excelTemplateActionTypes'

const listExcelTemplate = (state = { listExcelTemplate: [] }, action) => {
  switch (action.type) {
    case GET_EXCEL_TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_EXCEL_TEMPLATE_LIST_REQUEST,
      }
    case GET_EXCEL_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        listExcelTemplate: action.payload,
        type: GET_EXCEL_TEMPLATE_LIST_SUCCESS,
      }
    case GET_EXCEL_TEMPLATE_LIST_FAILED:
      return {
        ...state,
        loading: false,
        listExcelTemplate: [],
        message: action.payload,
        type: GET_EXCEL_TEMPLATE_LIST_FAILED,
      }
    default:
      return state
  }
}

const createExcelTemplate = (state = { excelTemplate: {} }, action) => {
  switch (action.type) {
    case CREATE_EXCEL_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        excelTemplate: action.payload,
        type: CREATE_EXCEL_TEMPLATE_REQUEST,
      }
    case CREATE_EXCEL_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        excelTemplate: { ...action.payload },
        type: CREATE_EXCEL_TEMPLATE_SUCCESS,
        message: 'success',
      }
    case CREATE_EXCEL_TEMPLATE_FAILED:
      return {
        ...state,
        loading: false,
        excelTemplate: {},
        message: action.payload,
        type: CREATE_EXCEL_TEMPLATE_FAILED,
      }
    case CLEAR_EXCEL_TEMPLATE:
      return { type: CLEAR_EXCEL_TEMPLATE, loading: false, status: null, excelTemplate: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, excelTemplate: {}, message: '' }
  }
}

const updateExcelTemplate = (state = { excelTemplate: {} }, action) => {
  switch (action.type) {
    case UPDATE_EXCEL_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        excelTemplate: action.payload,
        type: UPDATE_EXCEL_TEMPLATE_REQUEST,
      }
    case UPDATE_EXCEL_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        excelTemplate: { ...action.payload },
        type: UPDATE_EXCEL_TEMPLATE_SUCCESS,
        message: 'success',
      }
    case UPDATE_EXCEL_TEMPLATE_FAILED:
      return {
        ...state,
        loading: false,
        excelTemplate: {},
        message: action.payload,
        type: UPDATE_EXCEL_TEMPLATE_FAILED,
      }
    case CLEAR_EXCEL_TEMPLATE:
      return { type: CLEAR_EXCEL_TEMPLATE, loading: false, status: null, excelTemplate: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, excelTemplate: {}, message: '' }
  }
}

const deleteExcelTemplate = (state = { excelTemplate: {} }, action) => {
  switch (action.type) {
    case DELETE_EXCEL_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        excelTemplate: action.payload,
        type: DELETE_EXCEL_TEMPLATE_REQUEST,
      }
    case DELETE_EXCEL_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        excelTemplate: { ...action.payload },
        type: DELETE_EXCEL_TEMPLATE_SUCCESS,
        message: 'success',
      }
    case DELETE_EXCEL_TEMPLATE_FAILED:
      return {
        ...state,
        loading: false,
        excelTemplate: {},
        message: action.payload,
        type: DELETE_EXCEL_TEMPLATE_FAILED,
      }
    case CLEAR_EXCEL_TEMPLATE:
      return { type: CLEAR_EXCEL_TEMPLATE, loading: false, status: null, excelTemplate: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, excelTemplate: {}, message: '' }
  }
}

const checkExistExcelTemplate = (state = { data: [] }, action) => {
  switch (action.type) {
    case CHECK_EXCEL_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        type: CHECK_EXCEL_TEMPLATE_REQUEST,
      }
    case CHECK_EXCEL_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        type: CHECK_EXCEL_TEMPLATE_SUCCESS,
      }
    case CHECK_EXCEL_TEMPLATE_FAILED:
      return {
        ...state,
        loading: false,
        data: false,
        message: action.payload,
        type: CHECK_EXCEL_TEMPLATE_FAILED,
      }
    default:
      return state
  }
}

export { checkExistExcelTemplate, createExcelTemplate, deleteExcelTemplate, listExcelTemplate, updateExcelTemplate }
