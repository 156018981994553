import axios from 'commons/baseUrl'
import {
  CLEAR_CUSTOMER_NAME,
  CLEAR_CUSTUSER,
  CREATE_CUSTUSER_FAILED,
  CREATE_CUSTUSER_REQUEST,
  CREATE_CUSTUSER_SUCCESS,
  DELETE_CUSTUSER_FAILED,
  DELETE_CUSTUSER_REQUEST,
  DELETE_CUSTUSER_SUCCESS,
  GET_CUSTOMER_NAME_FAILED,
  GET_CUSTOMER_NAME_REQUEST,
  GET_CUSTOMER_NAME_SUCCESS,
  GET_CUSTUSER_LIST_FAILED,
  GET_CUSTUSER_LIST_REQUEST,
  GET_CUSTUSER_LIST_SUCCESS,
  UPDATE_CUSTUSER_FAILED,
  UPDATE_CUSTUSER_REQUEST,
  UPDATE_CUSTUSER_SUCCESS,
} from 'constants/actionTypes/custUserActionTypes'
import {
  API_CREATE_CUSTUSER,
  API_DELETE_CUSTUSER,
  API_GET_CUSTOMER_NAME,
  API_GET_CUSTUSER,
  API_UPDATE_CUSTUSER,
} from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const getCustUserAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_CUSTUSER_LIST_REQUEST })
    //fake response here
    const res = await axios.get(API_GET_CUSTUSER, { params })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_CUSTUSER_LIST_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res?.data?.STS === RESPONSE_TYPE.SUCCESS && res?.data?.data?.length >= 0) {
      dispatch({ type: GET_CUSTUSER_LIST_SUCCESS, payload: res?.data?.data })
    }
  } catch (err) {
    dispatch({ type: GET_CUSTUSER_LIST_FAILED, payload: null })
  }
}

const getCustomerNameAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_CUSTOMER_NAME_REQUEST })
    const res = await axios.get(API_GET_CUSTOMER_NAME, { params })
    if (!res.data || res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: GET_CUSTOMER_NAME_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data.data.length >= 0) {
      dispatch({ type: GET_CUSTOMER_NAME_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: GET_CUSTOMER_NAME_FAILED, payload: err.message })
  }
}

const createCustUserAction = (data, i18n) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CUSTUSER_REQUEST, payload: data })
    const res = await axios.post(API_CREATE_CUSTUSER, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: CREATE_CUSTUSER_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: CREATE_CUSTUSER_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_EXISTED) {
      dispatch({
        type: CREATE_CUSTUSER_FAILED,
        payload: i18n.t('validate.custuser.idUserInvalid', {
          fieldName: i18n.t('custUser.lblIdUser'),
          idUserValue: data.ID_USER,
        }),
      })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: CREATE_CUSTUSER_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: CREATE_CUSTUSER_FAILED, payload: err.message })
  }
}

const updateCustUserAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CUSTUSER_REQUEST, payload: data })
    const res = await axios.post(API_UPDATE_CUSTUSER, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: UPDATE_CUSTUSER_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: UPDATE_CUSTUSER_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: UPDATE_CUSTUSER_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: UPDATE_CUSTUSER_FAILED, payload: err.message })
  }
}

const deleteCustUserAction = (data, params) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CUSTUSER_REQUEST, payload: data })
    const res = await axios.post(API_DELETE_CUSTUSER, data)
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: DELETE_CUSTUSER_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: DELETE_CUSTUSER_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: DELETE_CUSTUSER_SUCCESS, payload: res.data.data })
      dispatch(getCustUserAction(params))
    }
  } catch (err) {
    dispatch({ type: DELETE_CUSTUSER_SUCCESS, payload: err.message })
  }
}

const clearCustUser = (data) => async (dispatch) => {
  dispatch({ type: CLEAR_CUSTUSER, payload: '' })
}

const clearCustomerName = (data) => async (dispatch) => {
  dispatch({ type: CLEAR_CUSTOMER_NAME, payload: '' })
}

export {
  clearCustomerName,
  clearCustUser,
  createCustUserAction,
  deleteCustUserAction,
  getCustomerNameAction,
  getCustUserAction,
  updateCustUserAction,
}
