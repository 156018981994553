const branchValidate = (dtStart, dtEnd, i18n) => {
  const messages = {}
  if ((dtStart && !new Date(dtStart).getTime()) || (dtEnd && !new Date(dtEnd).getTime()))
    messages.DATE = i18n.t('validate.branch.msgInvalidDateFormat')
  if (dtStart && dtEnd && new Date(dtEnd) < new Date(dtStart)) {
    messages.InvalidDtEnd = i18n.t('validate.branch.msgDtEndInvalid')
  }
  return messages
}

export default branchValidate
