import './Customer.css'

// import { Button } from '@material-ui/core'
import { clearCustomer, deleteCustomerAction, getCustomersAction } from 'actions/customerActions'
// import { IMAGES } from 'assets'
import {
  dataSearchResult,
  formatDateWithPadding,
  getSessionInfo,
  getTitleModalConfirm,
  getUserKbAdmin,
} from 'commons/utilities'
import {
  // Image,
  MessageErrorDialog,
  ModalConfirm as DeleteResult,
  ModalConfirm,
  ProgressDialog,
  // SearchCalendar,
  // SearchFormInput,
  // SelectInput,
} from 'components/commons'
import TableCommon from 'components/commons/Table/TableCommon'
import ToolBar from 'components/commons/ToolBar/ToolBar'
import {
  DELETE_CUSTOMER_FAILED,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  GET_CUSTOMERS_LIST_FAILED,
  GET_CUSTOMERS_LIST_REQUEST,
  GET_CUSTOMERS_LIST_SUCCESS,
} from 'constants/actionTypes/customerActionTypes'
import {
  ACTION_FORM,
  DATE_TYPE,
  FILTER_ITEMS,
  FLAG_BOOLEAN,
  MESSAGE_TYPE,
  SCREEN_NAME,
  SPECIAL_CELL_TYPE,
  TOOLBAR_TYPE,
} from 'constants/constant'
import React, { useEffect, useRef, useState } from 'react'
// import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

// import styles from './CustomerStyles'

function Customer() {
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return null
  })
  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const { t } = useTranslation()
  // const classes = styles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [listData, setListData] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [dateFilter, setDateFilter] = useState(DT_TODAY)
  const [filterItem, setFilterItem] = useState(FILTER_ITEMS[1])
  const [filterDateParams, setFilterDateParams] = useState({
    dateType: '',
    date: DT_TODAY,
  })
  // const [searchData, setSearchData] = useState([])
  const [filterData, setFilterData] = useState([])
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState()
  const [recordDelete, setRecordDelete] = useState()
  const listCustomersReducer = useSelector((state) => state.listCustomers || {})
  const customerDeleteReducer = useSelector((state) => state.deleteCustomer || {})
  const [isValidDate, setIsValidDate] = useState(true)
  const [isInDateRange, setIsInDateRange] = useState(true)
  const filteredFields = ['CD_CUST', 'NM_CUST']
  const [deletePermissionMessageError, setDeletePermissionMessageError] = useState('')

  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false)
  const [deletePopUp, setDeletePopUp] = useState(false)
  const [deleteMessageType, setDeleteMessageType] = useState('')
  const firstRender = useRef(true)

  const handleSearchInput = (event) => {
    event.preventDefault()
    setFilterData(dataSearchResult(listData, filteredFields, searchInput))
  }
  const handleFilterDate = React.useCallback(
    (date) => {
      if (isValidDate && isInDateRange) {
        let params = {
          dateType: filterItem === FILTER_ITEMS[0] ? DATE_TYPE.START : DATE_TYPE.END,
          date: date,
        }
        setFilterDateParams(params)
        dispatch(getCustomersAction(params))
      }
    },
    [dispatch, filterItem]
  )
  const handleDeleteOnClick = async (data) => {
    const kbAdmin = await getUserKbAdmin(userInfo)

    if (String(kbAdmin) === FLAG_BOOLEAN.FALSE) {
      setShowModalConfirmDelete(false)
      setDeletePermissionMessageError(t('commonComponent.noDeletePermission'))
    } else {
      setShowModalConfirmDelete(true)
      setRecordDelete(data)
    }
  }
  const deleteCustomer = () => {
    setShowModalConfirmDelete(false)
    if (filterDateParams && filterDateParams.dateType && filterDateParams.date) {
      filterDateParams.dateType === DATE_TYPE.START ? setFilterItem(FILTER_ITEMS[0]) : setFilterItem(FILTER_ITEMS[1])
      dispatch(deleteCustomerAction(recordDelete, filterDateParams))
    } else {
      dispatch(deleteCustomerAction(recordDelete))
    }
  }

  // handle edit portal
  const navigateToFormCustomer = (id, data, type = ACTION_FORM.CREATE) => {
    history.push({
      pathname: '/admin/customer-form',
      search: `?${id ? `id=${id}&` : ''}type=${type}`,
      state: {
        type: type,
        data: data,
        date: filterDateParams,
        searchInput: searchInput,
        screen: SCREEN_NAME.CUSTOMER,
      },
    })
  }
  useEffect(() => {
    const { date, searchInput, screen } = location?.state || {}
    if (searchInput && screen === SCREEN_NAME.CUSTOMER) {
      setSearchInput(searchInput)
    }
    if (date && date.dateType && date.date && screen === SCREEN_NAME.CUSTOMER) {
      date.dateType === DATE_TYPE.START ? setFilterItem(FILTER_ITEMS[0]) : setFilterItem(FILTER_ITEMS[1])
      setDateFilter(date.date)
      setFilterDateParams(date)
      dispatch(getCustomersAction(date))
    } else {
      dispatch(getCustomersAction({}))
    }
    window.history.replaceState({}, document.title)
  }, [dispatch, location])

  useEffect(() => {
    let dateParams = {
      dateType: filterItem === FILTER_ITEMS[0] ? DATE_TYPE.START : DATE_TYPE.END,
      date: dateFilter,
    }

    if (!firstRender.current) {
      // dateParams.dateType = DATE_TYPE.END
      setFilterDateParams(dateParams)
      dispatch(getCustomersAction(dateParams))
    }

    if (firstRender.current) {
      firstRender.current = false
    }
  }, [filterItem])

  useEffect(() => {
    if (listData.length >= 0 && searchInput === '') {
      setFilterData(listData)
    }
  }, [listData, searchInput])

  useEffect(() => {
    setDeletePopUp(false)
    if (listCustomersReducer.listCustomers.length >= 0) {
      let dataRespone = [...listCustomersReducer.listCustomers]
      setListData(dataRespone)
      if (searchInput !== '') {
        setFilterData(dataSearchResult(dataRespone, filteredFields, searchInput))
      }
    }
    setLoading(listCustomersReducer.loading || customerDeleteReducer.loading)
    switch (listCustomersReducer.type) {
      case GET_CUSTOMERS_LIST_REQUEST:
        break
      case GET_CUSTOMERS_LIST_FAILED:
        if (!getSessionInfo()) {
          setMessageError(listCustomersReducer.message)
        }
        break
      case GET_CUSTOMERS_LIST_SUCCESS:
        break
      default:
        return
    }
  }, [listCustomersReducer])

  useEffect(() => {
    setLoading(listCustomersReducer.loading || customerDeleteReducer.loading)
    switch (customerDeleteReducer.type) {
      case DELETE_CUSTOMER_REQUEST:
        break
      case DELETE_CUSTOMER_SUCCESS:
        setDeletePopUp(true)
        setDeleteMessageType(MESSAGE_TYPE.SUCCESS)
        break
      case DELETE_CUSTOMER_FAILED:
        if (!getSessionInfo()) {
          customerDeleteReducer.message && setMessageError(customerDeleteReducer.message)
          setDeletePopUp(true)
          setDeleteMessageType(MESSAGE_TYPE.ERROR)
        }
        break
      default:
        return
    }
  }, [customerDeleteReducer])

  const headersCsv = [
    { label: t('customer.lblCustCode'), key: 'CD_CUST' },
    { label: t('customer.lblNmCust'), key: 'NM_CUST' },
    { label: t('customer.lblStartDate'), key: 'DT_START' },
    { label: t('customer.lblEndDate'), key: 'DT_END' },
  ]
  const headerCsvLabel = headersCsv.map((data) => data.label)
  const headerCsvKey = headersCsv.map((data) => data.key)

  const listColumnWidth = ['62px', '15%', '', '15%', '15%']

  const listCellHeader = [
    { columnName: '', commonStyle: 'titleHead', cellStyle: '' },
    { columnName: 'customer.lblCdCust', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'customer.lblNmCust', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'customer.lblStartDate', commonStyle: 'titleHead', cellStyle: 'personTitle' },
    { columnName: 'customer.lblEndDate', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
  ]

  const listCellBody = [
    { keyValue: 'CD_CUST', cellStyle: 'data', gridStyle: 'centerAlignHq' },
    { keyValue: 'NM_CUST', cellStyle: 'data', gridStyle: 'centerAlignHq' },
    { specialType: SPECIAL_CELL_TYPE.DATE_START },
    { specialType: SPECIAL_CELL_TYPE.DATE_END },
  ]

  const listToolBarComponent = [
    { type: TOOLBAR_TYPE.ADD, commonStyle: 'createContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.FILTER_LOGO, commonStyle: 'filterIconContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.SEARCH, commonStyle: 'searchContainer', componentStyle: 'search-item' },
    { type: TOOLBAR_TYPE.FILTER_DATE, commonStyle: 'select-item', componentStyle: '' },
    { type: TOOLBAR_TYPE.CALENDAR, commonStyle: 'filterContainer', componentStyle: 'calendar-item' },
    { type: TOOLBAR_TYPE.CSV, commonStyle: '', componentStyle: '' },
  ]
  let setDownloadParams = {
    screenName: 'customer',
    searchText: searchInput,
    date: dateFilter,
    dateType: filterItem === FILTER_ITEMS[0] ? DATE_TYPE.START : DATE_TYPE.END,
  }
  const toolbar = (
    <ToolBar
      listToolBarComponent={listToolBarComponent}
      handleCreateOnclick={navigateToFormCustomer}
      setSearchInput={setSearchInput}
      searchInput={searchInput}
      handleSubmit={handleSearchInput}
      handleFilterDate={handleFilterDate}
      setSelectedDate={setDateFilter}
      selectedDate={dateFilter}
      headerCsvLabel={headerCsvLabel}
      headerCsvKey={headerCsvKey}
      fileCsvName={t('custUser.fileNameDownload')}
      listData={listData}
      setFilterItem={setFilterItem}
      filterItem={filterItem}
      customClassTitleFitlerIcon={'toolbarTitleNotTextWrap'}
      csvButtonStyle={true}
      toolbarWidth={'SMALL'}
      downloadParams={setDownloadParams}
      setIsValidDate={setIsValidDate}
      setIsInDateRange={setIsInDateRange}
    />
  )

  const msgDeleteConfirm = recordDelete
    ? getTitleModalConfirm(
        t('customer.msgDeleteConfirm.line1', {
          cdCustValue: recordDelete.CD_CUST,
        }),
        t('customer.msgDeleteConfirm.line2')
      )
    : ''

  const handleConfirmDeleteResult = () => {
    setRecordDelete(null)
    setDeletePopUp(false)
    dispatch(getCustomersAction())
    dispatch(clearCustomer())
  }
  return (
    <div className="customer__wrapper customer__maintain-wrapper">
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {deletePermissionMessageError && (
        <MessageErrorDialog
          message={deletePermissionMessageError}
          showMessageErrorDialog={setDeletePermissionMessageError}
        />
      )}
      {loading && <ProgressDialog label={recordDelete ? t('customer.msgDeleteData') : t('customer.loading')} />}
      <ModalConfirm
        title={msgDeleteConfirm}
        showModal={showModalConfirmDelete}
        setShowModal={setShowModalConfirmDelete}
        handleFunction={deleteCustomer}
      />
      <DeleteResult
        title={
          deleteMessageType === MESSAGE_TYPE.SUCCESS ? t('customer.deleteDataSuccess') : t('customer.deleteDataError')
        }
        showModal={deletePopUp}
        handleFunction={handleConfirmDeleteResult}
        screen={'customer'}
      />
      {loading ? (
        <ProgressDialog label={recordDelete ? t('customer.msgDeleteData') : t('customer.loading')} />
      ) : (
        <div className="customer__container maintain-container">
          {/* Table */}
          <TableCommon
            tableWidth={'SMALL'}
            listColumnWidth={listColumnWidth}
            listCellHeader={listCellHeader}
            listCellBody={listCellBody}
            listDataRender={filterData}
            handleUpdate={navigateToFormCustomer}
            idProperty={'ID_MENU'}
            handleDelete={handleDeleteOnClick}
            toolbar={toolbar}
          />
        </div>
      )}
    </div>
  )
}

export default Customer
