import { makeStyles } from '@material-ui/core/styles'
const styles = makeStyles((theme) => ({
  dropdownMessage: {
    fontSize: '12px ',
  },
  iconInfo: {
    color: 'rgb(85, 130, 50)',
    width: '20px',
    height: '20px',
  },
  iconDelete: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    position: 'absolute',
    top: '-25px',
  },
  iconDeleteTemplate: {
    width: '20px',
    height: '20px',
    cursor: 'pointer',
  },
  tableRow: {
    backgroundColor: '#f5faeb',
    position: 'relative',
  },

  width100: {
    width: '100px !important',
  },
  width180: {
    width: '180px !important',
  },

  width210: {
    width: '210px !important',
  },
  width290: {
    width: '290px !important',
  },
  formTemplate: {
    width: '518.8px !important',
  },
  marginRight20: {
    marginRight: '20px',
  },
  fontSize16: {
    fontSize: '14px !important',
  },
  outputWidthTable: {
    width: '100%',
    overflowY: 'auto',
    tableLayout: 'fixed',
  },
  tableHead: {
    backgroundColor: '#f2f2f2',
    height: '30px !important',
  },
  titleHead: {
    color: 'rgb(85, 130, 50)',
    fontSize: '12px',
    padding: '0px 10px !important',
  },
  bodyCell: {
    fontSize: '12px',
    padding: '5px 10px !important',
  },
  // titleCustomerTemplate: {
  //   textAlign: 'center',
  // },
  // titleCustomerName: {
  //   textAlign: 'center',
  // },
  titleHeadRow: {},
  outputTableRowColor: {
    backgroundColor: 'rgb(245, 250, 235)',
  },
  overFlowText: {
    overflow: 'hidden',
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
  },
  customerTemplateRow: {
    position: 'relative',
  },
}))

export default styles
