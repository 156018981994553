import './UploadMessage.css'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React, { useState } from 'react'

function UploadMessage({ message, errField, type, onClose, title, buttonLabel }) {
  const [open, setOpen] = useState(true)
  const setFocus = (posision) => {
    if (posision === 'begin') document.getElementById('btn-end').focus()
    if (posision === 'end') document.getElementById('btn-begin').focus()
  }
  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      maxWidth={false}
      aria-labelledby="confirm-dialog-title"
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        <div dangerouslySetInnerHTML={{ __html: message }} className="text-center"></div>
        {type === 'error' && <div>{errField}</div>}
      </DialogContent>

      <DialogActions>
        <div tabIndex="100" onFocus={() => setFocus('begin')}></div>
        {/* OK button */}
        <Button
          className="button-submit"
          type="submit"
          variant="contained"
          color="primary"
          onClick={handleClose}
          tabIndex="101"
          id="btn-begin"
        >
          {buttonLabel}
        </Button>
        <div tabIndex="103" onFocus={() => setFocus('end')}></div>
      </DialogActions>
    </Dialog>
  )
}

export default UploadMessage
