import { makeStyles } from '@material-ui/core/styles'
const styles = makeStyles((theme) => ({
  iconInfo: {
    color: 'rgb(85, 130, 50)',
    width: '20px',
    height: '20px',
  },
}))

export default styles
