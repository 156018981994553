import './PortalCreateEdit.css'

import { Button } from '@material-ui/core'
import getCodeInfo from 'actions/codeActions'
import { createPortalAction, resetPortal, updatePortalAction } from 'actions/portalActions'
import {
  addDays,
  formatDateWithPadding,
  getDateJPByDtToday,
  getUserKbAdmin,
  shortenValue,
  uploadFile,
} from 'commons/utilities'
import { getTitleModalConfirm, parseDateTime } from 'commons/utilities'
import {
  MessageErrorDialog,
  ModalConfirm,
  ModalConfirm as ModalConfirmChangePortalType,
  ModalConfirmNavigation,
  ProgressDialog,
  SelectInput,
} from 'components/commons'
import FormEdit from 'components/commons/FormEdits/FormEdit'
import { ACTION_FORM, CD_KEY, FLAG_BOOLEAN, PORTAL_TYPES } from 'constants/constant'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { fileValidate, portalValidate } from 'validation/portalValidate'

import styles from './PortalStyles'

export default function PortalCreateEdit() {
  const { i18n, t } = useTranslation()

  const lastButtonRef = useRef(null)

  const classes = styles()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return {}
  })
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })
  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')

  const codeInfo = useSelector((state) => state.codeInfo.code[0] || {})

  const [type, setType] = useState(ACTION_FORM.CREATE)
  const [isBlocked, setIsBlocked] = useState(false)
  // const [isDisableHighLight, setIsDisableHighLight] = useState(false)
  const [dataForm, setDataForm] = useState({
    KB_MESSAGE: '',
    NM_TITLE: '',
    NM_MESSAGE: '',
    LT_ATTACH: [],
    NM_URL: '',
    DT_START: addDays(new Date(DT_TODAY), sysInfo?.MP_DADD?.DT_ADSTART),
    DT_END: addDays(new Date(DT_TODAY), sysInfo?.MP_DADD?.DT_ADEND),
    NM_SENDER: '',
    NM_USER: userInfo.NM_USER,
    DT_REGIST: '',
    DT_UPDATE: '',
    KB_MLSND: '0',
    NM_RUDUSER: userInfo.NM_USER,
  })
  const [filteredDate, setFilteredDate] = useState({})
  const [files, setFiles] = useState([])
  const [errorData, setErrorData] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [showModalChangePortalType, setShowModalChangePortalType] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [requested, setRequested] = useState(false)
  const [isError, setIsError] = useState(false)
  const [messageFileError, setMessageFileError] = useState('')
  const [fileConflic, setFileConflic] = useState({})
  const portalResponse = useSelector((state) => {
    if (type === ACTION_FORM.UPDATE) if (state.updatePortal) return state.updatePortal
    if (type === ACTION_FORM.CREATE) if (state.createPortal) return state.createPortal
    return {}
  })
  const [loading, setLoading] = useState(false)
  const [isValidStartDate, setIsValidStartDate] = useState(true)
  const [isValidEndDate, setIsValidEndDate] = useState(true)
  const [isInStartDateRange, setIsInStartDateRange] = useState(true)
  const [isInEndDateRange, setIsInEndDateRange] = useState(true)
  const [createPermissionMessageError, setCreatePermissionMessageError] = useState('')
  const [updatePermissionMessageError, setUpdatePermissionMessageError] = useState('')

  const [dtRegist, setDtRegist] = useState(formatDateWithPadding(new Date(), 'yyyy-MM-dd HH:mm:ss.SSS'))
  const [dropDownType, setDropDownType] = useState([])

  const [searchInput, setSearchInput] = useState('')
  const [portalTypeValue, setPortalTypeValue] = useState('')
  const [dateCentral, setDateCentral] = useState(null)
  const [showModalCormfirmDuplicatedUpload, setShowModalCormfirmDuplicatedUpload] = useState(false)

  // handle update field portal
  const handleUpdate = (name, value) => {
    setErrorData({})
    if (name === 'KB_MESSAGE' && type !== ACTION_FORM.UPDATE) {
      setPortalTypeValue(value)
      if (isBlocked) {
        setShowModalChangePortalType(true)
      } else {
        setDataForm({
          KB_MESSAGE: value,
          NM_TITLE: '',
          NM_MESSAGE: '',
          LT_ATTACH: [],
          NM_URL: '',
          DT_START: addDays(new Date(DT_TODAY), sysInfo?.MP_DADD?.DT_ADSTART),
          DT_END: addDays(new Date(DT_TODAY), sysInfo?.MP_DADD?.DT_ADEND),
          NM_SENDER: '',
          NM_USER: userInfo.NM_USER,
          DT_REGIST: '',
          DT_UPDATE: '',
          KB_MLSND: '0',
          NM_RUDUSER: userInfo.NM_USER,
        })
      }
      return
    }
    setIsBlocked(true)
    if (name === 'LT_ATTACH') {
      const { messageError, fileExist, fileValid } = fileValidate(value, sysInfo, dataForm.LT_ATTACH, i18n)
      const { NM_EXT = null, NU_UPMAXSZ = null, NU_UPMAX = null, FILE_EXIST = null } = messageError
      setMessageFileError(NM_EXT || NU_UPMAXSZ || NU_UPMAX || '')
      setDataForm((prev) => ({ ...prev, LT_ATTACH: fileValid }))
      setFileConflic({ fileExist: fileExist, msg_err: FILE_EXIST })

      return
    }

    if (name === 'DT_START' || name === 'DT_END') {
      let t = dataForm
      t[name] = value
      setDataForm((prev) => ({ ...prev, ...t }))
      // setErrorData((prev) => ({ ...prev, DATE: '' }))
      return
    }
    if (name === 'KB_MLSND') {
      setDataForm((prev) => ({ ...prev, KB_MLSND: (value + 0).toString() }))
      return
    }
    setDataForm((prev) => ({ ...prev, [name]: value }))
  }

  const checkRequire = useCallback(() => {
    if (!dataForm.KB_MESSAGE || !dataForm.NM_TITLE) return false
    if (dataForm.KB_MESSAGE === PORTAL_TYPES.NOTICE && (!dataForm.NM_MESSAGE || !dataForm.NM_TITLE)) return false
    if (dataForm.KB_MESSAGE === PORTAL_TYPES.TOPIC && !dataForm.NM_MESSAGE) return false
    if (dataForm.KB_MESSAGE === PORTAL_TYPES.LINK && !dataForm.NM_URL) return false
    if (!dataForm.DT_START || !dataForm.DT_END) return false
    return isBlocked
  }, [dataForm, isBlocked])

  // submit data
  const handleSubmit = async (event) => {
    event.preventDefault()
    const kbAdmin = await getUserKbAdmin(userInfo)
    if (String(kbAdmin) === FLAG_BOOLEAN.FALSE) {
      if (type === ACTION_FORM.CREATE) {
        setCreatePermissionMessageError(t('commonComponent.noCreatePermission'))
      }
      if (type === ACTION_FORM.UPDATE) {
        setUpdatePermissionMessageError(t('commonComponent.noUpdatePermission'))
      }
    } else {
      const messages = portalValidate(dataForm, i18n)
      if (messages.NM_URL || messages.DATE || messages.InvalidDtEnd) {
        setErrorData(messages)
      } else {
        setShowModal(true)
        setErrorData({})
      }
    }
  }
  const handleClear = () => {
    // setDataForm({})
    history.push({
      pathname: '/admin/portal-master',
      state: {
        date: filteredDate,
        searchInput: searchInput,
        sortDateStart: history.location.state.sortDateStart,
        sortDateEnd: history.location.state.sortDateEnd,
        dateCentral: dateCentral,
      },
    })
    dispatch(resetPortal())
  }

  const submitData = () => {
    const currentDateTime = getDateJPByDtToday(sysInfo?.DT_TODAY)
    setDtRegist(currentDateTime)
    let data = {
      ...dataForm,
      LT_ATTACH: dataForm.LT_ATTACH
        ? [
            ...dataForm.LT_ATTACH.map((f) => {
              if (f instanceof File) return f.name
              return f
            }),
          ]
        : [],
      DT_START: formatDateWithPadding(dataForm.DT_START, 'yyyy-MM-dd'),
      DT_END: formatDateWithPadding(dataForm.DT_END, 'yyyy-MM-dd'),
      NM_RUDUSER: userInfo.NM_USER,
    }

    if (type === ACTION_FORM.UPDATE) {
      dispatch(updatePortalAction({ ...data, NM_RUDUSER: userInfo.NM_USER, DT_UPDATE: currentDateTime }))
    }
    if (type === ACTION_FORM.CREATE) {
      dispatch(createPortalAction({ ...data, DT_REGIST: currentDateTime }))
    }
    setRequested(true)
    setIsBlocked(false)
    setShowModal(false)
  }

  const uploadFileInForm = (fileList) => {
    setShowModalCormfirmDuplicatedUpload(false)
    // unique files
    const uniqueFiles = [...new Map(fileList.map((item) => [item['name'], item])).values()]
    setFiles(uniqueFiles)
    const newFiles = dataForm.LT_ATTACH
      ? [...dataForm.LT_ATTACH.filter((f) => !(f instanceof File)), ...uniqueFiles]
      : uniqueFiles
    handleUpdate('LT_ATTACH', newFiles)
  }

  const renderElementByType = () => {
    if (Object.values(PORTAL_TYPES).includes(dataForm.KB_MESSAGE))
      return (
        <FormEdit
          category={dataForm.KB_MESSAGE}
          type={type}
          data={dataForm}
          error={errorData}
          setIsValidStartDate={setIsValidStartDate}
          setIsValidEndDate={setIsValidEndDate}
          setIsInStartDateRange={setIsInStartDateRange}
          setIsInEndDateRange={setIsInEndDateRange}
          handleUpdate={handleUpdate}
          files={files}
          setFiles={setFiles}
          sysInfo={sysInfo}
          setShowModalCormfirmDuplicatedUpload={setShowModalCormfirmDuplicatedUpload}
          uploadFile={uploadFileInForm}
        />
      )
    return <div className="formEdit-container"></div>
  }

  const mergeFile = () => {
    const originFile = [...dataForm.LT_ATTACH],
      newFiles = [...fileConflic.fileExist]
    newFiles.forEach((file) => {
      originFile.splice(originFile.indexOf(file.name), 1)
    })
    setDataForm((prev) => ({ ...prev, LT_ATTACH: [...originFile, ...newFiles] }))
    setFileConflic({ msg_err: '', fileExist: [] })
  }

  const getTitleModal = (type, dataForm) => {
    const portalType = ['お知らせ', '取り組みトピックス', 'リンク'][dataForm.KB_MESSAGE]
    const formType = type === ACTION_FORM.UPDATE ? '更新' : '登録'
    const message = !Number(dataForm.KB_MLSND) ? 'submitConfirmMessage' : 'submitConfirmMessageSendMail'
    if (message === 'submitConfirmMessageSendMail') {
      return getTitleModalConfirm(
        t(`maintenanceCreateEdit.${message}.line1`, {
          portalType: portalType,
          formType: formType,
          nmTitle: shortenValue(dataForm.NM_TITLE, 10),
        }),
        t(`maintenanceCreateEdit.${message}.line3`),
        t(`maintenanceCreateEdit.${message}.line2`)
      )
    } else {
      return getTitleModalConfirm(
        t(`maintenanceCreateEdit.${message}.line1`, {
          portalType: portalType,
          formType: formType,
          nmTitle: shortenValue(dataForm.NM_TITLE, 10),
        }),
        t(`maintenanceCreateEdit.${message}.line2`)
      )
    }
  }

  useEffect(() => {
    const { data, type, date, searchInput, dateCentral } = location?.state || {}

    if (searchInput) {
      setSearchInput(searchInput)
    }
    if (type) setType(type)
    if (date) setFilteredDate(date)
    if (dateCentral) setDateCentral(dateCentral)
    if (data)
      setDataForm({
        ...data,
        DT_START: data.DT_START,
        DT_END: data.DT_END,
        NM_RUDUSER: data?.NM_RUDUSER,
      })
  }, [location])

  useEffect(() => {
    if (
      Object.keys(errorData).length > 0 ||
      !isValidStartDate ||
      !isValidEndDate ||
      !isInStartDateRange ||
      !isInEndDateRange ||
      !checkRequire()
    ) {
      setCanSubmit(false)
    } else {
      setCanSubmit(true)
    }
  }, [dataForm, errorData, checkRequire])

  useEffect(() => {
    if (requested) {
      setLoading(portalResponse?.loading)
      const f = { ...dataForm, ...portalResponse.portal }
      if (files.length && f.DT_REGIST && f.KB_MESSAGE.length > 0) {
        const filePath =
          'attempt-topics/' +
          f.KB_MESSAGE +
          '_' +
          parseDateTime(dataForm.DT_REGIST && dataForm.DT_REGIST.length > 0 ? dataForm.DT_REGIST : dtRegist)
        uploadFile(files, filePath)
        setRequested(false)
      }
    }
    if (portalResponse.status === 'success') {
      history.push({
        pathname: '/admin/portal-master',
        state: {
          date: filteredDate,
          searchInput: searchInput,
          sortDateStart: history.location.state.sortDateStart,
          sortDateEnd: history.location.state.sortDateEnd,
          dateCentral: dateCentral,
        },
      })
      dispatch(resetPortal())
    }
    if (portalResponse.status === 'failed') {
      setIsError(true)
      dispatch(resetPortal())
    }
  }, [portalResponse, requested, files, dataForm, history, dispatch])

  useEffect(() => {
    dispatch(getCodeInfo({ CD_KEY: CD_KEY.PORTAL }))
  }, [dispatch])

  useEffect(() => {
    if (Object.keys(codeInfo).length) {
      let types = [
        { value: codeInfo.MP_ITEM01.CD_VALUE, label: codeInfo.MP_ITEM01.NM_VALUE },
        { value: codeInfo.MP_ITEM02.CD_VALUE, label: codeInfo.MP_ITEM02.NM_VALUE },
        { value: codeInfo.MP_ITEM03.CD_VALUE, label: codeInfo.MP_ITEM03.NM_VALUE },
      ]
      setDropDownType(types)
    }
  }, [codeInfo])

  const confirmNavigationMessage = `<div class="message__pre-text"><div>
  ${t('maintenanceCreateEdit.msgConfirmNavigate.line1')} </div><div>
  ${t('maintenanceCreateEdit.msgConfirmNavigate.line2')}</div></div>`

  const submitChangePortalType = () => {
    setShowModalChangePortalType(false)
    setIsBlocked(false)
    setDataForm({
      KB_MESSAGE: portalTypeValue,
      NM_TITLE: '',
      NM_MESSAGE: '',
      LT_ATTACH: [],
      NM_URL: '',
      DT_START: addDays(new Date(DT_TODAY), sysInfo?.MP_DADD?.DT_ADSTART),
      DT_END: addDays(new Date(DT_TODAY), sysInfo?.MP_DADD?.DT_ADEND),
      NM_SENDER: '',
      NM_USER: userInfo.NM_USER,
      DT_REGIST: '',
      DT_UPDATE: '',
      KB_MLSND: '0',
      NM_RUDUSER: userInfo.NM_USER,
    })
  }

  const duplicateUploadMessage = `<div class="message__pre-text"><div>
  ${t('commonComponent.duplicatedUpload.line1')} </div><div>
  ${t('commonComponent.duplicatedUpload.line2')}</div></div>`
  const recreateNode = (el, withChildren) => {
    if (withChildren) {
      el.parentNode.replaceChild(el.cloneNode(true), el)
    } else {
      var newEl = el.cloneNode(false)
      while (el.hasChildNodes()) newEl.appendChild(el.firstChild)
      el.parentNode.replaceChild(newEl, el)
    }
  }
  return (
    <div className="portal-createWrapper">
      {loading && <ProgressDialog label={t('formHeadQuarter.loading')} />}
      {!!messageFileError && (
        <MessageErrorDialog message={messageFileError} showMessageErrorDialog={() => setMessageFileError('')} />
      )}
      {!!fileConflic.msg_err && fileConflic.fileExist.length && (
        <ModalConfirm
          title={fileConflic.msg_err || ''}
          showModal={!!fileConflic.msg_err && fileConflic.fileExist.length}
          setShowModal={() => {
            setFileConflic({ msg_err: '', fileExist: [] })
          }}
          handleFunction={mergeFile}
        />
      )}
      {createPermissionMessageError && (
        <MessageErrorDialog
          message={createPermissionMessageError}
          showMessageErrorDialog={setCreatePermissionMessageError}
        />
      )}
      {updatePermissionMessageError && (
        <MessageErrorDialog
          message={updatePermissionMessageError}
          showMessageErrorDialog={setUpdatePermissionMessageError}
        />
      )}
      {isError && <MessageErrorDialog message={portalResponse.errorMessage} showMessageErrorDialog={setIsError} />}
      {!requested && (
        <ModalConfirmNavigation
          title={confirmNavigationMessage}
          isBlocked={isBlocked}
          date={filteredDate}
          searchInput={searchInput}
          sortDateStart={history.location.state.sortDateStart}
          sortDateEnd={history.location.state.sortDateEnd}
        />
      )}
      <ModalConfirmChangePortalType
        title={confirmNavigationMessage}
        showModal={showModalChangePortalType}
        setShowModal={setShowModalChangePortalType}
        handleFunction={submitChangePortalType}
      />
      <ModalConfirm
        title={getTitleModal(type, dataForm)}
        showModal={showModal}
        setShowModal={setShowModal}
        handleFunction={submitData}
      />
      <ModalConfirm
        title={duplicateUploadMessage}
        showModal={showModalCormfirmDuplicatedUpload}
        setShowModal={setShowModalCormfirmDuplicatedUpload}
        handleFunction={() => uploadFileInForm(files)}
      />
      <form
        onKeyDown={(e) => {
          //clone input DT_END why reset all event when press tab from button
          if (e.target === lastButtonRef.current && e.code === 'Tab') {
            recreateNode(document.getElementById('DT_END'))
          }
        }}
      >
        <div className="portal-create form-scroll">
          <div className="portal-create-content form-min-width">
            <div className="space">
              <SelectInput
                id={dataForm.KB_MESSAGE ? 'KB_MESSAGE__selected' : 'KB_MESSAGE__select'}
                label={t('maintenanceCreateEdit.lblDefaultType')}
                value={dataForm.KB_MESSAGE}
                name="KB_MESSAGE"
                onChange={(e) => handleUpdate('KB_MESSAGE', e.target.value)}
                disabled={type === ACTION_FORM.UPDATE}
                className={`textDrop ${classes.dropdownMessage}`}
                items={dropDownType}
              />
            </div>
            {renderElementByType()}
            <div className="buttonSpace">
              <Button
                className="button submit-button"
                type="submit"
                onClick={handleSubmit}
                variant="contained"
                disabled={!canSubmit}
              >
                {t('maintenanceCreateEdit.btnSubmit')}
              </Button>
              <Button
                className="button clear-button"
                onClick={handleClear}
                variant="outlined"
                ref={(ref) => (lastButtonRef.current = ref)}
              >
                {t('maintenanceCreateEdit.btnCancel')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
