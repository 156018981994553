import axios from 'commons/baseUrl'
import {
  CLEAR_BRANCH,
  CREATE_BRANCH_FAILED,
  CREATE_BRANCH_REQUEST,
  CREATE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAILED,
  DELETE_BRANCH_REQUEST,
  DELETE_BRANCH_SUCCESS,
  GET_BRANCH_LIST_FAILED,
  GET_BRANCH_LIST_REQUEST,
  GET_BRANCH_LIST_SUCCESS,
  UPDATE_BRANCH_FAILED,
  UPDATE_BRANCH_REQUEST,
  UPDATE_BRANCH_SUCCESS,
} from 'constants/actionTypes/branchActionTypes'
import { API_CREATE_BRANCH, API_DELETE_BRANCH, API_GET_BRANCH, API_UPDATE_BRANCH } from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const getBranchAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_BRANCH_LIST_REQUEST })
    const res = await axios.get(API_GET_BRANCH, { params })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_BRANCH_LIST_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data.data.length >= 0) {
      dispatch({ type: GET_BRANCH_LIST_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: GET_BRANCH_LIST_FAILED, payload: null })
  }
}

const createBranchAction = (data, i18n) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_BRANCH_REQUEST, payload: data })
    const res = await axios.post(API_CREATE_BRANCH, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: CREATE_BRANCH_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_EXISTED) {
      dispatch({
        type: CREATE_BRANCH_FAILED,
        payload: i18n.t('validate.branch.cdBranchInvalid', {
          fieldName: i18n.t('branch.lblCdBranch'),
          cdBranchValue: data.CD_BRANCH,
        }),
      })
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: CREATE_BRANCH_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: CREATE_BRANCH_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: CREATE_BRANCH_FAILED, payload: err.message })
  }
}

const updateBranchAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_BRANCH_REQUEST, payload: data })
    const res = await axios.post(API_UPDATE_BRANCH, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: UPDATE_BRANCH_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: UPDATE_BRANCH_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: UPDATE_BRANCH_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: UPDATE_BRANCH_FAILED, payload: err.message })
  }
}

const deleteBranchAction = (data, params) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_BRANCH_REQUEST, payload: data })
    const res = await axios.post(API_DELETE_BRANCH, data)
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: DELETE_BRANCH_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: DELETE_BRANCH_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: DELETE_BRANCH_SUCCESS, payload: res.data.data })
      dispatch(getBranchAction(params))
    }
  } catch (err) {
    dispatch({ type: DELETE_BRANCH_FAILED, payload: err.message })
  }
}

const clearBranch = (data) => async (dispatch) => {
  dispatch({ type: CLEAR_BRANCH, payload: '' })
}

export { clearBranch, createBranchAction, deleteBranchAction, getBranchAction, updateBranchAction }
