import { formatDateWithPadding } from 'commons/utilities'
import { EMAIL_REGEX, ID_REGEX } from 'constants/constant'
export const custUserValidate = (idUser, email1, email2, deadl, customerName, DT_TODAY, i18n) => {
  const lblIdUser = i18n.t('custUser.lblIdUser')
  const today = formatDateWithPadding(new Date(DT_TODAY), 'yyyy-MM-dd')
  const messages = {}
  if (!ID_REGEX.test(idUser))
    messages.idUser = i18n.t('validate.custuser.idUserInvalid', { idValue: idUser, fieldName: lblIdUser })
  if (!EMAIL_REGEX.test(email1)) messages.email1 = i18n.t('validate.custuser.email1Invalid')
  if (email2 && typeof email2 !== undefined && !EMAIL_REGEX.test(email2)) {
    messages.email2 = i18n.t('validate.custuser.email2Invalid')
  }
  if (email2 && typeof email2 !== undefined && email2 === email1) {
    messages.email1 = i18n.t('validate.custuser.email1Duplicate')
    messages.email2 = i18n.t('validate.custuser.email2Duplicate')
  }
  if (customerName === '') {
    messages.customerID = i18n.t('validate.custuser.invalidCustomerId')
  }
  if (!new Date(deadl) || !new Date(deadl).getTime()) {
    messages.deadl = ' '
  } else if (new Date(deadl) < new Date(today)) {
    messages.deadl = i18n.t('validate.custuser.invalidDealdate')
  }
  return messages
}
