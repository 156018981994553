import './DownloadStatusDetail.css'

import { getDownloadStatusDetailAction } from 'actions/downloadStatusDetailAction'
import { checkValidDate, formatDateWithPadding, getSessionInfo } from 'commons/utilities'
import { MessageErrorDialog, ModalConfirm, ProgressDialog } from 'components/commons'
import TableCommon from 'components/commons/Table/TableCommon'
import ToolBar from 'components/commons/ToolBar/ToolBar'
import {
  GET_DOWNLOAD_STATUS_DETAIL_LIST_FAILED,
  GET_DOWNLOAD_STATUS_DETAIL_LIST_REQUEST,
  GET_DOWNLOAD_STATUS_DETAIL_LIST_SUCCESS,
} from 'constants/actionTypes/downloadStatusDetailActionTypes'
import { SPECIAL_CELL_TYPE, TOOLBAR_TYPE } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

function DownloadStatusDetail() {
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })
  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [listData, setListData] = useState([])
  const [dateFilter, setDateFilter] = useState(DT_TODAY)
  const [initialDate, setInitialDate] = useState('')
  const [filterData, setFilterData] = useState([])
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState()
  const [recordDelete, setRecordDelete] = useState()
  const listDownloadStatusDetailReducer = useSelector((state) => state.listDownloadStatusDetail || {})
  const [isValidDate, setIsValidDate] = useState(true)
  const [isInDateRange, setIsInDateRange] = useState(true)
  const [customer, setCustomer] = useState({})
  const [branch, setBranch] = useState({})
  const [folderName, setFolderName] = useState('')
  const [undownloaded, setUndownloaded] = useState('')

  const [kySRC, setKySRC] = useState('')
  const handleFilterDate = React.useCallback(
    (dateFilter) => {
      if (isValidDate && isInDateRange) {
        dispatch(
          getDownloadStatusDetailAction({
            date: dateFilter,
            kySRC: kySRC,
            undownloaded: undownloaded,
          })
        )
      }
    },

    [dispatch, kySRC, undownloaded]
  )

  const handleFilterByUndownloaded = (e) => {
    if (!checkValidDate(dateFilter)) {
      setDateFilter(initialDate)
    }
    dispatch(
      getDownloadStatusDetailAction({
        date: checkValidDate(dateFilter) ? dateFilter : initialDate,
        kySRC: kySRC,
        undownloaded: e,
      })
    )
  }

  useEffect(
    () => {
      const { date, branch, customer, NM_DISP_FLD, undownloaded, KY_SRC } = location?.state || {}
      const params = {
        date: DT_TODAY,
        kySRC: KY_SRC,
        undownloaded: undownloaded,
      }

      if (undownloaded) setUndownloaded(undownloaded)
      if (KY_SRC) setKySRC(KY_SRC)
      if (customer) setCustomer(customer)
      if (branch) setBranch(branch)
      if (NM_DISP_FLD) setFolderName(NM_DISP_FLD)
      if (date !== '') {
        setDateFilter(date)
        setInitialDate(date)
        dispatch(
          getDownloadStatusDetailAction({
            date: date,
            kySRC: KY_SRC,
            undownloaded: undownloaded,
          })
        )
      } else {
        setDateFilter(DT_TODAY)
        dispatch(getDownloadStatusDetailAction(params))
      }
    },
    [dispatch],
    [location]
  )

  useEffect(() => {
    if (listData) {
      setFilterData(listData)
    }
  }, [listData])

  useEffect(() => {
    if (listDownloadStatusDetailReducer?.listDownloadStatusDetail) {
      const fileList = listDownloadStatusDetailReducer?.listDownloadStatusDetail?.FILE_LIST
      setListData(fileList)
    }

    setLoading(listDownloadStatusDetailReducer.loading)
    switch (listDownloadStatusDetailReducer.type) {
      case GET_DOWNLOAD_STATUS_DETAIL_LIST_REQUEST:
        break
      case GET_DOWNLOAD_STATUS_DETAIL_LIST_FAILED:
        if (!getSessionInfo()) {
          setMessageError(listDownloadStatusDetailReducer.message)
        }
        break
      case GET_DOWNLOAD_STATUS_DETAIL_LIST_SUCCESS:
        break
      default:
        return
    }
  }, [listDownloadStatusDetailReducer])

  const listColumnWidth = ['15%', '10%', '40%', '20%', '10%', '10%']
  const listCellHeader = [
    { columnName: 'downloadStatusDetail.lblSendingDate', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'downloadStatusDetail.lblSendingTime', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'downloadStatusDetail.lblSendingDataName', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'downloadStatusDetail.lblDownloadDateTime', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'downloadStatusDetail.lblWarningTime', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'downloadStatusDetail.lblEmailNotiTime', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
  ]

  const listCellBody = [
    { keyValue: 'DT_REGIST', cellStyle: 'data', gridStyle: 'centerAlignHq' },
    { keyValue: 'TM_REGIST', cellStyle: 'data', gridStyle: 'centerAlignHq' },
    { keyValue: 'NM_FILE_DSP', cellStyle: 'data', gridStyle: 'centerAlignHq' },
    { keyValue: 'DT_DL', cellStyle: 'data', gridStyle: 'centerAlignHq' },
    { specialType: SPECIAL_CELL_TYPE.DT_DL_MAIL },
    { specialType: SPECIAL_CELL_TYPE.DT_SEND_MAIL },
  ]

  const listToolBarComponent = [
    {
      type: TOOLBAR_TYPE.TURN_BACK,
      commonStyle: 'turnBackIconDownloadStatusContainer',
      componentStyle: 'icon-turnback',
    },
    { type: TOOLBAR_TYPE.FILTER_LOGO_ONLY, commonStyle: 'filterIconOnlyContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.CHECKBOX, commonStyle: 'checkboxContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.SENDING_DATE, commonStyle: 'expireDateContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.CALENDAR, commonStyle: 'filterContainer', componentStyle: 'calendar-item' },
    { type: TOOLBAR_TYPE.CSV, commonStyle: '', componentStyle: '' },
  ]

  let setDownloadParams = {
    screenName: 'download-status-detail',
    date: dateFilter,
    kySRC: kySRC,
    undownloaded: undownloaded,
  }

  const handleGoBack = () => {
    history.push({
      pathname: '/admin/download',
      state: {
        date: checkValidDate(dateFilter) ? dateFilter : initialDate,
        undownloaded: Number(undownloaded),
        filterItem: location.state.filterItem,
        screen: location.state?.screen,
      },
    })
  }

  const sortListData = (listData) => {
    let sortedList = [...listData]
    return sortedList.sort(
      (a, b) => b?.DT_REGIST.localeCompare(a?.DT_REGIST) || b?.TM_REGIST.localeCompare(a?.TM_REGIST)
    )
  }

  const toolbar = (
    <ToolBar
      listToolBarComponent={listToolBarComponent}
      handleGoBack={handleGoBack}
      handleFilterDate={handleFilterDate}
      setSelectedDate={setDateFilter}
      selectedDate={dateFilter}
      listData={listData}
      downloadParams={setDownloadParams}
      setIsValidDate={setIsValidDate}
      setIsInDateRange={setIsInDateRange}
      filterLabel={t('downloadStatus.lblIdFolder')}
      toolbarNoHeight={true}
      toolbarNoHeightPaddingBottom={true}
      toolbarWidth={'SMALL'}
      undownloaded={undownloaded}
      setUndownloaded={setUndownloaded}
      handleFilterByUndownloaded={handleFilterByUndownloaded}
    />
  )
  const msgDeleteConfirm = recordDelete ? t('headquarter.msgDeleteConfirm', { idUserValue: recordDelete.ID_USER }) : ''
  return (
    <div className="hquser__wrapper">
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {loading && <ProgressDialog label={t('headquarter.loading')} />}
      <ModalConfirm title={msgDeleteConfirm} showModal={Boolean(recordDelete)} setShowModal={setRecordDelete} />
      {loading ? (
        <ProgressDialog label={t('headquarter.loading')} />
      ) : (
        <div className="hquser__container maintain-container">
          {/* Table */}
          <TableCommon
            tableWidth={'SMALL'}
            listColumnWidth={listColumnWidth}
            listCellHeader={listCellHeader}
            listCellBody={listCellBody}
            listDataRender={sortListData(filterData)}
            hasFirstCell={false}
            hasHeaderLine={true}
            toolbar={toolbar}
            hasRecordCounter={true}
            hasMiddleItem={true}
            middleItem={t('downloadStatusDetail.folderName', {
              folderName: folderName,
            })}
            hasStartLineItem={true}
            startLineItem={t('downloadStatusDetail.customerInfo', {
              cdCust: customer.NM_CUST,
              nmCust: branch.NM_BRANCH,
            })}
          />
        </div>
      )}
    </div>
  )
}

export default DownloadStatusDetail
