import { logout } from 'actions/userActions'
import { getTitleModalConfirm } from 'commons/utilities'
import { MessageErrorDialog } from 'components/commons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Admin from 'views/Admin/Admin'
import ForgotPassword from 'views/ForgotPassword/ForgotPassword'
import Login from 'views/Login/Login'

export default function App() {
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo
    return null
  })
  const [isLogout, setIsLogout] = useState(false)
  const { t } = useTranslation()

  React.useEffect(() => {
    window.addEventListener('beforeunload', function (event) {
      sessionStorage.removeItem('__lock')
    })

    window.addEventListener('load', function (e) {
      if (!window.sessionStorage.__lock) {
        sessionStorage.setItem('__lock', '1')
      }
      return null
    })
    if (sessionStorage.getItem('__lock')) {
      sessionStorage.clear()
      dispatch(logout())
      console.warn('The storage was cleared.')
    }
    sessionStorage.setItem('__lock', '1')
  }, [dispatch])

  React.useEffect(() => {
    if (userInfo && userInfo.showConfirmLogout) return setIsLogout(true)
  }, [userInfo])

  return (
    <BrowserRouter>
      {isLogout && (
        <MessageErrorDialog
          message={getTitleModalConfirm(
            t('commonComponent.sessionExpired.line1'),
            t('commonComponent.sessionExpired.line2')
          )}
          showModal={isLogout}
          showMessageErrorDialog={() => {
            dispatch(logout())
            setIsLogout(false)
          }}
        />
      )}
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/admin" render={(props) => <Admin {...props} />} />
        <Redirect from="/" to="/login" />
      </Switch>
    </BrowserRouter>
  )
}
