import {
  CLEAR_BRANCH,
  CREATE_BRANCH_FAILED,
  CREATE_BRANCH_REQUEST,
  CREATE_BRANCH_SUCCESS,
  DELETE_BRANCH_FAILED,
  DELETE_BRANCH_REQUEST,
  DELETE_BRANCH_SUCCESS,
  GET_BRANCH_LIST_FAILED,
  GET_BRANCH_LIST_REQUEST,
  GET_BRANCH_LIST_SUCCESS,
  UPDATE_BRANCH_FAILED,
  UPDATE_BRANCH_REQUEST,
  UPDATE_BRANCH_SUCCESS,
} from 'constants/actionTypes/branchActionTypes'

const listBranch = (state = { listBranch: [] }, action) => {
  switch (action.type) {
    case GET_BRANCH_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_BRANCH_LIST_REQUEST,
      }
    case GET_BRANCH_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        listBranch: action.payload,
        type: GET_BRANCH_LIST_SUCCESS,
      }
    case GET_BRANCH_LIST_FAILED:
      return {
        ...state,
        loading: false,
        listBranch: [],
        message: action.payload,
        type: GET_BRANCH_LIST_FAILED,
      }
    default:
      return state
  }
}

const createBranch = (state = { branch: {} }, action) => {
  switch (action.type) {
    case CREATE_BRANCH_REQUEST:
      return {
        ...state,
        loading: true,
        branch: action.payload,
        type: CREATE_BRANCH_REQUEST,
      }
    case CREATE_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        branch: { ...action.payload },
        type: CREATE_BRANCH_SUCCESS,
        message: 'success',
      }
    case CREATE_BRANCH_FAILED:
      return {
        ...state,
        loading: false,
        branch: {},
        message: action.payload,
        type: CREATE_BRANCH_FAILED,
      }
    case CLEAR_BRANCH:
      return { type: CLEAR_BRANCH, loading: false, status: null, branch: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, branch: {}, message: '' }
  }
}

const updateBranch = (state = { branch: {} }, action) => {
  switch (action.type) {
    case UPDATE_BRANCH_REQUEST:
      return {
        ...state,
        loading: true,
        branch: action.payload,
        type: UPDATE_BRANCH_REQUEST,
      }
    case UPDATE_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        branch: { ...action.payload },
        type: UPDATE_BRANCH_SUCCESS,
        message: 'success',
      }
    case UPDATE_BRANCH_FAILED:
      return {
        ...state,
        loading: false,
        branch: {},
        message: action.payload,
        type: UPDATE_BRANCH_FAILED,
      }
    case CLEAR_BRANCH:
      return { type: CLEAR_BRANCH, loading: false, status: null, branch: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, branch: {}, message: '' }
  }
}

const deleteBranch = (state = { branch: {} }, action) => {
  switch (action.type) {
    case DELETE_BRANCH_REQUEST:
      return {
        ...state,
        loading: true,
        branch: action.payload,
        type: DELETE_BRANCH_REQUEST,
      }
    case DELETE_BRANCH_SUCCESS:
      return {
        ...state,
        loading: false,
        branch: { ...action.payload },
        type: DELETE_BRANCH_SUCCESS,
        message: 'success',
      }
    case DELETE_BRANCH_FAILED:
      return {
        ...state,
        loading: false,
        branch: {},
        message: action.payload,
        type: DELETE_BRANCH_FAILED,
      }
    case CLEAR_BRANCH:
      return { type: CLEAR_BRANCH, loading: false, status: null, branch: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, branch: {}, message: '' }
  }
}

export { createBranch, deleteBranch, listBranch, updateBranch }
