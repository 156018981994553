export const GET_HEADQUARTERS_LIST_REQUEST = 'GET_HEADQUARTERS_LIST_REQUEST'
export const GET_HEADQUARTERS_LIST_SUCCESS = 'GET_HEADQUARTERS_LIST_SUCCESS'
export const GET_HEADQUARTERS_LIST_FAILED = 'GET_HEADQUARTERS_LIST_FAILED'
export const CREATE_HEADQUARTER_REQUEST = 'CREATE_HEADQUARTER_REQUEST'
export const CREATE_HEADQUARTER_FAILED = 'CREATE_HEADQUARTER_FAILED'
export const CREATE_HEADQUARTER_SUCCESS = 'CREATE_HEADQUARTER_SUCCESS'
export const UPDATE_HEADQUARTER_REQUEST = 'UPDATE_HEADQUARTER_REQUEST'
export const UPDATE_HEADQUARTER_FAILED = 'UPDATE_HEADQUARTER_FAILED'
export const UPDATE_HEADQUARTER_SUCCESS = 'UPDATE_HEADQUARTER_SUCCESS'
export const DELETE_HEADQUARTER_REQUEST = 'DELETE_HEADQUARTER_REQUEST'
export const DELETE_HEADQUARTER_FAILED = 'DELETE_HEADQUARTER_FAILED'
export const DELETE_HEADQUARTER_SUCCESS = 'DELETE_HEADQUARTER_SUCCESS'
export const CLEAR_HEADQUARTER = 'CLEAR_HEADQUARTER'
