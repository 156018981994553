import '../../components/commons/Table/TableCommon.css'

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles'
// import { CheckSharp } from '@material-ui/icons'
import {
  formatNumberData,
  formatString,
  separateNumberByThousand,
  // removeExcessCharacters,
  // showDot,
} from 'commons/utilities'
import { Image } from 'components/commons'
import {
  ACTION_FORM,
  // CELL_TYPE_DATA_TRANSFER,
  DATE_TYPE,
  SORT_TYPE,
  SPECIAL_CELL_TYPE,
  TABLE_WIDTH,
  // UNIT,
} from 'constants/constant'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { IMAGES } from '../../assets'
// import email from '../../assets/img/envelope-regular.svg'
// import attachIcon from '../../assets/img/paperclip-solid.svg'
import sortedDown from '../../assets/img/sort-down-solid.svg'
import sortedUp from '../../assets/img/sort-up-solid.svg'
import styles from '../../components/commons/Table/TableCommonStyle'

function TableDeliveryDataDetail({
  listColumnWidth = [],
  listCellHeader = [],
  listCellBody = [],
  listFolderName = [],
  listDataRender = [],
  handleUpdate,
  handleDelete,
  canDownloadFile = false,
  canUpdate = true,
  canDelete = true,
  hasFirstCell = true,
  hasEndCell = false,
  sortedByStartDate,
  sortedByEndDate,
  startDateSortedType,
  endDateSortedType,
  idProperty = '',
  tableWidth,
  toolbar,
  hasHeaderLine = false,
  hasRecordCounter = false,
  hasStartLineItem = false,
  handleNavigate,
  customer,
  branch,
  selectedDate,
  canViewDetail = false,
  handleRedirect,
  paddingLastCell = '',
  totalTableWidth,
}) {
  const { t } = useTranslation()
  const classes = styles(listColumnWidth)

  const SortedByStartDateIcon = () => <img src={sortedUp} alt="Sorted Up Icon" className="date-icon" />
  const SortedByEndDateIcon = () => <img src={sortedUp} alt="Sorted Up Icon" className="date-icon" />
  const StartDateReverseIcon = () => <img src={sortedDown} alt="Sorted Up Icon" className="date-icon" />
  const EndDateReverseIcon = () => <img src={sortedDown} alt="Sorted Up Icon" className="date-icon" />

  const renderCellHeader = (cellHeader) => {
    if (typeof cellHeader !== 'undefined') {
      const headerName = t(cellHeader.columnName)
      if (headerName.length > 10) {
        let substring = headerName.substring(0, 10).concat('...')
        return <div className={`${classes.overFlowText} ${classes.textCenter}`}>{substring}</div>
      }
    }
    return <div className={`${classes.overFlowText} ${classes.textCenter}`}>{t(cellHeader.columnName)}</div>
  }
  const sortListDataRender = (listDataRender) => {
    let sortedList = listDataRender
    if (sortedList?.length > 0) {
      sortedList.sort((firstItem, secondItem) =>
        // secondItem?.NM_FILE_DSP.localeCompare(firstItem?.NM_FILE_DSP, 'jp', { ignorePunctuation: true })
        {
          let firstItemDate = new Date(firstItem?.DT_REGIST)
          let secondItemDate = new Date(secondItem?.DT_REGIST)
          return firstItemDate - secondItemDate
        }
      )
    }
    return sortedList
  }

  /**
   * Get single cell in header of table
   * @return {JSX}
   */
  const getCellHeader = (cellHeader = {}, index = 0) => {
    let component = (
      <TableCell
        className={`${classes[cellHeader.commonStyle]} ${classes[cellHeader.cellStyle]}`}
        style={{
          textAlign: index === listFolderName.length ? '' : 'center',
          justifyContent: index === listFolderName.length ? '' : 'center',
          width: listColumnWidth[index],
        }}
        key={index}
      >
        <div className={`${classes.cellHeaderContainer} `}>{renderCellHeader(cellHeader)}</div>
      </TableCell>
    )
    if (cellHeader.hasOwnProperty('specialType')) {
      switch (cellHeader.specialType) {
        /* SORTED START */
        case SPECIAL_CELL_TYPE.SORTED_START:
          component = (
            <TableCell className={`${classes.titleHead}  ${classes.dateTitle}`} onClick={sortedByStartDate}>
              <div className="startDate-container">
                {t('maintenance.lblStartDate')}
                <div className="sortedIcon-container">
                  {startDateSortedType === DATE_TYPE.DEFAULT ? (
                    <>
                      <SortedByStartDateIcon />
                      <StartDateReverseIcon />
                    </>
                  ) : startDateSortedType === SORT_TYPE.INCREASE ? (
                    <SortedByStartDateIcon />
                  ) : (
                    <StartDateReverseIcon />
                  )}
                </div>
              </div>
            </TableCell>
          )
          return component
        /* SORTED END */
        case SPECIAL_CELL_TYPE.SORTED_END:
          component = (
            <TableCell className={`${classes.titleHead}  ${classes.dateTitle}`} onClick={sortedByEndDate}>
              <div className="startDate-container">
                {t('maintenance.lblEndDate')}
                <div className="sortedIcon-container">
                  {endDateSortedType === DATE_TYPE.DEFAULT ? (
                    <>
                      <SortedByEndDateIcon />
                      <EndDateReverseIcon />
                    </>
                  ) : endDateSortedType === SORT_TYPE.INCREASE ? (
                    <SortedByEndDateIcon />
                  ) : (
                    <EndDateReverseIcon />
                  )}
                </div>
              </div>
            </TableCell>
          )
          return component
        default:
          return <></>
      }
    }
    return component
  }

  const renderBranchFolderCount = (itemFolderName, index, data) => {
    const dataDisplayFolder = data.DISP_FLD.find((x) => x.NM_DISP_FLD === itemFolderName)
    return (
      <TableCell
        className={` ${classes.mailContainer} ${
          listFolderName.length === 1 ? classes.dataOneFolderNameDelivery : classes.dataDeliveryDetail
        }`}
        key={index}
      >
        <Grid className={`${classes.centerAlignHq}`} style={{ height: 0 }}>
          <div
            style={{
              textAlign: 'center',
              justifyContent: index !== listFolderName.length - 1 ? 'center' : '',
              width: listColumnWidth[index],
              // paddingLeft: index !== listFolderName.length - 1 ? '' : paddingLastCell,
            }}
          >
            <div>
              {dataDisplayFolder && dataDisplayFolder.NU_DL_COUNT > 0
                ? formatNumberData(dataDisplayFolder.NU_DL_COUNT)
                : ''}
            </div>
          </div>
        </Grid>
      </TableCell>
    )
  }

  const calculateTotalFolderCountForOneTable = () => {
    let sum = 0
    for (let i = 0; i < listDataRender.length; i++) {
      let folders = listDataRender[i].DISP_FLD
      for (let j = 0; j < folders.length; j++) {
        sum += folders[j].count
      }
    }
    return sum
  }
  return (
    <div className="table-container">
      <Grid item md={12}>
        <TableContainer>
          <div className="table-scroll">
            {/* Toolbar */}
            {toolbar}

            <div
              className={tableWidth === 'SMALL' ? classes.smallWidthTable : classes.fullWidthTable}
              style={totalTableWidth && totalTableWidth > TABLE_WIDTH ? { width: totalTableWidth + 'px' } : {}}
            >
              {hasStartLineItem && (
                <span className={classes.startLineItem}>
                  {formatString(
                    t('deliveryDataDetail.customerBranchInfo', {
                      nmCust: customer?.NM_CUST,
                      nmBranch: branch?.NM_BRANCH,
                    }),
                    25
                  )}
                </span>
              )}
              {hasRecordCounter && (
                <span
                  className={tableWidth === 'SMALL' ? classes.recordCounterSmallWidth : classes.recordCounterFullWidth}
                >
                  {t('receptionData.counter', {
                    count: separateNumberByThousand(calculateTotalFolderCountForOneTable()),
                  })}
                </span>
              )}
              {hasHeaderLine && (
                <div className={classes.headerLine}>
                  <hr
                    className={tableWidth === 'SMALL' ? classes.smallWidthHeaderLine : classes.fullWidthHeaderLine}
                    style={totalTableWidth && totalTableWidth > TABLE_WIDTH ? { width: totalTableWidth + 'px' } : {}}
                  />
                </div>
              )}
            </div>
            <Table
              aria-label="common table"
              className={tableWidth === 'SMALL' ? classes.smallWidthTable : classes.fullWidthTable}
            >
              {/* Col Group */}
              <colgroup>
                {listColumnWidth.map((col, index) => (
                  <col style={col !== '' ? { width: col } : {}} key={index}></col>
                ))}
              </colgroup>
              {/* Header */}
              {
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {/* Header Cell */}
                    {listCellHeader && listCellHeader.map((cellHeader, index) => getCellHeader(cellHeader, index))}
                  </TableRow>
                </TableHead>
              }
              {/* Body */}
              <TableBody>
                {/* Body Row */}
                {sortListDataRender(listDataRender)?.map((data, index) => {
                  return (
                    <TableRow key={index} className={index % 2 === 0 ? '' : classes.tableRow} style={{ height: 30 }}>
                      {/* Body Cell */}
                      <TableCell className={`${classes.data} ${classes.mailContainer}`}>
                        <Grid className={`${classes.centerAlignHq}`} style={{ height: 0 }}>
                          <div>{data.DT_REGIST}</div>
                        </Grid>
                      </TableCell>
                      {listFolderName.map((itemFolderName, indexFolderName) =>
                        renderBranchFolderCount(itemFolderName, indexFolderName, data)
                      )}
                      {hasEndCell && (
                        <TableCell className={classes.iconUpdate}>
                          <Grid className={classes.iconContainer}>
                            {canUpdate && (
                              <Image
                                src={IMAGES.ICON.EDIT_ICON_TABLE_HQUSER}
                                onClick={() => handleUpdate(data[`${idProperty}`], data, ACTION_FORM.UPDATE)}
                                className={classes.iconAction}
                              />
                            )}

                            {canDelete && (
                              <Image
                                src={IMAGES.ICON.DELETE_ICON_TABLE_HQUSER}
                                className={classes.iconAction}
                                onClick={() => {
                                  handleDelete(data)
                                }}
                              />
                            )}
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </Grid>
    </div>
  )
}

export default TableDeliveryDataDetail
