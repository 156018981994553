import axios from 'commons/baseUrl'
import {
  CREATE_PORTAL_FAILED,
  CREATE_PORTAL_REQUEST,
  CREATE_PORTAL_SUCCESS,
  DELETE_PORTAL_FAILED,
  DELETE_PORTAL_REQUEST,
  DELETE_PORTAL_SUCCESS,
  GET_PORTAL_LIST_FAILED,
  GET_PORTAL_LIST_REQUEST,
  GET_PORTAL_LIST_SUCCESS,
  RESET_PORTAL,
  UPDATE_PORTAL_FAILED,
  UPDATE_PORTAL_REQUEST,
  UPDATE_PORTAL_SUCCESS,
} from 'constants/actionTypes/portalActionTypes'
import {
  API_CREATE_PORTAL,
  API_DELETE_PORTAL,
  API_GET_PORTALS_DETAIL,
  API_GET_PORTALS_MASTER,
  API_UPDATE_PORTAL,
} from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const getPortalsAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_PORTAL_LIST_REQUEST })
    const res = await axios.get(API_GET_PORTALS_DETAIL, { params })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_PORTAL_LIST_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data.data.length >= 0) {
      dispatch({ type: GET_PORTAL_LIST_SUCCESS, payload: res.data })
    }
  } catch (err) {
    dispatch({ type: GET_PORTAL_LIST_FAILED, payload: err.message })
  }
}

const getPortalsMasterAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_PORTAL_LIST_REQUEST })
    const res = await axios.get(API_GET_PORTALS_MASTER, { params })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_PORTAL_LIST_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data.data.length >= 0) {
      dispatch({ type: GET_PORTAL_LIST_SUCCESS, payload: res.data })
    }
  } catch (err) {
    dispatch({ type: GET_PORTAL_LIST_FAILED, payload: err.message })
  }
}

const createPortalAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_PORTAL_REQUEST, payload: data })
    const res = await axios.post(API_CREATE_PORTAL, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: CREATE_PORTAL_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: CREATE_PORTAL_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: CREATE_PORTAL_FAILED, payload: err.message })
  }
}

const updatePortalAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PORTAL_REQUEST, payload: data })
    const res = await axios.post(API_UPDATE_PORTAL, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: UPDATE_PORTAL_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: UPDATE_PORTAL_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: UPDATE_PORTAL_FAILED, payload: err.message })
  }
}

const deletePortalAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PORTAL_REQUEST, payload: data })
    const res = await axios.post(API_DELETE_PORTAL, data)
    if (!res.data || res.data.STS === RESPONSE_TYPE.DATA_EXISTED || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: DELETE_PORTAL_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: DELETE_PORTAL_SUCCESS, payload: res.data })
    }
  } catch (err) {
    dispatch({ type: DELETE_PORTAL_FAILED, payload: err.message })
  }
}

const resetPortal = () => (dispatch) => {
  dispatch({ type: RESET_PORTAL, payload: {} })
}

export {
  createPortalAction,
  deletePortalAction,
  getPortalsAction,
  getPortalsMasterAction,
  resetPortal,
  updatePortalAction,
}
