import axios from 'commons/baseUrl'
import { getTitleModalConfirm } from 'commons/utilities'
import {
  CLEAR_HEADQUARTER,
  CREATE_HEADQUARTER_FAILED,
  CREATE_HEADQUARTER_REQUEST,
  CREATE_HEADQUARTER_SUCCESS,
  DELETE_HEADQUARTER_FAILED,
  DELETE_HEADQUARTER_REQUEST,
  DELETE_HEADQUARTER_SUCCESS,
  GET_HEADQUARTERS_LIST_FAILED,
  GET_HEADQUARTERS_LIST_REQUEST,
  GET_HEADQUARTERS_LIST_SUCCESS,
  UPDATE_HEADQUARTER_FAILED,
  UPDATE_HEADQUARTER_REQUEST,
  UPDATE_HEADQUARTER_SUCCESS,
} from 'constants/actionTypes/headQuartersActionTypes'
import {
  API_CREATE_HEADQUARTER,
  API_DELETE_HEADQUARTER,
  API_GET_HEADEQUARTER,
  API_UPDATE_HEADQUARTER,
} from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const getHeadQuartersAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_HEADQUARTERS_LIST_REQUEST })
    const res = await axios.get(API_GET_HEADEQUARTER, { params })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_HEADQUARTERS_LIST_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data.data.length >= 0) {
      dispatch({ type: GET_HEADQUARTERS_LIST_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: GET_HEADQUARTERS_LIST_FAILED, payload: null })
  }
}
const createHquserAction = (data, i18n) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_HEADQUARTER_REQUEST, payload: data })
    const res = await axios.post(API_CREATE_HEADQUARTER, { data })
    if (!res?.data || res?.data?.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: CREATE_HEADQUARTER_FAILED, payload: res?.data?.message })
      throw new Error()
    }
    if (res?.data?.STS === RESPONSE_TYPE.DATA_EXISTED) {
      dispatch({
        type: CREATE_HEADQUARTER_FAILED,
        // payload: i18n.t('validate.headquarter.idUserInvalid', {
        //   fieldName: i18n.t('headquarter.lblIdUser'),
        //   idUserValue: data?.ID_USER,
        // }),

        payload: getTitleModalConfirm(
          i18n.t('validate.headquarter.idUserInvalid.line1', {
            fieldName: i18n.t('headquarter.lblIdUser'),
            idUserValue: data?.ID_USER,
          }),
          i18n.t('validate.headquarter.idUserInvalid.line2')
        ),
      })
    }
    if (res?.data?.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: CREATE_HEADQUARTER_FAILED, payload: res?.data?.message })
    }
    if (res?.data?.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: CREATE_HEADQUARTER_SUCCESS, payload: res?.data?.data })
    }
  } catch (err) {
    dispatch({ type: CREATE_HEADQUARTER_FAILED, payload: err.message })
  }
}

const updateHquserAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_HEADQUARTER_REQUEST, payload: data })
    const res = await axios.post(API_UPDATE_HEADQUARTER, { data })
    if (!res?.data || res?.data?.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: UPDATE_HEADQUARTER_FAILED, payload: res?.data?.message })
      throw new Error()
    }
    if (res?.data?.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: UPDATE_HEADQUARTER_FAILED, payload: res?.data?.message })
    }
    if (res?.data?.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: UPDATE_HEADQUARTER_SUCCESS, payload: res?.data?.data })
    }
  } catch (err) {
    dispatch({ type: UPDATE_HEADQUARTER_FAILED, payload: err.message })
  }
}

const deleteHquserAction = (data, params) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_HEADQUARTER_REQUEST, payload: data })
    const res = await axios.post(API_DELETE_HEADQUARTER, data)
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: DELETE_HEADQUARTER_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: DELETE_HEADQUARTER_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: DELETE_HEADQUARTER_SUCCESS, payload: res.data.data })
      dispatch(getHeadQuartersAction(params))
    }
  } catch (err) {
    dispatch({ type: DELETE_HEADQUARTER_SUCCESS, payload: err.message })
  }
}

const clearHquer = (data) => async (dispatch) => {
  dispatch({ type: CLEAR_HEADQUARTER, payload: '' })
}

export { clearHquer, createHquserAction, deleteHquserAction, getHeadQuartersAction, updateHquserAction }
