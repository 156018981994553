import {
  AUTH_ALERT,
  GET_USER_DETAIL_FAIL,
  GET_USER_DETAIL_REQUEST,
  GET_USER_DETAIL_SUCCESS,
  USER_LOGIN_FAILED,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from 'constants/actionTypes/userActionTypes'

function userLoginReducer(state = { userInfo: {} }, action) {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        showConfirmLogout: false,
        type: USER_LOGIN_REQUEST,
      }
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        loading: false,
        showConfirmLogout: false,
        isLogined: true,
        type: USER_LOGIN_SUCCESS,
      }
    case USER_LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        showConfirmLogout: false,
        isLogined: true,
        type: USER_LOGIN_FAILED,
      }
    case AUTH_ALERT:
      return {
        ...state,
        showConfirmLogout: true,
        isLogined: false,
        type: AUTH_ALERT,
      }

    // reset state in case 'USER_LOGOUT'
    case USER_LOGOUT:
      return {
        loading: false,
        userInfo: {},
        type: USER_LOGOUT,
        isLogined: false,
        showConfirmLogout: false,
      }
    default:
      return state
  }
}

const getUserDetailReducer = (state = { userInfo: {} }, action) => {
  switch (action.type) {
    case GET_USER_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_USER_DETAIL_REQUEST,
      }
    case GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        type: GET_USER_DETAIL_SUCCESS,
      }
    case GET_USER_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        userInfo: {},
        message: action.payload,
        type: GET_USER_DETAIL_FAIL,
      }
    default:
      return state
  }
}

export { getUserDetailReducer, userLoginReducer }
