import axios from 'commons/baseUrl'
import {
  CLEAR_DOWNLOAD_STATUS_DETAIL,
  GET_DOWNLOAD_STATUS_DETAIL_LIST_FAILED,
  GET_DOWNLOAD_STATUS_DETAIL_LIST_REQUEST,
  GET_DOWNLOAD_STATUS_DETAIL_LIST_SUCCESS,
} from 'constants/actionTypes/downloadStatusDetailActionTypes'
import { API_GET_DOWNLOAD_STATUS_DETAIL } from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const getDownloadStatusDetailAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_DOWNLOAD_STATUS_DETAIL_LIST_REQUEST })
    const res = await axios.get(API_GET_DOWNLOAD_STATUS_DETAIL, { params })
    // const res = {
    //   data: {
    //     data: [
    //       {
    //         SENDING_DATE: '2021-12-01',
    //         SENDING_TIME: '15:00:00',
    //         SENDING_DATA_NAME: 'xxxxxx',
    //         DOWNLOAD_DATE_TIME: '',
    //         WARNING_TIME: '16:00:00',
    //         EMAIL_NOTI_TIME: '16:00:00',
    //       },
    //       {
    //         SENDING_DATE: '2021-11-01',
    //         SENDING_TIME: '15:00:00',
    //         SENDING_DATA_NAME: 'xxxxxx',
    //         DOWNLOAD_DATE_TIME: '2021-11-01 17:15:00',
    //         WARNING_TIME: '16:00:00',
    //         EMAIL_NOTI_TIME: '16:00:00',
    //       },
    //       {
    //         SENDING_DATE: '2021-10-01',
    //         SENDING_TIME: '15:00:00',
    //         SENDING_DATA_NAME: 'xxxxxx',
    //         DOWNLOAD_DATE_TIME: '2021-10-01 17:15:00',
    //         WARNING_TIME: '16:00:00',
    //         EMAIL_NOTI_TIME: '',
    //       },
    //     ],
    //     STS: 0,
    //     error: false,
    //     message: 'success',
    //   },
    // }
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_DOWNLOAD_STATUS_DETAIL_LIST_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data) {
      dispatch({ type: GET_DOWNLOAD_STATUS_DETAIL_LIST_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: GET_DOWNLOAD_STATUS_DETAIL_LIST_FAILED, payload: err.message })
  }
}

const clearDownloadStatusDetail = (data) => async (dispatch) => {
  dispatch({ type: CLEAR_DOWNLOAD_STATUS_DETAIL, payload: '' })
}

export { clearDownloadStatusDetail, getDownloadStatusDetailAction }
