import './Maintenance.css'

import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { deletePortalAction, getPortalsAction, getPortalsMasterAction } from 'actions/portalActions'
import {
  dataSearchResult,
  formatDateWithPadding,
  getSessionInfo,
  getUserKbAdmin,
  removeExcessCharacters,
} from 'commons/utilities'
import {
  MessageErrorDialog,
  ModalConfirm,
  ProgressDialog,
  SearchCalendar,
  // SearchFormInput,
  // SelectInput,
} from 'components/commons'
import TableCommon from 'components/commons/Table/TableCommon'
import ToolBar from 'components/commons/ToolBar/ToolBar'
import {
  DELETE_PORTAL_FAILED,
  DELETE_PORTAL_REQUEST,
  DELETE_PORTAL_SUCCESS,
  GET_PORTAL_LIST_FAILED,
  GET_PORTAL_LIST_REQUEST,
  GET_PORTAL_LIST_SUCCESS,
} from 'constants/actionTypes/portalActionTypes'
import {
  DATE_TYPE,
  FILTER_ITEMS,
  FLAG_BOOLEAN,
  PORTAL_ACTIONS,
  PORTAL_TYPES,
  RESPONSE_TYPE,
  SCREEN_NAME,
  SORT_TYPE,
  SPECIAL_CELL_TYPE,
  TOOLBAR_TYPE,
} from 'constants/constant'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import filterIcon from '../../assets/img/filter-solid.svg'
// import arrowRight from '../../assets/img/play-solid.svg'
// import createIcon from '../../assets/img/plus-solid.svg'
import returnIcon from '../../assets/img/times-solid.svg'
import Portal from '../Portal/Portal'
import { styles } from './MaintenanceStyles'

function Maintenance() {
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return null
  })

  const [kbAdmin, setKbAdmin] = useState('')
  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const { t } = useTranslation()
  const classes = styles()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const listPortalState = useSelector((state) => state.listPortals)
  const deletePortalState = useSelector((state) => state.deletePortal)
  const { listPortals } = listPortalState
  const { portal: deletePortal } = deletePortalState
  const [showModal, setShowModal] = useState(false)
  const [isOpenModalConfirm, setOpenModalConfirm] = useState(false)
  const [listData, setListData] = useState([])
  const [filterItem, setFilterItem] = useState(FILTER_ITEMS[1])
  const [searchInput, setSearchInput] = useState('')
  // const [searchData, setSearchData] = useState([])
  const [filterData, setFilterData] = useState([])
  const [selectedDate, setSelectedDate] = useState(DT_TODAY)
  const CheckSortState = (type) => {
    let result

    if (history && history.location && history.location.state) {
      if (history.location.state[type] === 'INCREASE') result = false
      else result = true
    } else {
      result = true
    }
    return result
  }
  const [startDateDecreaseSorted, setStartDateDecreaseSorted] = useState(
    // history && history.location && history.location.state ? history.location.state['sortDateStart'] : true

    CheckSortState('sortDateStart')
  )
  const [endDateDecreaseSorted, setEndDateDecreaseSorted] = useState(CheckSortState('sortDateEnd'))
  const [startDateSortedType, setStartDateSortedType] = useState(
    history && history.location && history.location.state && history.location.state.hasOwnProperty('sortDateStart')
      ? history.location.state['sortDateStart']
      : DATE_TYPE.DEFAULT
  )
  const [endDateSortedType, setEndDateSortedType] = useState(
    history && history.location && history.location.state && history.location.state.hasOwnProperty('sortDateEnd')
      ? history.location.state['sortDateEnd']
      : DATE_TYPE.DEFAULT
  )
  const [modalSelectedDate, setModalSelectedDate] = useState(formatDateWithPadding(new Date(), 'yyyy-MM-dd'))
  const [onDateModalSelect, setOnDateModalSelect] = useState(false)
  const [portalDelete, setPortalDelete] = useState({})
  const [deletePopUp, setDeletePopUp] = useState(false)
  const [deleteMessageType, setDeleteMessageType] = useState('')
  const [isDelete, setIsDelete] = useState(false)
  // const [isSearchInputEmpty, setIsSearchInputEmpty] = useState(true)
  const [dataSorted, setDataSorted] = useState([])
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState()
  const [filterDateParams, setFilterDateParams] = useState({
    dateType: '',
    date: DT_TODAY,
  })
  const [isValidDate, setIsValidDate] = useState(true)
  const [isInDateRange, setIsInDateRange] = useState(true)

  const [deletePermissionMessageError, setDeletePermissionMessageError] = useState('')
  const [dateCentral, setDateCentral] = useState(null)
  const FilterIcon = () => <img src={filterIcon} alt="Filter Icon" className={classes.iconFilter} />
  const ReturnIcon = () => (
    <img src={returnIcon} alt="Return Icon" className={classes.iconReturn} onClick={handleReturnOnclick} />
  )

  const firstRender = useRef(true)

  // const ArrowRight = () => (
  //   <img
  //     src={arrowRight}
  //     alt="ArrowRight Icon"
  //     className={classes.iconRedirect}
  //     onClick={() => {
  //       setShowModal(true)
  //     }}
  //   />
  // )

  // const CreateIcon = () => (
  //   <img src={createIcon} alt="Create Icon" className={classes.iconRedirect} onClick={handleCreateOnclick} />
  // )

  const handleFilterDate = React.useCallback(
    (date) => {
      if (isValidDate && isInDateRange) {
        if (onDateModalSelect && date) {
          let params = {
            date: date,
          }
          dispatch(getPortalsAction(params))
          return
        }
        let params = {
          dateType: filterItem === FILTER_ITEMS[0] ? DATE_TYPE.START : DATE_TYPE.END,
          date: date,
        }
        setFilterDateParams(params)
        setDateCentral(date)
        setModalSelectedDate(formatDateWithPadding(date, 'yyyy-MM-dd'))
        dispatch(getPortalsMasterAction(params))
      }
    },
    [dispatch, filterItem, onDateModalSelect]
  )

  /**
   * Format DT_START, DT_END to yyyy-MM-dd
   * @return {Void}
   */
  useEffect(() => {
    if (listPortals.length >= 0) {
      let dataFormat = listPortals.map((portal) => ({
        ...portal,
        DT_START: formatDateWithPadding(new Date(portal.DT_START), 'yyyy-MM-dd'),
        DT_END: formatDateWithPadding(new Date(portal.DT_END), 'yyyy-MM-dd'),
      }))
      setListData(dataFormat)
    }
  }, [listPortals])

  /**
   * Get Portal List
   * @return {Void}
   */

  window.history.replaceState({}, document.title)
  useEffect(() => {
    const { date, searchInput, dateCentral } = location?.state || {}
    if (searchInput) {
      setSearchInput(searchInput)
    }
    if (dateCentral && date && date.dateType && date.date) {
      date.dateType === DATE_TYPE.START ? setFilterItem(FILTER_ITEMS[0]) : setFilterItem(FILTER_ITEMS[1])
      let dateParams = {
        date: dateCentral,
        dateType: date.dateType,
      }
      setDateCentral(dateCentral)
      setSelectedDate(dateCentral)
      setFilterDateParams(dateParams)
      dispatch(getPortalsMasterAction(dateParams))
      return
    }
    if (date && date.dateType && date.date) {
      date.dateType === DATE_TYPE.START ? setFilterItem(FILTER_ITEMS[0]) : setFilterItem(FILTER_ITEMS[1])
      setSelectedDate(date.date)
      setFilterDateParams(date)
      dispatch(getPortalsMasterAction(date))
    } else {
      setSelectedDate(DT_TODAY)
      dispatch(getPortalsMasterAction())
    }
  }, [dispatch, location])

  useEffect(() => {
    let dateParams = {
      dateType: '',
      date: dateCentral || selectedDate,
    }
    if (filterItem === FILTER_ITEMS[0]) {
      dateParams.dateType = DATE_TYPE.START

      dispatch(getPortalsMasterAction(dateParams))
    } else {
      if (!firstRender.current) {
        dateParams.dateType = DATE_TYPE.END
        dispatch(getPortalsMasterAction(dateParams))
      }
    }

    if (firstRender.current) {
      firstRender.current = false
    }
  }, [filterItem])

  useEffect(() => {
    if (listData.length >= 0 && searchInput === '') {
      setFilterData(getDataFilter())
    }
  }, [listData, searchInput, dataSorted, filterItem])

  const getDataFilter = () => {
    // Sorted data by default
    if (startDateSortedType === DATE_TYPE.DEFAULT && endDateSortedType === DATE_TYPE.DEFAULT) {
      return dataSorted
    }
    // Sorted data by end date
    if (endDateSortedType === SORT_TYPE.INCREASE || endDateSortedType === SORT_TYPE.DECREASE) {
      return sortedDataByDateType(
        DATE_TYPE.END,
        !endDateDecreaseSorted,
        searchInput === '' || !filterData.length ? dataSorted : []
      )
    }
    // Sorted data by start date
    if (startDateSortedType === SORT_TYPE.INCREASE || startDateSortedType === SORT_TYPE.DECREASE) {
      return sortedDataByDateType(
        DATE_TYPE.START,
        !startDateDecreaseSorted,
        searchInput === '' || !filterData.length ? dataSorted : []
      )
    }
    return dataSorted
  }

  useEffect(() => {
    if (listPortalState.listPortals.length >= 0) {
      let dataResponse = [...listPortalState.listPortals]
      setListData(dataResponse)
      // let temp = sortedListData(dataResponse)
      if (searchInput !== '') {
        setFilterData(dataSearchResult(getDataFilter(), filteredFields, searchInput))
      }
    }
    setLoading(listPortalState.loading || deletePortalState.loading)
    switch (listPortalState.type) {
      case GET_PORTAL_LIST_REQUEST:
        break
      case GET_PORTAL_LIST_FAILED:
        if (!getSessionInfo()) {
          listPortalState.errorMessage && setMessageError(listPortalState.errorMessage)
        }
        break
      case GET_PORTAL_LIST_SUCCESS:
        break
      default:
        return
    }

    switch (deletePortalState.type) {
      case DELETE_PORTAL_REQUEST:
        break
      case DELETE_PORTAL_SUCCESS:
        break
      case DELETE_PORTAL_FAILED:
        if (!getSessionInfo()) {
          setMessageError(deletePortalState.errorMessage)
        }
        break
      default:
        return
    }
  }, [listPortalState, deletePortalState])

  /**
   * Sorted By Type And Start Date:
   * Order:
   * Type: お知らせ -> 取り組みトピックス -> リンク
   * Start Date: 2021/09/23 -> 2021/09/22
   * @return {Void}
   */
  useEffect(() => {
    let temp = sortedListData(listData)
    setDataSorted(temp)
  }, [listData])

  const sortedListData = (listData = []) => {
    return listData
      .map((data) => ({
        ...data,
        KB_TITLE: removeExcessCharacters(addClassificationName(data.KB_MESSAGE), 10),
      }))
      .sort(function (a, b) {
        if (a[DATE_TYPE.START] > b[DATE_TYPE.START]) {
          return -1
        }
        if (a[DATE_TYPE.START] < b[DATE_TYPE.START]) {
          return 1
        }
        return 0
      })
      .sort(function (a, b) {
        let a1 = parseInt(a.KB_MESSAGE)
        let b1 = parseInt(b.KB_MESSAGE)
        return a1 - b1
      })
  }

  /**
   * Adding classification name
   * @return {String}
   */
  const addClassificationName = (messageType = '') => {
    switch (messageType) {
      case PORTAL_TYPES.NOTICE:
        return t('maintenance.lblNotification')
      case PORTAL_TYPES.TOPIC:
        return t('maintenance.lblTopic')
      case PORTAL_TYPES.LINK:
        return t('maintenance.lblLink')
      default:
    }
  }

  const filteredFields = ['NM_MESSAGE', 'NM_TITLE', 'NM_URL', 'KB_TITLE', 'NM_SENDER']

  /**
   * Handle on click return modal
   * @return {Void}
   */
  const handleReturnOnclick = () => {
    setShowModal(false)
    setOnDateModalSelect(false)
    // setModalSelectedDate(new Date())
    setStartDateSortedType(DATE_TYPE.DEFAULT)
    setEndDateSortedType(DATE_TYPE.DEFAULT)
    const { date } = location?.state || {}
    if (date && date.dateType && date.date) {
      if (dateCentral) {
        date.date = dateCentral
      }
      date.dateType === DATE_TYPE.START ? setFilterItem(FILTER_ITEMS[0]) : setFilterItem(FILTER_ITEMS[1])
      setSelectedDate(date.date)
      setFilterDateParams(date)
      dispatch(getPortalsMasterAction(date))
    } else if (dateCentral) {
      setSelectedDate(dateCentral)
      dispatch(
        getPortalsMasterAction({
          date: dateCentral,
          dateType: filterItem === t('commonComponent.toolBar.lblStartDate') ? DATE_TYPE.START : DATE_TYPE.END,
        })
      )
    } else {
      dispatch(getPortalsMasterAction())
    }
    setStartDateSortedType(history.location.state['sortDateStart'])
    setEndDateSortedType(history.location.state['sortDateEnd'])
  }

  // Modal display when press triangle button
  const modal = (
    <Dialog open={showModal} onClose={handleReturnOnclick} aria-labelledby="modal-portal">
      <DialogTitle>
        <div className="preview-header">
          <div className="preview-icon">
            <ReturnIcon />
          </div>
          <div className="preview-filter calendar-item">
            <FilterIcon />
            <SearchCalendar
              inputVariant="outlined"
              value={modalSelectedDate}
              onChange={(e) => {
                setModalSelectedDate(formatDateWithPadding(e, 'yyyy-MM-dd'))
                setOnDateModalSelect(true)
                setDateCentral(formatDateWithPadding(e, 'yyyy-MM-dd'))
              }}
              PopoverProps={{
                anchorOrigin: { horizontal: 0, vertical: 'bottom' },
                transformOrigin: { horizontal: 0, vertical: 8 },
              }}
              onSubmit={(date) => {
                handleFilterDate(date)
              }}
              setIsValidDate={setIsValidDate}
              setIsInDateRange={setIsInDateRange}
            />
          </div>
        </div>
      </DialogTitle>
      <DialogContent className="portal-preview">
        <div className="maintenance-container">
          <Portal
            type="modal"
            modalClass={classes.modalStyle}
            dateCentral={dateCentral}
            setDateCentral={setDateCentral}
          />
        </div>
      </DialogContent>
    </Dialog>
  )

  /**
   * Handle on search submit
   * @return {Void}
   */
  const handleSubmit = (event) => {
    event.preventDefault()
    setFilterData(dataSearchResult(getDataFilter(), filteredFields, searchInput))
  }

  /**
   * Handle on click create
   * @return {Void}
   */

  const handleCreateOnclick = async () => {
    history.push({
      pathname: '/admin/portal-master-form',
      state: {
        type: PORTAL_ACTIONS[0],
        date: filterDateParams,
        searchInput: searchInput,
        sortDateStart: startDateSortedType,
        sortDateEnd: endDateSortedType,
        screen: SCREEN_NAME.PORTAL_MASTER,
        dateCentral: dateCentral,
      },
    })
  }

  /**
   * Handle on click delete
   * @return {Void}
   */
  const handleDeleteOnClick = async (data) => {
    const kbAdmin = await getUserKbAdmin(userInfo)
    setKbAdmin(kbAdmin)
    if (String(kbAdmin) === FLAG_BOOLEAN.FALSE) {
      setDeletePermissionMessageError(t('commonComponent.noDeletePermission'))
    } else {
      setOpenModalConfirm(true)
      setPortalDelete(data)
    }
  }

  /**
   * On Submit confirm delete
   * @param event
   */
  const handleSubmitDelete = async (event) => {
    event.preventDefault()
    setIsDelete(true)
    setOpenModalConfirm(false)
    try {
      await dispatch(deletePortalAction(portalDelete))
      if (deletePortal.STS === RESPONSE_TYPE.DATA_EXISTED || deletePortal.STS === RESPONSE_TYPE.ERROR) {
        setDeletePopUp(true)
        setDeleteMessageType('error')
      } else {
        await handleReloadListPortals()
        setDeleteMessageType('success')
        setDeletePopUp(true)
        setShowModal(false)
      }
    } catch (err) {}
  }

  /**
   * Reload list portals
   * @return {void}
   */
  const handleReloadListPortals = async () => {
    if (filterDateParams && filterDateParams.dateType && filterDateParams.date) {
      filterDateParams.dateType === DATE_TYPE.START ? setFilterItem(FILTER_ITEMS[0]) : setFilterItem(FILTER_ITEMS[1])
      dispatch(getPortalsMasterAction(filterDateParams))
    } else {
      setSelectedDate(DT_TODAY)
      dispatch(getPortalsMasterAction())
    }
  }

  // handle edit portal
  const handlePortal = async (id, data, type = PORTAL_ACTIONS[1]) => {
    history.push({
      pathname: '/admin/portal-master-form',
      search: `?id=${id}&type=${type}`,
      state: {
        type: type,
        data: data,
        date: filterDateParams,
        searchInput: searchInput,
        sortDateStart: startDateSortedType,
        sortDateEnd: endDateSortedType,
        dateCentral: dateCentral,
        screen: SCREEN_NAME.PORTAL_MASTER,
      },
    })
  }

  /**
   * Sorted data by start date
   * Ex: 2021/12/12 - > 2021/12/11 (default order)
   * 2021/12/11 - > 2021/12/12 (revrese order)
   * @return {Void}
   */
  const sortedByStartDate = () => {
    let sortedData = sortedDataByDateType(DATE_TYPE.START, startDateDecreaseSorted)
    setFilterData(sortedData)
    setStartDateDecreaseSorted(!startDateDecreaseSorted)
    handleStartDateSortedIcon(startDateSortedType)
    setEndDateSortedType(DATE_TYPE.DEFAULT)
  }

  /**
   * Start date sorted icon display
   * @return {Void}
   */
  const handleStartDateSortedIcon = (dateType) => {
    switch (dateType) {
      case DATE_TYPE.DEFAULT:
        setStartDateSortedType(SORT_TYPE.INCREASE)
        break
      case SORT_TYPE.INCREASE:
        setStartDateSortedType(SORT_TYPE.DECREASE)
        break
      case SORT_TYPE.DECREASE:
        setStartDateSortedType(SORT_TYPE.INCREASE)
        break
      default:
    }
  }

  /**
   * End date sorted icon display
   * @return {Void}
   */
  const handleEndDateSortedIcon = (dateType) => {
    switch (dateType) {
      case DATE_TYPE.DEFAULT:
        setEndDateSortedType(SORT_TYPE.INCREASE)
        break
      case SORT_TYPE.INCREASE:
        setEndDateSortedType(SORT_TYPE.DECREASE)
        break
      case SORT_TYPE.DECREASE:
        setEndDateSortedType(SORT_TYPE.INCREASE)
        break
      default:
    }
  }

  /**
   * Return sorted data by date type
   * @param dateType {DT_START || DT_END}
   * @param isDateSorted {true || false}
   * @return {Array}
   */
  const sortedDataByDateType = (dateType, isDateSorted, data = []) => {
    let dataResult = data.length > 0 ? [...data] : [...filterData]
    return [
      ...dataResult
        .sort((a, b) =>
          isDateSorted
            ? a[dateType].localeCompare(b[dateType], 'ja') || a.DT_REGIST.localeCompare(b.DT_REGIST, 'ja')
            : b[dateType].localeCompare(a[dateType], 'ja') || b.DT_REGIST.localeCompare(a.DT_REGIST, 'ja')
        )
        .sort(function (a, b) {
          let a1 = parseInt(a.KB_MESSAGE)
          let b1 = parseInt(b.KB_MESSAGE)
          return a1 - b1
        }),
    ]
  }

  /**
   * Sorted data by end date
   * @return {Void}
   */
  const sortedByEndDate = () => {
    let sortedData = sortedDataByDateType(DATE_TYPE.END, endDateDecreaseSorted)
    setFilterData(sortedData)
    setEndDateDecreaseSorted(!endDateDecreaseSorted)
    handleEndDateSortedIcon(endDateSortedType)
    setStartDateSortedType(DATE_TYPE.DEFAULT)
  }
  const setStatePopup = () => {
    history.location.state = {
      type: PORTAL_ACTIONS[0],
      filterDateParams: filterDateParams,
      searchInput: searchInput,
      sortDateStart: startDateSortedType,
      sortDateEnd: endDateSortedType,
    }
  }

  const listColumnWidth = ['62px', '12%', '17%', '31%', '8%', '11%', '7%']

  const listCellHeader = [
    { columnName: '', commonStyle: 'titleHead' },
    { columnName: 'maintenance.lblClassification', commonStyle: 'titleHead', cellStyle: 'classification' },
    { columnName: 'maintenance.lblTitle', commonStyle: 'titleHead', cellStyle: 'title' },
    { columnName: 'maintenance.lblUrl', commonStyle: 'titleHead', cellStyle: 'url' },
    { columnName: 'maintenance.lblAttachFile', commonStyle: 'titleHead', cellStyle: 'attachFile' },
    { columnName: 'maintenance.lblSendingPerson', commonStyle: 'titleHead', cellStyle: 'personTitle' },
    { specialType: 'SORTED_START' },
    { specialType: 'SORTED_END' },
  ]

  const listCellBody = [
    { keyValue: 'KB_TITLE', cellStyle: 'data', gridStyle: '' },
    { specialType: SPECIAL_CELL_TYPE.EMAIL },
    { specialType: SPECIAL_CELL_TYPE.URL },
    { specialType: SPECIAL_CELL_TYPE.FILE },
    { specialType: SPECIAL_CELL_TYPE.PERSON },
    { specialType: SPECIAL_CELL_TYPE.DATE_START },
    { specialType: SPECIAL_CELL_TYPE.DATE_END },
  ]

  const listToolBarComponent = [
    { type: TOOLBAR_TYPE.PREVIEW, commonStyle: 'previewContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.ADD, commonStyle: 'createContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.FILTER_LOGO, commonStyle: 'filterIconContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.SEARCH, commonStyle: 'searchContainer', componentStyle: 'search-item' },
    { type: TOOLBAR_TYPE.FILTER_DATE, commonStyle: 'select-item', componentStyle: '' },
    { type: TOOLBAR_TYPE.CALENDAR, commonStyle: 'filterContainer', componentStyle: 'calendar-item' },
    { type: TOOLBAR_TYPE.TIME_LABEL, commonStyle: 'timeLabelContainer', componentStyle: '' },
  ]

  const toolbar = (
    <ToolBar
      listToolBarComponent={listToolBarComponent}
      setShowModal={(value) => {
        setShowModal(value)
        setStatePopup()
        setOnDateModalSelect(value)
        if (dateCentral) setModalSelectedDate(formatDateWithPadding(dateCentral, 'yyyy-MM-dd'))
      }}
      handleCreateOnclick={handleCreateOnclick}
      setSearchInput={setSearchInput}
      searchInput={searchInput}
      customClassName={'filter-icon-table-portal'}
      handleSubmit={handleSubmit}
      setFilterItem={setFilterItem}
      filterItem={filterItem}
      handleFilterDate={handleFilterDate}
      setSelectedDate={setSelectedDate}
      selectedDate={selectedDate}
      setIsValidDate={setIsValidDate}
      setIsInDateRange={setIsInDateRange}
      kbAdmin={kbAdmin}
    />
  )
  const getTitleModal = () => {
    return `<div class="message__pre-text"><div>
    ${t('maintenance.msgDeleteConfirm.line1', {
      topicType: portalDelete.KB_TITLE,
      title: portalDelete.NM_TITLE,
    })}</div><div>${t('maintenance.msgDeleteConfirm.line2')}</div></div>`
  }
  const msgDeleteConfirm = portalDelete ? getTitleModal() : ''
  return (
    <div className="maintain-wrapper">
      {modal}
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {deletePermissionMessageError && (
        <MessageErrorDialog
          message={deletePermissionMessageError}
          showMessageErrorDialog={setDeletePermissionMessageError}
        />
      )}

      {loading ? (
        <ProgressDialog label={isDelete ? t('maintenance.msgDeleteData') : t('maintenance.msgLoading')} />
      ) : null}
      <ModalConfirm
        title={msgDeleteConfirm}
        showModal={isOpenModalConfirm}
        setShowModal={setOpenModalConfirm}
        handleFunction={handleSubmitDelete}
      />
      {deletePopUp && (
        <MessageErrorDialog
          onClose={() => {
            deleteMessageType === 'success' && handleReloadListPortals()
            setDeletePopUp(false)
            setIsDelete(false)
          }}
          message={
            deleteMessageType === 'success' ? t('maintenance.deleteDataSuccess') : t('maintenance.deleteDataError')
          }
          deletePopUp={setDeletePopUp}
          type={deleteMessageType}
          title={t('maintenance.deletePortalTitle')}
          buttonLabel={t('maintenance.deletePortalBtn')}
        />
      )}
      {loading ? (
        <ProgressDialog label={isDelete ? t('maintenance.msgDeleteData') : t('maintenance.msgLoading')} />
      ) : (
        <div className="maintain-container">
          {/* Table */}
          <TableCommon
            listColumnWidth={listColumnWidth}
            listCellHeader={listCellHeader}
            listCellBody={listCellBody}
            listDataRender={filterData}
            handleUpdate={handlePortal}
            idProperty={'ID_MESSAGE'}
            handleDelete={handleDeleteOnClick}
            sortedByStartDate={sortedByStartDate}
            sortedByEndDate={sortedByEndDate}
            startDateSortedType={startDateSortedType}
            endDateSortedType={endDateSortedType}
            toolbar={toolbar}
          />
        </div>
      )}
    </div>
  )
}

export default Maintenance
