import axios from 'commons/baseUrl'
import {
  GET_RECEPTION_DATA_LIST_FAILED,
  GET_RECEPTION_DATA_LIST_REQUEST,
  GET_RECEPTION_DATA_LIST_SUCCESS,
} from 'constants/actionTypes/receptionDataActionTypes'
import { API_GET_RECEPTION_DATA } from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const getReceptionDataAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_RECEPTION_DATA_LIST_REQUEST })
    const res = await axios.get(API_GET_RECEPTION_DATA, { params })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_RECEPTION_DATA_LIST_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data.data.length >= 0) {
      dispatch({ type: GET_RECEPTION_DATA_LIST_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: GET_RECEPTION_DATA_LIST_FAILED, payload: null })
  }
}

export { getReceptionDataAction }
