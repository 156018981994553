import './changePassword.css'

import Button from '@material-ui/core/Button'
import { changePass, resetState } from 'actions/changePassActions'
import { getTitleModalConfirm, getUserInfo } from 'commons/utilities'
import { MessageErrorDialog, ModalConfirm, ModalConfirmNavigation, ProgressDialog } from 'components/commons'
import PasswordFormInput from 'components/commons/FormInputs/PasswordForm'
import ModalNotification from 'components/commons/ModalNotification/ModalNotification'
import {
  CHANGE_PASS_FAILED,
  CHANGE_PASS_REQUEST,
  CHANGE_PASS_RESET,
  CHANGE_PASS_SUCCESS,
} from 'constants/actionTypes/changePassActionTypes'
import { SCREEN_NAME } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { changePasswordValidate } from 'validation/myPageValidate.js'

export default function ChangePassWord() {
  const [error, setError] = useState({})
  const [isBlocked, setIsBlocked] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const userState = useSelector((state) => state.userInfo)
  const { userInfo } = userState
  const { i18n, t } = useTranslation()
  const history = useHistory()
  const [messageError, setMessageError] = useState(false)
  const dispatch = useDispatch()
  const changePassState = useSelector((state) => state.userChangePass)
  const [showProgress, setShowProgress] = useState(false)
  const [showModalNotification, setShowModalNotification] = useState(false)

  const { loading = false, message = '', type } = changePassState

  const initialDataForm = {
    ID_USER: userInfo?.data?.ID_USER || '',
    password: '',
    newPassword: '',
    confirmPassword: '',
  }
  const [dataForm, setDataForm] = useState(initialDataForm)

  const checkRequire = React.useCallback(() => {
    if (dataForm.password || dataForm.newPassword || dataForm.confirmPassword) return false
    return isBlocked
  }, [dataForm, isBlocked])

  const isErrorChangePassword = (error = {}) => {
    const { errorNewPassword, errorPassword, errorConfirmPassword } = error
    if (errorNewPassword || errorPassword || errorConfirmPassword) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (dataForm.password || dataForm.newPassword || dataForm.confirmPassword) {
      if (dataForm.password && dataForm.newPassword && dataForm.confirmPassword && !isErrorChangePassword(error)) {
        setCanSubmit(true)
      } else {
        setCanSubmit(false)
      }
    }
  }, [dataForm, error, checkRequire])

  useEffect(() => {
    switch (type) {
      case CHANGE_PASS_REQUEST:
        setShowProgress(loading)
        break
      case CHANGE_PASS_SUCCESS:
        setShowProgress(loading)
        resetErrorMessage()
        setShowModalNotification(true)
        // redirectToPortal()
        dispatch(resetState())
        break
      case CHANGE_PASS_FAILED:
        setShowProgress(loading)
        setMessageError(message)

        break
      case CHANGE_PASS_RESET:
        break
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePassState])

  const redirectToPortal = () => {
    setIsBlocked(false)
    history.push({
      pathname: '/admin/portal',
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const userInfoDetail = await getUserInfo(userInfo?.data)
    const passwordInDB = userInfoDetail.ID_PASSW
    if (dataForm.password && dataForm.newPassword && dataForm.confirmPassword) {
      const { currentPasswordError, newPasswordError, confirmPasswordError } = changePasswordValidate(
        passwordInDB,
        dataForm.password,
        dataForm.newPassword,
        dataForm.confirmPassword,
        i18n
      )
      if (currentPasswordError || newPasswordError || confirmPasswordError) {
        const errorPassword = {
          errorPassword: currentPasswordError ? currentPasswordError : undefined,
          errorNewPassword: newPasswordError ? newPasswordError : undefined,
          errorConfirmPassword: confirmPasswordError ? confirmPasswordError : undefined,
        }
        setError({
          ...error,
          ...errorPassword,
        })
        return
      }
    }

    setShowModal(true)
    setError()
  }

  const handleUpdate = (name, value) => {
    hideMessageError(name)
    setIsBlocked(true)
    if (name === 'KB_DATA') {
      setDataForm((prev) => ({ ...prev, KB_DATA: (value + 0).toString() }))
      return
    }
    if (name === 'KB_SYSTEM') {
      setDataForm((prev) => ({ ...prev, KB_SYSTEM: (value + 0).toString() }))
      return
    }
    setDataForm((prev) => ({ ...prev, [name]: value }))
  }

  const hideMessageError = (name = '') => {
    if (name === 'password') {
      setError({ ...error, ...{ errorPassword: '' } })
    }
    if (name === 'newPassword') {
      setError({ ...error, ...{ errorNewPassword: '' } })
    }
    if (name === 'confirmPassword') {
      setError({ ...error, ...{ errorConfirmPassword: '' } })
    }
  }

  const resetErrorMessage = () => {
    setError({})
  }

  const handleClearOnClick = () => {
    setDataForm(initialDataForm)
    resetErrorMessage()
  }

  const submitData = () => {
    const submitData = {
      ...dataForm,
      userName: dataForm.ID_USER,
      oldPassword: dataForm.password,
      newPassword: dataForm.newPassword,
      reEnterPassword: dataForm.confirmPassword,
    }
    dispatch(changePass(submitData))
    setShowModal(false)
    setIsBlocked(false)
  }

  const getTitleModal = (dataForm) => {
    return `<div class="message__pre-text"><div>
    ${t('changePassword.msgChangePasswordConfirm.line1')} </div><div>
    ${t('changePassword.msgChangePasswordConfirm.line2')}</div></div>`
  }

  return (
    <div className="change-password-container changepass-container">
      {
        <ModalConfirmNavigation
          title={getTitleModalConfirm(t('myPage.msgConfirmNavigate.line1'), t('myPage.msgConfirmNavigate.line2'))}
          isBlocked={isBlocked}
        />
      }
      {showProgress && <ProgressDialog label={t('headquarter.loading')} />}
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {showModalNotification && (
        <ModalNotification
          message={t('changePassword.lblChangePasswordSuccessNoti')}
          showModalNotification={setShowModalNotification}
          onClose={redirectToPortal}
        />
      )}
      <ModalConfirm
        title={getTitleModal(dataForm)}
        showModal={showModal}
        setShowModal={setShowModal}
        handleFunction={submitData}
      />

      <PasswordFormInput
        password={dataForm.password}
        setPassword={(e) => handleUpdate('password', e)}
        errorMessage={error?.errorPassword}
        setErrorMessage={(value) => setError({ ...error, ...{ errorPassword: value } })}
        label={t('changePassword.lblOldPassword')}
        tabIndex="1"
        screen={SCREEN_NAME.CHANGE_PASSWORD}
        required={true}
        customClassLabel={'customChangePassword'}
      ></PasswordFormInput>
      <PasswordFormInput
        password={dataForm.newPassword}
        setPassword={(e) => handleUpdate('newPassword', e)}
        errorMessage={error?.errorNewPassword}
        setErrorMessage={(value) => setError({ ...error, ...{ errorNewPassword: value } })}
        label={t('changePassword.lblNewPassword')}
        tabIndex="2"
        screen={SCREEN_NAME.CHANGE_PASSWORD}
        required={true}
        customClassLabel={'customChangePassword'}
      ></PasswordFormInput>
      <PasswordFormInput
        password={dataForm.confirmPassword}
        setPassword={(e) => handleUpdate('confirmPassword', e)}
        errorMessage={error?.errorConfirmPassword}
        setErrorMessage={(value) => setError({ ...error, ...{ errorConfirmPassword: value } })}
        label={t('changePassword.lblPassWordReEnter')}
        screen={SCREEN_NAME.CHANGE_PASSWORD}
        customClassLabel={'customChangePassword'}
        tabIndex="3"
        required={true}
      ></PasswordFormInput>
      <div className="btn-container">
        <Button
          className="button submit-button"
          type="submit"
          variant="contained"
          tabIndex="4"
          disabled={!canSubmit}
          onClick={handleSubmit}
        >
          {t('changePassword.lblChangePasswordConfirm')}
        </Button>
        <Button className="button clear-button" variant="outlined" tabIndex="5" onClick={handleClearOnClick}>
          {t('myPage.btnCancel')}
        </Button>
      </div>
    </div>
  )
}
