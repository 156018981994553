import './MessageErrorDialog.css'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { IMAGES } from 'assets'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Image from '../Image/Image'

function MessageErrorDialog({ message, showMessageErrorDialog = () => {}, onClose = () => {} }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  const handleCloseDialog = () => {
    setOpen(false)
    showMessageErrorDialog(false)
    onClose()
  }
  return message ? (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      maxWidth={false}
      aria-labelledby="draggable-dialog-title"
      id="dialog__message-error"
    >
      <DialogTitle className="dialog__message-title">{t('commonComponent.messageError.titleDialog')}</DialogTitle>
      <DialogContent className="dialog__message-content">
        <Image
          src={IMAGES.ICON.ALERT_ICON}
          className="icon_warning"
          style={{ width: 18, height: 18, marginRight: 10 }}
        />
        <DialogContentText>
          <div dangerouslySetInnerHTML={{ __html: message }} className="text-center"></div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="dialog__message-action">
        <Button onClick={handleCloseDialog} color="primary">
          {t('commonComponent.messageError.btnAgree')}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null
}

export default MessageErrorDialog
