import axiosNoConfig from 'axios'
import { API_SIGN_S3 } from 'constants/apiPath'

import instance from './baseUrl'

const signS3 = async (file, key) => {
  let signData = await instance.post(API_SIGN_S3, {
    fileName: key,
    fileType: file.type,
  })

  return signData.data
}

const upload = async (file, key, bucket) => {
  let { signRequest } = await signS3(file, key)
  return new Promise((resolve, reject) => {
    let options = {
      headers: {
        'Content-Type': file.type,
      },
    }

    return axiosNoConfig
      .put(signRequest, file, options)
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export { upload }
