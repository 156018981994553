import axios from 'commons/baseUrl'
import {
  CLEAR_DELIVERY_DATA_DETAIL,
  GET_DELIVERY_DATA_DETAIL_LIST_FAILED,
  GET_DELIVERY_DATA_DETAIL_LIST_REQUEST,
  GET_DELIVERY_DATA_DETAIL_LIST_SUCCESS,
} from 'constants/actionTypes/deliveryDataDetailActionTypes'
import { API_GET_DELIVERY_DATA_DETAIL } from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const getDeliveryDataDetailAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_DELIVERY_DATA_DETAIL_LIST_REQUEST })
    const res = await axios.get(API_GET_DELIVERY_DATA_DETAIL, { params })
    // const res = {
    //   data: {
    //     data: [
    //       {
    //         customer: {
    //           CD_CUST: 'customer 1',
    //           NM_CUST: '取引先名A',
    //           branch: {
    //             CD_BRANCH: 'CDA',
    //             NM_BRANCH: '支店A',
    //             data: [
    //               {
    //                 DT_REGIST: '2022-08-01',
    //                 DISP_FLD: [
    //                   {
    //                     NM_DISP_FLD: 'Folder Name 1',
    //                     NU_DL_COUNT: '5',
    //                   },
    //                   {
    //                     NM_DISP_FLD: 'Folder Name 2',
    //                     NU_DL_COUNT: '20',
    //                   },
    //                   {
    //                     NM_DISP_FLD: 'Folder Name 5',
    //                     NU_DL_COUNT: '32',
    //                   },
    //                 ],
    //               },
    //               {
    //                 DT_REGIST: '2022-08-02',
    //                 DISP_FLD: [
    //                   {
    //                     NM_DISP_FLD: 'Folder Name 1',
    //                     NU_DL_COUNT: '999',
    //                   },
    //                   {
    //                     NM_DISP_FLD: 'Folder Name 2',
    //                     NU_DL_COUNT: '444',
    //                   },
    //                   {
    //                     NM_DISP_FLD: 'Folder Name 3',
    //                     NU_DL_COUNT: '666',
    //                   },
    //                   {
    //                     NM_DISP_FLD: 'Folder Name 4',
    //                     NU_DL_COUNT: '21',
    //                   },
    //                 ],
    //               },
    //               {
    //                 DT_REGIST: '2022-08-03',
    //                 DISP_FLD: [
    //                   {
    //                     NM_DISP_FLD: 'Folder Name 1',
    //                     NU_DL_COUNT: '123',
    //                   },
    //                   {
    //                     NM_DISP_FLD: 'Folder Name 3',
    //                     NU_DL_COUNT: '111',
    //                   },
    //                   {
    //                     NM_DISP_FLD: 'Folder Name 4',
    //                     NU_DL_COUNT: '222',
    //                   },
    //                 ],
    //               },
    //             ],
    //           },
    //         },
    //         list_folder_name: [
    //           'Folder Name 1',
    //           'Folder Name 2',
    //           'Folder Name 3',
    //           'Folder Name 4',
    //           'Folder Name 5',
    //           'Folder Name 6',
    //           'Folder Name 7',
    //         ],
    //       },
    //     ],
    //     STS: 0,
    //     error: false,
    //     message: 'success',
    //   },
    // }
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_DELIVERY_DATA_DETAIL_LIST_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data.data) {
      dispatch({ type: GET_DELIVERY_DATA_DETAIL_LIST_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: GET_DELIVERY_DATA_DETAIL_LIST_FAILED, payload: err.message })
  }
}

const clearDeliveryDataDetail = (data) => async (dispatch) => {
  dispatch({ type: CLEAR_DELIVERY_DATA_DETAIL, payload: '' })
}

export { clearDeliveryDataDetail, getDeliveryDataDetailAction }
