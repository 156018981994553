import './Reissue.css'
import '../../MyPage/MyPage.css'

import Button from '@material-ui/core/Button'
import { EmailOutlined } from '@material-ui/icons'
import { updateReissueAction } from 'actions/reissueActions'
import { IMAGES } from 'assets'
import { formatDateWithPadding } from 'commons/utilities'
import { Image } from 'components/commons'
import { MessageErrorDialog, ModalConfirm, ModalConfirmNavigation, ProgressDialog } from 'components/commons'
import PasswordFormInput from 'components/commons/FormInputs/PasswordForm'
import {
  UPDATE_REISSUE_FAILED,
  UPDATE_REISSUE_REQUEST,
  UPDATE_REISSUE_SUCCESS,
} from 'constants/actionTypes/reissueActionTypes'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { changePasswordValidateCustUser } from 'validation/hquserValidate'

function Reissue() {
  const [canSubmit, setCanSubmit] = useState(false)
  const [dataForm, setDataForm] = useState({
    idUser: '',
    customerID: '',
    mail: '',
    phone: '',
    newPassword: '',
    confirmPassword: '',
  })
  const [error, setError] = useState({})
  const location = useLocation()
  const [showModal, setShowModal] = useState(false)
  const [showProgress, setShowProgress] = useState(false)
  const dispatch = useDispatch()
  const [messageError, setMessageError] = useState(false)
  const updateReissueReducers = useSelector((state) => state.updateReissue)
  const history = useHistory()
  const [filteredDate, setFilteredDate] = useState(formatDateWithPadding(new Date(), 'yyyy-MM-dd'))
  const [searchInput, setSearchInput] = useState('')
  const [isBlocked, setIsBlocked] = useState(false)

  useEffect(() => {
    setDataForm({})
    setShowProgress(false)
  }, [])

  useEffect(() => {
    const { dateFilter, searchInput } = location?.state || {}
    if (searchInput) {
      setSearchInput(searchInput)
    }
    if (dateFilter) setFilteredDate(dateFilter)
    if (Object.keys(location?.state?.data).length > 0) {
      const { ID_USER = '', CD_CUST = '', RE_MAIL = '', NU_TEL = '' } = location?.state?.data
      setDataForm({ ...dataForm, idUser: ID_USER, customerID: CD_CUST, mail: RE_MAIL, phone: NU_TEL })
    }
  }, [location])

  const { i18n, t } = useTranslation()

  const handleClearOnClick = () => {
    history.push({
      pathname: '/admin/custuser',
      state: {
        dateFilter: filteredDate,
        searchInput: searchInput,
      },
    })
  }

  const checkRequire = React.useCallback(() => {
    if (dataForm.password === '' || dataForm.confirmPassword === '') {
      return false
    }
    return true
  }, [dataForm])

  useEffect(() => {
    if (!checkRequire()) {
      setCanSubmit(false)
    } else {
      setCanSubmit(true)
    }
  }, [dataForm, checkRequire])

  // hide message error base on form input field
  const hideMessageError = (name = '') => {
    if (name === 'newPassword') {
      setError({ ...error, ...{ errorPassword: '' } })
    }
    if (name === 'confirmPassword') {
      setError({ ...error, ...{ errorConfirmPassword: '' } })
    }
  }

  // handle update field portal
  const handleUpdate = (name, value) => {
    setIsBlocked(true)
    hideMessageError(name)
    setDataForm((prev) => ({ ...prev, [name]: value }))
  }

  // submit data
  const handleSubmit = (e) => {
    e.preventDefault()

    const { newPasswordError, confirmPasswordError } = changePasswordValidateCustUser(
      dataForm.newPassword,
      dataForm.confirmPassword,
      i18n
    )

    if (dataForm.newPassword || dataForm.confirmPassword) {
      if (newPasswordError || confirmPasswordError) {
        const errorPassword = {
          errorPassword: newPasswordError ? newPasswordError : undefined,
          errorConfirmPassword: confirmPasswordError ? confirmPasswordError : undefined,
        }
        setError({
          ...error,
          ...errorPassword,
        })
        return
      }
    }

    setShowModal(true)
    setError()
  }

  useEffect(() => {
    setIsBlocked(false)
    setShowProgress(updateReissueReducers?.loading)

    switch (updateReissueReducers?.type) {
      case UPDATE_REISSUE_REQUEST:
        break
      case UPDATE_REISSUE_FAILED:
        setMessageError(updateReissueReducers?.message)
        break
      case UPDATE_REISSUE_SUCCESS:
        history.push({
          pathname: '/admin/custuser',
          state: {
            dateFilter: filteredDate,
            searchInput: searchInput,
          },
        })
        break
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateReissueReducers, dispatch])

  const submitData = () => {
    dispatch(updateReissueAction(dataForm))
    setShowModal(false)
  }

  const getTitleModal = () => {
    return `<div class="message__pre-text"><div>
    ${t('reissues.confirmPassword.line1')} </div><div>
    ${t('reissues.confirmPassword.line2')}</div></div>`
  }

  const confirmNavigationMessage = `<div class="message__pre-text"><div>
  ${t('formAllergen.msgConfirmNavigate.line1')} </div><div>
  ${t('formAllergen.msgConfirmNavigate.line2')}</div></div>`

  return (
    <div className="reissue-wrapper">
      {<ModalConfirmNavigation title={confirmNavigationMessage} isBlocked={isBlocked} />}
      {showProgress && <ProgressDialog label={t('headquarter.loading')} />}
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      <ModalConfirm
        title={getTitleModal()}
        showModal={showModal}
        setShowModal={setShowModal}
        handleFunction={submitData}
        screen={'reissue'}
      />
      <div className="reissue-content">
        {/*  User Info*/}
        <div className="userInfo">
          {/* Request Person  */}
          <div className="request-person d-flex margin-b10">
            <div className="request-person-icon icon margin-r15">
              <Image src={IMAGES.ICON.CARD_USER} alt="Card User Icon" className="iconForm icon--deactive" />
            </div>
            <div className="request-person-content">
              <div className="request-person-label text-green fontSize-10">{t('reissue.lblRequestPerson')}</div>
              <div className="request-person-text fontSize-12 margin-l10">{`${t('reissue.lblUserID')} : ${
                dataForm.idUser
              }`}</div>
            </div>
          </div>
          {/* Suppliers ID  */}
          <div className="suppliers d-flex margin-b10">
            <div className="suppliers-icon icon margin-r15">
              <Image src={IMAGES.ICON.BRANCH.BUILDING} className="icon-deactive" />
            </div>
            <div className="suppliers-content">
              <div className="suppliers-label text-green fontSize-10">{t('reissue.lblSupplier')}</div>
              <div className="suppliers-text fontSize-12 margin-l10">{`${t('reissue.lblCustomerID')} : ${
                dataForm.customerID
              }`}</div>
            </div>
          </div>
          {/* Mail  */}
          <div className="mail d-flex margin-b10">
            <div className="mail-icon icon margin-r15">
              <EmailOutlined />
            </div>
            <div className="mail-content">
              <div className="mail-label text-green fontSize-10">{t('reissue.lblMailAddress')}</div>
              <div className="mail-text fontSize-12 margin-l10">{`${dataForm.mail}`}</div>
            </div>
          </div>

          {/* Phone  */}
          <div className="phone d-flex margin-b10">
            <div className="phone-icon icon margin-r15">
              <Image src={IMAGES.ICON.PHONE} alt="phone Icon" className="icon-deactive" />
            </div>
            <div className="phone-content">
              <div className="phone-label text-green fontSize-10">{t('reissue.lblPhoneNumber')}</div>
              <div className="phone-text fontSize-12 margin-l10">{`${dataForm.phone}`}</div>
            </div>
          </div>
        </div>
        {/*  Reissue PassWord */}

        <div className="change_userPass">
          {/* Header Change PassWord  */}
          <div className="change_userPass_header">
            <div className="change_userPass_header_text">
              <div className="change_userPass_header_text_wrapper">{t('changePassword.lblChangePassHeaderText')}</div>
            </div>
          </div>
          {/* Form Change PassWord  */}
          <div className="userInfo_content">
            <form className="userInfo_form">
              <div className="userInfo_form_container portal-create form-scroll">
                <div className="portal-create-content form-min-width">
                  <div className="changepass-container">
                    <PasswordFormInput
                      password={dataForm.newPassword}
                      setPassword={(e) => handleUpdate('newPassword', e)}
                      errorMessage={error?.errorPassword}
                      setErrorMessage={(value) => setError({ ...error, ...{ errorPassword: value } })}
                      label={t('changePassword.lblNewPassword')}
                      tabIndex="2"
                      required={true}
                    ></PasswordFormInput>
                    <PasswordFormInput
                      password={dataForm.confirmPassword}
                      setPassword={(e) => handleUpdate('confirmPassword', e)}
                      errorMessage={error?.errorConfirmPassword}
                      setErrorMessage={(value) => setError({ ...error, ...{ errorConfirmPassword: value } })}
                      label={t('reissue.lblNewPassReEnter')}
                      tabIndex="3"
                      required={true}
                    ></PasswordFormInput>

                    <div className="btn-container">
                      <Button
                        className="button submit-button"
                        type="submit"
                        variant="contained"
                        tabIndex="4"
                        disabled={!canSubmit}
                        onClick={handleSubmit}
                      >
                        {t('changePassword.lblReissueSubmit')}
                      </Button>
                      <Button
                        className="button clear-button"
                        variant="outlined"
                        tabIndex="5"
                        onClick={handleClearOnClick}
                      >
                        {t('myPage.btnCancel')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reissue
