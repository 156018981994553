import {
  CHANGE_PASS_FAILED,
  CHANGE_PASS_REQUEST,
  CHANGE_PASS_RESET,
  CHANGE_PASS_SUCCESS,
} from 'constants/actionTypes/changePassActionTypes'

let initialState = {
  loading: false,
  message: '',
  type: CHANGE_PASS_REQUEST,
  changeInfo: {},
}
function userChangePassReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PASS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CHANGE_PASS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
        type: CHANGE_PASS_SUCCESS,
      }
    case CHANGE_PASS_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload,
        type: CHANGE_PASS_FAILED,
      }
    case CHANGE_PASS_RESET:
      return initialState

    default:
      return state
  }
}

export { userChangePassReducer }
