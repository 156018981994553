import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
  table: {
    minWidth: 1050,
    marginBottom: '20px',
    maxHeight: '165px',
    overflowY: 'auto',
    tableLayout: 'fixed',
  },
  tableContainer: {
    overflowX: 'hidden',
  },
  tableHead: {
    backgroundColor: 'rgb(245, 250, 235)',
    borderBottom: 'hidden',
  },
  tableRow: {
    borderBottom: '2px solid #e6e7e6',
  },
  portalContent: {
    padding: '16px 20px 0px 20px',
    display: 'block',
    // overflow: 'auto',
  },
  cellNoticeTitle: {
    padding: '5px 20px',
    color: 'rgb(85, 130, 50)',
    fontSize: '15px',
    borderBottom: 'none',
  },
  cellTopicTitle: {
    padding: '5px 20px',
    color: 'rgb(85, 130, 50)',
    fontSize: '15px',
    borderBottom: 'none',
  },
  cellLinkTitle: {
    padding: '5px 20px',
    color: 'rgb(85, 130, 50)',
    fontSize: '15px',
    borderBottom: 'none',
  },
  cellName: {
    verticalAlign: 'top',
    padding: '10px 30px 5px 0px',
    borderBottomColor: 'rgb(191, 191, 191)',
  },
  cellNoticeDate: {
    padding: '10px 0px 5px 15px',
    verticalAlign: 'top',
    color: 'rgb(59, 56, 56)',
    fontSize: '13px',
    borderBottomColor: 'rgb(191, 191, 191)',
  },
  cellTopicDate: {
    padding: '10px 0px 5px 15px',
    verticalAlign: 'top',
    color: 'rgb(59, 56, 56)',
    fontSize: '13px',
    borderBottomColor: 'rgb(191, 191, 191)',
  },
  cellLinkDate: {
    padding: '10px 0px 5px 15px',
    verticalAlign: 'top',
    color: 'rgb(59, 56, 56)',
    fontSize: '13px',
    borderBottomColor: 'rgb(191, 191, 191)',
  },
  cellNoticeContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  cellContent: {
    padding: '10px 10px 5px 0px',
    fontWeight: 'bolder',
    color: 'rgb(59, 56, 56)',
    borderBottomColor: 'rgb(191, 191, 191)',
    verticalAlign: 'top',
  },
  cellNoticeContentTitle: {
    fontSize: '13px',
    lineBreak: 'anywhere',
    fontWeight: 'bolder',
    width: '450px',
    paddingRight: '10px',
  },
  cellTopicContentTitle: {
    fontSize: '13px',
  },

  cellNoticeContentMessage: {
    fontWeight: 'normal',
    fontSize: '12px',
    paddingTop: '5px',
    paddingLeft: '15px',
    wordWrap: 'normal',
    whiteSpace: 'break-spaces',
    wordBreak: 'break-word',
    width: 'calc(100%)',
    paddingBottom: '5px',
  },
  cellTopicContentMessage: {
    // width: '1366px !important',
    width: 'calc(100%)',
    fontWeight: 'normal',
    fontSize: '12px',
    paddingTop: '10px',
    paddingLeft: '15px',
    wordWrap: 'normal',
    whiteSpace: 'break-spaces',
    wordBreak: 'break-word',
  },
  personNoticeTitle: {
    fontWeight: 'bolder',
    color: 'rgb(59, 56, 56)',
    fontSize: '12px',
  },
  personNoticeName: {
    color: 'rgb(59, 56, 56)',
    fontSize: '12px',
    lineBreak: 'anywhere',
  },
  personTopicTitle: {
    fontWeight: 'bolder',
    color: 'rgb(59, 56, 56)',
    fontSize: '12px',
  },
  personTopicName: {
    color: 'rgb(59, 56, 56)',
    fontSize: '12px',
    fontWeight: 'normal',
  },
  fileAttachWrapper: {
    margin: '10px 0 0 12px',
    width: '1366px !important',
  },
  fileAttachContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginRight: '30px',
    height: '20px',
  },
  fileAttachItem: {
    marginRight: '5px',
  },
  linkTopic: {
    left: '1.2rem',
    top: '60%',
    color: '#5e7bae',
    fontSize: '12px',
    fontWeight: 'normal',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  fileIcon: {
    // left: '-0.5rem',
  },
  urlLink: {
    color: '#5e7bae',
    cursor: 'pointer',
    fontSize: '13px',
    fontWeight: 'normal',
    textDecoration: 'underline',
  },
  firstCol: {
    width: '144px !important',
  },
  secondCol: {
    width: '450px !important',
  },
  thirdCol: {},
  '@media (max-width: 1855px)': {
    cellContentMessage: {
      width: '1250px !important',
    },
    fileAttachWrapper: {
      width: '1250px !important',
    },
    cellNoticeContentMessage: {
      width: 'calc(100%)',
    },
  },
  '@media (max-width: 1755px)': {
    cellContentMessage: {
      width: '1000px !important',
    },
    fileAttachWrapper: {
      width: '1000px !important',
    },
    // firstCol: {
    //   width: '15%',
    // },
    // secondCol: {
    //   width: '38%',
    // },
    // thirdCol: {
    //   width: '47%',
    // },
  },
  customCellNoPading: {
    // display: 'flex',
    // direction: 'column',
    padding: '0px',
    margin: '0px',
  },
  titleBlock1: {
    display: 'flex',
    padding: '10px 0px 5px 0',
  },
  childTitleBlock1: {
    display: 'block',
  },
  childBlock1: {
    fontSize: '13px',
  },
  childTitleBlock2: {
    fontSize: '13px',
    lineBreak: 'anywhere',
  },
  childBlock2: {
    // display: 'inline-flex',
    fontSize: '13px',
    width: 'calc(55% - 30px)',
    padding: 0,
    margin: 0,
  },
  '@media (max-width: 1500px)': {
    cellContentMessage: {
      width: '985px !important',
    },
    // cellNoticeContentMessage: {
    //   width: '900px !important',
    // },
    fileAttachWrapper: {
      width: '985px !important',
    },
  },
  '@media (max-width: 1400px)': {
    // cellTopicContentMessage: {
    //   width: '900px',
    // },
  },
}))

export default styles
