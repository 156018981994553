import {
  FORGOT_PASS_FAILED,
  FORGOT_PASS_REQUEST,
  FORGOT_PASS_SUCCESS,
} from 'constants/actionTypes/forgotPassActionTypes'
import { RESPONSE_TYPE } from 'constants/constant'

const forgotPassword = (userId, email, i18n) => async (dispatch) => {
  try {
    dispatch({ type: FORGOT_PASS_REQUEST })
    // const params = {
    //   userName: userId,
    //   email: email,
    // }

    const res = {
      data: {
        STS: 0,
        error: null,
        message: 'success',
        data: {},
      },
    }
    if (!res.data || res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST)
      dispatch({ type: FORGOT_PASS_FAILED, payload: i18n.t('forgotPassword.msgErrorInfoIncorrect') })

    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: FORGOT_PASS_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: FORGOT_PASS_SUCCESS, payload: res.data.message })
    }
  } catch (error) {
    dispatch({ type: FORGOT_PASS_FAILED, payload: error.data.message })
  }
}

export { forgotPassword }
