import {
  GET_EXCEPTION_DATA_TEMPLATE_FAILED,
  GET_EXCEPTION_DATA_TEMPLATE_REQUEST,
  GET_EXCEPTION_DATA_TEMPLATE_SUCCESS,
} from 'constants/actionTypes/exceptionDataTemplateActionTypes'

const listExceptionData = (state = { listExceptionData: [] }, action) => {
  switch (action.type) {
    case GET_EXCEPTION_DATA_TEMPLATE_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_EXCEPTION_DATA_TEMPLATE_REQUEST,
      }
    case GET_EXCEPTION_DATA_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        listExceptionData: action.payload,
        type: GET_EXCEPTION_DATA_TEMPLATE_SUCCESS,
      }
    case GET_EXCEPTION_DATA_TEMPLATE_FAILED:
      return {
        ...state,
        loading: false,
        listExceptionData: [],
        message: action.payload,
        type: GET_EXCEPTION_DATA_TEMPLATE_FAILED,
      }
    default:
      return state
  }
}

export { listExceptionData }
