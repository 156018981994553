import axios from 'commons/baseUrl'
import {
  CLEAR_DOWNLOAD_STATUS,
  GET_DOWNLOAD_STATUS_LIST_FAILED,
  GET_DOWNLOAD_STATUS_LIST_REQUEST,
  GET_DOWNLOAD_STATUS_LIST_SUCCESS,
} from 'constants/actionTypes/downloadStatusActionTypes'
import { API_GET_DOWNLOAD_STATUS } from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const getDownloadStatusAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_DOWNLOAD_STATUS_LIST_REQUEST })
    const res = await axios.get(API_GET_DOWNLOAD_STATUS, { params })
    if (!res?.data || res?.data?.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_DOWNLOAD_STATUS_LIST_FAILED, payload: res?.data?.message })
      throw new Error()
    }
    if (res?.data?.STS === RESPONSE_TYPE.SUCCESS && res?.data?.data?.length >= 0) {
      dispatch({ type: GET_DOWNLOAD_STATUS_LIST_SUCCESS, payload: res?.data?.data })
    }
  } catch (err) {
    dispatch({ type: GET_DOWNLOAD_STATUS_LIST_FAILED, payload: null })
  }
}

const clearDownloadStatus = (data) => async (dispatch) => {
  dispatch({ type: CLEAR_DOWNLOAD_STATUS, payload: '' })
}

export { clearDownloadStatus, getDownloadStatusAction }
