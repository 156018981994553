import './HeadQuarters.css'

import { clearHquer, deleteHquserAction, getHeadQuartersAction } from 'actions/headQuartersAction'
import {
  dataSearchResult,
  formatDateWithPadding,
  getSessionInfo,
  getTitleModalConfirm,
  getUserKbAdmin,
} from 'commons/utilities'
import { MessageErrorDialog, ModalConfirm as DeleteResult, ModalConfirm, ProgressDialog } from 'components/commons'
import TableCommon from 'components/commons/Table/TableCommon'
import ToolBar from 'components/commons/ToolBar/ToolBar'
import {
  DELETE_HEADQUARTER_FAILED,
  DELETE_HEADQUARTER_REQUEST,
  DELETE_HEADQUARTER_SUCCESS,
  GET_HEADQUARTERS_LIST_FAILED,
  GET_HEADQUARTERS_LIST_REQUEST,
  GET_HEADQUARTERS_LIST_SUCCESS,
} from 'constants/actionTypes/headQuartersActionTypes'
import { ACTION_FORM, FLAG_BOOLEAN, MESSAGE_TYPE, SCREEN_NAME, TOOLBAR_TYPE } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

// import styles from './HeadQuartersStyles'

function HeadQuarters() {
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return null
  })
  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const { t } = useTranslation()
  // const classes = styles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [listData, setListData] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [dateFilter, setDateFilter] = useState(DT_TODAY)
  const [searchData, setSearchData] = useState([])
  const [filterData, setFilterData] = useState([])
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState()
  const [recordDelete, setRecordDelete] = useState()
  const listHeadQuartersReducer = useSelector((state) => state.listHeadQuarters || {})
  const hquserDeleteReducer = useSelector((state) => state.deleteHquser || {})
  const [isValidDate, setIsValidDate] = useState(true)
  const [isInDateRange, setIsInDateRange] = useState(true)
  const filteredFields = ['ID_USER', 'NM_USER', 'NM_MAIL1', 'NM_MAIL2']

  const [deletePermissionMessageError, setDeletePermissionMessageError] = useState('')

  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false)
  const [deletePopUp, setDeletePopUp] = useState(false)
  const [deleteMessageType, setDeleteMessageType] = useState('')

  const handleSearchInput = (event) => {
    event.preventDefault()
    setFilterData(dataSearchResult(listData, filteredFields, searchInput))
  }

  const handleFilterDate = React.useCallback(
    (dateFilter) => {
      if (isValidDate && isInDateRange) {
        dispatch(getHeadQuartersAction({ date: dateFilter }))
      }
    },
    [dispatch]
  )

  const handleDeleteOnClick = async (data) => {
    const userLocal = userInfo.ID_USER || null
    const userRow = data['ID_USER'] || ''
    if (!(userLocal !== null && userLocal.toString().toLowerCase() === userRow)) {
      const kbAdmin = await getUserKbAdmin(userInfo)

      if (String(kbAdmin) === FLAG_BOOLEAN.FALSE) {
        setShowModalConfirmDelete(false)
        setDeletePermissionMessageError(t('commonComponent.noDeletePermission'))
      } else {
        setShowModalConfirmDelete(true)
        setRecordDelete(data)
      }
    }
  }

  const deleteHqUser = () => {
    let params = { date: dateFilter }
    dispatch(deleteHquserAction(recordDelete, params))
    setShowModalConfirmDelete(false)
  }
  // handle edit portal
  const handleCreate = async (id, data, type = ACTION_FORM.CREATE) => {
    history.push({
      pathname: '/admin/head-quarters-form',
      search: `?${id ? `id=${id}&` : ''}type=${type}`,
      state: {
        type: type,
        data: data,
        date: dateFilter,
        searchInput: searchInput,
        screen: SCREEN_NAME.HEAD_QUARTER,
      },
    })
  }
  // handle edit portal
  const handleUpdate = async (id, data, type = ACTION_FORM.UPDATE) => {
    history.push({
      pathname: '/admin/head-quarters-form',
      search: `?${id ? `id=${id}&` : ''}type=${type}`,
      state: {
        type: type,
        data: data,
        date: dateFilter,
        searchInput: searchInput,
        screen: SCREEN_NAME.HEAD_QUARTER,
      },
    })
  }
  useEffect(
    () => {
      const { date, searchInput, screen } = location?.state || {}
      if (searchInput && screen === SCREEN_NAME.HEAD_QUARTER) {
        setSearchInput(searchInput)
      }
      if (date) {
        if (screen !== SCREEN_NAME.HEAD_QUARTER) {
          setDateFilter(DT_TODAY)
          dispatch(getHeadQuartersAction({}))
        } else {
          setDateFilter(date)
          dispatch(
            getHeadQuartersAction({
              date: date,
            })
          )
        }
      } else {
        setDateFilter(DT_TODAY)
        dispatch(getHeadQuartersAction({}))
      }
      window.history.replaceState({}, document.title)
    },
    [dispatch],
    [location]
  )
  useEffect(() => {
    window.addEventListener('beforeunload', reloadHandler)
    return () => {
      window.removeEventListener('beforeunload', reloadHandler)
    }
  }, [])
  const reloadHandler = (e) => {
    window.reload()
  }
  useEffect(() => {
    if (listData.length >= 0 && searchInput === '') {
      setFilterData(listData)
    }
  }, [listData, searchInput, searchData])

  useEffect(() => {
    setDeletePopUp(false)
    if (listHeadQuartersReducer.listHeadQuarters.length >= 0) {
      let dataResponse = [...listHeadQuartersReducer.listHeadQuarters]
      setListData(dataResponse)
      if (searchInput !== '') {
        setFilterData(dataSearchResult(dataResponse, filteredFields, searchInput))
      }
    }

    setLoading(listHeadQuartersReducer.loading || hquserDeleteReducer.loading)
    switch (listHeadQuartersReducer.type) {
      case GET_HEADQUARTERS_LIST_REQUEST:
        break
      case GET_HEADQUARTERS_LIST_FAILED:
        if (!getSessionInfo()) {
          listHeadQuartersReducer.message && setMessageError(listHeadQuartersReducer.message)
        }
        break
      case GET_HEADQUARTERS_LIST_SUCCESS:
        break
      default:
        return
    }
  }, [listHeadQuartersReducer])

  useEffect(() => {
    setLoading(listHeadQuartersReducer.loading || hquserDeleteReducer.loading)

    switch (hquserDeleteReducer.type) {
      case DELETE_HEADQUARTER_REQUEST:
        break
      case DELETE_HEADQUARTER_SUCCESS:
        setDeletePopUp(true)
        setDeleteMessageType(MESSAGE_TYPE.SUCCESS)
        break
      case DELETE_HEADQUARTER_FAILED:
        if (!getSessionInfo()) {
          setDeletePopUp(true)
          setDeleteMessageType(MESSAGE_TYPE.ERROR)
          setMessageError(hquserDeleteReducer.message)
        }
        break
      default:
        return
    }
  }, [hquserDeleteReducer])

  const headersCsv = [
    { label: t('headquarter.lblIdUser'), key: 'ID_USER' },
    { label: t('headquarter.lblNmUser'), key: 'NM_USER' },
    { label: t('headquarter.lblEmail1'), key: 'NM_MAIL1' },
    { label: t('headquarter.lblEmail2'), key: 'NM_MAIL2' },
    { label: t('formHeadQuarter.lblIdMenu'), key: 'ID_MENU' },
    { label: t('formHeadQuarter.lblNmNotes'), key: 'NM_NOTES' },
    { label: t('headquarter.lblUpdatePermission'), key: 'KB_ADMIN' },
    { label: t('headquarter.lblDataNoti'), key: 'KB_DATA' },
    { label: t('headquarter.lblSystemNoti'), key: 'KB_SYSTEM' },
    { label: t('headquarter.lblExpiredDate'), key: 'DT_DEADL' },
  ]
  const headerCsvLabel = headersCsv.map((data) => data.label)
  const headerCsvKey = headersCsv.map((data) => data.key)

  const listColumnWidth = ['62px', '12%', '21%', '13%', '13%', '8%', '8%', '8%']
  const listCellHeader = [
    { columnName: '', commonStyle: 'titleHead' },
    { columnName: 'headquarter.lblIdUser', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'headquarter.lblNmUser', commonStyle: 'titleHead', cellStyle: 'titleSpacing' },
    { columnName: 'headquarter.lblEmail1', commonStyle: 'titleHead', cellStyle: 'personTitle' },
    { columnName: 'headquarter.lblEmail2', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
    { columnName: 'headquarter.lblUpdatePermission', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
    { columnName: 'headquarter.lblDataNoti', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
    { columnName: 'headquarter.lblSystemNoti', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
    { columnName: 'headquarter.lblExpiredDate', commonStyle: 'titleHead', cellStyle: 'dateTitle' },
  ]

  const listCellBody = [
    { keyValue: 'ID_USER', cellStyle: 'data', gridStyle: 'centerAlignHq' },
    { keyValue: 'NM_USER', cellStyle: 'data', gridStyle: 'centerAlignHq' },
    { keyValue: 'NM_MAIL1', cellStyle: 'dataRightHq', gridStyle: 'centerAlignHq' },
    { keyValue: 'NM_MAIL2', cellStyle: 'dataRightHq', gridStyle: 'centerAlignHq' },
    { keyValue: 'KB_ADMIN', specialType: 'CHECK' },
    { keyValue: 'KB_DATA', specialType: 'CHECK' },
    { keyValue: 'KB_SYSTEM', specialType: 'CHECK' },
    { specialType: 'DATE_DEAL' },
  ]

  const listToolBarComponent = [
    { type: TOOLBAR_TYPE.ADD, commonStyle: 'createContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.FILTER_LOGO, commonStyle: 'filterIconContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.SEARCH, commonStyle: 'searchContainer', componentStyle: 'search-item' },
    { type: TOOLBAR_TYPE.EXPIRE_DATE, commonStyle: 'expireDateContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.CALENDAR, commonStyle: 'filterContainer', componentStyle: 'calendar-item' },
    { type: TOOLBAR_TYPE.CSV, commonStyle: '', componentStyle: '' },
  ]
  let setDownloadParams = {
    screenName: 'head-quaters',
    searchText: searchInput,
    date: dateFilter,
  }

  const toolbar = (
    <ToolBar
      listToolBarComponent={listToolBarComponent}
      handleCreateOnclick={handleCreate}
      setSearchData={setSearchData}
      setSearchInput={setSearchInput}
      searchInput={searchInput}
      handleSubmit={handleSearchInput}
      handleFilterDate={handleFilterDate}
      setSelectedDate={setDateFilter}
      selectedDate={dateFilter}
      headerCsvLabel={headerCsvLabel}
      headerCsvKey={headerCsvKey}
      fileCsvName={t('headquarter.fileNameDownload')}
      listData={listData}
      downloadParams={setDownloadParams}
      setIsValidDate={setIsValidDate}
      setIsInDateRange={setIsInDateRange}
    />
  )
  // const msgDeleteConfirm = recordDelete ? t('headquarter.msgDeleteConfirm', { idUserValue: recordDelete.ID_USER }) : ''
  const msgDeleteConfirm = recordDelete
    ? getTitleModalConfirm(
        t('headquarter.msgDeleteConfirm.line1', { idUserValue: recordDelete.ID_USER }),
        t('headquarter.msgDeleteConfirm.line2')
      )
    : ''

  const handleConfirmDeleteResult = () => {
    setRecordDelete(null)
    setDeletePopUp(false)
    dispatch(getHeadQuartersAction())
    dispatch(clearHquer())
  }

  return (
    <div className="hquser__wrapper">
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {deletePermissionMessageError && (
        <MessageErrorDialog
          message={deletePermissionMessageError}
          showMessageErrorDialog={setDeletePermissionMessageError}
        />
      )}

      {loading && <ProgressDialog label={recordDelete ? t('headquarter.msgDeleteData') : t('headquarter.loading')} />}
      <ModalConfirm
        title={msgDeleteConfirm}
        showModal={showModalConfirmDelete}
        setShowModal={setShowModalConfirmDelete}
        handleFunction={deleteHqUser}
      />

      <DeleteResult
        title={
          deleteMessageType === MESSAGE_TYPE.SUCCESS
            ? t('headquarter.deleteDataSuccess')
            : t('headquarter.deleteDataError')
        }
        showModal={deletePopUp}
        handleFunction={handleConfirmDeleteResult}
        screen={'headquarter'}
      />

      {loading ? (
        <ProgressDialog label={recordDelete ? t('headquarter.msgDeleteData') : t('headquarter.loading')} />
      ) : (
        <div className="hquser__container maintain-container">
          {/* Table */}
          <TableCommon
            listColumnWidth={listColumnWidth}
            listCellHeader={listCellHeader}
            listCellBody={listCellBody}
            listDataRender={filterData}
            handleUpdate={handleUpdate}
            idProperty={'ID_MENU'}
            handleDelete={handleDeleteOnClick}
            toolbar={toolbar}
            screen={SCREEN_NAME.HEAD_QUARTER}
          />
        </div>
      )}
    </div>
  )
}

export default HeadQuarters
