import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
  maintainTable: {
    minWidth: 1050,
    overflowY: 'auto',
    tableLayout: 'fixed',
  },
  tableHead: {
    backgroundColor: '#f2f2f2',
  },
  searchWrapper: {
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
  },
  searchContainer: {
    with: '100%',
    height: '100%',
  },
  filterWrapper: {
    height: '40px',
    padding: '0 0 0 100px',
    display: 'flex',
    flexDirection: 'row',
  },
  sortedWrapper: {
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
  },
  selectSorted: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  message: {
    width: '250px',
    wordWrap: 'break-word',
    padding: '5px 0px',
  },
  textMessage: {
    fontSize: '11px',
    display: 'flex',
    alignItems: 'center',
  },
  iconUpdate: {
    padding: '10px 0px 10px 10px',
  },
  titleHead: {
    fontSize: '11px',
    color: 'rgb(85, 130, 50)',
    paddingTop: '8px',
    paddingBottom: '8px',
    borderBottom: '0',
  },
  classification: {
    paddingLeft: '11px',
  },
  textCenter: {
    textAlign: 'center',
  },
  data: {
    fontSize: '11px',
    padding: '0px 10px',
  },
  dataRight: {
    fontSize: '11px',
    padding: 0,
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 'auto',
  },
  iconAction: {
    width: '16px',
    height: '16px',
    cursor: 'pointer',
  },
  iconRedirect: {
    width: '23px',
    height: '23px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  urlData: {
    color: '#5e7bae',
    cursor: 'pointer',
    fontSize: '11px',
    padding: '16px 0px',
    display: 'flex',
    alignItems: 'center',
  },
  url: {
    paddingLeft: '0px',
  },
  title: {
    paddingLeft: '44px',
  },
  iconBuild: {
    color: 'rgb(85, 130, 50)',
    fontSize: '26px',
  },
  iconPreview: {
    color: 'rgb(85, 130, 50)',
    transform: 'scale(2.8)',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  toolbarWrapper: {
    height: '40px',
  },
  iconFilter: {
    width: '16px',
    height: '18px',
    marginRight: '15px',
  },
  iconReverse: {
    width: '20px',
    height: '20px',
  },
  iconDate: {
    width: '14px',
    height: '14px',
    position: 'absolute',
    top: '12px',
    right: '50px',
    cursor: 'pointer',
  },
  iconReturn: {
    width: '25px',
    height: '25px',
    cursor: 'pointer',
    marginRight: '15px',
  },
  email: {
    display: 'flex',
    alignItems: 'center',
  },
  emailContainer: {
    display: 'flex',
  },
  iconEmail: {
    margin: '0px 10px',
    width: '15px',
    height: '15px',
  },
  dateTitle: {
    position: 'relative',
    padding: '8px 0px',
    cursor: 'pointer',
  },
  personTitle: {
    padding: '8px 0px',
  },
  tableRow: {
    backgroundColor: '#f5faeb',
  },
  centerAlign: {
    // height: '40.04px',
    // lineHeight: '20.02px', // cố định chiều cao = 50px (yêu cầu ngày 15/11)
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // require 15/11
    height: 0,
  },
  titleData: {
    width: 'calc(100% - 35px)',
    display: 'flex',
    alignItems: 'center',
  },
  personSender: {
    display: 'flex',
    alignItems: 'center',
  },
  date: {
    display: 'flex',
    alignItems: 'center',
  },
  modalStyle: {
    height: '20px',
    width: '20px',
    margin: '0px 5px 0px 0px !important',
    color: 'black !important',
  },
  '@media (max-width: 1570px)': {
    attachFile: {
      width: '10% !important',
      textAlign: 'center',
    },
  },
}))

export { styles }
