import './CustUserCreateEdit.css'
import '../../../components/commons/FormEdits/FormEdit.css'

import { Button } from '@material-ui/core'
import { CheckSharp, DateRange, Info, ListAlt, LocalOffer, MailOutline } from '@material-ui/icons'
import {
  clearCustomerName,
  clearCustUser,
  createCustUserAction,
  getCustomerNameAction,
  updateCustUserAction,
} from 'actions/custUserActions'
import { getMenuListAction } from 'actions/menuListAction'
import { IMAGES } from 'assets'
import {
  focusNextElement,
  formatDateWithPadding,
  getDateInfoSystem,
  getSessionInfo,
  getTitleModalConfirm,
  getUserKbAdmin,
  shortenValue,
} from 'commons/utilities'
import { Image, MessageErrorDialog, ModalConfirm, ModalConfirmNavigation, SelectInput } from 'components/commons'
import CustomCheckbox from 'components/commons/CustomCheckbox/Checkbox'
// import CustomTooltip from 'components/commons/CustomTooltip/CustomTooltip'
import CustomCalendar from 'components/commons/FormInputs/CustomCalendar'
import FormInputs from 'components/commons/FormInputs/FormInputs'
import Information from 'components/commons/Information/Information'
import { PassWord } from 'components/commons/PassWord/PassWord.jsx'
import ProgressDialog from 'components/commons/ProgressDialog/ProgressDialog'
import {
  CREATE_CUSTUSER_FAILED,
  CREATE_CUSTUSER_REQUEST,
  CREATE_CUSTUSER_SUCCESS,
  GET_CUSTOMER_NAME_FAILED,
  GET_CUSTOMER_NAME_SUCCESS,
  UPDATE_CUSTUSER_FAILED,
  UPDATE_CUSTUSER_REQUEST,
  UPDATE_CUSTUSER_SUCCESS,
} from 'constants/actionTypes/custUserActionTypes'
import { GET_MENU_LIST_SUCCESS } from 'constants/actionTypes/menuListActionTypes'
import { accountDeleteMessageConfirmEdit, ACTION_FORM, FLAG_BOOLEAN, SCREEN_NAME } from 'constants/constant'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { custUserValidate } from 'validation/custUserValidate'
import { changePasswordValidateCustUser } from 'validation/hquserValidate'

import styles from './CustUserCreateEditStyles'

export default function CustUserCreateEdit() {
  const { i18n, t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = styles()
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })
  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return {}
  })
  const createCustUserReducer = useSelector((state) => state.createCustUser || {})
  const updateCustUserReducer = useSelector((state) => state.updateCustUser || {})
  let getCustomerName = useSelector((state) => state.getCustomerName || {})
  const [type, setType] = useState('CREATE')
  const [isBlocked, setIsBlocked] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [error, setError] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [dataForm, setDataForm] = useState({
    ID_USER: '',
    NM_USER: '',
    CD_CUST: '',
    NM_CUST: '',
    NM_MAIL1: '',
    NM_MAIL2: '',
    KB_TRN: '0',
    KB_DL: '0',
    ID_MENU: '',
    NM_NOTES: '',
    DT_DEADL: getDateInfoSystem().DT_DEFAULT,
    DT_REGIST: '',
    DT_UPDATE: '',
    DT_DELETE: '',
    NM_RUDUSER: userInfo.NM_USER,
    password: '',
    confirmPassword: '',
  })
  const [filteredDate, setFilteredDate] = useState(formatDateWithPadding(new Date(), 'yyyy-MM-dd'))
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState(false)
  const [customerName, setCustomerName] = useState('')
  const [onCreateUpdate, setOnCreateUpdate] = useState(false)
  const [delayRenderComponent, setDelayRenderComponent] = useState(false)
  const [onFocusCustomerId, setOnFocusCustomerId] = useState(true)
  const inputCustomerRef = useRef(null)
  const [isValidDate, setIsValidDate] = useState(true)
  const [isInDateRange, setIsInDateRange] = useState(true)
  const [menuList, setMenuList] = useState([])
  const menuListReducer = useSelector((state) => state.listMenu || {})
  const [searchInput, setSearchInput] = useState('')
  const [createPermissionMessageError, setCreatePermissionMessageError] = useState('')
  const [updatePermissionMessageError, setUpdatePermissionMessageError] = useState('')

  // handle update field portal
  const handleUpdate = (name, value) => {
    hideMessageError(name)
    setIsBlocked(true)
    if (name === 'KB_TRN') {
      setDataForm((prev) => ({ ...prev, KB_TRN: (value + 0).toString() }))
      return
    }
    if (name === 'KB_DL') {
      setDataForm((prev) => ({ ...prev, KB_DL: (value + 0).toString() }))
      return
    }
    if (name === 'CD_CUST') {
      setCustomerName('')
    }
    setDataForm((prev) => ({ ...prev, [name]: value }))
  }

  // hide message error base on form input field
  const hideMessageError = (name = '') => {
    if (name === 'NM_MAIL1') {
      setError({ ...error, ...{ email1: '' } })
    }
    if (name === 'NM_MAIL2') {
      setError({ ...error, ...{ email2: '' } })
    }
    if (name === 'ID_USER') {
      setError({ ...error, ...{ idUser: '' } })
    }
    if (name === 'CD_CUST') {
      setError({ ...error, ...{ customerID: '' } })
    }
    if (name === 'DT_DEADL') {
      setError({ ...error, ...{ deadl: '' } })
    }
    if (name === 'password') {
      setError({ ...error, ...{ errorPassword: '' } })
    }
    if (name === 'confirmPassword') {
      setError({ ...error, ...{ errorConfirmPassword: '' } })
    }
  }

  // clear and exit
  const handleCancelButton = () => {
    // setError({})
    history.push({
      pathname: '/admin/custuser',
      state: {
        date: filteredDate,
        searchInput: searchInput,
        screen: SCREEN_NAME.CUSTUSER,
      },
    })
  }

  // submit data
  const handleSubmit = async (e) => {
    e.preventDefault()
    const kbAdmin = await getUserKbAdmin(userInfo)
    if (String(kbAdmin) === FLAG_BOOLEAN.FALSE) {
      if (type === ACTION_FORM.CREATE) {
        setCreatePermissionMessageError(t('commonComponent.noCreatePermission'))
      }
      if (type === ACTION_FORM.UPDATE) {
        setUpdatePermissionMessageError(t('commonComponent.noUpdatePermission'))
      }
    } else {
      setOnCreateUpdate(true)
      const { idUser, email1, email2, deadl, customerID } = custUserValidate(
        dataForm.ID_USER,
        dataForm.NM_MAIL1,
        dataForm.NM_MAIL2,
        dataForm.DT_DEADL,
        customerName,
        DT_TODAY,
        i18n
      )
      if (idUser || email1 || email2 || deadl || customerID) {
        setError({ idUser, email1, email2, deadl, customerID })
        return
      }

      const { newPasswordError, confirmPasswordError } = changePasswordValidateCustUser(
        dataForm.password,
        dataForm.confirmPassword,
        i18n
      )
      if (dataForm.password || dataForm.confirmPassword) {
        if (newPasswordError || confirmPasswordError) {
          const errorPassword = {
            errorPassword: newPasswordError ? newPasswordError : undefined,
            errorConfirmPassword: confirmPasswordError ? confirmPasswordError : undefined,
          }
          setError({
            ...error,
            ...errorPassword,
          })
          return
        }
      }

      setShowModal(true)
      setError()
    }
  }

  const submitData = () => {
    const submitData = { ...dataForm, NM_RUDUSER: userInfo.NM_USER }
    if (type === ACTION_FORM.CREATE) dispatch(createCustUserAction(submitData, i18n))
    if (type === ACTION_FORM.UPDATE) dispatch(updateCustUserAction(submitData))
    setIsBlocked(false)
    setShowModal(false)
  }

  const createCustCondition = () => {
    if (type === ACTION_FORM.CREATE) return !dataForm.password || !dataForm.confirmPassword
    return false
  }

  const checkRequire = React.useCallback(() => {
    if (
      !dataForm.ID_USER ||
      !dataForm.NM_USER ||
      !dataForm.NM_MAIL1 ||
      !dataForm.CD_CUST ||
      !dataForm.DT_DEADL ||
      !dataForm.ID_MENU ||
      createCustCondition()
    )
      return false
    return isBlocked
  }, [dataForm, isBlocked])

  const isError = (error = {}) => {
    const { idUser, email1, email2, deadl, errorPassword, errorConfirmPassword } = error
    if (idUser || email1 || email2 || deadl || errorPassword || errorConfirmPassword) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (isError(error) || !customerName || customerName === '' || !isValidDate || !isInDateRange || !checkRequire()) {
      setCanSubmit(false)
    } else {
      setCanSubmit(true)
    }
  }, [dataForm, error, customerName, checkRequire])

  useEffect(() => {
    const { data, type, date, searchInput } = location?.state || {}
    if (searchInput) {
      setSearchInput(searchInput)
    }
    if (type) setType(type)
    if (!data) {
      setDataForm({ ...dataForm, NM_RUDUSER: userInfo.NM_USER })
    } else {
      setDataForm({ ...data, password: '', confirmPassword: '' })
    }
    if (date) setFilteredDate(date)
  }, [location])

  useEffect(() => {
    dispatch(getMenuListAction({ kbMenu: 2 }))
  }, [dispatch])

  useEffect(() => {
    if (menuListReducer.type === GET_MENU_LIST_SUCCESS) {
      let listMenu = []
      if (menuListReducer?.listMenu) {
        let temp = 0
        menuListReducer?.listMenu?.forEach((item) => {
          listMenu.push({ value: temp.toString(), label: item.NM_MENU })
          temp++
        })
      }
      setMenuList(listMenu)
    }
  }, [menuListReducer, dispatch])

  useEffect(() => {
    if (onCreateUpdate) {
      setIsBlocked(false)
    }
    setLoading(createCustUserReducer.loading || updateCustUserReducer.loading)

    switch (createCustUserReducer.type) {
      case CREATE_CUSTUSER_REQUEST:
        break
      case CREATE_CUSTUSER_FAILED:
        if (!getSessionInfo()) {
          setMessageError(createCustUserReducer.message)
        }
        break
      case CREATE_CUSTUSER_SUCCESS:
        history.push({
          pathname: '/admin/custuser',
          state: {
            date: filteredDate,
            searchInput: searchInput,
            screen: SCREEN_NAME.CUSTUSER,
          },
        })
        dispatch(clearCustUser())
        break
      default:
        break
    }

    switch (updateCustUserReducer.type) {
      case UPDATE_CUSTUSER_REQUEST:
        break
      case UPDATE_CUSTUSER_FAILED:
        if (!getSessionInfo()) {
          setMessageError(updateCustUserReducer.message)
        }

        break
      case UPDATE_CUSTUSER_SUCCESS:
        history.push({
          pathname: '/admin/custuser',
          state: {
            date: filteredDate,
            searchInput: searchInput,
            screen: SCREEN_NAME.CUSTUSER,
          },
        })
        dispatch(clearCustUser())
        break
      default:
        break
    }
    setOnCreateUpdate(false)
  }, [createCustUserReducer, updateCustUserReducer, history, dispatch])

  useEffect(() => {
    if (!onFocusCustomerId) {
      dispatch(getCustomerNameAction({ customerID: inputCustomerRef.current.value || '' }))
    } else {
      setCustomerName('')
    }
    setOnFocusCustomerId(true)
    dispatch(clearCustUser())
  }, [onFocusCustomerId, dispatch])

  useEffect(() => {
    if (getCustomerName.type === GET_CUSTOMER_NAME_SUCCESS) {
      if (getCustomerName.customerName.length > 0) {
        setCustomerName(getCustomerName.customerName[0].NM_CUST)
        setError({ ...error, ...{ customerID: '' } })
      } else {
        setCustomerName('')
        setError({ ...error, ...{ customerID: i18n.t('validate.custuser.invalidCustomerId') } })
      }
      // setCanSubmit(true)
      checkRequire()
      dispatch(clearCustomerName())
    }
    if (getCustomerName.type === GET_CUSTOMER_NAME_FAILED) {
      setCustomerName('')
      setError({ ...error, ...{ customerID: i18n.t('validate.custuser.invalidCustomerId') } })
      dispatch(clearCustomerName())
      // setCanSubmit(false)
      checkRequire()
    }
  }, [getCustomerName.customerName])

  useEffect(() => {
    if (location?.state?.type === ACTION_FORM.UPDATE) {
      setCustomerName(location.state?.data?.NM_CUST)
      setError()
    } else {
      setCustomerName('')
    }
    setIsBlocked(false)
  }, [])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDelayRenderComponent(true)
    }, 1)
    return () => {
      clearTimeout(handler)
    }
  }, [])

  const getTitleModal = (type, dataForm) => {
    const formType =
      type === ACTION_FORM.CREATE ? t('commonComponent.form.createButton') : t('commonComponent.form.updateButton')
    if (dataForm.DT_DELETE) {
      return getTitleModalConfirm(
        accountDeleteMessageConfirmEdit,
        t('formCustUser.msgConfirmSubmission.line1', {
          lblIdUser: t('formCustUser.lblIdUser'),
          idUserValue: shortenValue(dataForm.ID_USER, 10),
          formType: formType,
        }),
        t('formCustUser.msgConfirmSubmission.line2')
      )
    } else
      return getTitleModalConfirm(
        t('formCustUser.msgConfirmSubmission.line1', {
          lblIdUser: t('formCustUser.lblIdUser'),
          idUserValue: shortenValue(dataForm.ID_USER, 10),
          formType: formType,
        }),
        t('formCustUser.msgConfirmSubmission.line2')
      )
  }

  const customEventEnter = (e) => {
    if (e.code === 'Enter') {
      setShowModal(true)
      e.preventDefault()
    }
  }

  const confirmNavigationMessage = `<div class="message__pre-text"><div>
  ${t('formCustUser.msgConfirmNavigate.line1')} </div><div>
  ${t('formCustUser.msgConfirmNavigate.line2')}</div></div>`

  return (
    <div className="portal-createWrapper cust-user-wrapper">
      {
        <ModalConfirmNavigation
          title={confirmNavigationMessage}
          isBlocked={isBlocked}
          date={filteredDate}
          searchInput={searchInput}
        />
      }
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {createPermissionMessageError && (
        <MessageErrorDialog
          message={createPermissionMessageError}
          showMessageErrorDialog={setCreatePermissionMessageError}
        />
      )}
      {updatePermissionMessageError && (
        <MessageErrorDialog
          message={updatePermissionMessageError}
          showMessageErrorDialog={setUpdatePermissionMessageError}
        />
      )}
      <ModalConfirm
        title={getTitleModal(type, dataForm)}
        showModal={showModal}
        setShowModal={setShowModal}
        handleFunction={submitData}
        focusSubmit={true}
      />
      {loading && <ProgressDialog label={t('formCustUser.loading')} />}
      {delayRenderComponent && (
        <form
          onKeyDown={(e) => {
            focusNextElement(e)
          }}
        >
          <div className="portal-create form-scroll">
            <div className="portal-create-content form-min-width">
              <div className="formEdit-container">
                {/* ID_USER */}
                <FormInputs
                  id="standard-basic"
                  canSpacing={false}
                  canEnterJapanese={false}
                  label={t('formCustUser.lblIdUser')}
                  value={dataForm.ID_USER || ''}
                  required
                  icon={<CheckSharp className="icon--deactive" />}
                  type="text"
                  name="ID_USER"
                  setValue={(e) => handleUpdate('ID_USER', e)}
                  error={error && !!error.idUser}
                  helperText={error && error.idUser ? error.idUser : null}
                  className="width120"
                  disabled={type === ACTION_FORM.UPDATE}
                  errorPosition={'70px'}
                />
                {/*NM_USER  */}
                <FormInputs
                  id="standard-basic"
                  label={t('formCustUser.lblNmUser')}
                  value={dataForm.NM_USER || ''}
                  required
                  icon={<Image src={IMAGES.ICON.CARD_USER} alt="Card User Icon" className="iconForm icon--deactive" />}
                  type="text"
                  name="NM_USER"
                  setValue={(e) => handleUpdate('NM_USER', e)}
                  className="width210"
                />
                {/* CD_CUST */}
                <div className="cd-cust-container">
                  <FormInputs
                    id="standard-basic"
                    canSpacing={false}
                    canEnterJapanese={false}
                    label={t('formCustUser.lblIdCust')}
                    value={dataForm.CD_CUST || ''}
                    required
                    icon={<Image src={IMAGES.ICON.BUILDING} alt="Building Icon" className="iconForm icon--deactive" />}
                    type="text"
                    name="CD_CUST"
                    setValue={(e) => handleUpdate('CD_CUST', e)}
                    error={error && !!error.customerID}
                    helperText={error && error.customerID ? error.customerID : null}
                    className="width120"
                    errorPosition={'70px'}
                    inputCustomerRef={inputCustomerRef}
                    setOnFocusCustomerId={setOnFocusCustomerId}
                    validateAsNumberAndWord={true}
                  />
                  {/*NM_CUST  */}
                  <div className="nm-cust-container">{customerName}</div>
                </div>

                {/*NM_EMAIL1*/}
                <FormInputs
                  id="standard-basic"
                  canEnterJapanese={false}
                  label={t('formCustUser.lblEmail1')}
                  value={dataForm.NM_MAIL1 || ''}
                  required
                  icon={<MailOutline className="icon--deactive" />}
                  type="email"
                  name="NM_MAIL1"
                  setValue={(e) => handleUpdate('NM_MAIL1', e)}
                  error={error && !!error.email1}
                  helperText={error && error.email1 ? error.email1 : null}
                  className="width450"
                  errorPosition={'105px'}
                />
                {/*NM_MAIL2  */}
                <FormInputs
                  id="standard-basic"
                  canEnterJapanese={false}
                  label={t('formCustUser.lblEmail2')}
                  value={dataForm.NM_MAIL2 || ''}
                  icon={<MailOutline className="icon--deactive" />}
                  type="email"
                  name="NM_MAIL2"
                  setValue={(e) => handleUpdate('NM_MAIL2', e)}
                  error={error && !!error.email2}
                  helperText={error && error.email2 && dataForm.NM_MAIL2 ? error.email2 : null}
                  className="width450"
                  errorPosition={'105px'}
                />
                {/* Checkbox */}
                <div className="row_checkbox--send-email">
                  <CustomCheckbox
                    label={t('formCustUser.lblDeliveryNoti')}
                    checked={Boolean(Number(dataForm.KB_TRN))}
                    onChange={(e) => handleUpdate('KB_TRN', e)}
                    className="checkbox"
                  />
                  <Information
                    icon={<Info className={classes.iconInfo} />}
                    message={t('formCustUser.infoDeliveryNoti')}
                  />
                </div>
                {/* Checkbox */}
                <div className="row_checkbox--send-email">
                  <CustomCheckbox
                    label={t('formCustUser.lblUnReceivedNoti')}
                    checked={Boolean(Number(dataForm.KB_DL))}
                    onChange={(e) => handleUpdate('KB_DL', e)}
                    className="checkbox"
                  />
                  <Information
                    icon={<Info className={classes.iconInfo} />}
                    message={t('formCustUser.infoUnReceivedNoti')}
                  />
                </div>

                {/* Password  */}
                <PassWord
                  dataForm={dataForm}
                  error={error}
                  handleUpdate={handleUpdate}
                  setError={setError}
                  required={type === ACTION_FORM.CREATE ? true : false}
                  screen={SCREEN_NAME.CUSTUSER}
                />

                <SelectInput
                  id={dataForm.ID_MENU ? 'ID_MENU__selected' : 'ID_MENU__select'}
                  label={t('formHeadQuarter.lblIdMenu')}
                  required
                  value={dataForm.ID_MENU}
                  name="ID_MENU"
                  onChange={(e) => handleUpdate('ID_MENU', e.target.value)}
                  className={`textDrop ${classes.dropdownMessage}`}
                  icon={<ListAlt />}
                  items={menuList}
                />

                {/* Calendar */}
                <div className="formEdit-calendar">
                  <div className="calender-spacing">
                    <DateRange className="icon--deactive" />
                  </div>
                  <div>
                    <div className="calendar">
                      <CustomCalendar
                        className="calendar-item calendar-item-single"
                        value={dataForm?.DT_DEADL ? new Date(dataForm.DT_DEADL) : ''}
                        onChange={(date) => handleUpdate('DT_DEADL', date)}
                        setIsValidDate={setIsValidDate}
                        setIsInDateRange={setIsInDateRange}
                        label={t('formCustUser.lblExpiredDate')}
                        name="DT_DEADL"
                        id="DT_DEADL"
                        required
                        // minDate={
                        //   new Date(new Date(sysInfo.DT_TODAY).setFullYear(new Date(sysInfo.DT_TODAY).getFullYear() - 100))
                        // }
                        // maxDate={
                        //   new Date(new Date(sysInfo.DT_TODAY).setFullYear(new Date(sysInfo.DT_TODAY).getFullYear() + 100))
                        // }
                      />
                      <div className={`error-message custuser-calendar ${error && error.deadl ? '' : 'hidden'}`}>
                        {error && error.deadl ? error.deadl : null}
                      </div>
                    </div>
                  </div>
                </div>

                {/* NM_NOTES */}
                <FormInputs
                  id="standard-basic"
                  label={t('formCustUser.lblNmNotes')}
                  value={dataForm.NM_NOTES || ''}
                  multiline
                  icon={<LocalOffer className="icon--deactive" />}
                  type="text"
                  name="NM_NOTES"
                  setValue={(e) => handleUpdate('NM_NOTES', e)}
                  className="width540"
                />

                <div className="formEdit-input ">
                  <div className="input-spacing"></div>
                  <FormInputs
                    id="standard-basic"
                    canSpacing={true}
                    label={t('formCustUser.lblRuduserUpdate')}
                    value={dataForm.NM_RUDUSER || ''}
                    type="text"
                    name="NM_RUDUSER"
                    setValue={(e) => handleUpdate('NM_RUDUSER', e)}
                    disabled
                    className="row_1"
                  />
                </div>
                <div className="formEdit-input">
                  <div className="input-spacing"></div>
                  <div className="formEdit-input wrap-mb">
                    <FormInputs
                      id="standard-basic"
                      canSpacing={true}
                      label={t('formCustUser.lblDateRegist')}
                      value={
                        type === ACTION_FORM.UPDATE
                          ? dataForm.DT_REGIST &&
                            formatDateWithPadding(new Date(dataForm.DT_REGIST), 'yyyy-MM-dd HH:mm:ss')
                          : ''
                      }
                      type="text"
                      name="DT_REGIST"
                      disabled
                      className="row_2"
                    />
                    <FormInputs
                      id="standard-basic"
                      canSpacing={true}
                      label={t('formCustUser.lblDateUpdate')}
                      value={
                        type === ACTION_FORM.UPDATE && dataForm.DT_UPDATE
                          ? formatDateWithPadding(new Date(dataForm.DT_UPDATE), 'yyyy-MM-dd HH:mm:ss')
                          : ''
                      }
                      type="text"
                      name="DT_UPDATE"
                      disabled
                      className="row_2"
                    />
                  </div>
                </div>
                <div className="formEdit-input">
                  <div className="input-spacing"></div>
                  <FormInputs
                    id="standard-basic"
                    canSpacing={true}
                    label={t('formCustUser.lblDateDelete')}
                    type="text"
                    value={
                      type === ACTION_FORM.UPDATE
                        ? dataForm.DT_DELETE &&
                          formatDateWithPadding(new Date(dataForm.DT_DELETE), 'yyyy-MM-dd HH:mm:ss')
                        : ''
                    }
                    name="DT_DELETE"
                    disabled
                    className="row_2"
                  />
                </div>
              </div>
              <div className={`buttonSpace ${classes.customButtonSpace}`}>
                <Button
                  className="button submit-button"
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained"
                  disabled={!canSubmit}
                  onKeyDown={(e) => {
                    customEventEnter(e)
                  }}
                >
                  {t('formCustUser.btnSubmit')}
                </Button>
                <Button className="button clear-button" onClick={handleCancelButton} variant="outlined">
                  {t('formCustUser.btnCancel')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}
