import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Prompt, useHistory, useLocation } from 'react-router-dom'

import ModalConfirm from '../ModalConfirm/ModalConfirm'

const ModalConfirmNavigation = ({
  content,
  title,
  isBlocked = true,
  date,
  deleteData,
  screen,
  searchInput,
  sortDateStart,
  sortDateEnd,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const location = useLocation()
  const history = useHistory()
  const [lastLocation, setLastLocation] = useState(location)
  const [shouldUnload, setShouldUnload] = useState(false)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
    setShouldUnload(false)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const showModal = (nextLocation) => {
    openModal()
    setLastLocation(nextLocation)
  }

  const handleBlockedRoute = (nextLocation) => {
    if (!confirmedNavigation && isBlocked) {
      showModal(nextLocation)
      return false
    }

    return true
  }

  const handleConfirmNavigationClick = () => {
    closeModal()
    setConfirmedNavigation(true)
  }

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true)
      if (history.location && history.location.state) {
        history.push({
          pathname: lastLocation.pathname,
          state: history.location.state,
        })
      } else {
        history.push({
          pathname: lastLocation.pathname,
          state: {
            date: date,
          },
        })
      }
    }
  }, [confirmedNavigation, lastLocation, history])

  // Block non-react routes
  useEffect(() => {
    const unload = (event) => {
      if (isBlocked && !shouldUnload) {
        event.returnValue = content
      }
      if (shouldUnload) {
        event.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', unload)

    return () => window.removeEventListener('beforeunload', unload)
  }, [isBlocked, content, shouldUnload])

  return (
    <>
      <Prompt when message={handleBlockedRoute} />
      {isModalOpen && (
        <ModalConfirm
          title={title}
          showModal={isModalOpen}
          setShowModal={closeModal}
          handleFunction={() => {
            handleConfirmNavigationClick()
            deleteData && deleteData()
          }}
        />
      )}
    </>
  )
}

ModalConfirmNavigation.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string.isRequired,
  isBlocked: PropTypes.bool,
}

export default ModalConfirmNavigation
