import { CD_ALRG, CD_LENGTH } from 'constants/constant'

export const allergenValidate = (cdAllergen, dtStart, dtEnd, i18n) => {
  const messages = {}
  if ((dtStart && !new Date(dtStart).getTime()) || (dtEnd && !new Date(dtEnd).getTime()))
    messages.DATE = i18n.t('validate.allergen.msgInvalidDateFormat')
  if (dtStart && dtEnd && new Date(dtEnd) < new Date(dtStart)) {
    messages.InvalidDtEnd = i18n.t('validate.allergen.msgDtEndInvalid')
  }
  if ((cdAllergen && cdAllergen < CD_ALRG.MIN) || cdAllergen >= CD_ALRG.MAX || cdAllergen.length !== CD_LENGTH.ALRG) {
    messages.CD_ALRG = i18n.t('validate.allergen.InvalidCdAllergen')
  }
  return messages
}
