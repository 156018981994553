import {
  CLEAR_CUSTOMER,
  CREATE_CUSTOMER_FAILED,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAILED,
  DELETE_CUSTOMER_REQUEST,
  DELETE_CUSTOMER_SUCCESS,
  GET_CUSTOMERS_LIST_FAILED,
  GET_CUSTOMERS_LIST_REQUEST,
  GET_CUSTOMERS_LIST_SUCCESS,
  UPDATE_CUSTOMER_FAILED,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
} from 'constants/actionTypes/customerActionTypes'

const listCustomers = (state = { listCustomers: [] }, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_CUSTOMERS_LIST_REQUEST,
      }
    case GET_CUSTOMERS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        listCustomers: action.payload,
        type: GET_CUSTOMERS_LIST_SUCCESS,
      }
    case GET_CUSTOMERS_LIST_FAILED:
      return {
        ...state,
        loading: false,
        listCustomers: [],
        message: action.payload,
        type: GET_CUSTOMERS_LIST_FAILED,
      }
    default:
      return state
  }
}

const createCustomer = (state = { customer: {} }, action) => {
  switch (action.type) {
    case CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        customer: action.payload,
        type: CREATE_CUSTOMER_REQUEST,
      }
    case CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customer: { ...action.payload },
        type: CREATE_CUSTOMER_SUCCESS,
        message: 'success',
      }
    case CREATE_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
        customer: {},
        message: action.payload,
        type: CREATE_CUSTOMER_FAILED,
      }
    case CLEAR_CUSTOMER:
      return { type: CLEAR_CUSTOMER, loading: false, status: null, customer: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, customer: {}, message: '' }
  }
}

const updateCustomer = (state = { customer: {} }, action) => {
  switch (action.type) {
    case UPDATE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        customer: action.payload,
        type: UPDATE_CUSTOMER_REQUEST,
      }
    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customer: { ...action.payload },
        type: UPDATE_CUSTOMER_SUCCESS,
        message: 'success',
      }
    case UPDATE_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
        customer: {},
        message: action.payload,
        type: UPDATE_CUSTOMER_FAILED,
      }
    case CLEAR_CUSTOMER:
      return { type: CLEAR_CUSTOMER, loading: false, status: null, customer: {}, message: '' }
    default:
      return { ...state, loading: false, status: null, customer: {}, message: '' }
  }
}

const deleteCustomer = (state = { customer: {} }, action) => {
  switch (action.type) {
    case DELETE_CUSTOMER_REQUEST:
      return {
        ...state,
        loading: true,
        customer: action.payload,
        type: DELETE_CUSTOMER_REQUEST,
      }
    case DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        customer: { ...action.payload },
        type: DELETE_CUSTOMER_SUCCESS,
        message: 'success',
      }
    case DELETE_CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
        customer: {},
        message: action.payload,
        type: DELETE_CUSTOMER_FAILED,
      }
    case CLEAR_CUSTOMER:
      return { type: CLEAR_CUSTOMER, loading: false, status: null, customer: {}, message: '' }

    default:
      return { ...state, loading: false, status: null, customer: {}, message: '' }
  }
}

export { createCustomer, deleteCustomer, listCustomers, updateCustomer }
