import {
  CLEAR_DISTRIBUTION_DATA,
  CREATE_DISTRIBUTION_DATA_FAILED,
  CREATE_DISTRIBUTION_DATA_REQUEST,
  CREATE_DISTRIBUTION_DATA_SUCCESS,
  DELETE_DISTRIBUTION_DATA_FAILED,
  DELETE_DISTRIBUTION_DATA_REQUEST,
  DELETE_DISTRIBUTION_DATA_SUCCESS,
  GET_DISTRIBUTION_DATA_FAILED,
  GET_DISTRIBUTION_DATA_REQUEST,
  GET_DISTRIBUTION_DATA_SUCCESS,
  UPDATE_DISTRIBUTION_DATA_FAILED,
  UPDATE_DISTRIBUTION_DATA_REQUEST,
  UPDATE_DISTRIBUTION_DATA_SUCCESS,
} from 'constants/actionTypes/distributionDataActionTypes'

const listDistributionData = (state = { listDistributionData: [] }, action) => {
  switch (action.type) {
    case GET_DISTRIBUTION_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        type: GET_DISTRIBUTION_DATA_REQUEST,
      }
    case GET_DISTRIBUTION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        listDistributionData: action.payload,
        type: GET_DISTRIBUTION_DATA_SUCCESS,
      }
    case GET_DISTRIBUTION_DATA_FAILED:
      return {
        ...state,
        loading: false,
        listDistributionData: [],
        message: action.payload,
        type: GET_DISTRIBUTION_DATA_FAILED,
      }

    default:
      return state
  }
}

const createDistributionData = (state = { distributionData: {} }, action) => {
  switch (action.type) {
    case CREATE_DISTRIBUTION_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        distributionData: action.payload,
        type: CREATE_DISTRIBUTION_DATA_REQUEST,
      }
    case CREATE_DISTRIBUTION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        distributionData: { ...action.payload },
        type: CREATE_DISTRIBUTION_DATA_SUCCESS,
        message: 'success',
      }
    case CREATE_DISTRIBUTION_DATA_FAILED:
      return {
        ...state,
        loading: false,
        distributionData: {},
        message: action.payload,
        type: CREATE_DISTRIBUTION_DATA_FAILED,
      }
    case CLEAR_DISTRIBUTION_DATA:
      return { type: CLEAR_DISTRIBUTION_DATA, loading: false, status: null, listDistributionData: {}, message: '' }

    default:
      return { ...state, loading: false, status: null, distributionData: {}, message: '' }
  }
}

const updateDistributionData = (state = { distributionData: {} }, action) => {
  switch (action.type) {
    case UPDATE_DISTRIBUTION_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        distributionData: action.payload,
        type: UPDATE_DISTRIBUTION_DATA_REQUEST,
      }
    case UPDATE_DISTRIBUTION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        distributionData: { ...action.payload },
        type: UPDATE_DISTRIBUTION_DATA_SUCCESS,
        message: 'success',
      }
    case UPDATE_DISTRIBUTION_DATA_FAILED:
      return {
        ...state,
        loading: false,
        distributionData: {},
        message: action.payload,
        type: UPDATE_DISTRIBUTION_DATA_FAILED,
      }
    case CLEAR_DISTRIBUTION_DATA:
      return { type: CLEAR_DISTRIBUTION_DATA, loading: false, status: null, listDistributionData: {}, message: '' }

    default:
      return { ...state, loading: false, status: null, distributionData: {}, message: '' }
  }
}

const deleteDistributionData = (state = { distributionData: {} }, action) => {
  switch (action.type) {
    case DELETE_DISTRIBUTION_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        distributionData: action.payload,
        type: DELETE_DISTRIBUTION_DATA_REQUEST,
      }
    case DELETE_DISTRIBUTION_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        distributionData: { ...action.payload },
        type: DELETE_DISTRIBUTION_DATA_SUCCESS,
        message: 'success',
      }
    case DELETE_DISTRIBUTION_DATA_FAILED:
      return {
        ...state,
        loading: false,
        distributionData: {},
        message: action.payload,
        type: DELETE_DISTRIBUTION_DATA_FAILED,
      }
    case CLEAR_DISTRIBUTION_DATA:
      return { type: CLEAR_DISTRIBUTION_DATA, loading: false, status: null, listDistributionData: {}, message: '' }

    default:
      return { ...state, loading: false, status: null, distributionData: {}, message: '' }
  }
}

export { createDistributionData, deleteDistributionData, listDistributionData, updateDistributionData }
