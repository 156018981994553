import axios from 'commons/baseUrl'
import {
  CHECK_EXCEL_TEMPLATE_FAILED,
  CHECK_EXCEL_TEMPLATE_REQUEST,
  CHECK_EXCEL_TEMPLATE_SUCCESS,
  CLEAR_EXCEL_TEMPLATE,
  CLEAR_SQS_CONVERT_EXCEL2PDF,
  CREATE_EXCEL_TEMPLATE_FAILED,
  CREATE_EXCEL_TEMPLATE_REQUEST,
  CREATE_EXCEL_TEMPLATE_SUCCESS,
  DELETE_EXCEL_TEMPLATE_FAILED,
  DELETE_EXCEL_TEMPLATE_REQUEST,
  DELETE_EXCEL_TEMPLATE_SUCCESS,
  GET_EXCEL_TEMPLATE_LIST_FAILED,
  GET_EXCEL_TEMPLATE_LIST_REQUEST,
  GET_EXCEL_TEMPLATE_LIST_SUCCESS,
  SQS_CONVERT_EXCEL2PDF_FAILED,
  SQS_CONVERT_EXCEL2PDF_REQUEST,
  SQS_CONVERT_EXCEL2PDF_SUCCESS,
  UPDATE_EXCEL_TEMPLATE_FAILED,
  UPDATE_EXCEL_TEMPLATE_REQUEST,
  UPDATE_EXCEL_TEMPLATE_SUCCESS,
} from 'constants/actionTypes/excelTemplateActionTypes'
import {
  API_CHECK_EXCEL_TEMPLATE_EXIST_IN_DB,
  API_CREATE_EXCEL_TEMPLATE,
  API_DELETE_EXCEL_TEMPLATE,
  API_GET_EXCEL_TEMPLATE,
  API_SQS_CONVERT_EXCEL2PDF,
  API_UPDATE_EXCEL_TEMPLATE,
} from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const getExcelTemplateAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_EXCEL_TEMPLATE_LIST_REQUEST })
    const res = await axios.get(API_GET_EXCEL_TEMPLATE, { params })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_EXCEL_TEMPLATE_LIST_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data.data.length >= 0) {
      dispatch({ type: GET_EXCEL_TEMPLATE_LIST_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: GET_EXCEL_TEMPLATE_LIST_FAILED, payload: null })
  }
}

const createExcelTemplateAction = (data, i18n) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_EXCEL_TEMPLATE_REQUEST, payload: data })
    const res = await axios.post(API_CREATE_EXCEL_TEMPLATE, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: CREATE_EXCEL_TEMPLATE_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_EXISTED) {
      dispatch({
        type: CREATE_EXCEL_TEMPLATE_FAILED,
        payload: i18n.t('validate.excelTemplate.cdExcelTemplateExisted', {
          fileName: data.ID_TEMP,
        }),
      })
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: CREATE_EXCEL_TEMPLATE_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: CREATE_EXCEL_TEMPLATE_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: CREATE_EXCEL_TEMPLATE_FAILED, payload: err.message })
  }
}

const updateExcelTemplateAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_EXCEL_TEMPLATE_REQUEST, payload: data })
    const res = await axios.post(API_UPDATE_EXCEL_TEMPLATE, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: UPDATE_EXCEL_TEMPLATE_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: UPDATE_EXCEL_TEMPLATE_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: UPDATE_EXCEL_TEMPLATE_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: UPDATE_EXCEL_TEMPLATE_FAILED, payload: err.message })
  }
}

const deleteExcelTemplateAction = (data, params) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_EXCEL_TEMPLATE_REQUEST, payload: data })
    const res = await axios.post(API_DELETE_EXCEL_TEMPLATE, data)
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: DELETE_EXCEL_TEMPLATE_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: DELETE_EXCEL_TEMPLATE_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: DELETE_EXCEL_TEMPLATE_SUCCESS, payload: res.data.data })
      dispatch(getExcelTemplateAction(params))
    }
  } catch (err) {
    dispatch({ type: DELETE_EXCEL_TEMPLATE_FAILED, payload: err.message })
  }
}

const clearExcelTemplate = () => async (dispatch) => {
  dispatch({ type: CLEAR_EXCEL_TEMPLATE, payload: '' })
}

const clearExportPDF = () => async (dispatch) => {
  dispatch({ type: CLEAR_SQS_CONVERT_EXCEL2PDF, payload: '' })
}

const exportPDF = (data) => async (dispatch) => {
  try {
    dispatch({ type: SQS_CONVERT_EXCEL2PDF_REQUEST, payload: data })
    const res = await axios.post(API_SQS_CONVERT_EXCEL2PDF, data)

    if (res) {
      dispatch({ type: SQS_CONVERT_EXCEL2PDF_SUCCESS, payload: res })
    }
  } catch (err) {
    dispatch({ type: SQS_CONVERT_EXCEL2PDF_FAILED, payload: err.message })
  }
}

const checkExistExcelTemplateAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: CHECK_EXCEL_TEMPLATE_REQUEST })
    const res = await axios.get(API_CHECK_EXCEL_TEMPLATE_EXIST_IN_DB, { params })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: CHECK_EXCEL_TEMPLATE_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data) {
      dispatch({ type: CHECK_EXCEL_TEMPLATE_SUCCESS, payload: res.data })
    }
  } catch (err) {
    dispatch({ type: CHECK_EXCEL_TEMPLATE_FAILED, payload: err.message })
  }
}

export {
  checkExistExcelTemplateAction,
  clearExcelTemplate,
  clearExportPDF,
  createExcelTemplateAction,
  deleteExcelTemplateAction,
  exportPDF,
  getExcelTemplateAction,
  updateExcelTemplateAction,
}
