import {
  FORGOT_PASS_FAILED,
  FORGOT_PASS_REQUEST,
  FORGOT_PASS_SUCCESS,
} from 'constants/actionTypes/forgotPassActionTypes'

let initialState = {
  loading: true,
  message: '',
  type: FORGOT_PASS_REQUEST,
  data: {},
}
function ForgotPasswordReducer(state = { data: initialState }, action) {
  switch (action.type) {
    case FORGOT_PASS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FORGOT_PASS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
        type: FORGOT_PASS_SUCCESS,
      }
    case FORGOT_PASS_FAILED:
      return {
        ...state,
        loading: false,
        message: action.payload,
        type: FORGOT_PASS_FAILED,
      }
    default:
      return state
  }
}

export { ForgotPasswordReducer }
