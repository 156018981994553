import './TableCommon.css'

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
// import { makeStyles } from '@material-ui/core/styles'
import { CheckSharp } from '@material-ui/icons'
import { IMAGES } from 'assets'
import {
  addNewlines,
  checkExistedFileInS3,
  formatDateWithPadding,
  formatString,
  getPreSignedUrlS3,
  handleDownloadSingleFile,
  // handlePersonSenderTextDisplay,
  removeExcessCharacters,
  separateNumberByThousand,
  showDot,
} from 'commons/utilities'
import { Image } from 'components/commons'
import {
  ACTION_FORM,
  CELL_TYPE_DATA_TRANSFER,
  DATE_TYPE,
  DELIVERY_CYCLE,
  FILE_FORMAT,
  FLAG_BOOLEAN,
  ID_CELL_TYPE,
  // S3_BUCKET_URL,
  // S3_DATA_NIGHT_FUNCTION_BUCKET,
  // S3_NIGHT_FUNCTION_BUCKET_URL,
  // S3_TEMPLATE,
  SCREEN_NAME,
  SORT_TYPE,
  SPECIAL_CELL_TYPE,
  UNIT,
} from 'constants/constant'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import email from '../../../assets/img/envelope-regular.svg'
import attachIcon from '../../../assets/img/paperclip-solid.svg'
import sortedDown from '../../../assets/img/sort-down-solid.svg'
import sortedUp from '../../../assets/img/sort-up-solid.svg'
import styles from './TableCommonStyle'

function TableCommon({
  listColumnWidth = [],
  listCellHeader = [],
  listCellBody = [],
  listDataRender = [],
  handleUpdate,
  handleDelete,
  canDownloadFile = false,
  canUpdate = true,
  canDelete = true,
  hasFirstCell = true,
  hasEndCell = false,
  sortedByStartDate,
  sortedByEndDate,
  startDateSortedType,
  endDateSortedType,
  idProperty = '',
  tableWidth,
  toolbar,
  hasHeaderLine = false,
  hasRecordCounter = false,
  hasStartLineItem = false,
  startLineItem,
  screen,
  canViewDetail = false,
  handleRedirect,
  hasMiddleItem = false,
  middleItem,
  handleRedirectReissue,
  setNotExistFileInS3Error,
}) {
  const { t } = useTranslation()
  // const styles = makeStyles((theme) => ({
  //   ...tableStyles,
  // }))
  const classes = styles(listColumnWidth)

  const EmailIcon = () => <img src={email} alt="Email Icon" className={classes.iconEmail} />
  const AttachFileIcon = () => <img src={attachIcon} alt="Attach File Icon" className={classes.iconEmail} />
  const SortedByStartDateIcon = () => <img src={sortedUp} alt="Sorted Up Icon" className="date-icon" />
  const SortedByEndDateIcon = () => <img src={sortedUp} alt="Sorted Up Icon" className="date-icon" />
  const StartDateReverseIcon = () => <img src={sortedDown} alt="Sorted Up Icon" className="date-icon" />
  const EndDateReverseIcon = () => <img src={sortedDown} alt="Sorted Up Icon" className="date-icon" />
  /**
   * Get single cell in header of table
   * @return {JSX}
   */
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return null
  })
  const checkButtonDiasbledHqUser = (data) => {
    const userLocal = userInfo?.ID_USER || null
    const userRow = data['ID_USER'] || ''
    try {
      if (
        !(userLocal !== null && userLocal.toString().toLowerCase() === userRow && screen === SCREEN_NAME.HEAD_QUARTER)
      ) {
        return true
      } else {
        return false
      }
    } catch {
      return true
    }
  }
  const getCellHeader = (cellHeader = {}, index = 0) => {
    let component = (
      <TableCell className={`${classes[cellHeader.commonStyle]} ${classes[cellHeader.cellStyle]}`} key={index}>
        {t(cellHeader.columnName)}
      </TableCell>
    )

    if (cellHeader.hasOwnProperty('specialType')) {
      switch (cellHeader.specialType) {
        /* SORTED START */
        case SPECIAL_CELL_TYPE.SORTED_START:
          component = (
            <TableCell className={`${classes.titleHead}  ${classes.dateTitle}`} onClick={sortedByStartDate} key={index}>
              <div className="startDate-container">
                {t('maintenance.lblStartDate')}
                <div className="sortedIcon-container">
                  {startDateSortedType === DATE_TYPE.DEFAULT ? (
                    <>
                      <SortedByStartDateIcon />
                      <StartDateReverseIcon />
                    </>
                  ) : startDateSortedType === SORT_TYPE.INCREASE ? (
                    <StartDateReverseIcon />
                  ) : (
                    <SortedByStartDateIcon />
                  )}
                </div>
              </div>
            </TableCell>
          )
          return component
        /* SORTED END */
        case SPECIAL_CELL_TYPE.SORTED_END:
          component = (
            <TableCell className={`${classes.titleHead}  ${classes.dateTitle}`} onClick={sortedByEndDate} key={index}>
              <div className="startDate-container">
                {t('maintenance.lblEndDate')}
                <div className="sortedIcon-container">
                  {endDateSortedType === DATE_TYPE.DEFAULT ? (
                    <>
                      <SortedByEndDateIcon />
                      <EndDateReverseIcon />
                    </>
                  ) : endDateSortedType === SORT_TYPE.INCREASE ? (
                    <EndDateReverseIcon />
                  ) : (
                    <SortedByEndDateIcon />
                  )}
                </div>
              </div>
            </TableCell>
          )
          return component
        default:
          return <></>
      }
    }
    return component
  }

  const handleDownloadFile = async (bucket, key, fileName) => {
    const messageError = t('commonComponent.notExistFileInS3')
    const params = {
      bucket: bucket,
      filePath: key,
    }
    const res = await checkExistedFileInS3(params)
    if (!res.data) {
      setNotExistFileInS3Error(messageError)
    } else {
      setNotExistFileInS3Error('')
      const fileUrl = await getPreSignedUrlS3(bucket, key)
      handleDownloadSingleFile(fileUrl, fileName)
    }
  }
  const renderDownloadIcon = (canDownloadFile, data, idProperty) => {
    if (canDownloadFile) {
      return (
        <Image
          src={IMAGES.ICON.DOWNLOAD_ICON}
          className={classes.iconAction}
          onClick={() => {
            if (idProperty === 'ID_TEMP') {
              const filePath = process.env.REACT_APP_S3_TEMPLATE + data.ID_TEMP
              const bucket = process.env.REACT_APP_S3_DATA_BUCKET
              handleDownloadFile(bucket, filePath, data.ID_TEMP)
            }
            if (idProperty === 'ID_RCV_DATA') {
              const bucket = process.env.REACT_APP_S3_DATA_NIGHT_FUNCTION_BUCKET
              const filePath = data.NM_PATH + data.ID_RCV_DATA
              handleDownloadFile(bucket, filePath, data.ID_RCV_DATA)
            }
          }}
        />
      )
    } else {
      return
    }
  }

  const checkClassNameWithDownloadDetail = () => {
    if (window.location.href.includes('/download-detail')) {
      return classes.setMinWithFolderName
    } else return ''
  }
  const renderCell = (cellName, data) => {
    if (typeof cellName !== 'undefined') {
      const filterResult = ID_CELL_TYPE.filter((cell) => cell.includes(cellName))
      if (filterResult.length >= 1) {
        if (data[cellName].length > 15) {
          let substringID = data[cellName].substring(0, 15).concat('...')
          return <div className={classes.overFlowText}>{substringID}</div>
        }
      }
      // Render Cell for Data Transfer Screen
      if (cellName === CELL_TYPE_DATA_TRANSFER.NU_WAIT || cellName === CELL_TYPE_DATA_TRANSFER.NU_ALERT)
        return <div>{data[cellName] + UNIT.MINUTES}</div>
      if (cellName === CELL_TYPE_DATA_TRANSFER.KB_CYCLE) {
        switch (data.KB_CYCLE) {
          case DELIVERY_CYCLE.DAILY:
            return <div>{UNIT.DAILY}</div>
          case DELIVERY_CYCLE.WEEKLY:
            return <div>{UNIT.WEEKLY}</div>
          case DELIVERY_CYCLE.MORE_THAN_ONCE:
            return <div>{UNIT.MORE_THAN_ONCE}</div>
          default:
            return <></>
        }
      }
      if (cellName === CELL_TYPE_DATA_TRANSFER.KB_FL_FORMAT) {
        switch (data.KB_FL_FORMAT) {
          case FILE_FORMAT.TEXT:
            return UNIT.TEXT
          case FILE_FORMAT.PDF:
            return UNIT.PDF
          default:
            return <></>
        }
      }
      if (cellName === CELL_TYPE_DATA_TRANSFER.EXCEP_NUMBER) {
        return String(data[cellName]) === '0' ? <></> : <div>{data[cellName] + UNIT.QUANTITY}</div>
      }
      if (cellName === CELL_TYPE_DATA_TRANSFER.KB_DELETE) {
        return Boolean(Number(`${data[cellName]}`)) ? (
          <CheckSharp className={`${classes.iconBlur} ${classes.iconSizeDistribution} `} />
        ) : null
      }
      if (cellName.toString().toUpperCase() === 'CD_BRANCH') {
        if (data[cellName].length > 10) {
          let substringID = data[cellName].substring(0, 10).concat('...')
          return (
            <div className={classes.overFlowText} style={{ minWidth: '110%' }}>
              {substringID}
            </div>
          )
        }
      }
    }
    return <div className={classes.overFlowText}>{data[`${cellName}`]}</div>
  }

  // const renderCell1 = () => {}

  /**
   * Get single cell in body row of table
   * @return {JSX}
   */
  const getCellBody = (body = {}, index = 0, data = {}) => {
    let component = (
      <TableCell
        className={`${classes[body.cellStyle]} ${
          window.location.href.includes('custuser') ? classes.tableCustUser : ''
        }`}
        key={index}
      >
        <Grid className={`${classes[body.gridStyle]}`}>{renderCell(body.keyValue, data)}</Grid>
      </TableCell>
    )
    if (body.hasOwnProperty('specialType')) {
      switch (body.specialType) {
        /* Email */
        case SPECIAL_CELL_TYPE.EMAIL:
          component = (
            <TableCell className={classes.data} key={index}>
              <Grid className={classes.email}>
                <Grid className={classes.emailContainer}>
                  {data.KB_TITLE === t('maintenance.lblNotification') && Boolean(Number(data.KB_MLSND)) ? (
                    <EmailIcon />
                  ) : (
                    <div style={{ width: '35px' }}></div>
                  )}
                </Grid>
                <Grid className={`${classes.titleData}`}>
                  <span className={classes.overFlowText}>{showDot(data.NM_TITLE)}</span>
                </Grid>
              </Grid>
            </TableCell>
          )
          return component
        /* URL */
        case SPECIAL_CELL_TYPE.URL:
          component = (
            <TableCell className={classes.message} key={index}>
              {/* set cannot redirect when click as redirect new link in new tab */}
              {data.KB_MESSAGE === '2' ? (
                <div
                  onClick={() => {
                    window.open(data.NM_URL, '_blank')
                  }}
                  className={`${classes.urlData}`}
                >
                  <span className={classes.overFlowText}>{showDot(data.NM_URL)}</span>
                </div>
              ) : (
                <Grid className={`${classes.textMessage}`}>
                  <span className={classes.overFlowText}>{showDot(data.NM_MESSAGE)}</span>
                </Grid>
              )}
            </TableCell>
          )
          return component
        case SPECIAL_CELL_TYPE.FILE:
          component = (
            <TableCell className={`${classes.dataRight} ${classes.textCenter}`} key={index}>
              {data.LT_ATTACH?.length > 0 ? <AttachFileIcon /> : null}
            </TableCell>
          )
          return component
        /* Person */
        case SPECIAL_CELL_TYPE.PERSON:
          component = (
            <TableCell className={`${classes.dataRight}`} key={index}>
              <Grid className={`${classes.personSender}`}>
                {removeExcessCharacters(addNewlines(data.NM_SENDER, 10), 21, true)}
                {/* {handlePersonSenderTextDisplay(data.NM_SENDER)} */}
              </Grid>
            </TableCell>
          )
          return component
        /* Date Start */
        case SPECIAL_CELL_TYPE.DATE_START:
          component = (
            <TableCell className={`${classes.dataRight} `} key={index}>
              <Grid className={`${classes.date}`}>{formatDateWithPadding(new Date(data.DT_START), 'yyyy-MM-dd')}</Grid>
            </TableCell>
          )
          return component
        /* Date End */
        case SPECIAL_CELL_TYPE.DATE_END:
          component = (
            <TableCell className={`${classes.dataRight} `} key={index}>
              <Grid className={`${classes.date}`}>{formatDateWithPadding(new Date(data.DT_END), 'yyyy-MM-dd')}</Grid>
            </TableCell>
          )
          return component
        /* Date Deadl */
        case SPECIAL_CELL_TYPE.DATE_DEAL:
          component = (
            <TableCell className={`${classes.dataRight} `} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                {formatDateWithPadding(new Date(data.DT_DEADL), 'yyyy-MM-dd')}
              </Grid>
            </TableCell>
          )
          return component
        /* Check */
        case SPECIAL_CELL_TYPE.CHECK:
          component = (
            <TableCell className={`${classes.dataRightHq} `} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                {Boolean(Number(`${data[body.keyValue]}`)) ? <CheckSharp className={`${classes.iconBlur} `} /> : null}
              </Grid>
            </TableCell>
          )
          return component
        /* MAIL MAIL_ADDRESS_1 */
        case SPECIAL_CELL_TYPE.MAIL_ADDRESS_1:
          component = (
            <TableCell className={`${classes.dataRightHq} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>{data.NM_MAIL1}</Grid>
            </TableCell>
          )
          return component
        /* MAIL MAIL_ADDRESS_2 */
        case SPECIAL_CELL_TYPE.MAIL_ADDRESS_2:
          component = (
            <TableCell className={`${classes.dataRightHq} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>{data.NM_MAIL2}</Grid>
            </TableCell>
          )
          return component
        /* HH:MM */
        case SPECIAL_CELL_TYPE.HHMM:
          component = (
            <TableCell className={`${classes.data} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>{data?.TM_RCV?.substring(0, 5)}</Grid>
            </TableCell>
          )
          return component
        /* SIZE */
        case SPECIAL_CELL_TYPE.NU_SIZE:
          component = (
            <TableCell className={`${classes.data} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                <div className={classes.overFlowText}>{separateNumberByThousand(data?.NU_SIZE.toFixed(1))}</div>
                <span>{'KB'}</span>
              </Grid>
            </TableCell>
          )
          return component
        /* COUNT */
        case SPECIAL_CELL_TYPE.NU_COUNT:
          component = (
            <TableCell className={`${classes.data} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                <div className={classes.overFlowText}>{separateNumberByThousand(data?.NU_COUNT)}</div>
                <span>{'件'}</span>
              </Grid>
            </TableCell>
          )
          return component
        /* FOLDER_NAME */
        case SPECIAL_CELL_TYPE.FOLDER_NAME:
          component = (
            <TableCell className={`${classes.dataRightHq} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                <div>
                  <Link to="/admin/download-detail">
                    <span>{data.DISP_FLD}</span>
                  </Link>
                </div>
              </Grid>
            </TableCell>
          )
          return component
        /* ADD_INFO */
        case SPECIAL_CELL_TYPE.ADD_INFO:
          component = (
            <TableCell className={`${classes.dataRightHq} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                <div>
                  <Link to="/admin/download-detail">
                    <span>{data.ADD_INFO}</span>
                  </Link>
                </div>
              </Grid>
            </TableCell>
          )
          return component
        /* PRODUCT_RCV_INFO */
        case SPECIAL_CELL_TYPE.PRODUCT_RCV_INFO:
          component = (
            <TableCell className={`${classes.dataRightHq} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                <Link to="/admin/download-detail">
                  <span>{data.PRODUCT_RCV_INFO}</span>
                </Link>
              </Grid>
            </TableCell>
          )
          return component

        /* DT_SEND_MAIL */
        case SPECIAL_CELL_TYPE.DT_SEND_MAIL:
          component = (
            <TableCell className={`${classes.data} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                <span>{data?.DT_SEND_MAIL?.substring(10, 19)}</span>
              </Grid>
            </TableCell>
          )
          return component
        /* DT_DL_MAIL */
        case SPECIAL_CELL_TYPE.DT_DL_MAIL:
          component = (
            <TableCell className={`${classes.data} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                <span>{data?.DT_DL_MAIL?.substring(10, 19)}</span>
              </Grid>
            </TableCell>
          )
          return component
        /* PASSWORD */

        case SPECIAL_CELL_TYPE.PASSWORD:
          component = (
            <TableCell className={`${classes.data} ${classes.mailContainer}`} key={index}>
              <Grid className={`${classes.centerAlignHq}`}>
                {data.KB_REISSUE === FLAG_BOOLEAN.TRUE ? (
                  <span className={classes.redLink} onClick={() => handleRedirectReissue(data)}>
                    {t('custUser.lblReissue')}
                  </span>
                ) : (
                  ''
                )}
              </Grid>
            </TableCell>
          )
          return component

        default:
          return <></>
      }
    }
    return component
  }

  return (
    <div className="table-container">
      <Grid item md={12}>
        <TableContainer>
          <div className="table-scroll">
            {/* Toolbar */}
            {toolbar}
            <div className={tableWidth === 'SMALL' ? classes.smallWidthTable : classes.fullWidthTable}>
              {hasStartLineItem && <span className={classes.startLineItem}>{formatString(startLineItem, 25)}</span>}
              {hasRecordCounter && (
                <div
                  className={
                    tableWidth === 'SMALL' ? classes.recordCounterSmallWidth : classes.recordCounterCommonFullWidth
                  }
                >
                  {t('receptionData.counter', {
                    count: separateNumberByThousand(listDataRender?.length),
                  })}
                </div>
              )}
              {hasMiddleItem && (
                <span
                  className={
                    tableWidth === 'SMALL'
                      ? checkClassNameWithDownloadDetail() + ' ' + classes.middleItemSmallWidth
                      : classes.middleItemFullWidth
                  }
                >
                  {window.location.href.includes('download-detail')
                    ? formatString(middleItem)
                    : formatString(middleItem, 25)}
                </span>
              )}
              {hasHeaderLine && (
                <div className={classes.headerLine}>
                  <hr className={tableWidth === 'SMALL' ? classes.smallWidthHeaderLine : classes.fullWidthHeaderLine} />
                </div>
              )}
            </div>
            <Table
              aria-label="common table"
              className={tableWidth === 'SMALL' ? classes.smallWidthTable : classes.fullWidthTable}
            >
              {/* Col Group */}
              <colgroup>
                {listColumnWidth.map((col, index) => (
                  <col style={col !== '' ? { width: col } : {}} key={index}></col>
                ))}
              </colgroup>
              {/* Header */}
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {/* Header Cell */}
                  {listCellHeader && listCellHeader.map((cellHeader, index) => getCellHeader(cellHeader, index))}
                </TableRow>
              </TableHead>
              {/* Body */}
              <TableBody>
                {/* Body Row */}
                {listDataRender.map((data, index) => {
                  return (
                    <TableRow
                      key={index}
                      className={index % 2 === 0 ? '' : classes.tableRow}
                      style={{
                        height: window.location.href.includes('/portal-master') ? 50 : 40,
                      }}
                    >
                      {/* Body Cell */}
                      {hasFirstCell && (
                        <TableCell className={classes.iconUpdate}>
                          <Grid className={classes.iconContainer}>
                            {canUpdate && (
                              <Image
                                src={IMAGES.ICON.EDIT_ICON_TABLE_HQUSER}
                                onClick={() => handleUpdate(data[`${idProperty}`], data, ACTION_FORM.UPDATE)}
                                className={classes.iconAction}
                              />
                            )}
                            {renderDownloadIcon(canDownloadFile, data, idProperty)}
                            {canDelete && (
                              <Image
                                src={
                                  checkButtonDiasbledHqUser(data) === true
                                    ? IMAGES.ICON.DELETE_ICON_TABLE_HQUSER
                                    : IMAGES.ICON.DELETE_ICON_FORM_DISTRIBUTION
                                }
                                className={`${
                                  checkButtonDiasbledHqUser(data) === true ? '' : classes.customDiasbledButtonDelete
                                } ${classes.iconAction}`}
                                onClick={() => {
                                  handleDelete(data)
                                }}
                              />
                            )}
                            {canViewDetail && (
                              <Image
                                src={IMAGES.ICON.DETAIL}
                                className={classes.iconAction}
                                onClick={() => {
                                  handleRedirect(data)
                                }}
                              />
                            )}
                          </Grid>
                        </TableCell>
                      )}
                      {listCellBody.map((body, indexBody) => getCellBody(body, indexBody, data))}
                      {hasEndCell && (
                        <TableCell className={classes.iconUpdate}>
                          <Grid className={classes.iconContainer}>
                            {canUpdate && (
                              <Image
                                src={IMAGES.ICON.EDIT_ICON_TABLE_HQUSER}
                                onClick={() => handleUpdate(data[`${idProperty}`], data, ACTION_FORM.UPDATE)}
                                className={classes.iconAction}
                              />
                            )}
                            {renderDownloadIcon(canDownloadFile, data, idProperty)}
                            {canDelete && (
                              <Image
                                src={IMAGES.ICON.DELETE_ICON_TABLE_HQUSER}
                                className={classes.iconAction}
                                onClick={() => {
                                  handleDelete(data)
                                }}
                              />
                            )}
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </Grid>
    </div>
  )
}

export default TableCommon
