import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
  dropdownMessage: {
    fontSize: '14px !important',
  },
  margin: {
    margin: theme.spacing(1),
  },
  selectTopicPortal: {
    fontSize: '14px !important',
  },
}))

export default styles
