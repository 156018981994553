import axios from 'commons/baseUrl'
import {
  CLEAR_REISSUE,
  UPDATE_REISSUE_FAILED,
  UPDATE_REISSUE_REQUEST,
  UPDATE_REISSUE_SUCCESS,
} from 'constants/actionTypes/reissueActionTypes'
import { API_UPDATE_REISSUE } from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const updateReissueAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_REISSUE_REQUEST, payload: data })
    const res = await axios.post(API_UPDATE_REISSUE, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: UPDATE_REISSUE_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: UPDATE_REISSUE_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: UPDATE_REISSUE_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: UPDATE_REISSUE_FAILED, payload: err.message })
  }
}

const clearReissue = (data) => async (dispatch) => {
  dispatch({ type: CLEAR_REISSUE, payload: '' })
}

export { clearReissue, updateReissueAction }
