import './FormHeadQuarter.css'
import '../../../components/commons/FormEdits/FormEdit.css'
import '../../MyPage/MyPage.css'

import { Button } from '@material-ui/core'
import { CheckSharp, DateRange, Info, ListAlt, LocalOffer, MailOutline } from '@material-ui/icons'
// Lock
import { clearHquer, createHquserAction, updateHquserAction } from 'actions/headQuartersAction'
import { getMenuListAction } from 'actions/menuListAction'
import { IMAGES } from 'assets'
import {
  focusNextElement,
  formatDateWithPadding,
  getDateInfoSystem,
  getSessionInfo,
  getTitleModalConfirm,
  getUserKbAdmin,
  shortenValue,
} from 'commons/utilities'
import {
  CustomCalendar,
  CustomCheckbox,
  // CustomTooltip,
  FormInputs,
  Image,
  MessageErrorDialog,
  ModalConfirm,
  ModalConfirmNavigation,
  ProgressDialog,
  SelectInput,
} from 'components/commons'
// import PasswordFormInput from 'components/commons/FormInputs/PasswordForm'
import Information from 'components/commons/Information/Information'
import { PassWord } from 'components/commons/PassWord/PassWord.jsx'
import {
  CREATE_HEADQUARTER_FAILED,
  CREATE_HEADQUARTER_REQUEST,
  CREATE_HEADQUARTER_SUCCESS,
  UPDATE_HEADQUARTER_FAILED,
  UPDATE_HEADQUARTER_REQUEST,
  UPDATE_HEADQUARTER_SUCCESS,
} from 'constants/actionTypes/headQuartersActionTypes'
import { GET_MENU_LIST_SUCCESS } from 'constants/actionTypes/menuListActionTypes'
import { accountDeleteMessageConfirmEdit, ACTION_FORM, FLAG_BOOLEAN, SCREEN_NAME } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { changePasswordValidateHeadQuarter, hquserValidate } from 'validation/hquserValidate'

import styles from './FormHeadQuarterStyle'

export default function FormHeadQuarter() {
  const classes = styles()
  const { i18n, t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return {}
  })

  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })
  const [menuList, setMenuList] = useState([])
  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const createHquserReducer = useSelector((state) => state.createHquser || {})
  const updateHquserReducer = useSelector((state) => state.updateHquser || {})
  const [type, setType] = useState('CREATE')
  const [isBlocked, setIsBlocked] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [error, setError] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [dataForm, setDataForm] = useState({
    ID_USER: '',
    NM_USER: '',
    NM_MAIL1: '',
    NM_MAIL2: '',
    KB_DATA: '0',
    KB_SYSTEM: '0',
    NM_NOTES: '',
    ID_MENU: '',
    KB_ADMIN: '0',
    DT_DEADL: getDateInfoSystem().DT_DEFAULT,
    DT_REGIST: '',
    DT_UPDATE: '',
    DT_DELETE: '',
    NM_RUDUSER: userInfo.NM_USER,
    password: '',
    confirmPassword: '',
  })
  const [filteredDate, setFilteredDate] = useState(formatDateWithPadding(new Date(), 'yyyy-MM-dd'))
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState(false)
  const [delayRenderComponent, setDelayRenderComponent] = useState(false)
  const [isValidDate, setIsValidDate] = useState(true)
  const [isInDateRange, setIsInDateRange] = useState(true)
  const [searchInput, setSearchInput] = useState('')

  const [createPermissionMessageError, setCreatePermissionMessageError] = useState('')
  const [updatePermissionMessageError, setUpdatePermissionMessageError] = useState('')
  const menuListReducer = useSelector((state) => state.listMenu || {})

  // handle update field portal
  const handleUpdate = (name, value) => {
    // hideMessageError(name)
    setError({})
    setIsBlocked(true)
    if (name === 'KB_ADMIN') {
      setDataForm((prev) => ({ ...prev, KB_ADMIN: (value + 0).toString() }))
      return
    }
    if (name === 'KB_DATA') {
      setDataForm((prev) => ({ ...prev, KB_DATA: (value + 0).toString() }))
      return
    }
    if (name === 'KB_SYSTEM') {
      setDataForm((prev) => ({ ...prev, KB_SYSTEM: (value + 0).toString() }))
      return
    }
    setDataForm((prev) => ({ ...prev, [name]: value }))
  }
  // hide message error base on form input field
  // const hideMessageError = (name = '') => {
  //   if (name === 'ID_USER') {
  //     setError({ ...error, ...{ idUser: '' } })
  //   }
  //   if (name === 'NM_MAIL1') {
  //     setError({ ...error, ...{ email1: '' } })
  //   }

  //   if (name === 'NM_MAIL2') {
  //     setError({ ...error, ...{ email2: '' } })
  //   }

  //   if (name === 'DT_DEADL') {
  //     setError({ ...error, ...{ deadl: '' } })
  //   }
  //   if (name === 'password') {
  //     setError({ ...error, ...{ errorPassword: '' } })
  //   }
  //   if (name === 'confirmPassword') {
  //     setError({ ...error, ...{ errorConfirmPassword: '' } })
  //   }
  // }

  // clear and exit
  const handleCancelButton = () => {
    history.push({
      pathname: '/admin/head-quarters',
      state: {
        date: filteredDate,
        searchInput: searchInput,
        screen: SCREEN_NAME.HEAD_QUARTER,
      },
    })
  }

  // submit data
  const handleSubmit = async (e) => {
    e.preventDefault()
    const kbAdmin = await getUserKbAdmin(userInfo)
    if (String(kbAdmin) === FLAG_BOOLEAN.FALSE) {
      if (type === ACTION_FORM.CREATE) {
        setCreatePermissionMessageError(t('commonComponent.noCreatePermission'))
      }
      if (type === ACTION_FORM.UPDATE) {
        setUpdatePermissionMessageError(t('commonComponent.noUpdatePermission'))
      }
    } else {
      const { idUser, email1, email2, deadl } = hquserValidate(
        dataForm.ID_USER,
        dataForm.NM_MAIL1,
        dataForm.NM_MAIL2,
        dataForm.DT_DEADL,
        DT_TODAY,
        i18n
      )

      if (idUser || email1 || email2 || deadl) {
        setError({ idUser, email1, email2, deadl })
        return
      }

      const { newPasswordError, confirmPasswordError } = changePasswordValidateHeadQuarter(
        dataForm.password,
        dataForm.confirmPassword,
        i18n
      )
      if (dataForm.password || dataForm.confirmPassword) {
        if (newPasswordError || confirmPasswordError) {
          const errorPassword = {
            errorPassword: newPasswordError ? newPasswordError : undefined,
            errorConfirmPassword: confirmPasswordError ? confirmPasswordError : undefined,
          }
          setError({
            ...error,
            ...errorPassword,
          })
          return
        }
      }

      setShowModal(true)
      setError({})
    }
  }

  const submitData = () => {
    const submitData = { ...dataForm, NM_RUDUSER: userInfo.NM_USER }
    if (type === ACTION_FORM.CREATE) dispatch(createHquserAction(submitData, i18n))
    if (type === ACTION_FORM.UPDATE) dispatch(updateHquserAction(submitData))
    setIsBlocked(false)
    setShowModal(false)
  }

  const createHqCondition = () => {
    if (type === ACTION_FORM.CREATE) return !dataForm.password || !dataForm.confirmPassword
    return false
  }

  const checkRequire = React.useCallback(() => {
    if (
      !dataForm.ID_USER ||
      !dataForm.NM_USER ||
      !dataForm.NM_MAIL1 ||
      !dataForm.ID_MENU ||
      !dataForm.DT_DEADL ||
      createHqCondition()
    )
      return false
    return isBlocked
  }, [dataForm, isBlocked])

  useEffect(() => {
    if (Object.keys(error).length > 0 || !isValidDate || !isInDateRange || !checkRequire()) {
      setCanSubmit(false)
    } else {
      setCanSubmit(true)
    }
  }, [dataForm, error, checkRequire])

  useEffect(() => {
    const { data, type, date, searchInput } = location?.state || {}
    if (searchInput) {
      setSearchInput(searchInput)
    }
    if (type) setType(type)
    if (!data) {
      setDataForm({ ...dataForm, NM_RUDUSER: userInfo.NM_USER })
    } else {
      setDataForm({ ...data })
    }
    if (date) setFilteredDate(date)
  }, [location])

  useEffect(() => {
    dispatch(getMenuListAction({ kbMenu: 1 }))
  }, [dispatch])

  useEffect(() => {
    if (menuListReducer.type === GET_MENU_LIST_SUCCESS) {
      let listMenu = []
      if (menuListReducer?.listMenu) {
        let temp = 0
        menuListReducer?.listMenu?.forEach((item) => {
          listMenu.push({ value: temp.toString(), label: item.NM_MENU })
          temp++
        })
      }
      setMenuList(listMenu)
    }
  }, [menuListReducer, dispatch])

  useEffect(() => {
    setLoading(createHquserReducer?.loading || updateHquserReducer?.loading)
    setIsBlocked(false)
    switch (createHquserReducer?.type) {
      case CREATE_HEADQUARTER_REQUEST:
        break
      case CREATE_HEADQUARTER_FAILED:
        if (!getSessionInfo()) {
          setMessageError(createHquserReducer?.message)
        }
        break
      case CREATE_HEADQUARTER_SUCCESS:
        history.push({
          pathname: '/admin/head-quarters',
          state: {
            date: filteredDate,
            searchInput: searchInput,
            screen: SCREEN_NAME.HEAD_QUARTER,
          },
        })
        dispatch(clearHquer())
        break
      default:
        break
    }
    switch (updateHquserReducer?.type) {
      case UPDATE_HEADQUARTER_REQUEST:
        break
      case UPDATE_HEADQUARTER_FAILED:
        if (!getSessionInfo()) {
          setMessageError(updateHquserReducer?.message)
        }
        break
      case UPDATE_HEADQUARTER_SUCCESS:
        history.push({
          pathname: '/admin/head-quarters',
          state: {
            date: filteredDate,
            searchInput: searchInput,
            screen: SCREEN_NAME.HEAD_QUARTER,
          },
        })
        dispatch(clearHquer())
        break
      default:
        break
    }
  }, [createHquserReducer, updateHquserReducer, history, dispatch])

  useEffect(() => {
    const handler = setTimeout(() => {
      setDelayRenderComponent(true)
    }, 1)
    return () => {
      clearTimeout(handler)
    }
  }, [])

  const getTitleModal = (type, dataForm) => {
    const formType =
      type === ACTION_FORM.CREATE ? t('commonComponent.form.createButton') : t('commonComponent.form.updateButton')
    if (dataForm.DT_DELETE) {
      return getTitleModalConfirm(
        accountDeleteMessageConfirmEdit,
        t('formHeadQuarter.msgConfirmSubmission.line1', {
          lblIdUser: t('formHeadQuarter.lblIdUser'),
          idUserValue: shortenValue(dataForm.ID_USER, 10),
          formType: formType,
        }),
        t('formHeadQuarter.msgConfirmSubmission.line2')
      )
    } else
      return getTitleModalConfirm(
        t('formHeadQuarter.msgConfirmSubmission.line1', {
          lblIdUser: t('formHeadQuarter.lblIdUser'),
          idUserValue: shortenValue(dataForm.ID_USER, 10),
          formType: formType,
        }),
        t('formHeadQuarter.msgConfirmSubmission.line2')
      )
  }

  const customEventEnter = (e) => {
    if (e.code === 'Enter') {
      setShowModal(true)
      e.preventDefault()
    }
  }

  const confirmNavigationMessage = `<div class="message__pre-text"><div>
  ${t('formHeadQuarter.msgConfirmNavigate.line1')} </div><div>
  ${t('formHeadQuarter.msgConfirmNavigate.line2')}</div></div>`

  //function as handle keydown event using current component or all component

  return (
    <div className="portal-createWrapper">
      {
        <ModalConfirmNavigation
          title={confirmNavigationMessage}
          isBlocked={isBlocked}
          date={filteredDate}
          searchInput={searchInput}
        />
      }
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {createPermissionMessageError && (
        <MessageErrorDialog
          message={createPermissionMessageError}
          showMessageErrorDialog={setCreatePermissionMessageError}
        />
      )}
      {updatePermissionMessageError && (
        <MessageErrorDialog
          message={updatePermissionMessageError}
          showMessageErrorDialog={setUpdatePermissionMessageError}
        />
      )}
      {loading && <ProgressDialog label={t('formHeadQuarter.loading')} />}
      <ModalConfirm
        title={getTitleModal(type, dataForm)}
        showModal={showModal}
        setShowModal={setShowModal}
        handleFunction={submitData}
        focusSubmit={true}
      />
      {delayRenderComponent && (
        <form
          onKeyDown={(e) => {
            focusNextElement(e)
          }}
        >
          <div className="portal-create form-scroll">
            <div className="portal-create-content form-min-width">
              <div className="formEdit-container">
                <div className="id-user-container ">
                  {/* ID_USER */}
                  <FormInputs
                    id="standard-basic"
                    canSpacing={false}
                    canEnterJapanese={false}
                    label={t('formHeadQuarter.lblIdUser')}
                    value={dataForm.ID_USER || ''}
                    required
                    icon={<CheckSharp className="icon--deactive" />}
                    type="text"
                    name="ID_USER"
                    setValue={(e) => handleUpdate('ID_USER', e)}
                    error={error && !!error.idUser}
                    helperText={error && error.idUser ? error.idUser : null}
                    className="width120"
                    disabled={type === ACTION_FORM.UPDATE}
                    errorPosition={'70px'}
                  />
                  {/* Checkbox */}
                  <div className="row_checkbox--send-email">
                    <CustomCheckbox
                      label={t('formHeadQuarter.lblCheckRegister')}
                      value={Boolean(Number(dataForm.KB_ADMIN))}
                      onChange={(e) => handleUpdate('KB_ADMIN', e)}
                      className="checkbox"
                    />
                    <Information
                      icon={<Info className={classes.iconInfo} />}
                      message={t('formHeadQuarter.infoTooltipConfirmRegister')}
                    />
                  </div>
                </div>
                {/*NM_USER  */}
                <FormInputs
                  id="standard-basic"
                  label={t('formHeadQuarter.lblUserName')}
                  value={dataForm.NM_USER || ''}
                  required
                  icon={<Image src={IMAGES.ICON.CARD_USER} alt="Card User Icon" className="iconForm icon--deactive" />}
                  type="text"
                  name="NM_USER"
                  setValue={(e) => handleUpdate('NM_USER', e)}
                  className="width210"
                />

                {/*NM_EMAIL1*/}
                <FormInputs
                  id="standard-basic"
                  label={t('formHeadQuarter.lblEmail1')}
                  value={dataForm.NM_MAIL1 || ''}
                  required
                  icon={<MailOutline className="icon--deactive" />}
                  type="email"
                  name="NM_MAIL1"
                  setValue={(e) => handleUpdate('NM_MAIL1', e)}
                  error={error && !!error.email1}
                  helperText={error && error.email1 ? error.email1 : null}
                  canEnterJapanese={false}
                  className="width450"
                  errorPosition={'105px'}
                />
                {/*NM_MAIL2  */}
                <FormInputs
                  id="standard-basic"
                  label={t('formHeadQuarter.lblEmail2')}
                  value={dataForm.NM_MAIL2 || ''}
                  icon={<MailOutline className="icon--deactive" />}
                  type="email"
                  name="NM_MAIL2"
                  setValue={(e) => handleUpdate('NM_MAIL2', e)}
                  error={error && !!error.email2}
                  helperText={error && error.email2 && dataForm.NM_MAIL2 ? error.email2 : null}
                  className="width450"
                  canEnterJapanese={false}
                  errorPosition={'105px'}
                />
                {/* KB_DATA */}
                <div className="row_checkbox--send-email margin-top-30 mutiple-line">
                  <CustomCheckbox
                    label={t('formHeadQuarter.lblKbData')}
                    name="KB_DATA"
                    value={Boolean(Number(dataForm.KB_DATA))}
                    onChange={(e) => handleUpdate('KB_DATA', e)}
                    className="checkbox"
                  />

                  <Information
                    icon={<Info className={classes.iconInfo} />}
                    message={t('formHeadQuarter.lblTooltipNotifyData')}
                  />
                </div>
                {/* KB_SYSTEM */}
                <div className="row_checkbox--send-email margin-20">
                  <CustomCheckbox
                    label={t('formHeadQuarter.lblKbSystem')}
                    name="KB_SYSTEM"
                    value={Boolean(Number(dataForm.KB_SYSTEM))}
                    onChange={(e) => handleUpdate('KB_SYSTEM', e)}
                    className="checkbox"
                  />
                  <Information
                    icon={<Info className={classes.iconInfo} />}
                    message={t('formHeadQuarter.lblTooltipNotifySystem')}
                  />
                  {/* <CustomTooltip title={t('formHeadQuarter.lblTooltipNotifySystem')} placement="right-start">
                     <Info className={classes.iconInfo} />
                   </CustomTooltip> */}
                </div>
                {/* Password  */}
                {/* <div className="hq-password-header">
                  <div className="hq-password-icon">
                    {' '}
                    <Lock style={{ fontSize: 24 }} />
                  </div>
                  <div className="hq-password-title">{t('formHeadQuarter.lblPassWord')}</div>
                </div>

                  <PasswordFormInput
                    noIcon={true}
                    label={t('formHeadQuarter.lblPassWord')}
                    password={dataForm.password}
                    setPassword={(e) => handleUpdate('password', e)}
                    errorMessage={error?.errorPassword}
                    setErrorMessage={(value) => setError({ ...error, ...{ errorPassword: value } })}
                    tabIndex="1"
                    // required={true}
                  ></PasswordFormInput>
                </div>
                <div className="hq-password">
                  <div style={{ width: '39px', height: '38px' }}></div>
                  <PasswordFormInput
                    noIcon={true}
                    errorMargin={true}
                    label={t('formHeadQuarter.lblPassWordReEnter')}
                    password={dataForm.confirmPassword}
                    setPassword={(e) => handleUpdate('confirmPassword', e)}
                    errorMessage={error?.errorConfirmPassword}
                    setErrorMessage={(value) => setError({ ...error, ...{ errorConfirmPassword: value } })}
                    tabIndex="2"
                    // required={true}
                  ></PasswordFormInput>
                </div> */}

                <PassWord
                  dataForm={dataForm}
                  error={error}
                  handleUpdate={handleUpdate}
                  setError={setError}
                  required={type === ACTION_FORM.CREATE ? true : false}
                  screen={SCREEN_NAME.HEAD_QUARTER}
                  customPasswordClass="custom-password-headquarter"
                />

                <SelectInput
                  CustomDropdownClassName={'margin-top-1'}
                  id={dataForm.ID_MENU ? 'ID_MENU__selected' : 'ID_MENU__select'}
                  label={t('formHeadQuarter.lblIdMenu')}
                  required
                  value={dataForm.ID_MENU}
                  name="ID_MENU"
                  onChange={(e) => handleUpdate('ID_MENU', e.target.value)}
                  className={`textDrop ${classes.dropdownMessage}`}
                  icon={<ListAlt />}
                  items={menuList}
                />
                {/* Calendar */}
                <div className="formEdit-calendar">
                  <div className="calender-spacing">
                    <DateRange className="icon--deactive" />
                  </div>
                  <div>
                    <div className="calendar">
                      <CustomCalendar
                        className="calendar-item calendar-item-single"
                        value={dataForm?.DT_DEADL ? new Date(dataForm.DT_DEADL) : ''}
                        onChange={(date) => handleUpdate('DT_DEADL', date)}
                        setIsValidDate={setIsValidDate}
                        setIsInDateRange={setIsInDateRange}
                        label={t('formHeadQuarter.lblDataDeal')}
                        name="DT_DEADL"
                        id="DT_DEADL"
                        required
                        // minDate={
                        //   new Date(new Date(sysInfo.DT_TODAY).setFullYear(new Date(sysInfo.DT_TODAY).getFullYear() - 100))
                        // }
                        // maxDate={
                        //   new Date(new Date(sysInfo.DT_TODAY).setFullYear(new Date(sysInfo.DT_TODAY).getFullYear() + 100))
                        // }
                      />
                      <div className={`error-message hquser-calendar ${error && error.deadl ? '' : 'hidden'}`}>
                        {error && error.deadl ? error.deadl : null}
                      </div>
                    </div>
                  </div>
                </div>

                {/* NM_NOTES */}
                <FormInputs
                  id="standard-basic"
                  label={t('formHeadQuarter.lblNmNotes')}
                  value={dataForm.NM_NOTES || ''}
                  multiline
                  icon={<LocalOffer className="icon--deactive" />}
                  type="text"
                  name="NM_NOTES"
                  setValue={(e) => handleUpdate('NM_NOTES', e)}
                  className="width540"
                />

                <div className="formEdit-input ">
                  <div className="input-spacing"></div>
                  <FormInputs
                    id="standard-basic"
                    canSpacing={true}
                    label={t('formHeadQuarter.lblNmRuduser')}
                    value={dataForm.NM_RUDUSER || ''}
                    type="text"
                    name="NM_RUDUSER"
                    setValue={(e) => handleUpdate('NM_RUDUSER', e)}
                    disabled
                    className="row_1"
                  />
                </div>
                <div className="formEdit-input">
                  <div className="input-spacing"></div>
                  <div className="formEdit-input wrap-mb">
                    <FormInputs
                      id="standard-basic"
                      canSpacing={true}
                      label={t('formHeadQuarter.lblDtRegist')}
                      value={
                        type === ACTION_FORM.UPDATE
                          ? dataForm.DT_REGIST &&
                            formatDateWithPadding(new Date(dataForm.DT_REGIST), 'yyyy-MM-dd HH:mm:ss')
                          : ''
                      }
                      type="text"
                      name="DT_REGIST"
                      disabled
                      className="row_2"
                    />
                    <FormInputs
                      id="standard-basic"
                      canSpacing={true}
                      label={t('formHeadQuarter.lblDtUpdate')}
                      value={
                        type === ACTION_FORM.UPDATE && dataForm.DT_UPDATE
                          ? formatDateWithPadding(new Date(dataForm.DT_UPDATE), 'yyyy-MM-dd HH:mm:ss')
                          : ''
                      }
                      type="text"
                      name="DT_UPDATE"
                      disabled
                      className="row_2"
                    />
                  </div>
                </div>
                <div className="formEdit-input">
                  <div className="input-spacing"></div>
                  <FormInputs
                    id="standard-basic"
                    canSpacing={true}
                    label={t('formHeadQuarter.lblDtDelete')}
                    type="text"
                    value={
                      type === ACTION_FORM.UPDATE
                        ? dataForm.DT_DELETE &&
                          formatDateWithPadding(new Date(dataForm.DT_DELETE), 'yyyy-MM-dd HH:mm:ss')
                        : ''
                    }
                    name="DT_DELETE"
                    disabled
                    className="row_2"
                  />
                </div>
              </div>
              <div className={`buttonSpace ${classes.customButtonSpace}`}>
                <Button
                  className="button submit-button"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={!canSubmit}
                  onKeyDown={(e) => {
                    customEventEnter(e)
                  }}
                >
                  {t('formHeadQuarter.btnSubmit')}
                </Button>
                <Button className="button clear-button" variant="outlined" onClick={handleCancelButton}>
                  {t('formHeadQuarter.btnCancel')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  )
}
