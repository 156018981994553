import './CustomerCreateEdit.css'
import '../../../components/commons/FormEdits/FormEdit.css'

import { Button } from '@material-ui/core'
import { CheckSharp, DateRange } from '@material-ui/icons'
import { IMAGES } from 'assets'
import {
  focusNextElement,
  formatDateWithPadding,
  getDateInfoSystem,
  getSessionInfo,
  getTitleModalConfirm,
  getUserKbAdmin,
  shortenValue,
} from 'commons/utilities'
import { Image, MessageErrorDialog, ModalConfirm, ModalConfirmNavigation } from 'components/commons'
import CustomCalendar from 'components/commons/FormInputs/CustomCalendar'
import FormInputs from 'components/commons/FormInputs/FormInputs'
import ProgressDialog from 'components/commons/ProgressDialog/ProgressDialog'
import { ACTION_FORM, FLAG_BOOLEAN, SCREEN_NAME } from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { customerValidate } from 'validation/customerValidate'

import { clearCustomer, createCustomerAction, updateCustomerAction } from '../../../actions/customerActions'
import {
  CREATE_CUSTOMER_FAILED,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILED,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS,
} from '../../../constants/actionTypes/customerActionTypes'

export default function CustomerCreateEdit() {
  const { i18n, t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  // const sysInfo = useSelector((state) => {
  //   if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
  //   return {}
  // })
  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return {}
  })
  const createCustomerReducer = useSelector((state) => state.createCustomer || {})
  const updateCustomerReducer = useSelector((state) => state.updateCustomer || {})
  const [type, setType] = useState(ACTION_FORM.CREATE)
  const [isBlocked, setIsBlocked] = useState(false)
  const [canSubmit, setCanSubmit] = useState(false)
  const [errorData, setErrorData] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [dataForm, setDataForm] = useState({
    CD_CUST: '',
    NM_CUST: '',
    NU_TEL: '',

    DT_START: formatDateWithPadding(new Date(Date.now()), 'yyyy-MM-dd'),
    DT_END: getDateInfoSystem().DT_DEND,
    NM_RUDUSER: userInfo.NM_USER,
    DT_REGIST: '',
    DT_UPDATE: '',
    DT_DELETE: '',
  })
  const [filteredDate, setFilteredDate] = useState({})
  const [loading, setLoading] = useState(false)
  const [messageError, setMessageError] = useState(false)
  const [isValidStartDate, setIsValidStartDate] = useState(true)
  const [isValidEndDate, setIsValidEndDate] = useState(true)
  const [isInStartDateRange, setIsInStartDateRange] = useState(true)
  const [isInEndDateRange, setIsInEndDateRange] = useState(true)
  const [searchInput, setSearchInput] = useState('')
  const [createPermissionMessageError, setCreatePermissionMessageError] = useState('')
  const [updatePermissionMessageError, setUpdatePermissionMessageError] = useState('')
  // handle update field portal
  const handleUpdate = (name, value) => {
    setErrorData({})
    setIsBlocked(true)
    if (name === 'DT_START' || name === 'DT_END') {
      let t = dataForm
      t[name] = value
      setDataForm((prev) => ({ ...prev, ...t }))
      // setErrorData((prev) => ({ ...prev, DATE: '' }))
      return
    }
    setDataForm((prev) => ({ ...prev, [name]: value }))
  }

  // clear and exit
  const handleCancelButton = () => {
    history.push({
      pathname: '/admin/customer',
      state: {
        date: filteredDate,
        searchInput: searchInput,
        screen: SCREEN_NAME.CUSTOMER,
      },
    })
  }

  // submit data
  const handleSubmit = async (e) => {
    e.preventDefault()
    const kbAdmin = await getUserKbAdmin(userInfo)
    if (String(kbAdmin) === FLAG_BOOLEAN.FALSE) {
      if (type === ACTION_FORM.CREATE) {
        setCreatePermissionMessageError(t('commonComponent.noCreatePermission'))
      }
      if (type === ACTION_FORM.UPDATE) {
        setUpdatePermissionMessageError(t('commonComponent.noUpdatePermission'))
      }
    } else {
      const dateError = customerValidate(dataForm.DT_START, dataForm.DT_END, i18n)
      if (dateError.DATE || dateError.InvalidDtEnd) {
        setErrorData(dateError)
      } else {
        setShowModal(true)
        setErrorData({})
      }
    }
  }

  const submitData = () => {
    if (type === ACTION_FORM.CREATE) dispatch(createCustomerAction({ ...dataForm, NM_RUDUSER: userInfo.NM_USER }, i18n))
    if (type === ACTION_FORM.UPDATE) dispatch(updateCustomerAction({ ...dataForm, NM_RUDUSER: userInfo.NM_USER }))
    setIsBlocked(false)
    setShowModal(false)
  }

  const checkRequire = React.useCallback(() => {
    if (!dataForm.CD_CUST || !dataForm.NM_CUST || !dataForm.NU_TEL || !dataForm.DT_START || !dataForm.DT_END)
      return false
    return isBlocked
  }, [dataForm, isBlocked])

  useEffect(() => {
    if (
      Object.keys(errorData).length > 0 ||
      !isValidStartDate ||
      !isValidEndDate ||
      !isInStartDateRange ||
      !isInEndDateRange ||
      !checkRequire()
    ) {
      setCanSubmit(false)
    } else {
      setCanSubmit(true)
    }
  }, [dataForm, errorData, checkRequire])

  const getTitleModal = (type, dataForm) => {
    const formType =
      type === ACTION_FORM.CREATE ? t('commonComponent.form.createButton') : t('commonComponent.form.updateButton')
    return getTitleModalConfirm(
      t('formCustomer.msgConfirmSubmission.line1', {
        lblCdCust: t('formCustomer.lblCdCust'),
        cdCustValue: shortenValue(dataForm.CD_CUST, 10),
        formType: formType,
      }),
      t('formCustomer.msgConfirmSubmission.line2')
    )
  }

  useEffect(() => {
    const { data, type, date, searchInput } = location?.state || {}
    if (searchInput) {
      setSearchInput(searchInput)
    }
    if (type) setType(type)
    if (!data) {
      setDataForm({ ...dataForm, NM_RUDUSER: userInfo.NM_USER })
    } else {
      setDataForm({ ...data })
    }
    if (date) setFilteredDate(date)
  }, [location])

  useEffect(() => {
    setLoading(createCustomerReducer.loading || updateCustomerReducer.loading)
    setIsBlocked(false)
    switch (createCustomerReducer.type) {
      case CREATE_CUSTOMER_REQUEST:
      case CREATE_CUSTOMER_FAILED:
        if (!getSessionInfo()) {
          setMessageError(createCustomerReducer.message)
        }
        break
      case CREATE_CUSTOMER_SUCCESS:
        history.push({
          pathname: '/admin/customer',
          state: {
            date: filteredDate,
            searchInput: searchInput,
            screen: SCREEN_NAME.CUSTOMER,
          },
        })
        dispatch(clearCustomer())
        break
      default:
        break
    }
    switch (updateCustomerReducer.type) {
      case UPDATE_CUSTOMER_REQUEST:
        break
      case UPDATE_CUSTOMER_FAILED:
        if (!getSessionInfo()) {
          setMessageError(updateCustomerReducer.message)
        }
        break
      case UPDATE_CUSTOMER_SUCCESS:
        history.push({
          pathname: '/admin/customer',
          state: {
            date: filteredDate,
            searchInput: searchInput,
            screen: SCREEN_NAME.CUSTOMER,
          },
        })
        dispatch(clearCustomer())
        break
      default:
        break
    }
  }, [createCustomerReducer, updateCustomerReducer, history, dispatch])

  const customEventEnter = (e) => {
    if (e.code === 'Enter') {
      setShowModal(true)
      e.preventDefault()
    }
  }

  const confirmNavigationMessage = `<div class="message__pre-text"><div>
  ${t('formCustomer.msgConfirmNavigate.line1')} </div><div>
  ${t('formCustomer.msgConfirmNavigate.line2')}</div></div>`

  return (
    <div className="portal-createWrapper">
      {
        <ModalConfirmNavigation
          title={confirmNavigationMessage}
          isBlocked={isBlocked}
          date={filteredDate}
          searchInput={searchInput}
        />
      }
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {createPermissionMessageError && (
        <MessageErrorDialog
          message={createPermissionMessageError}
          showMessageErrorDialog={setCreatePermissionMessageError}
        />
      )}
      {updatePermissionMessageError && (
        <MessageErrorDialog
          message={updatePermissionMessageError}
          showMessageErrorDialog={setUpdatePermissionMessageError}
        />
      )}
      {loading && <ProgressDialog label={t('formCustomer.loading')} />}
      <ModalConfirm
        title={getTitleModal(type, dataForm)}
        showModal={showModal}
        setShowModal={setShowModal}
        handleFunction={submitData}
        focusSubmit={true}
      />
      <form
        onKeyDown={(e) => {
          focusNextElement(e)
        }}
      >
        <div className="portal-create form-scroll">
          <div className="portal-create-content form-min-width">
            <div className="formEdit-container">
              {/*CD_CUST */}
              <FormInputs
                id="standard-basic"
                canSpacing={false}
                canEnterJapanese={false}
                label={t('formCustomer.lblCdCust')}
                value={dataForm.CD_CUST || ''}
                required
                icon={<CheckSharp className="icon--deactive" />}
                type="text"
                name="CD_CUST"
                setValue={(e) => handleUpdate('CD_CUST', e)}
                className="width120"
                disabled={type === ACTION_FORM.UPDATE}
              />

              {/*NM_CUST  */}
              <FormInputs
                id="standard-basic"
                label={t('formCustomer.lblNmCust')}
                value={dataForm.NM_CUST || ''}
                required
                icon={<Image src={IMAGES.ICON.SPEAKER} alt="Speaker Icon" className="iconForm icon--deactive" />}
                type="text"
                name="NM_CUST"
                setValue={(e) => handleUpdate('NM_CUST', e)}
                className="width300"
              />

              {/*NU_TEL */}
              <FormInputs
                id="standard-basic"
                label={t('formCustomer.lblNuTel')}
                value={dataForm.NU_TEL || ''}
                canEnterJapanese={false}
                required
                icon={<Image src={IMAGES.ICON.PHONE} alt="phone Icon" className="iconForm icon--deactive" />}
                type="text"
                name="NU_TEL"
                setValue={(e) => handleUpdate('NU_TEL', e)}
                className="width120"
                onlyNumber
              />

              {/* Calendar */}
              <div className="formEdit-calendar">
                <div className="calender-spacing">
                  <DateRange className="icon--deactive" />
                </div>
                <div>
                  <div className="calendar">
                    <CustomCalendar
                      className="calendar-item calendar-item-1"
                      value={dataForm?.DT_START ? new Date(dataForm.DT_START) : ''}
                      onChange={(date) => handleUpdate('DT_START', date)}
                      setIsValidDate={setIsValidStartDate}
                      setIsInDateRange={setIsInStartDateRange}
                      label={t('formCustomer.lblStartDate')}
                      name="DT_START"
                      id="DT_START"
                      required
                      // minDate={
                      //   new Date(new Date(sysInfo.DT_TODAY).setFullYear(new Date(sysInfo.DT_TODAY).getFullYear() - 100))
                      // }
                      // maxDate={
                      //   new Date(new Date(sysInfo.DT_TODAY).setFullYear(new Date(sysInfo.DT_TODAY).getFullYear() + 100))
                      // }
                    />
                    <CustomCalendar
                      className="calendar-item calendar-item-2"
                      value={dataForm?.DT_END ? new Date(dataForm.DT_END) : ''}
                      onChange={(date) => handleUpdate('DT_END', date)}
                      setIsValidDate={setIsValidEndDate}
                      setIsInDateRange={setIsInEndDateRange}
                      label={t('formCustomer.lblEndDate')}
                      name="DT_END"
                      id="DT_END"
                      required
                      // minDate={
                      //   new Date(new Date(sysInfo.DT_TODAY).setFullYear(new Date(sysInfo.DT_TODAY).getFullYear() - 100))
                      // }
                      // maxDate={
                      //   new Date(new Date(sysInfo.DT_TODAY).setFullYear(new Date(sysInfo.DT_TODAY).getFullYear() + 100))
                      // }
                    />
                    <div className={`customer-error-message ${errorData && errorData.DATE ? '' : 'hidden'}`}>
                      {errorData && errorData.DATE ? errorData.DATE : null}
                    </div>
                    <div className={`error-message-dtEnd ${errorData && errorData.InvalidDtEnd ? '' : 'hidden'}`}>
                      {errorData && errorData.InvalidDtEnd ? errorData.InvalidDtEnd : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="formEdit-input ">
                <div className="input-spacing"></div>
                <FormInputs
                  id="standard-basic"
                  label={t('formCustomer.lblNmRuduserUpdate')}
                  value={dataForm.NM_RUDUSER || ''}
                  type="text"
                  name="NM_RUDUSER"
                  setValue={(e) => handleUpdate('NM_RUDUSER', e)}
                  disabled
                  className="row_1"
                />
              </div>
              <div className="formEdit-input">
                <div className="input-spacing"></div>
                <div className="formEdit-input wrap-mb">
                  <FormInputs
                    id="standard-basic"
                    label={t('formCustomer.lblDateRegist')}
                    value={
                      type === ACTION_FORM.UPDATE
                        ? dataForm.DT_REGIST &&
                          formatDateWithPadding(new Date(dataForm.DT_REGIST), 'yyyy-MM-dd HH:mm:ss')
                        : ''
                    }
                    type="text"
                    name="DT_REGIST"
                    disabled
                    className="row_2"
                  />
                  <FormInputs
                    id="standard-basic"
                    label={t('formCustomer.lblDateUpdate')}
                    value={
                      type === ACTION_FORM.UPDATE && dataForm.DT_UPDATE
                        ? formatDateWithPadding(new Date(dataForm.DT_UPDATE), 'yyyy-MM-dd HH:mm:ss')
                        : ''
                    }
                    type="text"
                    name="DT_UPDATE"
                    disabled
                    className="row_2"
                  />
                </div>
              </div>
              <div className="formEdit-input">
                <div className="input-spacing"></div>
                <FormInputs
                  id="standard-basic"
                  label={t('formCustomer.lblDateDelete')}
                  type="text"
                  value={
                    type === ACTION_FORM.UPDATE
                      ? dataForm.DT_DELETE && formatDateWithPadding(new Date(dataForm.DT_DELETE), 'yyyy-MM-dd HH:mm:ss')
                      : ''
                  }
                  name="DT_DELETE"
                  disabled
                  className="row_2"
                />
              </div>
            </div>
            <div className="buttonSpace">
              <Button
                className="button submit-button"
                type="submit"
                onClick={handleSubmit}
                variant="contained"
                disabled={!canSubmit}
                onKeyDown={(e) => {
                  customEventEnter(e)
                }}
              >
                {t('formCustomer.btnSubmit')}
              </Button>
              <Button className="button clear-button" onClick={handleCancelButton} variant="outlined">
                {t('formCustomer.btnCancel')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
