export const GET_DISTRIBUTION_DATA_REQUEST = 'GET_DISTRIBUTION_DATA_REQUEST'
export const GET_DISTRIBUTION_DATA_SUCCESS = 'GET_DISTRIBUTION_DATA_SUCCESS'
export const GET_DISTRIBUTION_DATA_FAILED = 'GET_DISTRIBUTION_DATA_FAILED'
export const CREATE_DISTRIBUTION_DATA_REQUEST = 'CREATE_DISTRIBUTION_DATA_REQUEST'
export const CREATE_DISTRIBUTION_DATA_FAILED = 'CREATE_DISTRIBUTION_DATA_FAILED'
export const CREATE_DISTRIBUTION_DATA_SUCCESS = 'CREATE_DISTRIBUTION_DATA_SUCCESS'
export const UPDATE_DISTRIBUTION_DATA_REQUEST = 'UPDATE_DISTRIBUTION_DATA_REQUEST'
export const UPDATE_DISTRIBUTION_DATA_FAILED = 'UPDATE_DISTRIBUTION_DATA_FAILED'
export const UPDATE_DISTRIBUTION_DATA_SUCCESS = 'UPDATE_DISTRIBUTION_DATA_SUCCESS'
export const DELETE_DISTRIBUTION_DATA_REQUEST = 'DELETE_DISTRIBUTION_DATA_REQUEST'
export const DELETE_DISTRIBUTION_DATA_FAILED = 'DELETE_DISTRIBUTION_DATA_FAILED'
export const DELETE_DISTRIBUTION_DATA_SUCCESS = 'DELETE_DISTRIBUTION_DATA_SUCCESS'
export const CLEAR_DISTRIBUTION_DATA = 'CLEAR_DISTRIBUTION_DATA'
