import './FormInputs.css'

import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { removeAllJapaneseCharacters, removeAllSpace, validateAsNumberAndWord } from 'commons/utilities'
import { KEY_CODE } from 'constants/constant'
import React from 'react'

function FormInputs({
  label,
  icon,
  defaultIcon = true,
  type,
  canSpacing = true,
  canEnterJapanese = true,
  canSubmitByEnter = false,
  inputProps,
  setValue,
  autoComplete = 'off',
  errorPosition = '50px',
  onlyNumber = false,
  inputCustomerRef,
  setOnFocusCustomerId,
  minutesLabel,
  numberMaxlength = '',
  errorTopPosition = '7%',
  ...rest
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        position: 'relative',
        '& .MuiInputLabel-formControl.Mui-required': {
          '&::after': {
            content: '"*"',
            background: '#fff',
            color: 'red',
            // borderRadius: 4,
            // border: '1px solid red',
            // padding: '2px 6px',
            fontSize: 20,
            margin: 3,
            marginRight: 20,
            position: 'absolute',
            width: 'max-content',
            top: -5,
            left: 'calc(100% + 5px)',
          },
          '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
            display: 'none',
          },
        },
        '& .MuiFormHelperText-root.Mui-error': {
          width: 'max-content',
          fontSize: 10,
          position: 'absolute',
          top: errorTopPosition,
          left: errorPosition,
        },
        '& .MuiInput-formControl.MuiInputBase-multiline.MuiInput-multiline': {
          // paddingBottom: 5,
        },
      },
    },
  }))
  const classes = useStyles()
  const onChange = (event) => {
    if (onlyNumber) {
      const re = /^[0-9\b]+$/
      if (event.target.value === '' || re.test(event.target.value)) {
        setValue(event.target.value)
      }
      return
    }
    if (rest['onlyNumberSpecial'] === true) {
      let re = /^[0-9\b]+$/
      if (event.target.value.toString().length > 3 || !re.test(event.target.value)) {
        const childValue = event.target.value.toString().substring(0, 2)
        setValue(childValue)
      } else setValue(event.target.value)
    }
    if (rest['validateAsNumberAndWord'] === true) {
      event.target.value = validateAsNumberAndWord(event.target.value)
    }
    if (!canEnterJapanese) {
      event.target.value = removeAllJapaneseCharacters(event.target.value)
    }
    if (!canSpacing) {
      event.target.value = removeAllSpace(event.target.value)
    }
    if (numberMaxlength) {
      // Regex remove all character other than 0-9
      let value = event.target.value.toString().replace(/[^0-9]+/g, '')
      if (value.length > numberMaxlength) {
        setValue(value.substring(0, numberMaxlength))
        return
      } else {
        setValue(value)
        return
      }
    }

    setValue(event.target.value)
  }
  const handleBLur = () => {
    if (typeof setOnFocusCustomerId === 'function') {
      setOnFocusCustomerId(false)
    }
  }
  const handleOnkeyPress = (event) => {
    if (!canSpacing && event.keyCode === KEY_CODE.SPACE) {
      event.target.value = removeAllSpace(event.target.value)
    }
    if (
      canSubmitByEnter === false &&
      event.code === 'Enter' &&
      event.target?.localName.toString().toLowerCase() !== 'textarea'
    ) {
      event.preventDefault()
    }
  }

  return (
    <div className={`${classes.root} wrap`}>
      {!defaultIcon ? <></> : <div className="wrap-icon">{icon}</div>}
      <TextField
        label={label}
        type={type}
        inputProps={{ ...inputProps, ...{ className: 'text-hidden short-text' } }}
        autoComplete={autoComplete}
        onChange={onChange}
        onKeyPress={handleOnkeyPress}
        spellCheck="false"
        inputRef={inputCustomerRef}
        // inputProps={{ className: 'text-hidden short-text' }}
        onBlur={handleBLur}
        multiline={window.location.href.includes('/portal-master-form') && rest['name'] !== 'NM_URL'}
        {...rest}
        // inputProps={{ className: 'short-text' }}
      />
      {minutesLabel}
    </div>
  )
}

export default FormInputs
